<template>
  <div>
    <Header />
    <div class="home-slider">
      <div class="container">
        <div class="row h-100">
          <div class="col-md-3 col-sm-3 col-12 pt-2">
            <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-text">
              <img :src="imgBackarrow" width="25" height="25" /> {{ $t("new.Back") }}
            </a>
          </div>
          <div class="col-md-6 col-sm-6 col-12 my-auto pt-15">
            <h2 class="h2-title" style="text-align: left">
              {{ $t("new.Product List") }}
            </h2>
          </div>
          <div class="col-md-3 col-sm-3 float-end pt-15"></div>
        </div>
      </div>
    </div>

    <section class="product-list-page">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-3 mb-4">
            <div class="white-box">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse1"
                      aria-expanded="true"
                      aria-controls="collapse1"
                    >
                      {{ $t("brand.Brands") }}
                    </button>
                  </h2>
                  <div
                    id="collapse1"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div
                        class="custom-control custom-checkbox"
                        v-for="option in brandAllList"
                        :key="option"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="option.id"
                          name="Brand"
                        />
                        <label class="custom-control-label ps-2" :for="option.id">{{
                          option.title
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2"
                      aria-expanded="false"
                      aria-controls="collapse2"
                    >
                      {{ $t("search.Customer Ratings") }}
                    </button>
                  </h2>
                  <div
                    id="collapse2"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="pre-review mb-2">
                        <star-rating
                          id="rating-list"
                          class="mb-1 text-center"
                          rating="0"
                          :show-rating="false"
                          :star-size="25"
                          :read-only="true"
                          @click="setRating(0)"
                          :active-color="activeColor0"
                        >
                        </star-rating>
                      </div>
                      <div class="pre-review mb-2">
                        <star-rating
                          id="rating-list"
                          class="mb-1 text-center"
                          rating="1"
                          :show-rating="false"
                          :star-size="25"
                          :read-only="true"
                          @click="setRating(1)"
                          :active-color="activeColor1"
                        >
                        </star-rating>
                      </div>
                      <div class="pre-review mb-2">
                        <star-rating
                          id="rating-list"
                          class="mb-1 text-center"
                          rating="2"
                          :show-rating="false"
                          :star-size="25"
                          :read-only="true"
                          @click="setRating(2)"
                          :active-color="activeColor2"
                        >
                        </star-rating>
                      </div>
                      <div class="pre-review mb-2">
                        <star-rating
                          id="rating-list"
                          class="mb-1 text-center"
                          rating="3"
                          :show-rating="false"
                          :star-size="25"
                          :read-only="true"
                          @click="setRating(3)"
                          :active-color="activeColor3"
                        >
                        </star-rating>
                      </div>
                      <div class="pre-review mb-2">
                        <star-rating
                          id="rating-list"
                          class="mb-1 text-center"
                          rating="4"
                          :show-rating="false"
                          :star-size="25"
                          :read-only="true"
                          @click="setRating(4)"
                          :active-color="activeColor4"
                        >
                        </star-rating>
                      </div>

                      <div class="pre-review mb-2">
                        <star-rating
                          id="rating-list"
                          class="mb-1 text-center"
                          rating="5"
                          :show-rating="false"
                          :star-size="25"
                          :read-only="true"
                          @click="setRating(5)"
                          :active-color="activeColor5"
                        >
                        </star-rating>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      {{ $t("productList.Max Price") }}
                    </button>
                  </h2>
                  <div
                    id="collapse3"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="m-3">
                        <label for="minprice_range" class="text-secondary font-14">{{
                          $t("productList.Min Price")
                        }}</label>
                        <VueSlider v-model="searchObj.from_price" min="0" max="1000" />
                        <div class="d-flex justify-content-between">
                          <span
                            ><span class="range-value">{{ symbol }} 0</span></span
                          >
                          <span class="max-value"
                            >{{ symbol }}
                            {{
                              searchObj.from_price == 1000
                                ? "1000+"
                                : searchObj.from_price
                            }}</span
                          >
                        </div>
                      </div>

                      <div class="m-3">
                        <label for="maxprice_range" class="text-secondary font-14">{{
                          $t("productList.Max Price")
                        }}</label>
                        <VueSlider v-model="searchObj.to_price" min="0" max="1000" />
                        <div class="d-flex justify-content-between">
                          <span
                            ><span class="range-value">{{ symbol }} 0</span></span
                          >
                          <span class="max-value"
                            >{{ symbol }}
                            {{
                              searchObj.to_price == 1000 ? "1000+" : searchObj.to_price
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse4"
                      aria-expanded="false"
                      aria-controls="collapse4"
                    >
                      {{ $t("productList.Select") }} {{ $t("search.Customer Reviews") }}
                    </button>
                  </h2>
                  <div
                    id="collapse4"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="form-check" style="margin-left: 3px">
                        <input
                          class="form-check-input"
                          v-model="searchObj.review"
                          type="radio"
                          value="YES"
                          id="flexCheckDefaultReviewYes"
                        />
                        <label class="form-check-label" for="flexCheckDefaultReviewYes">
                          {{ $t("new.Yes") }}
                        </label>
                      </div>

                      <div class="form-check" style="margin-left: 3px">
                        <input
                          class="form-check-input"
                          v-model="searchObj.review"
                          type="radio"
                          value="NO"
                          id="flexCheckDefaultReviewNo"
                        />
                        <label class="form-check-label" for="flexCheckDefaultReviewNo">
                          {{ $t("new.No") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item" v-for="(item, i) in filterList" :key="i">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#collapse' + (i + 5)"
                      aria-expanded="false"
                      :aria-controls="'#collapse' + (i + 5)"
                    >
                      {{ item.title }}
                    </button>
                  </h2>
                  <div
                    :id="'collapse' + (i + 5)"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div
                        class="custom-control custom-checkbox"
                        v-for="option in item.optionList"
                        :key="option"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          :value="option.id"
                          :id="option.id"
                          name="dashboardStudent"
                        />
                        <label class="custom-control-label ps-2" :for="option.id">{{
                          option.title
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <button type="button" class="unfill-btn" v-on:click="clearFilter()">
                  {{ $t("new.Clear") }}
                </button>
                <button type="button" class="fill-btn" v-on:click="getProductList(0)">
                  {{ $t("new.Apply") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-9 col-sm-9">
            <div class="row">
              <div
                class="col-md-4 col-sm-4 mb-4 col-6 text-center"
                v-for="(data, index) in productList"
                :key="index"
                v-on:click="$router.push('/product/' + data.slug)"
              >
                <div class="white-box">
                  <a href="javascript:void(0)" class="text-decoration">
                    <div>
                      <img
                        :src="data.image ? data.image : productPlaceholder"
                        class="img-fluid list-img"
                      />
                    </div>
                    <div class="product-title pt-3 pb-2">{{ data.name }}</div>
                    <div class="d-flex justify-content-center">
                      <star-rating
                        id="expert-list"
                        class="mb-1 text-center"
                        :increment="0.5"
                        :rating="data.avg_rating"
                        :show-rating="false"
                        :star-size="15"
                        :read-only="true"
                      ></star-rating
                      ><span class="font-14 ps-2 pt-1"
                        >({{ data.number_of_ratings ? data.number_of_ratings : 0 }})</span
                      >
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <ErrorComponent @retry="getProductList(0)" ref="errorComponent" />

            <div class="row mx-0 mb-4">
              <div class="col-lg-12 mt-5">
                <div class="page-bottom-pagination">
                  <Pagination
                    @page-change="pageChange"
                    @items-per-page-change="itemsPerPageChange"
                    ref="listPagination"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script setup>
import imgBackarrow from "../../../public/static/img/back-arrow.svg";
import productPlaceholder from "../../../public/static/img/productPlaceholder.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ErrorComponent from "@/components/ErrorComponent";
import StarRating from "vue-star-rating";
import Pagination from "@/components/Pagination.vue";
import VueSlider from "vue-3-slider-component";
import $ from "jquery";
export default {
  name: "ProductListPage",
  components: { Header, Footer, ErrorComponent, StarRating, Pagination, VueSlider },
  data() {
    return {
      count: "",
      isLoader: false,
      productList: [],
      itemsPerPage: 12,
      offset: 0,
      type: this.$route.params.type,
      searchObj: {
        keyword: "",
        offset: 0,
        slug: this.$route.params.slug,
        brands: [],
        to_price: 0,
        from_price: 0,
        order_by: "",
        sort_by: "",
        brand_id: "",
        categorie_id: "",
        rating: "",
        review: "",
      },
      brandAllList: [],
      subCategoryList: [],
      categoryList: [],
      filterList: [],
      categorie_type: "",
      activeColor5: "",
      activeColor4: "",
      activeColor3: "",
      activeColor2: "",
      activeColor1: "",
      activeColor0: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.fullPath != from.fullPath) {
        this.productList = [];
        this.slug = this.$route.params.slug;
        this.searchObj.slug = this.$route.params.slug;
        this.getFilterList();
        this.getAllBrandList();
        document.documentElement.scrollTop = 0;
      }
    },
  },
  mounted() {
    this.getMetaContent();
    this.getAllBrandList();
    this.getFilterList();
  },
  methods: {
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/7",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    setRating(rating) {
      this.searchObj.rating = rating;
      if (rating == 0) {
        this.activeColor0 = "#ffc107";
        this.activeColor1 = "";
        this.activeColor2 = "";
        this.activeColor3 = "";
        this.activeColor4 = "";
        this.activeColor5 = "";
      } else if (rating == 1) {
        this.activeColor0 = "";
        this.activeColor1 = "#ffc107";
        this.activeColor2 = "";
        this.activeColor3 = "";
        this.activeColor4 = "";
        this.activeColor5 = "";
      } else if (rating == 2) {
        this.activeColor0 = "";
        this.activeColor1 = "";
        this.activeColor2 = "#ffc107";
        this.activeColor3 = "";
        this.activeColor4 = "";
        this.activeColor5 = "";
      } else if (rating == 3) {
        this.activeColor0 = "";
        this.activeColor1 = "";
        this.activeColor2 = "";
        this.activeColor3 = "#ffc107";
        this.activeColor4 = "";
        this.activeColor5 = "";
      } else if (rating == 4) {
        this.activeColor0 = "";
        this.activeColor1 = "";
        this.activeColor2 = "";
        this.activeColor3 = "";
        this.activeColor4 = "#ffc107";
        this.activeColor5 = "";
      } else if (rating == 5) {
        this.activeColor0 = "";
        this.activeColor1 = "";
        this.activeColor2 = "";
        this.activeColor3 = "";
        this.activeColor4 = "";
        this.activeColor5 = "#ffc107";
      }
    },
    pageChange(offset) {
      this.getProductList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getProductList(0);
    },
    addFavorite(data) {
      if (localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        var obj = {};
        obj.product_id = data.id;
        this.$api
          .webRequest({
            _method: "POST",
            _action: "/favourite",
            _body: obj,
          })
          .then((res) => {
            this.$toast.success(res.message, { position: "top-right" });
            if (data.is_fav == "1") {
              data.is_fav = "0";
            } else {
              data.is_fav = "1";
            }
          });
      }
    },
    getProductList(offset) {
      this.productList = [];
      this.$refs.errorComponent.updateListLoader(true);
      this.isLoader = true;
      this.searchObj.offset = offset;
      this.searchObj.limit = this.itemsPerPage;
      this.searchObj.slug = this.$route.params.slug;

      if (this.searchObj.to_price == 0) {
        this.searchObj.to_price = "";
      } else {
        this.searchObj.to_price = parseInt(this.searchObj.to_price);
      }

      if (this.searchObj.from_price == 0) {
        this.searchObj.from_price = "";
      } else {
        this.searchObj.from_price = parseInt(this.searchObj.from_price);
      }

      var brand_array = [];
      brand_array = $("input[name='Brand']:checked")
        .map(function () {
          return this.value;
        })
        .get();
      this.searchObj.brands = brand_array;

      var student_array = [];
      student_array = $("input[name='dashboardStudent']:checked")
        .map(function () {
          return this.value;
        })
        .get();
      this.searchObj.attribute_master_ids = student_array;

      var url = "/product/list/categorie-web/" + this.searchObj.slug;
      if (this.type == "category") {
        url = "/product/list/categorie-web/" + this.searchObj.slug;
      } else if (this.type == "brand") {
        url = "/product/by/brand-slug-web/" + this.searchObj.slug;
      }

      if (this.searchObj.slug) {
        this.$api
          .webRequest({
            _method: "POST",
            _action: url,
            _body: this.searchObj,
          })
          .then((res) => {
            this.isLoader = false;
            this.count = res.count;
            for (var i in res.list) {
              res.list[i].name = this.$helperService.getName(res.list[i].name);
              this.productList.push(res.list[i]);
            }
            this.offset = res.next_offset;

            if (this.type == "brand" && res.info) {
              $("input[id=" + res.info.id + "]:checkbox").prop("checked", true);
            }

            if (this.productList.length > 0) {
              this.categorie_type = res.type;
              if (res.type == "MAIN_CATEGORY") {
                this.getCategoryList(res.categorie_id);
              }

              if (res.type == "CATEGORY") {
                this.getSubCategoryList(res.categorie_id);
              }
            }

            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(
                res,
                this.$i18n.t("productList.Product not available")
              );
              this.$refs.errorComponent.updateListLoader(false);
            }

            if (offset == 0) {
              if (this.$refs.listPagination) {
                if (res.count != null && res.count > 0) {
                  this.$refs.listPagination.setTotalCount(res.count, this.itemsPerPage);
                } else {
                  this.$refs.listPagination.setTotalCount(0);
                }
              }
            }

            this.isLoader = false;
          })
          .catch(() => {
            this.isLoader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateListLoader(false);
            }
          });
      }
    },
    getFilterList() {
      var url = "/product/attributes/" + this.searchObj.slug;
      if (this.type == "category") {
        url = "/product/attributes/" + this.searchObj.slug;
      } else if (this.type == "brand") {
        url = "/brand/attributes/" + this.searchObj.slug;
      }
      if (this.searchObj.slug) {
        this.$api
          .webRequest({
            _method: "POST",
            _action: url,
            _body: this.searchObj,
          })
          .then((res) => {
            this.isLoader = false;
            this.filterList = res.list;
            this.isLoader = false;

            for (var i = 0; i < this.filterList.length; i++) {
              this.filterList[i].selected_id = "";
            }
          })
          .catch(() => {
            this.isLoader = false;
          });
      }
    },
    getAllBrandList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/brand/list",
        })
        .then((res) => {
          this.brandAllList = res.list;
          this.getProductList(0);
        })
        .catch(() => {});
    },
    getSubCategoryList(id) {
      this.$api
        .getAPI({
          _action: "/sub-category/list",
          _body: { category_id: id },
        })
        .then((res) => {
          this.subCategoryList = res.list;
        })
        .catch(() => {});
    },

    getCategoryList(id) {
      this.$api
        .getAPI({
          _action: "/sub-category/list",
          _body: { category_id: id },
        })
        .then((res) => {
          this.categoryList = res.list;
        })
        .catch(() => {});
    },

    clearFilter() {
      this.searchObj.to_price = "";
      this.searchObj.from_price = "";
      $("input[name='Brand']:checkbox").prop("checked", false);
      this.searchObj.brands = [];
      $("input[name='dashboardStudent']:checkbox").prop("checked", false);
      this.searchObj.attribute_master_ids = [];
      this.searchObj.rating = "";
      this.searchObj.review = "";
      this.getProductList(0);
    },
  },
};
</script>
