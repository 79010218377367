<template>
  <div>
    <div class="post-title py-4"><img :src="imgFollow" />&nbsp; {{ $t("new.Follow the Topic") }}</div>
    <div class="sm-card mb-3" v-for="(data, index) in list" :key="index">
      <div class="row h-100">
        <div class="col-md-2 col-sm-3 col-2">
          <img
            :src="data.image ? data.image : placeholder"
            width="50"
            height="50"
          />
        </div>
        <div class="col-md-7 col-sm-7 col-6 pl25 my-auto">
          <span class="cate-title">{{ data.title }}</span>
        </div>
        <div class="col-md-3 col-sm-4 col-4 my-auto"  v-if="data.is_follow == 0" v-on:click="follow(data.id)">
          <a href="javascript:void(0)" class="follow-btn">+ {{ $t("new.Follow") }}</a>
        </div>
        <div class="col-md-3 col-sm-3 col-4 my-auto" v-if="data.is_follow == 1" v-on:click="follow(data.id)">
          <a href="javascript:void(0)" class="unfollow-btn">{{ $t("new.UnFollow") }}</a>
        </div>
      </div>
    </div>
    <div class="sm-card mb-3" v-if="list.length < 1"><strong>{{ $t("new.No topics found") }}</strong></div>
  </div>
</template>

<script setup>
import imgFollow from "../../public/static/img/follow.svg";
import placeholder from "../../public/static/img/hotpost-placeholder.png";
</script>

<script>
export default {
  name: "FollowTheTopic",
  data() {
    return {
      list: [],
      searchObj: {
        keyword: "",
        offset: 0,
      },
    };
  },
  computed: {
    user() {
      return this.$storeService.getters.getUser;
    },
  },

  watch: {
    user(newValue) {
      console.log(newValue);
      if (localStorage.getItem("access_token")) {
        this.getList();
      }
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    follow(id) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.followUser(id);
      }
    },
    followUser(id) {
      var obj = {};
      obj.topic_id = id;
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/follow/topic",
          _body: obj,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.getList();
        });
    },
    getList() {
      this.list = [];
      this.isLoader = true;

      this.$api
        .webRequest({
          _method: "GET",
          _action: "topic/all",
          _body: this.searchObj,
        })
        .then((res) => {
          this.isLoader = false;
          this.list = res.list;
        })
        .catch(() => {
          this.isLoader = false;
        });
    },
  },
};
</script>

