import {
    defineRule
} from "vee-validate";

defineRule("required", (value, [name, isSelect]) => {
    if (!value || value.length <= 0) {
        if (name) {
            if (!isSelect || isSelect == false) {
                return `Please enter ${name}`;
            } else {
                return `Please select ${name}`;
            }
        }
        return "This field is required";
    }
    return true;
});

defineRule("message", (value, [name]) => {
    if (value && value == "select") {
        return `Please select ${name}`;
    }
    return true;
});

defineRule("email", (value) => {
    if (
        value &&
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
        )
    ) {
        return "Please enter valid email";
    }
    return true;
});

defineRule("phone", (value) => {
    if (value && !/^[0-9]{8,12}$/.test(value)) {
        return "Please enter valid contact number";
    }
    return true;
});


defineRule("pincode", (value) => {
    if (value && !/^[0-9]{6}$/.test(value)) {
        return "Please enter valid pin code";
    }
    return true;
});

defineRule("userName", (value, [name]) => {
    if (value && !/^[A-Za-z][A-Za-z_]{1,}$/.test(value)) {
        if (name) {
            return `Please enter valid ${name}`;
        }
        return "Please enter valid name";
    }
    return true;
});

defineRule("minLength", (value, [limit]) => {
    if (value.length < limit) {
        return `This field must be at least ${limit} characters`;
    }
    return true;
});

defineRule("password", (value) => {
    if (value && !/(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value)) {
        return "Password must contain at least eight characters, at least one number and both lower and uppercase letters and special characters";
    }
    return true;
});

defineRule("confirmed", (value, [target]) => {
    if (value === target) {
        return true;
    }
    return "Passwords must match";
});
defineRule("aadhaar", (value) => {
    if (value && ! /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/.test(value)) {
       return "Please enter valid aadhar no"
    }
    return true;
});
defineRule("PAN", (value) => {
    if (value && ! /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value)) {
       return "Please enter valid PAN no"
    }
    return true;
});

defineRule("price", (value) => {
    if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
        return "Please enter valid price";
    }
    return true;
});

defineRule("numeric", (value) => {
    if (value && !/^[0-9]\d*(\.\d{1,2}?)?$/.test(value)) {
        return "Please enter numeric values only";
    }
    return true;
});

defineRule("multiply", (value) => {
    if (value.length < 4) {
        return "Value must be minimum 5000";
    } else if (value % 5000 === 0) {
        return true;
    } else {
        return "Value must be multiple of 5000";
    }
});

defineRule("minValue", (value, [limit]) => {
    if (value < limit) {
        return `This field minimum value is  ${limit}`;
    }
    return true;
});

defineRule("maxValue", (value, [limit]) => {
    console.log(value);
    console.log(limit);
    console.log(value > limit);
    console.log("============================");
    if (parseInt(value) > parseInt(limit)) {
        return `This field maximum value is  ${limit}`;
    }
    return true;
});