<template>
  <div>
    <Header />
    <section class="product-detail-page">
      <div class="container">
        <div class="col-md-12 col-sm-12">
          <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-text">
            <img :src="imgBackarrow" width="25" height="25" /> {{ $t("new.Back") }}
          </a>
        </div>
      </div>
      <section class="container pt-5" v-if="productDetail.name">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="card-box">
              <div class="row h-100">
                <div class="col-md-6 col-sm-6 text-center my-auto mb-3">
                  <img
                    :src="productDetail.image ? productDetail.image : productPlaceholder2"
                    class="img-fluid"
                  />
                </div>
                <div class="col-md-6 col-sm-6">
                  <h1 style="text-align: left" class="mb-0">
                    {{ productDetail.name }}
                    <span class="fl-right">
                      <a
                        href="#"
                        v-if="productDetail.is_fav == '0'"
                        v-on:click="addFavorite(productDetail)"
                        ><img :src="imgHeart" width="25" height="25" class="me-2"
                      /></a>
                      <a
                        href="#"
                        v-if="productDetail.is_fav == '1'"
                        v-on:click="addFavorite(productDetail)"
                        ><img :src="imgFillHeart" width="25" height="25" class="me-2"
                      /></a>
                      <a
                        href="#"
                        v-on:click="
                          showSharePopup(productDetail.title, productDetail.description)
                        "
                        ><img :src="imgShare" width="25" height="25"
                      /></a>
                    </span>
                  </h1>
                  <h6 v-if="productDetail.description">
                    {{ $t("productDetail.Description") }}
                  </h6>
                  <p>{{ productDetail.description }}</p>
                  <div class="d-flex mt-3">
                    <div class="mr-4">
                      <span class="sub-title">{{ $t("myProfile.EAN Code") }}</span
                      ><br />
                      <b style="color: #101828">{{ productDetail.barcode }}</b>
                    </div>
                    <div>
                      <span class="sub-title">{{ $t("productList.Price") }}</span
                      ><br />
                      <b style="color: #00ba75">
                        {{ productDetail.symbol ? productDetail.symbol : "USD" }}
                        {{ productDetail.price }}</b
                      >
                    </div>
                  </div>
                  <div class="text-left pt-1 d-flex">
                    <star-rating
                      id="expert-list"
                      class="mb-1 text-left"
                      style="justify-content: left"
                      :increment="1"
                      :rating="productDetail.avg_rating"
                      :show-rating="false"
                      :star-size="15"
                      :read-only="true"
                    ></star-rating>
                    <span class="sub-title ps-2 pt-2"
                      >({{ productDetail.total_user_rated }})</span
                    >
                  </div>
                  <div v-if="!productDetail.is_reviwed" v-on:click="showPopup(0)">
                    <a class="write-review"
                      >{{ $t("new.Write to Review") }}
                      <img :src="imgWhitearrow" width="20" height="20"
                    /></a>

                    <a
                      href="#reviewModal"
                      class="write-review"
                      data-bs-toggle="modal"
                      id="writeReview"
                      hidden="true"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container pt-4" v-if="ingredientList.length">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header h2-title" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    style="background: none; border-radius: 0; border: 0"
                  >
                    {{ $t("new.Ingredients") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <template v-for="(item, i) in ingredientList">
                      <span v-if="item.ingredient" :key="item.id"
                        >{{ item.ingredient.name
                        }}<span v-if="ingredientList.length != i + 1">, </span>
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container pt-5" v-if="reviewList.length > 0">
        <div class="row">
          <div class="col-md-6 col-sm-6 mb-4">
            <h2 class="h2-title text-start">{{ $t("review.Reviews") }}</h2>
          </div>
          <div class="col-md-6 col-sm-6 mb-4">
            <select
              id="rating"
              class="form-select w-auto ms-auto"
              name="rating"
              v-model="searchObj.country"
              @change="getReviewList(0)"
            >
              <option value="NOT_ALL">
                {{ $t("productDetail.Same Country") }}
              </option>
              <option value="ALL">{{ $t("productDetail.View All ") }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-6 col-sm-6 mb-4 text-start"
            v-for="(item, index) in reviewList"
            :key="index"
          >
            <div class="white-box" style="min-height: 230px">
              <div
                class="d-flex"
                style="cursor: pointer"
                v-on:click="$router.push('/user-info/' + item.customer.uuid)"
              >
                <div class="mr-3">
                  <img
                    :src="
                      item.customer
                        ? item.customer.image
                          ? item.customer.image
                          : profilePlaceholder
                        : profilePlaceholder
                    "
                    class="img-fluid"
                    style="border-radius: 100px"
                    width="45"
                    height="45"
                  />
                </div>
                <div>
                  <span class="client-name mr-3">{{ item.customer.name }}</span>
                  <span class="purple-badge"></span>
                  <br />
                  <span class="dates">{{ item.created_at }}</span>
                </div>
              </div>
              <!-- <h4>{{ item.comment }}</h4> -->
              <div class="pb-2 mt-3">
                <span class="review">{{ $t("new.Product Review") }}</span>
                <star-rating
                  class="mb-1 text-left"
                  style="justify-content: left"
                  :increment="1"
                  :rating="item.rating"
                  :star-size="15"
                  :read-only="true"
                ></star-rating>
              </div>
              <p class="mb-0">
                {{ item.comment }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <Recommendation />
    </section>
    <Footer />
  </div>
  <a href="#successModal" id="review-success" data-bs-toggle="modal" />
  <div
    class="modal fade"
    id="successModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 340px"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 col-sm-12 text-center">
              <h1>{{ $t("new.Success") }}!</h1>
              <img :src="imgSuccess" width="90" height="90" />
              <p class="pt-2">
                {{ $t("new.Your review has been") }}<br />{{
                  $t("new.posted successfully")
                }}
                {{ reward_points }}
                {{ $t("new.Points Earned Till Now!") }}
              </p>
              <button
                type="button"
                name=""
                value="Ok"
                class="fill-btn w-100 mx-0"
                data-bs-dismiss="modal"
              >
                {{ $t("ForgetPassword.OK") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="reviewModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 340px"
    >
      <div class="modal-content">
        <Form @submit="submit">
          <div class="modal-header" style="display: inline-block; text-align: center">
            <h5
              class="modal-title"
              id="exampleModalLongTitle"
              style="font-size: 18px; text-align: left"
            >
              {{ $t("productDetail.Write Review") }}
            </h5>
            <button
              type="button"
              id="close-review"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="right: 5px; top: 15px"
              v-on:click="cancelRating()"
            >
              <span aria-hidden="true">
                <img :src="imgClosebtn" width="20" height="20" />
              </span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="login-form">
                <div class="col-md-12 col-sm-12 mb-3">
                  <star-rating
                    :star-size="50"
                    style="justify-content: center; width: 100%"
                    :increment="1"
                    :show-rating="false"
                    v-model:rating="detail.rating"
                  ></star-rating>
                </div>
                <div class="col-md-12 col-sm-12 mb-3">
                  <Field
                    type="textarea"
                    name="review"
                    placeholder="t('productDetail.review')"
                    v-model="detail.comment"
                    :rules="'required:' + $t('productDetail.review')"
                    :validateOnInput="true"
                  >
                    <textarea
                      type="text"
                      class="form-control"
                      style="background: #dee2e6"
                      rows="4"
                      v-model="detail.comment"
                      v-bind="field"
                    />
                  </Field>
                </div>
                <ErrorMessage name="review" class="validation-msg" />
                <div class="col-md-12 col-sm-12 mb-2">
                  <button type="submit" id="submit-button" class="fill-btn w-100 mx-0">
                    {{ $t("new.Post") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <ShareModal ref="shareModal" />
</template>

<script setup>
import imgBackarrow from "../../../public/static/img/back-arrow.svg";
import productPlaceholder2 from "../../../public/static/img/ProductPlaceholder2.png";
import imgWhitearrow from "../../../public/static/img/white-arrow.svg";
import imgClosebtn from "../../../public/static/img/close-btn.svg";
import imgSuccess from "../../../public/static/img/success.svg";
import profilePlaceholder from "../../../public/static/img/profilePlaceholder.png";
import imgHeart from "../../../public/static/img/heart.png";
import imgFillHeart from "../../../public/static/img/fill-heart.png";
import imgShare from "../../../public/static/img/share.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Recommendation from "@/components/Recommendation";
import StarRating from "vue-star-rating";
import { Field, Form, ErrorMessage } from "vee-validate";
import ShareModal from "../../components/SocialShare/ShareModal.vue";
import moment from "moment";
import $ from "jquery";
export default {
  name: "ProductDetailsPage",
  components: {
    Header,
    Footer,
    Recommendation,
    StarRating,
    Field,
    Form,
    ErrorMessage,
    ShareModal,
  },
  data() {
    return {
      slug: this.$route.params.slug,
      id: "",
      productDetail: {},
      similarList: [],
      reviewList: [],
      similarCount: "",
      detail: {
        product_id: "",
      },
      brand: {},
      reward_points: 0,
      rating: null,
      resetableRating: 2,
      currentRating: "No Rating",
      mouseOverRating: null,
      name: this.$route.query.name,
      searchObj: {
        offset: "",
        keyword: "",
        country: "NOT_ALL",
      },
      url: window.location.href,
      count: "",
      uuid: "",
      showingFullText: false,
      showingFullIngredients: false,
      body: "",
      ingredients: "",
      ingredientList: [],
      type: this.$route.query.type,
      attributesList: [],
      colorCode: "",
      colorName: "",
      globalRating: 0,
      currency: localStorage.getItem("symbol"),
    };
  },
  computed: {
    formattedBody() {
      if (this.showingFullText) {
        return this.body;
      }

      return `${this.body.slice(0, 200).trim()}...`;
    },
    formattedIngredients() {
      if (this.showingFullIngredients) {
        return this.ingredients;
      }

      return `${this.ingredients.slice(0, 200).trim()}...`;
    },
  },

  watch: {
    "$route.fullPath"() {
      this.slug = this.$route.params.slug;
      this.getProductDetail();
      document.documentElement.scrollTop = 10;
    },
  },

  mounted() {
    this.getProductDetail();
  },
  methods: {
    getProductDetail() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/product/slug/detail/" + this.slug,
          _body: this.searchObj,
        })
        .then((res) => {
          this.attributesList = res.attributes;
          this.detail.product_id = res.id;
          this.productDetail = res;
          this.body = res.description;
          if (res && res.ingredient) {
            this.ingredientList = res.ingredient
              ? res.ingredient.length > 0
                ? res.ingredient
                : []
              : [];
          }
          this.brand = res.brand;
          this.getSimilarList(0);
          this.getReviewList(0);
          this.addRecentProduct();
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, " ");
          }

          document.title = this.productDetail.meta_title
            ? this.productDetail.meta_title
            : this.productDetail.name;
          $("meta[property='og:title']").attr("content", this.productDetail.meta_title);
          $("meta[property='og:description']").attr(
            "content",
            this.productDetail.meta_description
          );

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = this.productDetail.meta_description;
            }
          }

          var obj = {
            "@context": "http://schema.org/",
            "@type": "Product",
            name: this.productDetail.name,
            image: this.productDetail.image,
            description: this.productDetail.description,
            url: "https://www.grademybeauty.com/en/product/" + this.productDetail.slug,
          };
          var head = document.getElementsByTagName("head")[0];
          var scripts = head.getElementsByTagName("script");
          console.log(scripts);
          for (var j = 0; j < scripts.length; j++) {
            if (scripts[j].type == "application/ld+json") {
              head.removeChild(scripts[j]);
            }
          }
          setTimeout(() => {
            const script = document.createElement("script");
            script.setAttribute("type", "application/ld+json");
            script.textContent = JSON.stringify(obj);
            document.head.appendChild(script);
          }, 500);
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
          }
        });
    },
    totalcount() {
      $("html, body").animate(
        {
          scrollTop: $(".totalcount").offset().top,
        },
        500
      );
    },
    showRating: function (rating) {
      console.log(rating);
      this.globalRating = rating;
      this.showPopup(rating);
    },
    cancelRating() {
      this.productDetail.avg_rating = this.globalRating;
      this.globalRating = 0;
    },
    addFavorite(data) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        var obj = {};
        obj.product_id = data.id;
        this.$api
          .webRequest({
            _method: "POST",
            _action: "/favourite",
            _body: obj,
          })
          .then((res) => {
            this.$toast.success(res.message, { position: "top-right" });
            if (data.is_fav == "1") {
              this.productDetail.is_fav = "0";
            } else {
              this.productDetail.is_fav = "1";
            }
          });
      }
    },
    showSharePopup(title, description) {
      if (!localStorage.getItem("access_token")) {
        this.$refs.shareModal.showModal(this.url, title, description);
      } else {
        this.$refs.shareModal.showModal(this.url, title, description);
        this.shareCall();
      }
    },
    shareCall() {
      var obj = {};
      obj.product_id = this.productDetail.id;
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/shared/product",
          _body: obj,
        })
        .then(() => {
          // this.$toast.success(res.message, { position: "top-right" });
        })
        .catch(() => {});
    },
    userRoute(id) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        if (id) {
          this.$router.push("/customer-details/" + id);
        }
      }
    },
    reviewRoute() {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.$router.push("/review/" + this.slug);
      }
    },
    showPopup(e) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        console.log("e ", e);
        this.detail.rating = this.globalRating ? this.globalRating : e;
        console.log(this.detail.rating);
        document.getElementById("writeReview").click();
      }
    },
    showReview(id) {
      this.$router.push("/my-review/" + id);
    },
    showCurrentRating(rating) {
      this.currentSelectedRating =
        rating === 0
          ? this.currentSelectedRating
          : "Click to select " + rating + " stars";
    },
    setCurrentSelectedRating(rating) {
      this.currentSelectedRating = "You have Selected: " + rating + " stars";
    },
    submit() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/review",
          _body: this.detail,
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.reward_points = res.reward_points;
          this.$toast.success(res.message, { position: "top-right" });
          document.getElementById("close-review").click();
          document.getElementById("review-success").click();
          this.getProductDetail(0);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    groupByKey(array, key) {
      return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, {
          [obj[key]]: (hash[obj[key]] || []).concat(obj),
        });
      }, {});
    },
    getSimilarList(offset) {
      this.searchObj.offset = offset;
      // this.searchObj.limit = this.itemsPerPage;
      if (this.productDetail) {
        this.searchObj.sub_categorie_id = this.productDetail.sub_categorie_id;
        this.searchObj.product_id = this.productDetail.uuid;
      }

      this.$api
        .webRequest({
          _method: "GET",
          _action: "/similar/product?limit=4&offset=0",
        })
        .then((res) => {
          this.similarCount = res.count;
          this.similarList = res.list;
        })
        .catch(() => {});
    },
    getReviewList(offset) {
      if (offset) {
        this.searchObj.offset = offset;
        // this.searchObj.limit = this.itemsPerPage;
      }
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/review/list/pagination/" + this.detail.product_id + "?limit=6",
          _body: this.searchObj,
        })
        .then((res) => {
          this.count = res.count;
          for (var i in res.list) {
            res.list[i].created_at = moment(res.list[i].created_at).format("DD MMM YYYY");
          }
          this.reviewList = res.list;
        })
        .catch(() => {});
    },
    likeComment(data) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        var obj = {};
        obj.product_id = data.product_id;
        obj.review_id = data.id;
        this.$api
          .webRequest({
            _method: "POST",
            _action: "/like/product",
            _body: obj,
          })
          .then((res) => {
            this.$toast.success(res.message, { position: "top-right" });
            if (data.is_like == "1") {
              data.is_like = "0";
              data.like_count -= 1;
            } else {
              data.is_like = "1";
              data.like_count += 1;
            }
          });
      }
    },
    route(slug) {
      let routeData = "/product/" + slug;
      this.$router.push(routeData);
    },
    addRecentProduct() {
      if (this.detail.product_id && localStorage.getItem("access_token")) {
        var obj = {};
        obj.product_id = this.detail.product_id;
        this.$api
          .webRequest({
            _method: "POST",
            _action: "/recent/viewed/product",
            _body: obj,
          })
          .then(() => {})
          .catch(() => {});
      }
    },
  },
};
</script>
