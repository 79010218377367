const messages = {
  en: {
    new: {
      "Login to Grade my beauty account using your credintials": "Login to GradeMyBeauty using your credentials",
      "Create An Account": "Create an account ",
      "OR Continue with": "Or continue with",
      "Already Have An Account": "Already have an account ",
      "Please enter following details to sign up": "Please enter the following details to sign up",
      "Success": "Success",
      "OTP has been verified": "OTP has been verified",
      "successfully": "Successfully.",
      "Go To Login": "Go to login",
      "Verify OTP": "Verify OTP",
      "Verify OTP which we have sent on your email": "Verify OTP which we have sent to your e-mail.",
      "Verify": "Verify",
      "Select Country": "Select country",
      "Send": "Send",
      "Explore More": "Explore more",
      "Categories": "Categories",
      "View All": "View All",
      "View All Categories": "View all categories",
      "View All Brands": "View all brands",
      "Subscribe to our newsletter": "Subscribe to our newsletter.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Stay tuned, subscribe to our newsletter and stay updated.",
      "Subscribe": "Subscribe",
      "Hot Posts": "Hot posts",
      "No post found": "No posts found",
      "Follow the Topic": "Follow the topic",
      "Follow": "Follow",
      "UserName": "UserName",
      "UnFollow": "Unfollow",
      "No topics found": "No topics found",
      "People you should follow": "People you should follow",
      "No users found": "No users found",
      "Back": "Back",
      "Edit Profile": "Edit profile",
      "alergic ingredient": "Allergens",
      "favorite ingredient": "Favorite ingredients",
      "Products": "Products",
      "Read More": "Read more",
      "View All Products": "View all products",
      "All": "All",
      "Create": "Create",
      "Create Post": "Create post",
      "Start Discussion": "Start discussion",
      "Phone": "Phone",
      "Post": "Post",
      "Discussion": "Discussion",
      "Start New Discussion": "Start new discussion",
      "Public": "Public",
      "Private": "Private",
      "Select Topic": "Select topic",
      "Review Posted Successfully": "Review posted successfully!",
      "5 Points Earned!": "5 Points earned!",
      "Comments": "Comments",
      "Replies": "Replies",
      "No comments found": "No comments found",
      "Reply": "Reply",
      "Delete": "Delete",
      "Cancel": "Cancel",
      "Are you sure, you want to Delete?": "Are you sure, you want to delete?",
      "Type your reply": "Type your reply...",
      "Search": "Search",
      "Edit": "Edit",
      "Reward Points": "Reward points",
      "Points Earned Till Now!": "Points earned till now",
      "Earn More Rewards": "Earn more rewards",
      "Earn Reward Points": "Earn reward points",
      "Earn reward points by posting product review and posting on the discussion": "Earn reward points by posting product reviews and replying to posts and disccusions.",
      "About": "About",
      "Product Review": "Product review",
      "Your review has been": "Your review has been",
      "posted successfully": "posted successfully.",
      "Ingredients": "Ingredients",
      "Write to Review": "Write to review",
      "Product List": "Product list",
      "Yes": "Yes",
      "No": "No",
      "Apply": "Apply",
      "Clear": "Clear",
      "Profile": "Profile",
      "Like": "Like",
      "View Activity": "View activity",
      "View All Blogs": "View all blogs",
    },
    login: {
      "Email": "Email",
      "Password": "Password",
      "Forgot Password": "Forgot password?",
      "Log in": "Login",
      "Dont have an account": "Don't have an account",
      "Sign Up": "Sign up",
      "OR Login With": "Or login with",
      "Verified": "Verified"
    },
    signup: {
      "Create a fee account within few minutes": "Create a free account within few minutes",
      "Have access to a large beauty database": "Have access to a large beauty database",
      "Read what other beauty specialists say": "Read what other beauty specialists say",
      "List your favorite products": "List your beauty products",
      "Name": "Name",
      "Email": "Email",
      "Mobile Number": "Mobile number",
      "Country": "Country",
      "Select": "Select",
      "Password": "Password",
      "Confirm Password": "Confirm password",
      "Create": "Create",
      "By creating an account you have to agree with our": "By creating an account you have to agree with our",
      "terms conditions": "Terms & Conditions.",
      "Subscribe for newsletter": "Subscribe for our newsletter",
      "Already have an account": "Already have an account? ",
      "Login": "Login",
      "OR Login With": "Or login with",
    },
    ForgetPassword: {
      "Forgot your password": "Forgot your password? ",
      "Enter your registered email address to get a link to reset your password": "Enter your registered email address to get a link to reset your password",
      "Email": "Email",
      "Submit": "Submit",
      "Back to login": "Back to login",
      "Login": "Login",
      "Successful": "Successful!",
      "We have sent an OTP": "We have sent a confirmation code",
      "to your email address": "to your email address",
      "OK": "Ok",
      "Didnt received yet": "Didn't receive yet?",
      "Resend": "Resend",
      "Recently Scanned": "Recently Scanned"
    },
    Header: {
      "Favorite": "Favorite",
      "Account": "Account",
      "My Profile": "My Profile",
      "Notifications": "Notifications",
      "Settings": "Settings",
      "Logout": "Log Out",
      "Login": "Login",
      "Signup": "Sign up",
      "All": "All",
    },
    Footer: {
      "About Us": "About us",
      "Contact Us": "Contact us",
      "Terms Conditions": "Terms & Conditions",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Privacy Policy",
      "Grade My Beauty All rights reserved": "Grade My Beauty. All rights reserved."
    },
    contact: {
      "Message": "Message",
      "Message Error": "Please enter message",
      "Subject": "Subject",
      "Subject Error": "Please enter subject",
      "Email": "Email",
      "Email Error": "Please enter email",
      "Name": "Name",
      "Name Error": "Please enter name"
    },
    Delete: {
      "Alert": "Alert",
      "Are you sure you want to delete": "Are you sure you want to delete?",
      "Yes": "Yes",
      "No": "No",
    },
    Thankyou: {
      "Thank You": "Thank you",
      "Our Admin team will verify this request and contact you": "Our customer team will verify this request and contact you",
      "OK": "Ok"
    },
    TimePicker: {
      "Hours": "Hours",
      "Minuts": "Minutes"
    },
    recommendation: {
      "Our Recommendations": "Our recommendations",
      "View all": "View all",

    },
    settings: {
      "Settings": "Settings",
      "Change Password": "Change password",
      "Newsletter": "Newsletter",
      "Current Password": "Current password",
      "New Password": "New password",
      "Confirm Password": "Confirm password",
      "Save": "Save",
      "Newsletter Notifications": "Newsletter"
    },
    notification: {
      "Home": "Home",
      "Notifications": "Notifications",
      "Notification not available": "Notification not available"
    },
    blog: {
      "Home": "Home",
      "Blogs": "Blogs",
      "Latest Blog Post": "Latest blog post",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Please retry",
      "Loading": "Loading...",
      "Looks like something went wrong": "Looks like something went wrong!",
      "Error": "Error",
      "retry": "Retry"
    },
    brand: {
      "Home": "Home",
      "Brands": "Brands",
      "No brand available": "This brand is not available..",
      "Select to search": "Select to search"
    },
    pagination: {
      "Previous": "Previous",
      "Next": "Next"
    },
    customerDetail: {
      "Follow": "Follow",
      "Following": "Following",
      "Followers": "Followers",
      "Country": "Country",
      "About Me": "About me",
      "View Activity": "View activity",
      "Hair Style": "Hair style",
      "Skin Tone": "Skin tone",
      "Favorite Products": "My beauty products",
      "View all": "View all",
      "No favorite available": "You don't have any favorite products yet."
    },
    highRated: {
      "Home": "Home",
      "Highest Rated": "Highest rated",
      "Highest Rated Products": "Highest rated products",
      "Load More": "Load more",
      "No high rated products available": "No highly rated products available"
    },
    instaList: {
      "Home": "Home",
      "Instagram posts": "Instagram posts",
    },
    lastViewed: {
      "Home": "Home",
      "Last Viewed": "Last viewed",
      "Last Viewed Products": "Last viewed products",
      "Product not available": "No products to display here yet."
    },
    myProfile: {
      "Home": "Home",
      "My Profile": "My profile",
      "Change photo": "Change photo",
      "Name": "Name",
      "Email": "Email",
      "Mobile Number": "Mobile number",
      "Country": "Country",
      "Hair Style": "Hair style",
      "Select Hair": "Select hair type",
      "Wavy Hair": "Wavy hair",
      "Curly Hair": "Curly hair",
      "Straight Hair": "Straight hair",
      "Kinky Hair": "Kinky hair",
      "Instagram": "Instagram",
      "Skin Tone": "Skin tone",
      "Alergic Ingredients": "Alergic Ingredients",
      "Favourite Ingredients": "Favourite ingredients",
      "About Me": "About me",
      "Submit": "Submit",
      "Select": "Select",
      "EAN Code": "EAN Code"
    },
    otp: {
      "OTP Confirmation": "OTP Verification",
      "Please enter the verification code sent to your email": "Please enter the verification code sent to your email.",
      "OTP is required": "Code is required",
      "Submit": "Submit",
      "Resend OTP": "Resend code",
      "Please scan the verification code in google authenticator and enter the OTP": "Please scan the verification code in google authenticator and enter the OTP.",

    },
    wishList: {
      "Home": "Home",
      "Favorite": "My Beauty",
      "Sort By": "Sort by",
      "Popularity": "Popularity",
      "Low to High": "Low to high",
      "High to Low": "High to low",
      "Apply": "Apply",
      "Clear": "Clear",
      "Filter By": "Filter by",
      "Brand Name": "Brand name",
      "Min Price": "Min price",
      "Max Price": "Max price",
      "Price": "Price",
      "Select": "Select",
      "No favorite available": "You don't have any favorite products yet.",
      "No recent scan available": "You haven't scanned any products yet."
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "New password",
      "Confirm Password": "Confirm password",
      "Submit": "Submit"
    },
    productCategory: {
      "Home": "Home",
      "Product Categories": "Product categories",
    },
    myReview: {
      "My Review": "My review",
      "Write Review": "Write review",
      "review": "Review",
      "Submit": "Submit"
    },
    productDetail: {
      "Home": "Home",
      "Category": "Category",
      "Sub Category": "Subcategory",
      "Brands": "Brands",
      "Highest Rated": "Highest rated",
      "Last Viewed": "Last viewed",
      "Recommendations": "Recommendations",
      "Products": "Products",
      "Product Detail": "Product details",
      "USD": "USD",
      "Write Review": "Write review",
      "View Review": "View reviews",
      "Description": "Description",
      "Read": "Read",
      "Less": "Less",
      "More": "More",
      "Ingredient": "Ingredients",
      "Similar Products": "Similar products",
      "View all": "View all",
      "Reviews Ratings": "Reviews & Ratings",
      "No Review Added Yet": "No reviews added yet",
      "Submit": "Submit",
      "review": "Review",
      "Same Country": "My country",
      "View All ": "View all "
    },
    review: {
      "Reviews": "Reviews",
      "Add a Review": "Add a review",
      "View Review": "View reviews",
      "Review Rating": "Reviews & Ratings",
      "Write Review": "Write review",
      "Submit": "Submit",
      "Sort By": "Sort by",
      "Most Recent": "Most recent",
      "Top Rating": "Top rating",
      "Like High to Low": "Like high to low",
      "Apply": "Apply",
      "Clear": "Clear",
      "Filter By": "Filter by",
      "Review not available": "Reviews not available",
      "Click to select": "Click to select",
      "stars": "Stars",
      "You have Selected": "You have selected: "
    },
    productList: {
      "Home": "Home",
      "Product Categories": "Main Category",
      "Brands": "Brand",
      "Product Sub Categories": "Category",
      "Product Sub Sub Categories": "Sub Category",
      "Products": "Products",
      "Sort By": "Sort by",
      "Popularity": "Popularity",
      "Low to High": "Low to high",
      "High to Low": "High to low",
      "Apply": "Apply",
      "Clear": "Clear",
      "Filter By": "Filter by",
      "Brand Name": "Brand name",
      "Select": "Select",
      "Min Price": "Min Price",
      "Max Price": "Max Price",
      "Price": "Price",
      "Product not available": "Product not available",
    },
    productSubCategory: {
      "Home": "Home",
      "Product Categories": "Main categories",
      "Product Sub Categories": "Categories",
      "Product Sub Sub Categories": "Subcategories",
      "Sub category not available": "Category not available",
      "Category not available": "Category not available"
    },
    recommendationList: {
      "Home": "Home",
      "Our Recommendation": "Our recommendations",
      "No product available": "No products available"
    },
    search: {
      "Search": "Search",
      "Product Categories": "Categories",
      "View all": "View all",
      "Brands": "Brands",
      "Products": "Products",
      "Filter By": "Filter by",
      "Brand Name": "Brand name",
      "Select": "Select",
      "Ingredients": "Ingredients",
      "Ingredient": "Ingredient",
      "Include": "Include",
      "Not Include": "Not include",
      "Min Price": "Min price",
      "Max Price": "Max price",
      "Customer Reviews": "Customer reviews",
      "Customer Ratings": "Customer ratings",
      "Apply": "Apply",
      "Clear": "Clear",
      "Product not available": "Products not available",
      "Brands not available": "Brands not available",
    },
    similar: {
      "Product Detail": "Product detail",
      "Similar Products": "Similar products",
      "Product not available": "Products not available"
    },
    activity: {
      "View Activity": "View activity",
    },
    home: {
      "Product Categories": "Categories",
      "View all": "View all",
      "Brands": "Brands",
      "Highest Rated": "Highest rated",
      "Last Viewed Products": "Last viewed products",
      "Latest Blog Post": "Latest blog post",
      "Instagram Post": "Instagram posts",
    },
    share: {
      "Share": "Share",
      "Copy Link": "Copy link",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Successfully copied to clipboard",
    },
    validation: {
      "Please enter": "Please enter",
      "Please select": "Please select",
      "This field is required": "This field is required",
      "Please enter valid email": "Please enter a valid email address",
      "Please enter valid contact number": "Please enter a valid contact number",
      "Please enter valid pin code": "Please enter a valid pincode",
      "Please enter valid": "Please enter valid",
      "Please enter valid name": "Please enter a valid name",
      "This field must be at least": "This field must be at least",
      "characters": "characters",
      "Space is not allowed in password": "Space is not allowed in password",
      "Passwords must match": "Passwords must match",
      "Please enter valid price": "Please enter a valid price",
      "Please enter numeric values only": "Please enter numeric values only"
    },
    webService: {
      "Server Not Responding": "Server not responding",
      "Invalid Username or Password": "Invalid username or password",
      "Bad Response": "Bad response",
      "Server Error": "Server error",
    }
  },
  nl: {
    new: {
      "Login to Grade my beauty account using your credintials": "Login op jouw GradeMyBeauty-account met je gegevens",
      "Create An Account": "Maak een account aan",
      "OR Continue with": "Of ga verder met:",
      "Already Have An Account": "Heb je al een account",
      "Please enter following details to sign up": "Voer de volgende gegevens in om je aan te melden",
      "Success": "Succes",
      "OTP has been verified": "OTP is geverifieerd",
      "successfully": "Succesvol.",
      "Go To Login": "Ga naar login",
      "Verify OTP": "Verifieer OTP",
      "Verify OTP which we have sent on your email": "Verifieer de OTP die we naar je e-mail hebben gestuurd.",
      "Verify": "Verifieer",
      "Select Country": "Selecteer land",
      "Send": "Verstuur",
      "Explore More": "Ontdek meer",
      "Categories": "Categorieën",
      "View All": "View All",
      "View All Categories": "Bekijk alle categorieën",
      "View All Brands": "Bekijk alle merken",
      "Subscribe to our newsletter": "Abonneer je op onze nieuwsbrief.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Blijf op de hoogte, abonneer je op onze nieuwsbrief en blijf geïnformeerd.",
      "Subscribe": "Abonneer",
      "Hot Posts": "Populaire berichten",
      "No post found": "Geen berichten gevonden",
      "Follow the Topic": "Volg het onderwerp",
      "Follow": "Volg",
      "UserName": "UserName",
      "UnFollow": "Ontvolg",
      "No topics found": "Geen onderwerpen gevonden",
      "People you should follow": "Mensen die je zou moeten volgen",
      "No users found": "Geen gebruikers gevonden",
      "Back": "Terug",
      "Edit Profile": "Bewerk profiel",
      "alergic ingredient": "Allergenen",
      "favorite ingredient": "Favoriete ingrediënten",
      "Products": "Producten",
      "Read More": "Lees meer",
      "View All Products": "Bekijk alle producten",
      "All": "Alles",
      "Create": "Creëer",
      "Create Post": "Maak bericht",
      "Start Discussion": "Start discussie",
      "Phone": "Telefoon",
      "Post": "Bericht",
      "Discussion": "Discussie",
      "Start New Discussion": "Start nieuwe discussie",
      "Public": "Openbaar",
      "Private": "Privé",
      "Select Topic": "Selecteer onderwerp",
      "Review Posted Successfully": "Beoordeling succesvol geplaatst!",
      "5 Points Earned!": "5 punten verdiend!",
      "Comments": "Reacties",
      "Replies": "Antwoorden",
      "No comments found": "Geen reacties gevonden",
      "Reply": "Antwoord",
      "Delete": "Verwijder",
      "Cancel": "Annuleer",
      "Are you sure, you want to Delete?": "Weet je zeker dat je wilt verwijderen?",
      "Type your reply": "Typ je antwoord...",
      "Search": "Zoek",
      "Edit": "Bewerk",
      "Reward Points": "Beloningspunten",
      "Points Earned Till Now!": "Tot nu toe verdiende punten",
      "Earn More Rewards": "Verdien meer beloningen",
      "Earn Reward Points": "Verdien beloningspunten",
      "Earn reward points by posting product review and posting on the discussion": "Verdien beloningspunten door productbeoordelingen te plaatsen en te reageren op berichten en discussies.",
      "About": "Over",
      "Product Review": "Productbeoordeling",
      "Your review has been": "Je beoordeling is",
      "posted successfully": "succesvol geplaatst.",
      "Ingredients": "Ingrediënten",
      "Write to Review": "Schrijf een beoordeling",
      "Product List": "Productlijst",
      "Yes": "Ja",
      "No": "Nee",
      "Apply": "Toepassen",
      "Clear": "Wissen",
      "Profile": "Profiel",
      "Like": "Vind ik leuk",
      "View Activity": "Bekijk activiteit",
      "View All Blogs": "Bekijk alle blogs",
    },
    login: {
      "Email": "E-mail",
      "Password": "Wachtwoord",
      "Forgot Password": "Wachtwoord vergeten?",
      "Log in": "Inloggen",
      "Dont have an account": "Heb je geen account",
      "Sign Up": "Registreren",
      "OR Login With": "Of inloggen met"
    },
    signup: {
      "Create a fee account within few minutes": "Maak binnen enkele minuten een gratis account aan.",
      "Have access to a large beauty database": "Krijg toegang tot informatie over alle beauty producten.",
      "Read what other beauty specialists say": "Lees wat andere beauty specialisten zeggen.",
      "List your favorite products": "Maak een lijst van je favoriete producten.",
      "Name": "Naam",
      "Email": "E-mail",
      "Mobile Number": "Mobiel nummer",
      "Country": "Land",
      "Select": "Selecteer",
      "Password": "Wachtwoord",
      "Confirm Password": "Bevestig wachtwoord",
      "Create": "Aanmaken",
      "By creating an account you have to agree with our": "Door een account aan te maken ga je akkoord met onze",
      "terms conditions": "Algemene voorwaarden.",
      "Subscribe for newsletter": "Abonneer je op onze nieuwsbrief",
      "Already have an account": "Heb je al een account?",
      "Login": "Inloggen",
      "OR Login With": "Of inloggen met",
    },
    ForgetPassword: {
      "Forgot your password": "Wachtwoord vergeten?",
      "Enter your registered email address to get a link to reset your password": "Voer je geregistreerde e-mailadres in om een link te krijgen om je wachtwoord opnieuw in te stellen",
      "Email": "E-mail",
      "Submit": "Verzenden",
      "Back to login": "Terug naar inloggen",
      "Login": "Inloggen",
      "Successful": "Succesvol!",
      "We have sent an OTP": "We hebben een bevestigingscode verzonden",
      "to your email address": "naar je e-mailadres",
      "OK": "Ok",
      "Didnt received yet": "Nog niet ontvangen?",
      "Resend": "Opnieuw verzenden",
    },
    Header: {
      "Favorite": "Mijn Beauty",
      "Account": "Account",
      "My Profile": "Mijn profiel",
      "Notifications": "Notificaties",
      "Settings": "Instellingen",
      "Logout": "Uitloggen",
      "Login": "Inloggen",
      "Signup": "Registreren",
      "All": "Alles",
    },
    Footer: {
      "About Us": "Over ons",
      "Contact Us": "Contact",
      "Terms Conditions": "Algemene voorwaarden",
      "Privacy Policy": "Privacybeleid",
      "Follow us on": "Follow us on : ",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Alle rechten voorbehouden."
    },
    contact: {
      "Message": "Bericht",
      "Message Error": "Voer alsjeblieft een bericht in",
      "Subject": "Onderwerp",
      "Subject Error": "Voer alsjeblieft een onderwerp in",
      "Email": "E-mail",
      "Email Error": "Voer alsjeblieft een e-mailadres in",
      "Name": "Naam",
      "Name Error": "Voer alsjeblieft een naam in"
    },
    Delete: {
      "Alert": "Waarschuwing",
      "Are you sure you want to delete": "Weet je zeker dat je wilt verwijderen?",
      "Yes": "Ja",
      "No": "Nee",
    },
    Thankyou: {
      "Thank You": "Dank je",
      "Our Admin team will verify this request and contact you": "Ons klantenteam zal dit verzoek verifiëren en contact met je opnemen",
      "OK": "Ok"
    },
    TimePicker: {
      "Hours": "Uren",
      "Minuts": "Minuten"
    },
    recommendation: {
      "Our Recommendations": "Onze aanbevelingen",
      "View all": "Bekijk alles",
    },
    settings: {
      "Settings": "Instellingen",
      "Change Password": "Wachtwoord wijzigen",
      "Newsletter": "Nieuwsbrief",
      "Current Password": "Huidig wachtwoord",
      "New Password": "Nieuw wachtwoord",
      "Confirm Password": "Bevestig wachtwoord",
      "Save": "Opslaan",
      "Newsletter Notifications": "Nieuwsbrief"
    },
    notification: {
      "Home": "Home",
      "Notifications": "Notificaties",
      "Notification not available": "Melding niet beschikbaar"
    },
    blog: {
      "Home": "Home",
      "Blogs": "Blogs",
      "Latest Blog Post": "Laatste blogpost",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Probeer alsjeblieft opnieuw",
      "Loading": "Laden...",
      "Looks like something went wrong": "Er is iets misgegaan!",
      "Error": "Fout",
      "retry": "Opnieuw proberen"
    },
    brand: {
      "Home": "Home",
      "Brands": "Merken",
      "No brand available": "Dit merk is niet beschikbaar..",
      "Select to search": "Selecteer om te zoeken"
    },
    pagination: {
      "Previous": "Vorige",
      "Next": "Volgende"
    },
    customerDetail: {
      "Follow": "Volgen",
      "Following": "Volgend",
      "Followers": "Volgers",
      "Country": "Land",
      "About Me": "Over mij",
      "View Activity": "Bekijk activiteit",
      "Hair Style": "Haarstijl",
      "Skin Tone": "Huidskleur",
      "Favorite Products": "Mijn beauty producten",
      "View all": "Bekijk alles",
      "No favorite available": "Je hebt nog geen favoriete producten."
    },
    highRated: {
      "Home": "Home",
      "Highest Rated": "Hoogst beoordeeld",
      "Highest Rated Products": "Hoogst beoordeelde producten",
      "Load More": "Meer laden",
      "No high rated products available": "Geen hoog beoordeelde producten beschikbaar"
    },
    instaList: {
      "Home": "Home",
      "Instagram posts": "Instagram berichten",
    },
    lastViewed: {
      "Home": "Home",
      "Last Viewed": "Laatst bekeken",
      "Last Viewed Products": "Laatst bekeken producten",
      "Product not available": "Geen producten om hier weer te geven."
    },
    myProfile: {
      "Home": "Home",
      "My Profile": "Mijn profiel",
      "Change photo": "Foto wijzigen",
      "Name": "Naam",
      "Email": "Email",
      "Mobile Number": "Mobiel nummer",
      "Country": "Land",
      "Hair Style": "Haarstijl",
      "Select Hair": "Selecteer haartype",
      "Wavy Hair": "Golvend haar",
      "Curly Hair": "Krullend haar",
      "Straight Hair": "Stijl haar",
      "Kinky Hair": "Kroeshaar",
      "Instagram": "Instagram",
      "Skin Tone": "Huidskleur",
      "Alergic Ingredients": "Allergenen",
      "Favourite Ingredients": "Favoriete ingrediënten",
      "About Me": "Over mij",
      "Submit": "Verzenden",
      "Select": "Selecteren",
      "EAN Code": "EAN Code"
    },
    otp: {
      "OTP Confirmation": "OTP Verificatie",
      "Please enter the verification code sent to your email": "Voer de verificatiecode in die naar jouw email is gestuurd.",
      "OTP is required": "Code is vereist",
      "Submit": "Verzenden",
      "Resend OTP": "Code opnieuw sturen",
    },
    wishList: {
      "Home": "Home",
      "Favorite": "Mijn Beauty",
      "Sort By": "Sorteer op",
      "Popularity": "Populariteit",
      "Low to High": "Laag naar hoog",
      "High to Low": "Hoog naar laag",
      "Apply": "Toepassen",
      "Clear": "Wissen",
      "Filter By": "Filteren op",
      "Brand Name": "Merknaam",
      "Min Price": "Min prijs",
      "Max Price": "Max prijs",
      "Select": "Selecteren",
      "No favorite available": "Je hebt nog geen favoriete producten."
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Nieuw wachtwoord",
      "Confirm Password": "Bevestig wachtwoord",
      "Submit": "Verzenden"
    },
    productCategory: {
      "Home": "Home",
      "Product Categories": "Productcategorieën",
    },
    myReview: {
      "My Review": "Mijn beoordeling",
      "Write Review": "Schrijf beoordeling",
      "review": "Beoordeling",
      "Submit": "Verzenden"
    },
    productDetail: {
      "Home": "Home",
      "Category": "Categorie",
      "Sub Category": "Subcategorie",
      "Brands": "Merken",
      "Highest Rated": "Hoogst beoordeeld",
      "Last Viewed": "Laatst bekeken",
      "Recommendations": "Aanbevelingen",
      "Products": "Producten",
      "Product Detail": "Productdetails",
      "USD": "USD",
      "Write Review": "Schrijf beoordeling",
      "View Review": "Bekijk beoordelingen",
      "Description": "Beschrijving",
      "Read": "Lezen",
      "Less": "Minder",
      "More": "Meer",
      "Ingredient": "Ingrediënten",
      "Similar Products": "Vergelijkbare producten",
      "View all": "Alles bekijken",
      "Reviews Ratings": "Beoordelingen & Ratings",
      "No Review Added Yet": "Nog geen beoordelingen toegevoegd",
      "Submit": "Verzenden",
      "review": "Beoordeling",
      "Same Country": "Mijn land",
      "View All ": "Alles bekijken "
    },
    review: {
      "Reviews": "Beoordelingen",
      "Add a Review": "Voeg een beoordeling toe",
      "View Review": "Bekijk beoordelingen",
      "Review Rating": "Beoordelingen & Ratings",
      "Write Review": "Schrijf beoordeling",
      "Submit": "Verzenden",
      "Sort By": "Sorteer op",
      "Most Recent": "Meest recent",
      "Top Rating": "Top beoordeling",
      "Like High to Low": "Likes van hoog naar laag",
      "Apply": "Toepassen",
      "Clear": "Wissen",
      "Filter By": "Filteren op",
      "Review not available": "Geen beoordelingen beschikbaar",
      "Click to select": "Klik om te selecteren",
      "stars": "Sterren",
      "You have Selected": "Je hebt geselecteerd: "
    },
    productList: {
      "Home": "Home",
      "Product Categories": "Hoofdcategorieën",
      "Brands": "Merken",
      "Product Sub Categories": "Categorieën",
      "Product Sub Sub Categories": "Subcategorieën",
      "Products": "Producten",
      "Sort By": "Sorteer op",
      "Popularity": "Populariteit",
      "Low to High": "Laag naar hoog",
      "High to Low": "Hoog naar laag",
      "Apply": "Toepassen",
      "Clear": "Wissen",
      "Filter By": "Filteren op",
      "Brand Name": "Merknaam",
      "Select": "Selecteren",
      "Product not available": "Product niet beschikbaar"
    },
    productSubCategory: {
      "Home": "Home",
      "Product Categories": "Hoofdcategorieën",
      "Product Sub Categories": "Categorieën",
      "Product Sub Sub Categories": "Subcategorieën",
      "Sub category not available": "Subcategorie niet beschikbaar",
      "Category not available": "Categorie niet beschikbaar"
    },
    recommendationList: {
      "Home": "Home",
      "Our Recommendation": "Onze aanbevelingen",
      "No product available": "Geen producten beschikbaar"
    },
    search: {
      "Search": "Zoeken",
      "Product Categories": "Categorieën",
      "View all": "Alles bekijken",
      "Brands": "Merken",
      "Products": "Producten",
      "Filter By": "Filteren op",
      "Brand Name": "Merknaam",
      "Select": "Selecteren",
      "Ingredients": "Ingrediënten",
      "Ingredient": "Ingrediënt",
      "Include": "Inclusief",
      "Not Include": "Exclusief",
      "Min Price": "Min prijs",
      "Max Price": "Max prijs",
      "Customer Reviews": "Klantbeoordelingen",
      "Customer Ratings": "Klantervaringen",
      "Apply": "Toepassen",
      "Clear": "Wissen",
      "Product not available": "Producten niet beschikbaar",
      "Brands not available": "Merken niet beschikbaar",
    },
    similar: {
      "Product Detail": "Productdetails",
      "Similar Products": "Vergelijkbare producten",
      "Product not available": "Producten niet beschikbaar"
    },
    activity: {
      "View Activity": "Activiteit bekijken",
    },
    home: {
      "Product Categories": "Categorieën",
      "View all": "Alles bekijken",
      "Brands": "Merken",
      "Highest Rated": "Hoogst beoordeeld",
      "Last Viewed Products": "Laatst bekeken producten",
      "Latest Blog Post": "Nieuwste blogpost",
      "Instagram Post": "Instagram berichten",
    },
    share: {
      "Share": "Delen",
      "Copy Link": "Link kopiëren",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Succesvol naar klembord gekopieerd",
    },
    validation: {
      "Please enter": "Voer in alsjeblieft",
      "Please select": "Selecteer alsjeblieft",
      "This field is required": "Dit veld is verplicht",
      "Please enter valid email": "Voer een geldig e-mailadres in",
      "Please enter valid contact number": "Voer een geldig telefoonnummer in",
      "Please enter valid pin code": "Voer een geldige pincode in",
      "Please enter valid": "Voer een geldige in",
      "Please enter valid name": "Voer een geldige naam in",
      "This field must be at least": "Dit veld moet minstens",
      "characters": "karakters",
      "Space is not allowed in password": "Spaties zijn niet toegestaan in het wachtwoord",
      "Passwords must match": "Wachtwoorden moeten overeenkomen",
      "Please enter valid price": "Voer een geldige prijs in",
      "Please enter numeric values only": "Voer alleen numerieke waarden in"
    },
    webService: {
      "Server Not Responding": "Server reageert niet",
      "Invalid Username or Password": "Ongeldige gebruikersnaam of wachtwoord",
      "Bad Response": "Geen reactie",
      "Server Error": "Serverfout",
    }
  },
  pl: {
    new: {
      "Login to Grade my beauty account using your credintials": "Zaloguj się do swojego konta GradeMyBeauty, używając swoich danych",
      "Create An Account": "Utwórz konto",
      "OR Continue with": "Lub kontynuuj z:",
      "Already Have An Account": "Masz już konto",
      "Please enter following details to sign up": "Proszę wprowadzić następujące dane, aby się zarejestrować",
      "Success": "Sukces",
      "OTP has been verified": "OTP zostało zweryfikowane",
      "successfully": "Pomyślnie.",
      "Go To Login": "Przejdź do logowania",
      "Verify OTP": "Zweryfikuj OTP",
      "Verify OTP which we have sent on your email": "Zweryfikuj OTP, który wysłaliśmy na Twój e-mail.",
      "Verify": "Zweryfikuj",
      "Select Country": "Wybierz kraj",
      "Send": "Wyślij",
      "Explore More": "Odkryj więcej",
      "Categories": "Kategorie",
      "View All": "View All",
      "View All Categories": "Zobacz wszystkie kategorie",
      "View All Brands": "Zobacz wszystkie marki",
      "Subscribe to our newsletter": "Zapisz się do naszego newslettera.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Bądź na bieżąco, zapisz się do naszego newslettera i bądź na bieżąco.",
      "Subscribe": "Subskrybuj",
      "Hot Posts": "Popularne posty",
      "No post found": "Nie znaleziono postów",
      "Follow the Topic": "Śledź temat",
      "Follow": "Śledź",
      "UserName": "UserName",
      "UnFollow": "Przestań śledzić",
      "No topics found": "Nie znaleziono tematów",
      "People you should follow": "Osoby, które powinieneś śledzić",
      "No users found": "Nie znaleziono użytkowników",
      "Back": "Wstecz",
      "Edit Profile": "Edytuj profil",
      "alergic ingredient": "Alergeny",
      "favorite ingredient": "Ulubione składniki",
      "Products": "Produkty",
      "Read More": "Czytaj więcej",
      "View All Products": "Zobacz wszystkie produkty",
      "All": "Wszystko",
      "Create": "Utwórz",
      "Create Post": "Utwórz post",
      "Start Discussion": "Rozpocznij dyskusję",
      "Phone": "Telefon",
      "Post": "Post",
      "Discussion": "Dyskusja",
      "Start New Discussion": "Rozpocznij nową dyskusję",
      "Public": "Publiczny",
      "Private": "Prywatny",
      "Select Topic": "Wybierz temat",
      "Review Posted Successfully": "Recenzja opublikowana pomyślnie!",
      "5 Points Earned!": "Zdobyto 5 punktów!",
      "Comments": "Komentarze",
      "Replies": "Odpowiedzi",
      "No comments found": "Nie znaleziono komentarzy",
      "Reply": "Odpowiedz",
      "Delete": "Usuń",
      "Cancel": "Anuluj",
      "Are you sure, you want to Delete?": "Czy na pewno chcesz usunąć?",
      "Type your reply": "Wpisz swoją odpowiedź...",
      "Search": "Szukaj",
      "Edit": "Edytuj",
      "Reward Points": "Punkty nagród",
      "Points Earned Till Now!": "Punkty zdobyte do tej pory",
      "Earn More Rewards": "Zdobądź więcej nagród",
      "Earn Reward Points": "Zdobądź punkty nagród",
      "Earn reward points by posting product review and posting on the discussion": "Zdobywaj punkty nagród, publikując recenzje produktów i odpowiadając na posty i dyskusje.",
      "About": "O nas",
      "Product Review": "Recenzja produktu",
      "Your review has been": "Twoja recenzja została",
      "posted successfully": "pomyślnie opublikowana.",
      "Ingredients": "Składniki",
      "Write to Review": "Napisz recenzję",
      "Product List": "Lista produktów",
      "Yes": "Tak",
      "No": "Nie",
      "Apply": "Zastosuj",
      "Clear": "Wyczyść",
      "Profile": "Profil",
      "Like": "Lubię to",
      "View Activity": "Zobacz aktywność",
      "View All Blogs": "Zobacz wszystkie blogi",
    },
    login: {
      "Email": "Email",
      "Password": "Hasło",
      "Forgot Password": "Zapomniałeś hasła?",
      "Log in": "Zaloguj się",
      "Dont have an account": "Nie masz konta",
      "Sign Up": "Zarejestruj się",
      "OR Login With": "Lub zaloguj się przez"
    },
    signup: {
      "Create a fee account within few minutes": "Utwórz darmowe konto w kilka minut.",
      "Have access to a large beauty database": "Miej dostęp do informacji o wszystkich produktach beauty.",
      "Read what other beauty specialists say": "Czytaj, co mówią inni specjaliści od beauty.",
      "List your favorite products": "Utwórz listę swoich ulubionych produktów.",
      "Name": "Imię",
      "Email": "Email",
      "Mobile Number": "Numer telefonu",
      "Country": "Kraj",
      "Select": "Wybierz",
      "Password": "Hasło",
      "Confirm Password": "Potwierdź hasło",
      "Create": "Utwórz",
      "By creating an account you have to agree with our": "Tworząc konto, musisz wyrazić zgodę na nasze",
      "terms conditions": "Regulamin i Warunki.",
      "Subscribe for newsletter": "Zapisz się do naszego newslettera",
      "Already have an account": "Masz już konto?",
      "Login": "Zaloguj się",
      "OR Login With": "Lub zaloguj się przez",
    },
    ForgetPassword: {
      "Forgot your password": "Zapomniałeś hasła?",
      "Enter your registered email address to get a link to reset your password": "Wprowadź swój zarejestrowany adres e-mail, aby otrzymać link do resetowania hasła",
      "Email": "Email",
      "Submit": "Wyślij",
      "Back to login": "Wróć do logowania",
      "Login": "Zaloguj się",
      "Successful": "Sukces!",
      "We have sent an OTP": "Wysłaliśmy kod potwierdzający",
      "to your email address": "na Twój adres e-mail",
      "OK": "Ok",
      "Didnt received yet": "Nie otrzymałeś kodu?",
      "Resend": "Wyślij ponownie.",
    },
    Header: {
      "Favorite": "Moje Beauty",
      "Account": "Konto",
      "My Profile": "Mój profil",
      "Notifications": "Powiadomienia",
      "Settings": "Ustawienia",
      "Logout": "Wyloguj się",
      "Login": "Zaloguj się",
      "Signup": "Zarejestruj się",
      "All": "Wszystkie",
    },
    Footer: {
      "About Us": "O nas",
      "Contact Us": "Kontakt",
      "Terms Conditions": "Regulamin i Warunki",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Polityka prywatności",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Wszelkie prawa zastrzeżone."
    },
    contact: {
      "Message": "Wiadomość",
      "Message Error": "Proszę wprowadzić wiadomość",
      "Subject": "Temat",
      "Subject Error": "Proszę wprowadzić temat",
      "Email": "Email",
      "Email Error": "Proszę wprowadzić email",
      "Name": "Imię",
      "Name Error": "Proszę wprowadzić imię"
    },
    Delete: {
      "Alert": "Ostrzeżenie",
      "Are you sure you want to delete": "Czy na pewno chcesz usunąć?",
      "Yes": "Tak",
      "No": "Nie",
    },
    Thankyou: {
      "Thank You": "Dziękujemy",
      "Our Admin team will verify this request and contact you": "Nasz zespół obsługi klienta zweryfikuje to zgłoszenie i skontaktuje się z tobą",
      "OK": "Ok"
    },
    TimePicker: {
      "Hours": "Godziny",
      "Minuts": "Minuty"
    },
    recommendation: {
      "Our Recommendations": "Nasze rekomendacje",
      "View all": "Zobacz wszystko",
    },
    settings: {
      "Settings": "Ustawienia",
      "Change Password": "Zmień hasło",
      "Newsletter": "Biuletyn",
      "Current Password": "Aktualne hasło",
      "New Password": "Nowe hasło",
      "Confirm Password": "Potwierdź hasło",
      "Save": "Zapisz",
      "Newsletter Notifications": "Biuletyn"
    },
    notification: {
      "Home": "Strona główna",
      "Notifications": "Powiadomienia",
      "Notification not available": "Powiadomienie niedostępne"
    },
    blog: {
      "Home": "Strona główna",
      "Blogs": "Blogi",
      "Latest Blog Post": "Najnowszy wpis na blogu",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Spróbuj ponownie",
      "Loading": "Ładowanie...",
      "Looks like something went wrong": "Wygląda na to, że coś poszło nie tak!",
      "Error": "Błąd",
      "retry": "Ponów"
    },
    brand: {
      "Home": "Strona główna",
      "Brands": "Marki",
      "No brand available": "Ta marka nie jest dostępna.",
      "Select to search": "Wybierz, aby wyszukać"
    },
    pagination: {
      "Previous": "Poprzedni",
      "Next": "Następny"
    },
    customerDetail: {
      "Follow": "Obserwuj",
      "Following": "Obserwowane",
      "Followers": "Obserwujący",
      "Country": "Kraj",
      "About Me": "O mnie",
      "View Activity": "Zobacz aktywność",
      "Hair Style": "Styl włosów",
      "Skin Tone": "Odcień skóry",
      "Favorite Products": "Moje produkty beauty",
      "View all": "Zobacz wszystko",
      "No favorite available": "Nie masz jeszcze ulubionych produktów."
    },
    highRated: {
      "Home": "Strona główna",
      "Highest Rated": "Najwyżej oceniane",
      "Highest Rated Products": "Najwyżej oceniane produkty",
      "Load More": "Załaduj więcej",
      "No high rated products available": "Brak produktów o wysokich ocenach"
    },
    instaList: {
      "Home": "Strona główna",
      "Instagram posts": "Posty na Instagramie",
    },
    lastViewed: {
      "Home": "Strona główna",
      "Last Viewed": "Ostatnio przeglądane",
      "Last Viewed Products": "Ostatnio przeglądane produkty",
      "Product not available": "Nie ma jeszcze produktów do wyświetlenia."
    },
    myProfile: {
      "Home": "Strona główna",
      "My Profile": "Mój profil",
      "Change photo": "Zmień zdjęcie",
      "Name": "Nazwa",
      "Email": "E-mail",
      "Mobile Number": "Numer telefonu",
      "Country": "Kraj",
      "Hair Style": "Styl włosów",
      "Select Hair": "Wybierz rodzaj włosów",
      "Wavy Hair": "Falowane włosy",
      "Curly Hair": "Kręcone włosy",
      "Straight Hair": "Proste włosy",
      "Kinky Hair": "Kędzierzawe włosy",
      "Instagram": "Instagram",
      "Skin Tone": "Odcień skóry",
      "Alergic Ingredients": "Alergeny",
      "Favourite Ingredients": "Ulubione składniki",
      "About Me": "O mnie",
      "Submit": "Wyślij",
      "Select": "Wybierz",
      "EAN Code": "Kod EAN"
    },
    otp: {
      "OTP Confirmation": "Weryfikacja OTP",
      "Please enter the verification code sent to your email": "Wprowadź kod weryfikacyjny wysłany na twój adres e-mail.",
      "OTP is required": "Kod jest wymagany",
      "Submit": "Wyślij",
      "Resend OTP": "Wyślij kod ponownie",
    },
    wishList: {
      "Home": "Strona główna",
      "Favorite": "Moje Beauty",
      "Sort By": "Sortuj według",
      "Popularity": "Popularność",
      "Low to High": "Od najniższej do najwyższej",
      "High to Low": "Od najwyższej do najniższej",
      "Apply": "Zastosuj",
      "Clear": "Wyczyść",
      "Filter By": "Filtruj według",
      "Brand Name": "Nazwa marki",
      "Min Price": "Minimalna cena",
      "Max Price": "Maksymalna cena",
      "Select": "Wybierz",
      "No favorite available": "Nie masz jeszcze ulubionych produktów."
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Nowe hasło",
      "Confirm Password": "Potwierdź hasło",
      "Submit": "Wyślij"
    },
    productCategory: {
      "Home": "Strona główna",
      "Product Categories": "Kategorie produktów",
    },
    myReview: {
      "My Review": "Moja recenzja",
      "Write Review": "Napisz recenzję",
      "review": "Recenzja",
      "Submit": "Wyślij"
    },
    productDetail: {
      "Home": "Strona główna",
      "Category": "Kategoria",
      "Sub Category": "Podkategoria",
      "Brands": "Marki",
      "Highest Rated": "Najwyżej oceniane",
      "Last Viewed": "Ostatnio przeglądane",
      "Recommendations": "Rekomendacje",
      "Products": "Produkty",
      "Product Detail": "Szczegóły produktu",
      "USD": "USD",
      "Write Review": "Napisz recenzję",
      "View Review": "Zobacz recenzje",
      "Description": "Opis",
      "Read": "Czytaj",
      "Less": "Mniej",
      "More": "Więcej",
      "Ingredient": "Składniki",
      "Similar Products": "Podobne produkty",
      "View all": "Zobacz wszystko",
      "Reviews Ratings": "Recenzje i oceny",
      "No Review Added Yet": "Nie dodano jeszcze recenzji",
      "Submit": "Wyślij",
      "review": "Recenzja",
      "Same Country": "Mój kraj",
      "View All ": "Zobacz wszystko "
    },
    review: {
      "Reviews": "Recenzje",
      "Add a Review": "Dodaj recenzję",
      "View Review": "Zobacz recenzje",
      "Review Rating": "Oceny recenzji",
      "Write Review": "Napisz recenzję",
      "Submit": "Wyślij",
      "Sort By": "Sortuj według",
      "Most Recent": "Najnowsze",
      "Top Rating": "Najwyższa ocena",
      "Like High to Low": "Lubić od najwyższej do najniższej",
      "Apply": "Zastosuj",
      "Clear": "Wyczyść",
      "Filter By": "Filtruj według",
      "Review not available": "Brak recenzji",
      "Click to select": "Kliknij, aby wybrać",
      "stars": "Gwiazdki",
      "You have Selected": "Wybrałeś: "
    },
    productList: {
      "Home": "Strona główna",
      "Product Categories": "Główne kategorie",
      "Brands": "Marki",
      "Product Sub Categories": "Kategorie",
      "Product Sub Sub Categories": "Podkategorie",
      "Products": "Produkty",
      "Sort By": "Sortuj według",
      "Popularity": "Popularność",
      "Low to High": "Od najniższej do najwyższej",
      "High to Low": "Od najwyższej do najniższej",
      "Apply": "Zastosuj",
      "Clear": "Wyczyść",
      "Filter By": "Filtruj według",
      "Brand Name": "Nazwa marki",
      "Select": "Wybierz",
      "Min Price": "Minimalna cena",
      "Max Price": "Maksymalna cena",
      "Product not available": "Produkt niedostępny"
    },
    productSubCategory: {
      "Home": "Strona główna",
      "Product Categories": "Główne kategorie",
      "Product Sub Categories": "Kategorie",
      "Product Sub Sub Categories": "Podkategorie",
      "Sub category not available": "Podkategoria niedostępna",
      "Category not available": "Kategoria niedostępna"
    },
    recommendationList: {
      "Home": "Strona główna",
      "Our Recommendation": "Nasze rekomendacje",
      "No product available": "Brak dostępnych produktów"
    },
    search: {
      "Search": "Szukaj",
      "Product Categories": "Kategorie",
      "View all": "Zobacz wszystko",
      "Brands": "Marki",
      "Products": "Produkty",
      "Filter By": "Filtruj według",
      "Brand Name": "Nazwa marki",
      "Select": "Wybierz",
      "Ingredients": "Składniki",
      "Ingredient": "Składnik",
      "Include": "Zawiera",
      "Not Include": "Nie zawiera",
      "Min Price": "Minimalna cena",
      "Max Price": "Maksymalna cena",
      "Customer Reviews": "Opinie klientów",
      "Customer Ratings": "Oceny klientów",
      "Apply": "Zastosuj",
      "Clear": "Wyczyść",
      "Product not available": "Produkt niedostępny",
      "Brands not available": "Marka niedostępna",
    },
    similar: {
      "Product Detail": "Szczegóły produktu",
      "Similar Products": "Podobne produkty",
      "Product not available": "Produkt niedostępny"
    },
    activity: {
      "View Activity": "Zobacz aktywność",
    },
    home: {
      "Product Categories": "Kategorie",
      "View all": "Zobacz wszystko",
      "Brands": "Marki",
      "Highest Rated": "Najwyżej oceniane",
      "Last Viewed Products": "Ostatnio przeglądane produkty",
      "Latest Blog Post": "Najnowszy wpis na blogu",
      "Instagram Post": "Posty z Instagrama",
    },
    share: {
      "Share": "Udostępnij",
      "Copy Link": "Kopiuj link",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Pomyślnie skopiowano do schowka",
    },
    validation: {
      "Please enter": "Wprowadź",
      "Please select": "Wybierz",
      "This field is required": "To pole jest wymagane",
      "Please enter valid email": "Wprowadź poprawny adres e-mail",
      "Please enter valid contact number": "Wprowadź poprawny numer kontaktowy",
      "Please enter valid pin code": "Wprowadź poprawny kod pocztowy",
      "Please enter valid": "Wprowadź poprawne",
      "Please enter valid name": "Wprowadź poprawne imię",
      "This field must be at least": "To pole musi mieć przynajmniej",
      "characters": "znaki",
      "Space is not allowed in password": "W haśle nie można używać spacji",
      "Passwords must match": "Hasła muszą być takie same",
      "Please enter valid price": "Wprowadź poprawną cenę",
      "Please enter numeric values only": "Wprowadź tylko wartości numeryczne"
    },
    webService: {
      "Server Not Responding": "Serwer nie odpowiada",
      "Invalid Username or Password": "Niepoprawna nazwa użytkownika lub hasło",
      "Bad Response": "Zła odpowiedź",
      "Server Error": "Błąd serwera",
    }
  },
  pt: {
    new: {
      "Login to Grade my beauty account using your credintials": "Faça login na sua conta GradeMyBeauty com suas credenciais",
      "Create An Account": "Crie uma conta",
      "OR Continue with": "Ou continue com:",
      "Already Have An Account": "Já possui uma conta",
      "Please enter following details to sign up": "Por favor, insira os seguintes detalhes para se inscrever",
      "Success": "Sucesso",
      "OTP has been verified": "OTP foi verificado",
      "successfully": "Com sucesso.",
      "Go To Login": "Ir para login",
      "Verify OTP": "Verificar OTP",
      "Verify OTP which we have sent on your email": "Verifique o OTP que enviamos para o seu e-mail.",
      "Verify": "Verificar",
      "Select Country": "Selecionar país",
      "Send": "Enviar",
      "Explore More": "Explorar mais",
      "Categories": "Categorias",
      "View All": "View All",
      "View All Categories": "Ver todas as categorias",
      "View All Brands": "Ver todas as marcas",
      "Subscribe to our newsletter": "Inscreva-se na nossa newsletter.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Fique atento, inscreva-se na nossa newsletter e mantenha-se atualizado.",
      "Subscribe": "Inscrever-se",
      "Hot Posts": "Posts populares",
      "No post found": "Nenhum post encontrado",
      "Follow the Topic": "Seguir o tópico",
      "Follow": "Seguir",
      "UserName": "UserName",
      "UnFollow": "Deixar de seguir",
      "No topics found": "Nenhum tópico encontrado",
      "People you should follow": "Pessoas que você deveria seguir",
      "No users found": "Nenhum usuário encontrado",
      "Back": "Voltar",
      "Edit Profile": "Editar perfil",
      "alergic ingredient": "Alergênicos",
      "favorite ingredient": "Ingredientes favoritos",
      "Products": "Produtos",
      "Read More": "Ler mais",
      "View All Products": "Ver todos os produtos",
      "All": "Todos",
      "Create": "Criar",
      "Create Post": "Criar post",
      "Start Discussion": "Iniciar discussão",
      "Phone": "Telefone",
      "Post": "Post",
      "Discussion": "Discussão",
      "Start New Discussion": "Iniciar nova discussão",
      "Public": "Público",
      "Private": "Privado",
      "Select Topic": "Selecionar tópico",
      "Review Posted Successfully": "Avaliação publicada com sucesso!",
      "5 Points Earned!": "5 pontos ganhos!",
      "Comments": "Comentários",
      "Replies": "Respostas",
      "No comments found": "Nenhum comentário encontrado",
      "Reply": "Responder",
      "Delete": "Deletar",
      "Cancel": "Cancelar",
      "Are you sure, you want to Delete?": "Tem certeza de que deseja deletar?",
      "Type your reply": "Digite sua resposta...",
      "Search": "Pesquisar",
      "Edit": "Editar",
      "Reward Points": "Pontos de recompensa",
      "Points Earned Till Now!": "Pontos ganhos até agora",
      "Earn More Rewards": "Ganhe mais recompensas",
      "Earn Reward Points": "Ganhe pontos de recompensa",
      "Earn reward points by posting product review and posting on the discussion": "Ganhe pontos de recompensa publicando avaliações de produtos e participando de discussões.",
      "About": "Sobre",
      "Product Review": "Avaliação de Produto",
      "Your review has been": "Sua avaliação foi",
      "posted successfully": "publicada com sucesso.",
      "Ingredients": "Ingredientes",
      "Write to Review": "Escrever uma avaliação",
      "Product List": "Lista de Produtos",
      "Yes": "Sim",
      "No": "Não",
      "Apply": "Aplicar",
      "Clear": "Limpar",
      "Profile": "Perfil",
      "Like": "Curtir",
      "View Activity": "Ver atividade",
      "View All Blogs": "Ver todos os blogs",
    },
    login: {
      "Email": "E-mail",
      "Password": "Senha",
      "Forgot Password": "Esqueceu a senha?",
      "Log in": "Entrar",
      "Dont have an account": "Não tem uma conta",
      "Sign Up": "Cadastre-se",
      "OR Login With": "Ou entrar com"
    },
    signup: {
      "Create a fee account within few minutes": "Crie uma conta grátis em poucos minutos.",
      "Have access to a large beauty database": "Tenha acesso a informações sobre todos os produtos de beauty.",
      "Read what other beauty specialists say": "Leia o que outros especialistas em beauty dizem.",
      "List your favorite products": "Crie uma lista dos seus produtos favoritos.",
      "Name": "Nome",
      "Email": "E-mail",
      "Mobile Number": "Número de celular",
      "Country": "País",
      "Select": "Selecionar",
      "Password": "Senha",
      "Confirm Password": "Confirmar senha",
      "Create": "Criar",
      "By creating an account you have to agree with our": "Ao criar uma conta, você concorda com nossos",
      "terms conditions": "Termos & Condições.",
      "Subscribe for newsletter": "Inscreva-se na nossa newsletter",
      "Already have an account": "Já tem uma conta?",
      "Login": "Entrar",
      "OR Login With": "Ou entrar com",
    },
    ForgetPassword: {
      "Forgot your password": "Esqueceu sua senha?",
      "Enter your registered email address to get a link to reset your password": "Digite seu endereço de e-mail registrado para receber um link para redefinir sua senha",
      "Email": "E-mail",
      "Submit": "Enviar",
      "Back to login": "Voltar para o login",
      "Login": "Entrar",
      "Successful": "Sucesso!",
      "We have sent an OTP": "Enviamos um código de confirmação",
      "to your email address": "para o seu endereço de e-mail",
      "OK": "Ok",
      "Didnt received yet": "Não recebeu o código?",
      "Resend": "Solicitar novamente.",
    },
    Header: {
      "Favorite": "Meu Beauty",
      "Account": "Conta",
      "My Profile": "Meu perfil",
      "Notifications": "Notificações",
      "Settings": "Configurações",
      "Logout": "Sair",
      "Login": "Entrar",
      "Signup": "Cadastre-se",
      "All": "Todos",
    },
    Footer: {
      "About Us": "Sobre nós",
      "Contact Us": "Fale conosco",
      "Terms Conditions": "Termos & Condições",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Política de Privacidade",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Todos os direitos reservados."
    },
    contact: {
      "Message": "Mensagem",
      "Message Error": "Por favor, insira uma mensagem",
      "Subject": "Assunto",
      "Subject Error": "Por favor, insira um assunto",
      "Email": "E-mail",
      "Email Error": "Por favor, insira o e-mail",
      "Name": "Nome",
      "Name Error": "Por favor, insira o nome"
    },
    Delete: {
      "Alert": "Alerta",
      "Are you sure you want to delete": "Tens certeza que queres apagar?",
      "Yes": "Sim",
      "No": "Não",
    },
    Thankyou: {
      "Thank You": "Obrigado",
      "Our Admin team will verify this request and contact you": "Nossa equipe de atendimento verificará este pedido e entrará em contato contigo",
      "OK": "Ok"
    },
    TimePicker: {
      "Hours": "Horas",
      "Minuts": "Minutos"
    },
    recommendation: {
      "Our Recommendations": "Nossas recomendações",
      "View all": "Ver tudo",
    },
    settings: {
      "Settings": "Configurações",
      "Change Password": "Alterar senha",
      "Newsletter": "Newsletter",
      "Current Password": "Senha atual",
      "New Password": "Nova senha",
      "Confirm Password": "Confirmar senha",
      "Save": "Salvar",
      "Newsletter Notifications": "Newsletter"
    },
    notification: {
      "Home": "Início",
      "Notifications": "Notificações",
      "Notification not available": "Notificação não disponível"
    },
    blog: {
      "Home": "Início",
      "Blogs": "Blogs",
      "Latest Blog Post": "Última publicação do blog",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Por favor, tente novamente",
      "Loading": "Carregando...",
      "Looks like something went wrong": "Parece que algo deu errado!",
      "Error": "Erro",
      "retry": "Tentar novamente"
    },
    brand: {
      "Home": "Início",
      "Brands": "Marcas",
      "No brand available": "Esta marca não está disponível..",
      "Select to search": "Selecionar para pesquisar"
    },
    pagination: {
      "Previous": "Anterior",
      "Next": "Próximo"
    },
    customerDetail: {
      "Follow": "Seguir",
      "Following": "Seguindo",
      "Followers": "Seguidores",
      "Country": "País",
      "About Me": "Sobre mim",
      "View Activity": "Ver atividade",
      "Hair Style": "Estilo de cabelo",
      "Skin Tone": "Tom de pele",
      "Favorite Products": "Meus produtos de beauty",
      "View all": "Ver tudo",
      "No favorite available": "Não tens nenhum produto favorito ainda."
    },
    highRated: {
      "Home": "Início",
      "Highest Rated": "Mais bem avaliado",
      "Highest Rated Products": "Produtos mais bem avaliados",
      "Load More": "Carregar mais",
      "No high rated products available": "Nenhum produto altamente avaliado disponível"
    },
    instaList: {
      "Home": "Início",
      "Instagram posts": "Publicações do Instagram",
    },
    lastViewed: {
      "Home": "Início",
      "Last Viewed": "Últimos visualizados",
      "Last Viewed Products": "Produtos vistos recentemente",
      "Product not available": "Nenhum produto para mostrar aqui ainda."
    },
    myProfile: {
      "Home": "Início",
      "My Profile": "Meu perfil",
      "Change photo": "Alterar foto",
      "Name": "Nome",
      "Email": "Email",
      "Mobile Number": "Número de telemóvel",
      "Country": "País",
      "Hair Style": "Estilo de cabelo",
      "Select Hair": "Selecionar tipo de cabelo",
      "Wavy Hair": "Cabelo ondulado",
      "Curly Hair": "Cabelo encaracolado",
      "Straight Hair": "Cabelo liso",
      "Kinky Hair": "Cabelo crespo",
      "Instagram": "Instagram",
      "Skin Tone": "Tom de pele",
      "Alergic Ingredients": "Alérgenos",
      "Favourite Ingredients": "Ingredientes favoritos",
      "About Me": "Sobre mim",
      "Submit": "Enviar",
      "Select": "Selecionar",
      "EAN Code": "Código EAN"
    },
    otp: {
      "OTP Confirmation": "Verificação de OTP",
      "Please enter the verification code sent to your email": "Por favor, insere o código de verificação enviado para o teu email.",
      "OTP is required": "Código é necessário",
      "Submit": "Enviar",
      "Resend OTP": "Reenviar código",
    },
    wishList: {
      "Home": "Início",
      "Favorite": "Meu Beauty",
      "Sort By": "Ordenar por",
      "Popularity": "Popularidade",
      "Low to High": "De baixo para alto",
      "High to Low": "De alto para baixo",
      "Apply": "Aplicar",
      "Clear": "Limpar",
      "Filter By": "Filtrar por",
      "Brand Name": "Nome da marca",
      "Min Price": "Preço mínimo",
      "Max Price": "Preço máximo",
      "Select": "Selecionar",
      "No favorite available": "Não tens nenhum produto favorito ainda."
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Nova senha",
      "Confirm Password": "Confirmar senha",
      "Submit": "Enviar"
    },
    productCategory: {
      "Home": "Início",
      "Product Categories": "Categorias de produtos",
    },
    myReview: {
      "My Review": "Minha avaliação",
      "Write Review": "Escrever avaliação",
      "review": "Avaliação",
      "Submit": "Enviar"
    },
    productDetail: {
      "Home": "Início",
      "Category": "Categoria",
      "Sub Category": "Subcategoria",
      "Brands": "Marcas",
      "Highest Rated": "Mais bem avaliados",
      "Last Viewed": "Últimos vistos",
      "Recommendations": "Recomendações",
      "Products": "Produtos",
      "Product Detail": "Detalhes do produto",
      "USD": "USD",
      "Write Review": "Escrever avaliação",
      "View Review": "Ver avaliações",
      "Description": "Descrição",
      "Read": "Ler",
      "Less": "Menos",
      "More": "Mais",
      "Ingredient": "Ingredientes",
      "Similar Products": "Produtos similares",
      "View all": "Ver todos",
      "Reviews Ratings": "Avaliações e classificações",
      "No Review Added Yet": "Nenhuma avaliação adicionada ainda",
      "Submit": "Enviar",
      "review": "Avaliação",
      "Same Country": "Meu país",
      "View All ": "Ver todos "
    },
    review: {
      "Reviews": "Avaliações",
      "Add a Review": "Adicionar uma avaliação",
      "View Review": "Ver avaliações",
      "Review Rating": "Avaliações e classificações",
      "Write Review": "Escrever avaliação",
      "Submit": "Enviar",
      "Sort By": "Ordenar por",
      "Most Recent": "Mais recentes",
      "Top Rating": "Melhores avaliações",
      "Like High to Low": "Curtidas do maior para o menor",
      "Apply": "Aplicar",
      "Clear": "Limpar",
      "Filter By": "Filtrar por",
      "Review not available": "Avaliação não disponível",
      "Click to select": "Clique para selecionar",
      "stars": "estrelas",
      "You have Selected": "Você selecionou: "
    },
    productList: {
      "Home": "Início",
      "Product Categories": "Categorias principais",
      "Brands": "Marcas",
      "Product Sub Categories": "Categorias",
      "Product Sub Sub Categories": "Subcategorias",
      "Products": "Produtos",
      "Sort By": "Ordenar por",
      "Popularity": "Popularidade",
      "Low to High": "Do menor para o maior",
      "High to Low": "Do maior para o menor",
      "Apply": "Aplicar",
      "Clear": "Limpar",
      "Filter By": "Filtrar por",
      "Brand Name": "Nome da marca",
      "Select": "Selecionar",
      "Min Price": "Preço mínimo",
      "Max Price": "Preço máximo",
      "Product not available": "Produto não disponível"
    },
    productSubCategory: {
      "Home": "Início",
      "Product Categories": "Categorias principais",
      "Product Sub Categories": "Categorias",
      "Product Sub Sub Categories": "Subcategorias",
      "Sub category not available": "Subcategoria não disponível",
      "Category not available": "Categoria não disponível"
    },
    recommendationList: {
      "Home": "Início",
      "Our Recommendation": "Nossas recomendações",
      "No product available": "Nenhum produto disponível"
    },
    search: {
      "Search": "Buscar",
      "Product Categories": "Categorias",
      "View all": "Ver todos",
      "Brands": "Marcas",
      "Products": "Produtos",
      "Filter By": "Filtrar por",
      "Brand Name": "Nome da marca",
      "Select": "Selecionar",
      "Ingredients": "Ingredientes",
      "Ingredient": "Ingrediente",
      "Include": "Incluir",
      "Not Include": "Não incluir",
      "Min Price": "Preço mínimo",
      "Max Price": "Preço máximo",
      "Customer Reviews": "Avaliações dos clientes",
      "Customer Ratings": "Classificações dos clientes",
      "Apply": "Aplicar",
      "Clear": "Limpar",
      "Product not available": "Produto não disponível",
      "Brands not available": "Marcas não disponíveis",
    },
    similar: {
      "Product Detail": "Detalhe do produto",
      "Similar Products": "Produtos similares",
      "Product not available": "Produto não disponível"
    },
    activity: {
      "View Activity": "Ver atividade",
    },
    home: {
      "Product Categories": "Categorias",
      "View all": "Ver todos",
      "Brands": "Marcas",
      "Highest Rated": "Mais bem avaliados",
      "Last Viewed Products": "Últimos produtos vistos",
      "Latest Blog Post": "Última postagem no blog",
      "Instagram Post": "Postagens no Instagram",
    },
    share: {
      "Share": "Compartilhar",
      "Copy Link": "Copiar link",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Copiado com sucesso para a área de transferência",
    },
    validation: {
      "Please enter": "Por favor, insira",
      "Please select": "Por favor, selecione",
      "This field is required": "Este campo é obrigatório",
      "Please enter valid email": "Por favor, insira um endereço de e-mail válido",
      "Please enter valid contact number": "Por favor, insira um número de contato válido",
      "Please enter valid pin code": "Por favor, insira um CEP válido",
      "Please enter valid": "Por favor, insira válido",
      "Please enter valid name": "Por favor, insira um nome válido",
      "This field must be at least": "Este campo deve ter pelo menos",
      "characters": "caracteres",
      "Space is not allowed in password": "Espaço não é permitido na senha",
      "Passwords must match": "As senhas devem ser iguais",
      "Please enter valid price": "Por favor, insira um preço válido",
      "Please enter numeric values only": "Por favor, insira apenas valores numéricos"
    },
    webService: {
      "Server Not Responding": "Servidor não está respondendo",
      "Invalid Username or Password": "Nome de usuário ou senha inválidos",
      "Bad Response": "Resposta ruim",
      "Server Error": "Erro no servidor",
    }
  },
  ru: {
    new: {
      "Login to Grade my beauty account using your credintials": "Войдите в свой аккаунт GradeMyBeauty, используя свои учетные данные",
      "Create An Account": "Создать аккаунт",
      "OR Continue with": "Или продолжить с:",
      "Already Have An Account": "Уже есть аккаунт",
      "Please enter following details to sign up": "Пожалуйста, введите следующие данные для регистрации",
      "Success": "Успех",
      "OTP has been verified": "OTP был проверен",
      "successfully": "Успешно.",
      "Go To Login": "Перейти к входу",
      "Verify OTP": "Проверить OTP",
      "Verify OTP which we have sent on your email": "Проверьте OTP, который мы отправили на вашу электронную почту.",
      "Verify": "Проверить",
      "Select Country": "Выбрать страну",
      "Send": "Отправить",
      "Explore More": "Узнать больше",
      "Categories": "Категории",
      "View All": "View All",
      "View All Categories": "Посмотреть все категории",
      "View All Brands": "Посмотреть все бренды",
      "Subscribe to our newsletter": "Подписаться на нашу рассылку.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Оставайтесь на связи, подписывайтесь на нашу рассылку и будьте в курсе.",
      "Subscribe": "Подписаться",
      "Hot Posts": "Горячие посты",
      "No post found": "Посты не найдены",
      "Follow the Topic": "Следить за темой",
      "Follow": "Следить",
      "UserName": "UserName",
      "UnFollow": "Отписаться",
      "No topics found": "Темы не найдены",
      "People you should follow": "Люди, за которыми стоит следить",
      "No users found": "Пользователи не найдены",
      "Back": "Назад",
      "Edit Profile": "Редактировать профиль",
      "alergic ingredient": "Аллергены",
      "favorite ingredient": "Любимые ингредиенты",
      "Products": "Продукция",
      "Read More": "Читать далее",
      "View All Products": "Смотреть все продукты",
      "All": "Все",
      "Create": "Создать",
      "Create Post": "Создать пост",
      "Start Discussion": "Начать обсуждение",
      "Phone": "Телефон",
      "Post": "Пост",
      "Discussion": "Обсуждение",
      "Start New Discussion": "Начать новое обсуждение",
      "Public": "Публичный",
      "Private": "Частный",
      "Select Topic": "Выбрать тему",
      "Review Posted Successfully": "Отзыв успешно опубликован!",
      "5 Points Earned!": "Заработано 5 очков!",
      "Comments": "Комментарии",
      "Replies": "Ответы",
      "No comments found": "Комментарии не найдены",
      "Reply": "Ответить",
      "Delete": "Удалить",
      "Cancel": "Отменить",
      "Are you sure, you want to Delete?": "Вы уверены, что хотите удалить?",
      "Type your reply": "Введите ваш ответ...",
      "Search": "Поиск",
      "Edit": "Редактировать",
      "Reward Points": "Бонусные очки",
      "Points Earned Till Now!": "Набранные очки на данный момент",
      "Earn More Rewards": "Получайте больше наград",
      "Earn Reward Points": "Зарабатывайте бонусные очки",
      "Earn reward points by posting product review and posting on the discussion": "Зарабатывайте бонусные очки, размещая отзывы о продукции и участвуя в обсуждениях.",
      "About": "О",
      "Product Review": "Обзор продукции",
      "Your review has been": "Ваш отзыв был",
      "posted successfully": "успешно опубликован.",
      "Ingredients": "Ингредиенты",
      "Write to Review": "Написать отзыв",
      "Product List": "Список продукции",
      "Yes": "Да",
      "No": "Нет",
      "Apply": "Применить",
      "Clear": "Очистить",
      "Profile": "Профиль",
      "Like": "Нравится",
      "View Activity": "Посмотреть активность",
      "View All Blogs": "Посмотреть все блоги",
    },
    login: {
      "Email": "Электронная почта",
      "Password": "Пароль",
      "Forgot Password": "Забыли пароль?",
      "Log in": "Войти",
      "Dont have an account": "У вас нет аккаунта?",
      "Sign Up": "Регистрация",
      "OR Login With": "Или войдите через"
    },
    signup: {
      "Create a fee account within few minutes": "Создайте бесплатный аккаунт за несколько минут.",
      "Have access to a large beauty database": "Получите доступ к информации о всех продуктах beauty.",
      "Read what other beauty specialists say": "Читайте, что говорят другие специалисты по beauty.",
      "List your favorite products": "Создайте список своих любимых продуктов.",
      "Name": "Имя",
      "Email": "Электронная почта",
      "Mobile Number": "Мобильный номер",
      "Country": "Страна",
      "Select": "Выбрать",
      "Password": "Пароль",
      "Confirm Password": "Подтвердите пароль",
      "Create": "Создать",
      "By creating an account you have to agree with our": "Создавая аккаунт, вы соглашаетесь с нашими",
      "terms conditions": "Условиями и Положениями.",
      "Subscribe for newsletter": "Подписаться на новостную рассылку",
      "Already have an account": "Уже есть аккаунт?",
      "Login": "Войти",
      "OR Login With": "Или войдите через",
    },
    ForgetPassword: {
      "Forgot your password": "Забыли пароль?",
      "Enter your registered email address to get a link to reset your password": "Введите ваш зарегистрированный адрес электронной почты, чтобы получить ссылку для сброса пароля",
      "Email": "Электронная почта",
      "Submit": "Отправить",
      "Back to login": "Вернуться к входу",
      "Login": "Войти",
      "Successful": "Успешно!",
      "We have sent an OTP": "Мы отправили код подтверждения",
      "to your email address": "на ваш адрес электронной почты",
      "OK": "Ок",
      "Didnt received yet": "Не получили код?",
      "Resend": "Запросить снова.",
    },
    Header: {
      "Favorite": "Мой Beauty",
      "Account": "Аккаунт",
      "My Profile": "Мой профиль",
      "Notifications": "Уведомления",
      "Settings": "Настройки",
      "Logout": "Выход",
      "Login": "Войти",
      "Signup": "Регистрация",
      "All": "Все",
    },
    Footer: {
      "About Us": "О нас",
      "Contact Us": "Свяжитесь с нами",
      "Terms Conditions": "Условия и Положения",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Политика конфиденциальности",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Все права защищены."
    },
    contact: {
      "Message": "Сообщение",
      "Message Error": "Пожалуйста, введите сообщение",
      "Subject": "Тема",
      "Subject Error": "Пожалуйста, введите тему",
      "Email": "Электронная почта",
      "Email Error": "Пожалуйста, введите электронную почту",
      "Name": "Имя",
      "Name Error": "Пожалуйста, введите имя"
    },
    Delete: {
      "Alert": "Предупреждение",
      "Are you sure you want to delete": "Ты действительно хочешь удалить?",
      "Yes": "Да",
      "No": "Нет",
    },
    Thankyou: {
      "Thank You": "Спасибо",
      "Our Admin team will verify this request and contact you": "Наша команда клиентской поддержки проверит этот запрос и свяжется с тобой",
      "OK": "Ок"
    },
    TimePicker: {
      "Hours": "Часы",
      "Minuts": "Минуты"
    },
    recommendation: {
      "Our Recommendations": "Наши рекомендации",
      "View all": "Посмотреть все",
    },
    settings: {
      "Settings": "Настройки",
      "Change Password": "Сменить пароль",
      "Newsletter": "Новостная рассылка",
      "Current Password": "Текущий пароль",
      "New Password": "Новый пароль",
      "Confirm Password": "Подтвердите пароль",
      "Save": "Сохранить",
      "Newsletter Notifications": "Новостная рассылка"
    },
    notification: {
      "Home": "Главная",
      "Notifications": "Уведомления",
      "Notification not available": "Уведомления отсутствуют"
    },
    blog: {
      "Home": "Главная",
      "Blogs": "Блоги",
      "Latest Blog Post": "Последний пост в блоге",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Пожалуйста, попробуйте снова",
      "Loading": "Загрузка...",
      "Looks like something went wrong": "Похоже, что-то пошло не так!",
      "Error": "Ошибка",
      "retry": "Повторить"
    },
    brand: {
      "Home": "Главная",
      "Brands": "Бренды",
      "No brand available": "Этот бренд недоступен..",
      "Select to search": "Выбрать для поиска"
    },
    pagination: {
      "Previous": "Назад",
      "Next": "Вперед"
    },
    customerDetail: {
      "Follow": "Подписаться",
      "Following": "Подписан",
      "Followers": "Подписчики",
      "Country": "Страна",
      "About Me": "Обо мне",
      "View Activity": "Посмотреть активность",
      "Hair Style": "Прическа",
      "Skin Tone": "Тон кожи",
      "Favorite Products": "Мои продукты по beauty",
      "View all": "Посмотреть все",
      "No favorite available": "У тебя пока нет любимых продуктов."
    },
    highRated: {
      "Home": "Главная",
      "Highest Rated": "Самые высоко оцененные",
      "Highest Rated Products": "Самые высоко оцененные продукты",
      "Load More": "Показать еще",
      "No high rated products available": "Товары с высокими оценками отсутствуют"
    },
    instaList: {
      "Home": "Главная",
      "Instagram posts": "Посты в Instagram",
    },
    lastViewed: {
      "Home": "Главная",
      "Last Viewed": "Последние просмотренные",
      "Last Viewed Products": "Последние просмотренные товары",
      "Product not available": "Товары для отображения пока отсутствуют."
    },
    myProfile: {
      "Home": "Главная",
      "My Profile": "Мой профиль",
      "Change photo": "Сменить фото",
      "Name": "Имя",
      "Email": "Эл. почта",
      "Mobile Number": "Мобильный номер",
      "Country": "Страна",
      "Hair Style": "Прическа",
      "Select Hair": "Выбрать тип волос",
      "Wavy Hair": "Волнистые волосы",
      "Curly Hair": "Кудрявые волосы",
      "Straight Hair": "Прямые волосы",
      "Kinky Hair": "Кинки волосы",
      "Instagram": "Instagram",
      "Skin Tone": "Тон кожи",
      "Alergic Ingredients": "Аллергены",
      "Favourite Ingredients": "Любимые ингредиенты",
      "About Me": "Обо мне",
      "Submit": "Отправить",
      "Select": "Выбрать",
      "EAN Code": "Код EAN"
    },
    otp: {
      "OTP Confirmation": "Подтверждение OTP",
      "Please enter the verification code sent to your email": "Пожалуйста, введи код подтверждения, отправленный на твою электронную почту.",
      "OTP is required": "Требуется код",
      "Submit": "Отправить",
      "Resend OTP": "Отправить код повторно",
    },
    wishList: {
      "Home": "Главная",
      "Favorite": "Моя Beauty",
      "Sort By": "Сортировать по",
      "Popularity": "Популярность",
      "Low to High": "От низкой к высокой",
      "High to Low": "От высокой к низкой",
      "Apply": "Применить",
      "Clear": "Очистить",
      "Filter By": "Фильтр по",
      "Brand Name": "Название бренда",
      "Min Price": "Минимальная цена",
      "Max Price": "Максимальная цена",
      "Select": "Выбрать",
      "No favorite available": "У тебя еще нет любимых продуктов."
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Новый пароль",
      "Confirm Password": "Подтвердите пароль",
      "Submit": "Отправить"
    },
    productCategory: {
      "Home": "Главная",
      "Product Categories": "Категории продуктов"
    },
    myReview: {
      "My Review": "Мой отзыв",
      "Write Review": "Написать отзыв",
      "review": "Отзыв",
      "Submit": "Отправить"
    },
    productDetail: {
      "Home": "Домой",
      "Category": "Категория",
      "Sub Category": "Подкатегория",
      "Brands": "Бренды",
      "Highest Rated": "Самый высокий рейтинг",
      "Last Viewed": "Последний просмотр",
      "Recommendations": "Рекомендации",
      "Products": "Товары",
      "Product Detail": "Детали товара",
      "USD": "USD",
      "Write Review": "Написать отзыв",
      "View Review": "Просмотреть отзывы",
      "Description": "Описание",
      "Read": "Читать",
      "Less": "Меньше",
      "More": "Больше",
      "Ingredient": "Ингредиенты",
      "Similar Products": "Похожие товары",
      "View all": "Просмотреть все",
      "Reviews Ratings": "Отзывы и рейтинги",
      "No Review Added Yet": "Отзывы пока нет",
      "Submit": "Отправить",
      "review": "Отзыв",
      "Same Country": "Моя страна",
      "View All ": "Просмотреть все "
    },
    review: {
      "Reviews": "Отзывы",
      "Add a Review": "Добавить отзыв",
      "View Review": "Посмотреть отзывы",
      "Review Rating": "Оценки и отзывы",
      "Write Review": "Написать отзыв",
      "Submit": "Отправить",
      "Sort By": "Сортировать по",
      "Most Recent": "Самые новые",
      "Top Rating": "Самые высокие оценки",
      "Like High to Low": "Лайки от многих к меньшему",
      "Apply": "Применить",
      "Clear": "Очистить",
      "Filter By": "Фильтровать по",
      "Review not available": "Отзывы отсутствуют",
      "Click to select": "Кликните для выбора",
      "stars": "Звёзды",
      "You have Selected": "Вы выбрали: "
    },
    productList: {
      "Home": "Главная",
      "Product Categories": "Основные категории",
      "Brands": "Бренды",
      "Product Sub Categories": "Категории",
      "Product Sub Sub Categories": "Подкатегории",
      "Products": "Товары",
      "Sort By": "Сортировать по",
      "Popularity": "Популярность",
      "Low to High": "От низкого к высокому",
      "High to Low": "От высокого к низкому",
      "Apply": "Применить",
      "Clear": "Очистить",
      "Filter By": "Фильтровать по",
      "Brand Name": "Название бренда",
      "Select": "Выбрать",
      "Min Price": "Минимальная цена",
      "Max Price": "Максимальная цена",
      "Product not available": "Товар отсутствует"
    },
    productSubCategory: {
      "Home": "Главная",
      "Product Categories": "Основные категории",
      "Product Sub Categories": "Категории",
      "Product Sub Sub Categories": "Подкатегории",
      "Sub category not available": "Категория отсутствует",
      "Category not available": "Категория отсутствует"
    },
    recommendationList: {
      "Home": "Главная",
      "Our Recommendation": "Наши рекомендации",
      "No product available": "Товары отсутствуют"
    },
    search: {
      "Search": "Поиск",
      "Product Categories": "Категории",
      "View all": "Посмотреть все",
      "Brands": "Бренды",
      "Products": "Товары",
      "Filter By": "Фильтровать по",
      "Brand Name": "Название бренда",
      "Select": "Выбрать",
      "Ingredients": "Ингредиенты",
      "Ingredient": "Ингредиент",
      "Include": "Включить",
      "Not Include": "Не включать",
      "Min Price": "Минимальная цена",
      "Max Price": "Максимальная цена",
      "Customer Reviews": "Отзывы клиентов",
      "Customer Ratings": "Оценки клиентов",
      "Apply": "Применить",
      "Clear": "Очистить",
      "Product not available": "Товары отсутствуют",
      "Brands not available": "Бренды отсутствуют",
    },
    similar: {
      "Product Detail": "Детали товара",
      "Similar Products": "Похожие товары",
      "Product not available": "Товар отсутствует"
    },
    activity: {
      "View Activity": "Посмотреть активность",
    },
    home: {
      "Product Categories": "Категории",
      "View all": "Посмотреть все",
      "Brands": "Бренды",
      "Highest Rated": "С наивысшей оценкой",
      "Last Viewed Products": "Последние просмотренные товары",
      "Latest Blog Post": "Последний пост в блоге",
      "Instagram Post": "Посты в Instagram",
    },
    share: {
      "Share": "Поделиться",
      "Copy Link": "Скопировать ссылку",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Успешно скопировано в буфер обмена",
    },
    validation: {
      "Please enter": "Пожалуйста, введите",
      "Please select": "Пожалуйста, выберите",
      "This field is required": "Это поле обязательно",
      "Please enter valid email": "Пожалуйста, введите действительный адрес электронной почты",
      "Please enter valid contact number": "Пожалуйста, введите действительный контактный номер",
      "Please enter valid pin code": "Пожалуйста, введите действительный почтовый индекс",
      "Please enter valid": "Пожалуйста, введите действительный",
      "Please enter valid name": "Пожалуйста, введите настоящее имя",
      "This field must be at least": "Это поле должно содержать как минимум",
      "characters": "символов",
      "Space is not allowed in password": "Пробелы в пароле не допускаются",
      "Passwords must match": "Пароли должны совпадать",
      "Please enter valid price": "Пожалуйста, введите действительную цену",
      "Please enter numeric values only": "Пожалуйста, вводите только числовые значения"
    },
    webService: {
      "Server Not Responding": "Сервер не отвечает",
      "Invalid Username or Password": "Неверное имя пользователя или пароль",
      "Bad Response": "Плохой ответ",
      "Server Error": "Ошибка сервера",
    }
  },
  swe: {
    new: {
      "Login to Grade my beauty account using your credintials": "Logga in på ditt GradeMyBeauty-konto med dina uppgifter",
      "Create An Account": "Skapa ett konto",
      "OR Continue with": "Eller fortsätt med:",
      "Already Have An Account": "Har du redan ett konto",
      "Please enter following details to sign up": "Vänligen ange följande uppgifter för att registrera dig",
      "Success": "Framgång",
      "OTP has been verified": "OTP har verifierats",
      "successfully": "Framgångsrikt.",
      "Go To Login": "Gå till inloggning",
      "Verify OTP": "Verifiera OTP",
      "Verify OTP which we have sent on your email": "Verifiera OTP som vi har skickat till din e-post.",
      "Verify": "Verifiera",
      "Select Country": "Välj land",
      "Send": "Skicka",
      "Explore More": "Utforska mer",
      "Categories": "Kategorier",
      "View All": "View All",
      "View All Categories": "Visa alla kategorier",
      "View All Brands": "Visa alla märken",
      "Subscribe to our newsletter": "Prenumerera på vårt nyhetsbrev.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Håll dig uppdaterad, prenumerera på vårt nyhetsbrev och håll dig informerad.",
      "Subscribe": "Prenumerera",
      "Hot Posts": "Heta inlägg",
      "No post found": "Inga inlägg hittades",
      "Follow the Topic": "Följ ämnet",
      "Follow": "Följ",
      "UserName": "UserName",
      "UnFollow": "Sluta följa",
      "No topics found": "Inga ämnen hittades",
      "People you should follow": "Personer du borde följa",
      "No users found": "Inga användare hittades",
      "Back": "Tillbaka",
      "Edit Profile": "Redigera profil",
      "alergic ingredient": "Allergiframkallande ingredienser",
      "favorite ingredient": "Favoritingredienser",
      "Products": "Produkter",
      "Read More": "Läs mer",
      "View All Products": "Visa alla produkter",
      "All": "Alla",
      "Create": "Skapa",
      "Create Post": "Skapa inlägg",
      "Start Discussion": "Starta diskussion",
      "Phone": "Telefon",
      "Post": "Inlägg",
      "Discussion": "Diskussion",
      "Start New Discussion": "Starta ny diskussion",
      "Public": "Offentlig",
      "Private": "Privat",
      "Select Topic": "Välj ämne",
      "Review Posted Successfully": "Recensionen publicerades framgångsrikt!",
      "5 Points Earned!": "5 poäng tjänade!",
      "Comments": "Kommentarer",
      "Replies": "Svar",
      "No comments found": "Inga kommentarer hittades",
      "Reply": "Svara",
      "Delete": "Radera",
      "Cancel": "Avbryt",
      "Are you sure, you want to Delete?": "Är du säker på att du vill radera?",
      "Type your reply": "Skriv ditt svar...",
      "Search": "Sök",
      "Edit": "Redigera",
      "Reward Points": "Belöningspoäng",
      "Points Earned Till Now!": "Poäng intjänade hittills",
      "Earn More Rewards": "Tjäna fler belöningar",
      "Earn Reward Points": "Tjäna belöningspoäng",
      "Earn reward points by posting product review and posting on the discussion": "Tjäna belöningspoäng genom att skriva produktrecensioner och delta i diskussioner.",
      "About": "Om",
      "Product Review": "Produktrecension",
      "Your review has been": "Din recension har",
      "posted successfully": "publicerats framgångsrikt.",
      "Ingredients": "Ingredienser",
      "Write to Review": "Skriv en recension",
      "Product List": "Produktlista",
      "Yes": "Ja",
      "No": "Nej",
      "Apply": "Tillämpa",
      "Clear": "Rensa",
      "Profile": "Profil",
      "Like": "Gilla",
      "View Activity": "Visa aktivitet",
      "View All Blogs": "Visa alla bloggar",
    },
    login: {
      "Email": "E-post",
      "Password": "Lösenord",
      "Forgot Password": "Glömt lösenord?",
      "Log in": "Logga in",
      "Dont have an account": "Har du inget konto",
      "Sign Up": "Registrera dig",
      "OR Login With": "Eller logga in med"
    },
    signup: {
      "Create a fee account within few minutes": "Skapa ett gratis konto inom några minuter.",
      "Have access to a large beauty database": "Få tillgång till information om alla beauty produkter.",
      "Read what other beauty specialists say": "Läs vad andra beauty specialister säger.",
      "List your favorite products": "Skapa en lista över dina favoritprodukter.",
      "Name": "Namn",
      "Email": "E-post",
      "Mobile Number": "Mobilnummer",
      "Country": "Land",
      "Select": "Välj",
      "Password": "Lösenord",
      "Confirm Password": "Bekräfta lösenord",
      "Create": "Skapa",
      "By creating an account you have to agree with our": "Genom att skapa ett konto måste du godkänna våra",
      "terms conditions": "Villkor & Bestämmelser.",
      "Subscribe for newsletter": "Prenumerera på vårt nyhetsbrev",
      "Already have an account": "Har du redan ett konto?",
      "Login": "Logga in",
      "OR Login With": "Eller logga in med",
    },
    ForgetPassword: {
      "Forgot your password": "Glömt ditt lösenord?",
      "Enter your registered email address to get a link to reset your password": "Ange din registrerade e-postadress för att få en länk för att återställa ditt lösenord",
      "Email": "E-post",
      "Submit": "Skicka",
      "Back to login": "Tillbaka till inloggningen",
      "Login": "Logga in",
      "Successful": "Lyckades!",
      "We have sent an OTP": "Vi har skickat en bekräftelsekod",
      "to your email address": "till din e-postadress",
      "OK": "Ok",
      "Didnt received yet": "Fick du ingen kod?",
      "Resend": "Begär igen.",
    },
    Header: {
      "Favorite": "Min Beauty",
      "Account": "Konto",
      "My Profile": "Min Profil",
      "Notifications": "Meddelanden",
      "Settings": "Inställningar",
      "Logout": "Logga ut",
      "Login": "Logga in",
      "Signup": "Registrera dig",
      "All": "Alla",
    },
    Footer: {
      "About Us": "Om oss",
      "Contact Us": "Kontakta oss",
      "Terms Conditions": "Villkor & Bestämmelser",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Integritetspolicy",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Alla rättigheter förbehållna."
    },
    contact: {
      "Message": "Meddelande",
      "Message Error": "Vänligen skriv ett meddelande",
      "Subject": "Ämne",
      "Subject Error": "Vänligen ange ämne",
      "Email": "E-post",
      "Email Error": "Vänligen ange e-post",
      "Name": "Namn",
      "Name Error": "Vänligen ange namn"
    },
    Delete: {
      "Alert": "Varning",
      "Are you sure you want to delete": "Är du säker på att du vill radera?",
      "Yes": "Ja",
      "No": "Nej",
    },
    Thankyou: {
      "Thank You": "Tack",
      "Our Admin team will verify this request and contact you": "Vårt kundteam kommer att verifiera denna förfrågan och kontakta dig",
      "OK": "Okej"
    },
    TimePicker: {
      "Hours": "Timmar",
      "Minuts": "Minuter"
    },
    recommendation: {
      "Our Recommendations": "Våra rekommendationer",
      "View all": "Visa alla",
    },
    settings: {
      "Settings": "Inställningar",
      "Change Password": "Byt lösenord",
      "Newsletter": "Nyhetsbrev",
      "Current Password": "Nuvarande lösenord",
      "New Password": "Nytt lösenord",
      "Confirm Password": "Bekräfta lösenord",
      "Save": "Spara",
      "Newsletter Notifications": "Nyhetsbrev"
    },
    notification: {
      "Home": "Hem",
      "Notifications": "Notifikationer",
      "Notification not available": "Notifikation ej tillgänglig"
    },
    blog: {
      "Home": "Hem",
      "Blogs": "Bloggar",
      "Latest Blog Post": "Senaste blogginlägget",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Vänligen försök igen",
      "Loading": "Laddar...",
      "Looks like something went wrong": "Något verkar ha gått fel!",
      "Error": "Fel",
      "retry": "Försök igen"
    },
    brand: {
      "Home": "Hem",
      "Brands": "Varumärken",
      "No brand available": "Detta varumärke är inte tillgängligt..",
      "Select to search": "Välj för att söka"
    },
    pagination: {
      "Previous": "Föregående",
      "Next": "Nästa"
    },
    customerDetail: {
      "Follow": "Följ",
      "Following": "Följer",
      "Followers": "Följare",
      "Country": "Land",
      "About Me": "Om mig",
      "View Activity": "Visa aktivitet",
      "Hair Style": "Hårstil",
      "Skin Tone": "Hudton",
      "Favorite Products": "Mina beauty produkter",
      "View all": "Visa alla",
      "No favorite available": "Du har inga favoritprodukter än."
    },
    highRated: {
      "Home": "Hem",
      "Highest Rated": "Högst betyg",
      "Highest Rated Products": "Produkter med högst betyg",
      "Load More": "Ladda mer",
      "No high rated products available": "Inga produkter med högt betyg tillgängliga"
    },
    instaList: {
      "Home": "Hem",
      "Instagram posts": "Instagram inlägg",
    },
    lastViewed: {
      "Home": "Hem",
      "Last Viewed": "Senast visade",
      "Last Viewed Products": "Senast visade produkter",
      "Product not available": "Inga produkter att visa här än."
    },
    myProfile: {
      "Home": "Hem",
      "My Profile": "Min profil",
      "Change photo": "Byt foto",
      "Name": "Namn",
      "Email": "E-post",
      "Mobile Number": "Mobilnummer",
      "Country": "Land",
      "Hair Style": "Hårstil",
      "Select Hair": "Välj hårtyp",
      "Wavy Hair": "Vågigt hår",
      "Curly Hair": "Lockigt hår",
      "Straight Hair": "Rakt hår",
      "Kinky Hair": "Kinky hår",
      "Instagram": "Instagram",
      "Skin Tone": "Hudton",
      "Alergic Ingredients": "Allergener",
      "Favourite Ingredients": "Favoritingredienser",
      "About Me": "Om mig",
      "Submit": "Skicka in",
      "Select": "Välj",
      "EAN Code": "EAN-kod"
    },
    otp: {
      "OTP Confirmation": "Bekräftelse av engångskod",
      "Please enter the verification code sent to your email": "Ange verifikationskoden som skickades till din e-post.",
      "OTP is required": "Koden krävs",
      "Submit": "Skicka in",
      "Resend OTP": "Skicka om koden",
    },
    wishList: {
      "Home": "Hem",
      "Favorite": "Mina Beauty",
      "Sort By": "Sortera efter",
      "Popularity": "Popularitet",
      "Low to High": "Lågt till högt",
      "High to Low": "Högt till lågt",
      "Apply": "Tillämpa",
      "Clear": "Rensa",
      "Filter By": "Filtrera efter",
      "Brand Name": "Varumärkesnamn",
      "Min Price": "Min pris",
      "Max Price": "Max pris",
      "Select": "Välj",
      "No favorite available": "Du har inga favoritprodukter än."
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Nytt lösenord",
      "Confirm Password": "Bekräfta lösenord",
      "Submit": "Skicka in"
    },
    productCategory: {
      "Home": "Hem",
      "Product Categories": "Produktkategorier",
    },
    myReview: {
      "My Review": "Min recension",
      "Write Review": "Skriv recension",
      "review": "Recension",
      "Submit": "Skicka in"
    },
    productDetail: {
      "Home": "Hem",
      "Category": "Kategori",
      "Sub Category": "Underkategori",
      "Brands": "Varumärken",
      "Highest Rated": "Högst betygsatt",
      "Last Viewed": "Senast visade",
      "Recommendations": "Rekommendationer",
      "Products": "Produkter",
      "Product Detail": "Produktdetaljer",
      "USD": "USD", // Currency codes remain the same
      "Write Review": "Skriv recension",
      "View Review": "Visa recensioner",
      "Description": "Beskrivning",
      "Read": "Läs",
      "Less": "Mindre",
      "More": "Mer",
      "Ingredient": "Ingrediens",
      "Similar Products": "Liknande produkter",
      "View all": "Visa alla",
      "Reviews Ratings": "Recensioner & betyg",
      "No Review Added Yet": "Ingen recension tillagd ännu",
      "Submit": "Skicka",
      "review": "recension",
      "Same Country": "Mitt land",
      "View All ": "Visa alla "
    },
    review: {
      "Reviews": "Recensioner",
      "Add a Review": "Lägg till en recension",
      "View Review": "Visa recensioner",
      "Review Rating": "Recensioner & betyg",
      "Write Review": "Skriv recension",
      "Submit": "Skicka",
      "Sort By": "Sortera efter",
      "Most Recent": "Mest aktuella",
      "Top Rating": "Topprating",
      "Like High to Low": "Likes från hög till låg",
      "Apply": "Använd",
      "Clear": "Rensa",
      "Filter By": "Filtrera efter",
      "Review not available": "Recension ej tillgänglig",
      "Click to select": "Klicka för att välja",
      "stars": "stjärnor",
      "You have Selected": "Du har valt: "
    },
    productList: {
      "Home": "Hem",
      "Product Categories": "Huvudkategorier",
      "Brands": "Varumärken",
      "Product Sub Categories": "Kategorier",
      "Product Sub Sub Categories": "Underkategorier",
      "Products": "Produkter",
      "Sort By": "Sortera efter",
      "Popularity": "Popularitet",
      "Low to High": "Lågt till högt",
      "High to Low": "Högt till lågt",
      "Apply": "Tillämpa",
      "Clear": "Rensa",
      "Filter By": "Filtrera efter",
      "Brand Name": "Varumärkesnamn",
      "Select": "Välj",
      "Min Price": "Minsta pris",
      "Max Price": "Högsta pris",
      "Product not available": "Produkten är inte tillgänglig"
    },
    productSubCategory: {
      "Home": "Hem",
      "Product Categories": "Huvudkategorier",
      "Product Sub Categories": "Kategorier",
      "Product Sub Sub Categories": "Underkategorier",
      "Sub category not available": "Kategorin finns inte",
      "Category not available": "Kategorin finns inte"
    },
    recommendationList: {
      "Home": "Hem",
      "Our Recommendation": "Våra rekommendationer",
      "No product available": "Inga produkter tillgängliga"
    },
    search: {
      "Search": "Sök",
      "Product Categories": "Kategorier",
      "View all": "Visa alla",
      "Brands": "Varumärken",
      "Products": "Produkter",
      "Filter By": "Filtrera efter",
      "Brand Name": "Varumärkesnamn",
      "Select": "Välj",
      "Ingredients": "Ingredienser",
      "Ingredient": "Ingrediens",
      "Include": "Inkludera",
      "Not Include": "Inkluderar inte",
      "Min Price": "Minsta pris",
      "Max Price": "Högsta pris",
      "Customer Reviews": "Kundrecensioner",
      "Customer Ratings": "Kundbetyg",
      "Apply": "Tillämpa",
      "Clear": "Rensa",
      "Product not available": "Produkten är inte tillgänglig",
      "Brands not available": "Varumärket är inte tillgängligt",
    },
    similar: {
      "Product Detail": "Produktinformation",
      "Similar Products": "Liknande produkter",
      "Product not available": "Produkten är inte tillgänglig"
    },
    activity: {
      "View Activity": "Visa aktivitet",
    },
    home: {
      "Product Categories": "Kategorier",
      "View all": "Visa alla",
      "Brands": "Varumärken",
      "Highest Rated": "Högst betyg",
      "Last Viewed Products": "Senast visade produkter",
      "Latest Blog Post": "Senaste blogginlägget",
      "Instagram Post": "Instagram-inlägg",
    },
    share: {
      "Share": "Dela",
      "Copy Link": "Kopiera länk",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Kopierat till urklipp framgångsrikt",
    },
    validation: {
      "Please enter": "Var vänlig ange",
      "Please select": "Var vänlig välj",
      "This field is required": "Detta fält krävs",
      "Please enter valid email": "Var vänlig ange en giltig e-postadress",
      "Please enter valid contact number": "Var vänlig ange ett giltigt telefonnummer",
      "Please enter valid pin code": "Var vänlig ange en giltig postkod",
      "Please enter valid": "Var vänlig ange giltig",
      "Please enter valid name": "Var vänlig ange ett giltigt namn",
      "This field must be at least": "Detta fält måste vara minst",
      "characters": "tecken",
      "Space is not allowed in password": "Mellanslag är inte tillåtet i lösenord",
      "Passwords must match": "Lösenorden måste stämma överens",
      "Please enter valid price": "Var vänlig ange ett giltigt pris",
      "Please enter numeric values only": "Var vänlig ange endast numeriska värden"
    },
    webService: {
      "Server Not Responding": "Servern svarar inte",
      "Invalid Username or Password": "Ogiltigt användarnamn eller lösenord",
      "Bad Response": "Dåligt svar",
      "Server Error": "Serverfel",
    }
  },
  zh: {
    new: {
      "Login to Grade my beauty account using your credintials": "使用您的凭证登录到您的GradeMyBeauty账户",
      "Create An Account": "创建账户",
      "OR Continue with": "或继续使用：",
      "Already Have An Account": "已有账户",
      "Please enter following details to sign up": "请输入以下详细信息以注册",
      "Success": "成功",
      "OTP has been verified": "OTP已验证",
      "successfully": "成功。",
      "Go To Login": "前往登录",
      "Verify OTP": "验证OTP",
      "Verify OTP which we have sent on your email": "验证我们发送到您邮箱的OTP。",
      "Verify": "验证",
      "Select Country": "选择国家",
      "Send": "发送",
      "Explore More": "探索更多",
      "Categories": "分类",
      "View All": "View All",
      "View All Categories": "查看所有分类",
      "View All Brands": "查看所有品牌",
      "Subscribe to our newsletter": "订阅我们的新闻通讯。",
      "Stay tuned, Subscribe to our newsletter and stay updated": "保持关注，订阅我们的新闻通讯并保持更新。",
      "Subscribe": "订阅",
      "Hot Posts": "热门帖子",
      "No post found": "未找到帖子",
      "Follow the Topic": "关注话题",
      "Follow": "关注",
      "UserName": "UserName",
      "UnFollow": "取消关注",
      "No topics found": "未找到话题",
      "People you should follow": "您应该关注的人",
      "No users found": "未找到用户",
      "Back": "返回",
      "Edit Profile": "编辑个人资料",
      "alergic ingredient": "过敏成分",
      "favorite ingredient": "喜爱的成分",
      "Products": "产品",
      "Read More": "阅读更多",
      "View All Products": "查看所有产品",
      "All": "全部",
      "Create": "创建",
      "Create Post": "创建帖子",
      "Start Discussion": "开始讨论",
      "Phone": "电话",
      "Post": "帖子",
      "Discussion": "讨论",
      "Start New Discussion": "开始新的讨论",
      "Public": "公开",
      "Private": "私密",
      "Select Topic": "选择话题",
      "Review Posted Successfully": "评论成功发布！",
      "5 Points Earned!": "赚取5分！",
      "Comments": "评论",
      "Replies": "回复",
      "No comments found": "未找到评论",
      "Reply": "回复",
      "Delete": "删除",
      "Cancel": "取消",
      "Are you sure, you want to Delete?": "您确定要删除吗？",
      "Type your reply": "输入您的回复…",
      "Search": "搜索",
      "Edit": "编辑",
      "Reward Points": "奖励积分",
      "Points Earned Till Now!": "至今赚取的积分",
      "Earn More Rewards": "赚取更多奖励",
      "Earn Reward Points": "赚取奖励积分",
      "Earn reward points by posting product review and posting on the discussion": "通过发布产品评论和参与讨论赚取奖励积分。",
      "About": "关于",
      "Product Review": "产品评论",
      "Your review has been": "您的评论已",
      "posted successfully": "成功发布。",
      "Ingredients": "成分",
      "Write to Review": "写评论",
      "Product List": "产品列表",
      "Yes": "是",
      "No": "否",
      "Apply": "应用",
      "Clear": "清除",
      "Profile": "个人资料",
      "Like": "喜欢",
      "View Activity": "查看活动",
      "View All Blogs": "查看所有博客",
    },
    login: {
      "Email": "电子邮件",
      "Password": "密码",
      "Forgot Password": "忘记密码?",
      "Log in": "登录",
      "Dont have an account": "还没有账号",
      "Sign Up": "注册",
      "OR Login With": "或使用以下方式登录"
    },
    signup: {
      "Create a fee account within few minutes": "几分钟内免费创建账号。",
      "Have access to a large beauty database": "获取所有beauty产品的信息。",
      "Read what other beauty specialists say": "阅读其他beauty专家的意见。",
      "List your favorite products": "列出你喜欢的产品。",
      "Name": "名字",
      "Email": "电子邮件",
      "Mobile Number": "手机号码",
      "Country": "国家",
      "Select": "选择",
      "Password": "密码",
      "Confirm Password": "确认密码",
      "Create": "创建",
      "By creating an account you have to agree with our": "创建账号即表示你同意我们的",
      "terms conditions": "条款和条件。",
      "Subscribe for newsletter": "订阅我们的新闻简报",
      "Already have an account": "已经有账号?",
      "Login": "登录",
      "OR Login With": "或使用以下方式登录",
    },
    ForgetPassword: {
      "Forgot your password": "忘记你的密码?",
      "Enter your registered email address to get a link to reset your password": "输入你注册的电子邮件地址以获取重置密码的链接",
      "Email": "电子邮件",
      "Submit": "提交",
      "Back to login": "返回登录",
      "Login": "登录",
      "Successful": "成功!",
      "We have sent an OTP": "我们已发送确认码",
      "to your email address": "到你的电子邮件地址",
      "OK": "确定",
      "Didnt received yet": "还没有收到代码?",
      "Resend": "再次请求。",
    },
    Header: {
      "Favorite": "我的Beauty",
      "Account": "账号",
      "My Profile": "我的资料",
      "Notifications": "通知",
      "Settings": "设置",
      "Logout": "退出",
      "Login": "登录",
      "Signup": "注册",
      "All": "全部",
    },
    Footer: {
      "About Us": "关于我们",
      "Contact Us": "联系我们",
      "Terms Conditions": "条款与条件",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "隐私政策",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. 版权所有。"
    },
    contact: {
      "Message": "信息",
      "Message Error": "请输入信息",
      "Subject": "主题",
      "Subject Error": "请输入主题",
      "Email": "电子邮件",
      "Email Error": "请输入电子邮件",
      "Name": "名字",
      "Name Error": "请输入名字"
    },
    Delete: {
      "Alert": "警告",
      "Are you sure you want to delete": "你确定要删除吗?",
      "Yes": "是",
      "No": "否",
    },
    Thankyou: {
      "Thank You": "谢谢",
      "Our Admin team will verify this request and contact you": "我们的客户团队会核实这个请求并联系你",
      "OK": "确定"
    },
    TimePicker: {
      "Hours": "小时",
      "Minuts": "分钟"
    },
    recommendation: {
      "Our Recommendations": "我们的推荐",
      "View all": "查看全部",
    },
    settings: {
      "Settings": "设置",
      "Change Password": "更改密码",
      "Newsletter": "新闻通讯",
      "Current Password": "当前密码",
      "New Password": "新密码",
      "Confirm Password": "确认密码",
      "Save": "保存",
      "Newsletter Notifications": "新闻通讯"
    },
    notification: {
      "Home": "主页",
      "Notifications": "通知",
      "Notification not available": "没有通知"
    },
    blog: {
      "Home": "主页",
      "Blogs": "博客",
      "Latest Blog Post": "最新博客帖子",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "请重试",
      "Loading": "加载中...",
      "Looks like something went wrong": "似乎出了些问题！",
      "Error": "错误",
      "retry": "重试"
    },
    brand: {
      "Home": "主页",
      "Brands": "品牌",
      "No brand available": "这个品牌不可用..",
      "Select to search": "选择搜索"
    },
    pagination: {
      "Previous": "上一个",
      "Next": "下一个"
    },
    customerDetail: {
      "Follow": "关注",
      "Following": "正在关注",
      "Followers": "粉丝",
      "Country": "国家",
      "About Me": "关于我",
      "View Activity": "查看活动",
      "Hair Style": "发型",
      "Skin Tone": "肤色",
      "Favorite Products": "我的beauty产品",
      "View all": "查看全部",
      "No favorite available": "你还没有喜欢的产品。"
    },
    highRated: {
      "Home": "主页",
      "Highest Rated": "评价最高",
      "Highest Rated Products": "评价最高的产品",
      "Load More": "加载更多",
      "No high rated products available": "没有高评价的产品"
    },
    instaList: {
      "Home": "主页",
      "Instagram posts": "Instagram帖子",
    },
    lastViewed: {
      "Home": "主页",
      "Last Viewed": "最后查看",
      "Last Viewed Products": "最后查看的产品",
      "Product not available": "这里还没有显示的产品。"
    },
    myProfile: {
      "Home": "主页",
      "My Profile": "我的资料",
      "Change photo": "更换照片",
      "Name": "姓名",
      "Email": "电子邮件",
      "Mobile Number": "手机号码",
      "Country": "国家",
      "Hair Style": "发型",
      "Select Hair": "选择发型",
      "Wavy Hair": "波浪发",
      "Curly Hair": "卷发",
      "Straight Hair": "直发",
      "Kinky Hair": "卷曲的发",
      "Instagram": "Instagram",
      "Skin Tone": "肤色",
      "Alergic Ingredients": "过敏源",
      "Favourite Ingredients": "喜欢的成分",
      "About Me": "关于我",
      "Submit": "提交",
      "Select": "选择",
      "EAN Code": "EAN代码"
    },
    otp: {
      "OTP Confirmation": "OTP验证",
      "Please enter the verification code sent to your email": "请输入发送到你的邮箱的验证码。",
      "OTP is required": "需要验证码",
      "Submit": "提交",
      "Resend OTP": "重新发送验证码",
    },
    wishList: {
      "Home": "主页",
      "Favorite": "我的Beauty",
      "Sort By": "排序方式",
      "Popularity": "受欢迎",
      "Low to High": "从低到高",
      "High to Low": "从高到低",
      "Apply": "应用",
      "Clear": "清除",
      "Filter By": "过滤方式",
      "Brand Name": "品牌名称",
      "Min Price": "最低价格",
      "Max Price": "最高价格",
      "Select": "选择",
      "No favorite available": "你还没有喜欢的产品。"
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "新密码",
      "Confirm Password": "确认密码",
      "Submit": "提交"
    },
    productCategory: {
      "Home": "主页",
      "Product Categories": "产品分类",
    },
    myReview: {
      "My Review": "我的评论",
      "Write Review": "写评论",
      "review": "评论",
      "Submit": "提交"
    },
    productDetail: {
      "Home": "首页",
      "Category": "分类",
      "Sub Category": "子分类",
      "Brands": "品牌",
      "Highest Rated": "评分最高的",
      "Last Viewed": "最近查看的",
      "Recommendations": "推荐",
      "Products": "产品",
      "Product Detail": "产品详情",
      "USD": "美元",
      "Write Review": "写评论",
      "View Review": "查看评论",
      "Description": "描述",
      "Read": "阅读",
      "Less": "少",
      "More": "更多",
      "Ingredient": "成分",
      "Similar Products": "相似的产品",
      "View all": "查看全部",
      "Reviews Ratings": "评论和评分",
      "No Review Added Yet": "尚未添加评论",
      "Submit": "提交",
      "review": "评论",
      "Same Country": "我的国家",
      "View All ": "查看全部 "
    },
    review: {
      "Reviews": "评论",
      "Add a Review": "添加评论",
      "View Review": "查看评论",
      "Review Rating": "评论和评分",
      "Write Review": "写评论",
      "Submit": "提交",
      "Sort By": "排序方式",
      "Most Recent": "最新的",
      "Top Rating": "最高评分",
      "Like High to Low": "点赞从多到少",
      "Apply": "应用",
      "Clear": "清除",
      "Filter By": "筛选方式",
      "Review not available": "评论不可用",
      "Click to select": "点击选择",
      "stars": "星",
      "You have Selected": "你已选择: "
    },
    productList: {
      "Home": "首页",
      "Product Categories": "主要分类",
      "Brands": "品牌",
      "Product Sub Categories": "分类",
      "Product Sub Sub Categories": "子分类",
      "Products": "产品",
      "Sort By": "排序方式",
      "Popularity": "流行度",
      "Low to High": "从低到高",
      "High to Low": "从高到低",
      "Apply": "应用",
      "Clear": "清除",
      "Filter By": "筛选方式",
      "Brand Name": "品牌名称",
      "Select": "选择",
      "Min Price": "最低价",
      "Max Price": "最高价",
      "Product not available": "产品不可用"
    },
    productSubCategory: {
      "Home": "首页",
      "Product Categories": "主要分类",
      "Product Sub Categories": "分类",
      "Product Sub Sub Categories": "子分类",
      "Sub category not available": "子分类不可用",
      "Category not available": "分类不可用"
    },
    recommendationList: {
      "Home": "首页",
      "Our Recommendation": "我们的推荐",
      "No product available": "没有可用的产品"
    },
    search: {
      "Search": "搜索",
      "Product Categories": "分类",
      "View all": "查看全部",
      "Brands": "品牌",
      "Products": "产品",
      "Filter By": "筛选方式",
      "Brand Name": "品牌名称",
      "Select": "选择",
      "Ingredients": "成分",
      "Ingredient": "成分",
      "Include": "包含",
      "Not Include": "不包含",
      "Min Price": "最低价",
      "Max Price": "最高价",
      "Customer Reviews": "客户评论",
      "Customer Ratings": "客户评分",
      "Apply": "应用",
      "Clear": "清除",
      "Product not available": "产品不可用",
      "Brands not available": "品牌不可用",
    },
    similar: {
      "Product Detail": "产品详情",
      "Similar Products": "相似的产品",
      "Product not available": "产品不可用"
    },
    activity: {
      "View Activity": "查看活动",
    },
    home: {
      "Product Categories": "分类",
      "View all": "查看全部",
      "Brands": "品牌",
      "Highest Rated": "评分最高的",
      "Last Viewed Products": "最近查看的产品",
      "Latest Blog Post": "最新的博客文章",
      "Instagram Post": "Instagram帖子",
    },
    share: {
      "Share": "分享",
      "Copy Link": "复制链接",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "成功复制到剪贴板",
    },
    validation: {
      "Please enter": "请输入",
      "Please select": "请选择",
      "This field is required": "这个字段是必须的",
      "Please enter valid email": "请输入有效的电子邮件地址",
      "Please enter valid contact number": "请输入有效的联系电话",
      "Please enter valid pin code": "请输入有效的邮政编码",
      "Please enter valid": "请输入有效的",
      "Please enter valid name": "请输入有效的姓名",
      "This field must be at least": "这个字段至少需要",
      "characters": "字符",
      "Space is not allowed in password": "密码中不允许有空格",
      "Passwords must match": "密码必须匹配",
      "Please enter valid price": "请输入有效的价格",
      "Please enter numeric values only": "只能输入数字值"
    },
    webService: {
      "Server Not Responding": "服务器未响应",
      "Invalid Username or Password": "无效的用户名或密码",
      "Bad Response": "错误的响应",
      "Server Error": "服务器错误",
    }
  },
  da: {
    new: {
      "Login to Grade my beauty account using your credintials": "Log ind på din GradeMyBeauty-konto med dine legitimationsoplysninger",
      "Create An Account": "Opret en konto",
      "OR Continue with": "Eller fortsæt med:",
      "Already Have An Account": "Har du allerede en konto",
      "Please enter following details to sign up": "Indtast venligst følgende oplysninger for at tilmelde dig",
      "Success": "Succes",
      "OTP has been verified": "OTP er blevet verificeret",
      "successfully": "Succesfuldt.",
      "Go To Login": "Gå til login",
      "Verify OTP": "Bekræft OTP",
      "Verify OTP which we have sent on your email": "Bekræft OTP, som vi har sendt til din e-mail.",
      "Verify": "Bekræft",
      "Select Country": "Vælg land",
      "Send": "Send",
      "Explore More": "Udforsk mere",
      "Categories": "Kategorier",
      "View All": "View All",
      "View All Categories": "Se alle kategorier",
      "View All Brands": "Se alle mærker",
      "Subscribe to our newsletter": "Abonner på vores nyhedsbrev.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Hold dig opdateret, abonner på vores nyhedsbrev og hold dig opdateret.",
      "Subscribe": "Abonner",
      "Hot Posts": "Populære opslag",
      "No post found": "Ingen opslag fundet",
      "Follow the Topic": "Følg emnet",
      "Follow": "Følg",
      "UserName": "UserName",
      "UnFollow": "Følg ikke længere",
      "No topics found": "Ingen emner fundet",
      "People you should follow": "Personer, du bør følge",
      "No users found": "Ingen brugere fundet",
      "Back": "Tilbage",
      "Edit Profile": "Rediger profil",
      "alergic ingredient": "Allergifremkaldende ingrediens",
      "favorite ingredient": "Foretrukne ingredienser",
      "Products": "Produkter",
      "Read More": "Læs mere",
      "View All Products": "Se alle produkter",
      "All": "Alle",
      "Create": "Opret",
      "Create Post": "Opret opslag",
      "Start Discussion": "Start diskussion",
      "Phone": "Telefon",
      "Post": "Opslag",
      "Discussion": "Diskussion",
      "Start New Discussion": "Start ny diskussion",
      "Public": "Offentlig",
      "Private": "Privat",
      "Select Topic": "Vælg emne",
      "Review Posted Successfully": "Anmeldelse indsendt succesfuldt!",
      "5 Points Earned!": "5 point optjent!",
      "Comments": "Kommentarer",
      "Replies": "Svar",
      "No comments found": "Ingen kommentarer fundet",
      "Reply": "Svar",
      "Delete": "Slet",
      "Cancel": "Annuller",
      "Are you sure, you want to Delete?": "Er du sikker på, at du vil slette?",
      "Type your reply": "Skriv dit svar...",
      "Search": "Søg",
      "Edit": "Rediger",
      "Reward Points": "Belønningspoint",
      "Points Earned Till Now!": "Optjente point indtil nu!",
      "Earn More Rewards": "Tjen flere belønninger",
      "Earn Reward Points": "Tjen belønningspoint",
      "Earn reward points by posting product review and posting on the discussion": "Tjen belønningspoint ved at skrive produktanmeldelser og deltage i diskussioner.",
      "About": "Om",
      "Product Review": "Produktanmeldelse",
      "Your review has been": "Din anmeldelse er blevet",
      "posted successfully": "indsendt succesfuldt.",
      "Ingredients": "Ingredienser",
      "Write to Review": "Skriv en anmeldelse",
      "Product List": "Produktliste",
      "Yes": "Ja",
      "No": "Nej",
      "Apply": "Anvend",
      "Clear": "Ryd",
      "Profile": "Profil",
      "Like": "Synes godt om",
      "View Activity": "Se aktivitet",
      "View All Blogs": "Se alle blogs",
    },
    login: {
      "Email": "E-mail",
      "Password": "Adgangskode",
      "Forgot Password": "Glemt adgangskode?",
      "Log in": "Log ind",
      "Dont have an account": "Har du ikke en konto",
      "Sign Up": "Tilmeld dig",
      "OR Login With": "Eller log ind med"
    },
    signup: {
      "Create a fee account within few minutes": "Opret en gratis konto på få minutter.",
      "Have access to a large beauty database": "Få adgang til en stor beauty database.",
      "Read what other beauty specialists say": "Læs hvad andre beauty specialister siger.",
      "List your favorite products": "Opret en liste over dine favoritprodukter.",
      "Name": "Navn",
      "Email": "E-mail",
      "Mobile Number": "Mobilnummer",
      "Country": "Land",
      "Select": "Vælg",
      "Password": "Adgangskode",
      "Confirm Password": "Bekræft adgangskode",
      "Create": "Opret",
      "By creating an account you have to agree with our": "Ved at oprette en konto accepterer du vores",
      "terms conditions": "Vilkår & betingelser.",
      "Subscribe for newsletter": "Tilmeld dig vores nyhedsbrev",
      "Already have an account": "Har du allerede en konto?",
      "Login": "Log ind",
      "OR Login With": "Eller log ind med",
    },
    ForgetPassword: {
      "Forgot your password": "Glemt din adgangskode?",
      "Enter your registered email address to get a link to reset your password": "Indtast din registrerede e-mailadresse for at få et link til at nulstille din adgangskode",
      "Email": "E-mail",
      "Submit": "Indsend",
      "Back to login": "Tilbage til login",
      "Login": "Log ind",
      "Successful": "Succesfuldt!",
      "We have sent an OTP": "Vi har sendt en bekræftelseskode",
      "to your email address": "til din e-mailadresse",
      "OK": "Ok",
      "Didnt received yet": "Har du ikke modtaget den endnu?",
      "Resend": "Gensend",
    },
    Header: {
      "Favorite": "Min Beauty",
      "Account": "Konto",
      "My Profile": "Min profil",
      "Notifications": "Notifikationer",
      "Settings": "Indstillinger",
      "Logout": "Log ud",
      "Login": "Log ind",
      "Signup": "Tilmeld",
      "All": "Alle",
    },
    Footer: {
      "About Us": "Om os",
      "Contact Us": "Kontakt os",
      "Terms Conditions": "Vilkår & betingelser",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Privatlivspolitik",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Alle rettigheder forbeholdes."
    },
    contact: {
      "Message": "Besked",
      "Message Error": "Indtast besked",
      "Subject": "Emne",
      "Subject Error": "Indtast emne",
      "Email": "E-mail",
      "Email Error": "Indtast e-mail",
      "Name": "Navn",
      "Name Error": "Indtast navn"
    },
    Delete: {
      "Alert": "Advarsel",
      "Are you sure you want to delete": "Er du sikker på, at du vil slette?",
      "Yes": "Ja",
      "No": "Nej",
    },
    Thankyou: {
      "Thank You": "Tak",
      "Our Admin team will verify this request and contact you": "Vores kundeteam vil verificere denne anmodning og kontakte dig",
      "OK": "Ok"
    },
    TimePicker: {
      "Hours": "Timer",
      "Minuts": "Minutter"
    },
    recommendation: {
      "Our Recommendations": "Vores anbefalinger",
      "View all": "Se alle",
    },
    settings: {
      "Settings": "Indstillinger",
      "Change Password": "Skift adgangskode",
      "Newsletter": "Nyhedsbrev",
      "Current Password": "Nuværende adgangskode",
      "New Password": "Ny adgangskode",
      "Confirm Password": "Bekræft adgangskode",
      "Save": "Gem",
      "Newsletter Notifications": "Nyhedsbrev"
    },
    notification: {
      "Home": "Hjem",
      "Notifications": "Notifikationer",
      "Notification not available": "Notifikation ikke tilgængelig"
    },
    blog: {
      "Home": "Hjem",
      "Blogs": "Blogs",
      "Latest Blog Post": "Seneste blogindlæg",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Prøv igen",
      "Loading": "Indlæser...",
      "Looks like something went wrong": "Noget gik galt!",
      "Error": "Fejl",
      "retry": "Prøv igen"
    },
    brand: {
      "Home": "Hjem",
      "Brands": "Mærker",
      "No brand available": "Dette mærke er ikke tilgængeligt..",
      "Select to search": "Vælg for at søge"
    },
    pagination: {
      "Previous": "Forrige",
      "Next": "Næste"
    },
    customerDetail: {
      "Follow": "Følg",
      "Following": "Følger",
      "Followers": "Følgere",
      "Country": "Land",
      "About Me": "Om mig",
      "View Activity": "Se aktivitet",
      "Hair Style": "Hårstil",
      "Skin Tone": "Hudtone",
      "Favorite Products": "Mine beauty produkter",
      "View all": "Se alle",
      "No favorite available": "Du har ikke nogen favoritprodukter endnu."
    },
    highRated: {
      "Home": "Hjem",
      "Highest Rated": "Højest bedømte",
      "Highest Rated Products": "Højest bedømte produkter",
      "Load More": "Indlæs mere",
      "No high rated products available": "Ingen højt bedømte produkter tilgængelige"
    },
    instaList: {
      "Home": "Hjem",
      "Instagram posts": "Instagram opslag",
    },
    lastViewed: {
      "Home": "Hjem",
      "Last Viewed": "Sidst set",
      "Last Viewed Products": "Sidst sete produkter",
      "Product not available": "Ingen produkter at vise her endnu."
    },
    myProfile: {
      "Home": "Hjem",
      "My Profile": "Min profil",
      "Change photo": "Skift foto",
      "Name": "Navn",
      "Email": "E-mail",
      "Mobile Number": "Mobilnummer",
      "Country": "Land",
      "Hair Style": "Hårstil",
      "Select Hair": "Vælg hårtype",
      "Wavy Hair": "Bølget hår",
      "Curly Hair": "Krøllet hår",
      "Straight Hair": "Glat hår",
      "Kinky Hair": "Kinky hår",
      "Instagram": "Instagram",
      "Skin Tone": "Hudtone",
      "Alergic Ingredients": "Allergener",
      "Favourite Ingredients": "Favoritingredienser",
      "About Me": "Om mig",
      "Submit": "Indsend",
      "Select": "Vælg",
      "EAN Code": "EAN-kode"
    },
    otp: {
      "OTP Confirmation": "OTP bekræftelse",
      "Please enter the verification code sent to your email": "Indtast venligst verifikationskoden, der er sendt til din e-mail.",
      "OTP is required": "OTP er nødvendig",
      "Submit": "Indsend",
      "Resend OTP": "Gensend OTP",
    },
    wishList: {
      "Home": "Hjem",
      "Favorite": "Min Beauty",
      "Sort By": "Sorter efter",
      "Popularity": "Popularitet",
      "Low to High": "Lav til høj",
      "High to Low": "Høj til lav",
      "Apply": "Anvend",
      "Clear": "Ryd",
      "Filter By": "Filtrer efter",
      "Brand Name": "Mærkenavn",
      "Min Price": "Min pris",
      "Max Price": "Max pris",
      "Select": "Vælg",
      "No favorite available": "Du har endnu ingen favoritprodukter.",
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Nyt kodeord",
      "Confirm Password": "Bekræft kodeord",
      "Submit": "Indsend"
    },
    productCategory: {
      "Home": "Hjem",
      "Product Categories": "Produktkategorier",
    },
    myReview: {
      "My Review": "Min anmeldelse",
      "Write Review": "Skriv anmeldelse",
      "review": "Anmeldelse",
      "Submit": "Indsend"
    },
    productDetail: {
      "Home": "Hjem",
      "Category": "Kategori",
      "Sub Category": "Underkategori",
      "Brands": "Mærker",
      "Highest Rated": "Højest bedømt",
      "Last Viewed": "Sidst set",
      "Recommendations": "Anbefalinger",
      "Products": "Produkter",
      "Product Detail": "Produktdetaljer",
      "USD": "USD",
      "Write Review": "Skriv anmeldelse",
      "View Review": "Se anmeldelser",
      "Description": "Beskrivelse",
      "Read": "Læs",
      "Less": "Mindre",
      "More": "Mere",
      "Ingredient": "Ingredienser",
      "Similar Products": "Lignende produkter",
      "View all": "Se alle",
      "Reviews Ratings": "Anmeldelser & Vurderinger",
      "No Review Added Yet": "Der er endnu ikke tilføjet anmeldelser",
      "Submit": "Indsend",
      "review": "Anmeldelse",
      "Same Country": "Mit land",
      "View All ": "Se alle "
    },
    review: {
      "Reviews": "Anmeldelser",
      "Add a Review": "Tilføj en anmeldelse",
      "View Review": "Se anmeldelser",
      "Review Rating": "Anmeldelser & Vurderinger",
      "Write Review": "Skriv anmeldelse",
      "Submit": "Indsend",
      "Sort By": "Sorter efter",
      "Most Recent": "Mest aktuelle",
      "Top Rating": "Top bedømmelse",
      "Like High to Low": "Likes fra høj til lav",
      "Apply": "Anvend",
      "Clear": "Ryd",
      "Filter By": "Filtrer efter",
      "Review not available": "Der er ingen anmeldelser tilgængelige",
      "Click to select": "Klik for at vælge",
      "stars": "Stjerner",
      "You have Selected": "Du har valgt: "
    },
    productList: {
      "Home": "Hjem",
      "Product Categories": "Hovedkategorier",
      "Brands": "Mærker",
      "Product Sub Categories": "Kategorier",
      "Product Sub Sub Categories": "Underkategorier",
      "Products": "Produkter",
      "Sort By": "Sorter efter",
      "Popularity": "Popularitet",
      "Low to High": "Lav til høj",
      "High to Low": "Høj til lav",
      "Apply": "Anvend",
      "Clear": "Ryd",
      "Filter By": "Filtrer efter",
      "Brand Name": "Mærkenavn",
      "Select": "Vælg",
      "Min Price": "Min pris",
      "Max Price": "Max pris",
      "Product not available": "Produktet er ikke tilgængeligt"
    },
    productSubCategory: {
      "Home": "Hjem",
      "Product Categories": "Hovedkategorier",
      "Product Sub Categories": "Kategorier",
      "Product Sub Sub Categories": "Underkategorier",
      "Sub category not available": "Underkategori ikke tilgængelig",
      "Category not available": "Kategori ikke tilgængelig"
    },
    recommendationList: {
      "Home": "Hjem",
      "Our Recommendation": "Vores anbefalinger",
      "No product available": "Ingen produkter tilgængelige"
    },
    search: {
      "Search": "Søg",
      "Product Categories": "Kategorier",
      "View all": "Se alle",
      "Brands": "Mærker",
      "Products": "Produkter",
      "Filter By": "Filtrer efter",
      "Brand Name": "Mærkenavn",
      "Select": "Vælg",
      "Ingredients": "Ingredienser",
      "Ingredient": "Ingrediens",
      "Include": "Inkluder",
      "Not Include": "Inkluder ikke",
      "Min Price": "Min pris",
      "Max Price": "Max pris",
      "Customer Reviews": "Kundeanmeldelser",
      "Customer Ratings": "Kundevurderinger",
      "Apply": "Anvend",
      "Clear": "Ryd",
      "Product not available": "Produkter ikke tilgængelige",
      "Brands not available": "Mærker ikke tilgængelige",
    },
    similar: {
      "Product Detail": "Produktdetalje",
      "Similar Products": "Lignende produkter",
      "Product not available": "Produkter ikke tilgængelige"
    },
    activity: {
      "View Activity": "Se aktivitet",
    },
    home: {
      "Product Categories": "Kategorier",
      "View all": "Se alle",
      "Brands": "Mærker",
      "Highest Rated": "Højest bedømt",
      "Last Viewed Products": "Sidst sete produkter",
      "Latest Blog Post": "Seneste blogindlæg",
      "Instagram Post": "Instagram opslag",
    },
    share: {
      "Share": "Del",
      "Copy Link": "Kopier link",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Kopieret til udklipsholderen med succes",
    },
    validation: {
      "Please enter": "Indtast venligst",
      "Please select": "Vælg venligst",
      "This field is required": "Dette felt er påkrævet",
      "Please enter valid email": "Indtast venligst en gyldig e-mailadresse",
      "Please enter valid contact number": "Indtast venligst et gyldigt telefonnummer",
      "Please enter valid pin code": "Indtast venligst en gyldig postkode",
      "Please enter valid": "Indtast venligst gyldig",
      "Please enter valid name": "Indtast venligst et gyldigt navn",
      "This field must be at least": "Dette felt skal være mindst",
      "characters": "tegn",
      "Space is not allowed in password": "Mellemrum er ikke tilladt i adgangskoden",
      "Passwords must match": "Adgangskoderne skal matche",
      "Please enter valid price": "Indtast venligst en gyldig pris",
      "Please enter numeric values only": "Indtast kun numeriske værdier"
    },
    webService: {
      "Server Not Responding": "Serveren svarer ikke",
      "Invalid Username or Password": "Ugyldigt brugernavn eller adgangskode",
      "Bad Response": "Dårligt svar",
      "Server Error": "Serverfejl",
    }
  },
  de: {
    new: {
      "Login to Grade my beauty account using your credintials": "Melde dich mit deinen Zugangsdaten bei deinem GradeMyBeauty-Konto an",
      "Create An Account": "Erstelle ein Konto",
      "OR Continue with": "Oder weiter mit:",
      "Already Have An Account": "Hast du bereits ein Konto",
      "Please enter following details to sign up": "Bitte gib die folgenden Details an, um dich zu registrieren",
      "Success": "Erfolg",
      "OTP has been verified": "OTP wurde verifiziert",
      "successfully": "Erfolgreich.",
      "Go To Login": "Zum Login gehen",
      "Verify OTP": "OTP verifizieren",
      "Verify OTP which we have sent on your email": "Verifiziere das OTP, das wir an deine E-Mail gesendet haben.",
      "Verify": "Verifizieren",
      "Select Country": "Land auswählen",
      "Send": "Senden",
      "Explore More": "Mehr erkunden",
      "Categories": "Kategorien",
      "View All": "View All",
      "View All Categories": "Alle Kategorien anzeigen",
      "View All Brands": "Alle Marken anzeigen",
      "Subscribe to our newsletter": "Abonniere unseren Newsletter.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Bleib dran, abonniere unseren Newsletter und bleibe auf dem Laufenden.",
      "Subscribe": "Abonnieren",
      "Hot Posts": "Beliebte Beiträge",
      "No post found": "Keine Beiträge gefunden",
      "Follow the Topic": "Dem Thema folgen",
      "Follow": "Folgen",
      "UserName": "UserName",
      "UnFollow": "Nicht mehr folgen",
      "No topics found": "Keine Themen gefunden",
      "People you should follow": "Personen, denen du folgen solltest",
      "No users found": "Keine Benutzer gefunden",
      "Back": "Zurück",
      "Edit Profile": "Profil bearbeiten",
      "alergic ingredient": "Allergieauslösende Inhaltsstoffe",
      "favorite ingredient": "Lieblingszutaten",
      "Products": "Produkte",
      "Read More": "Mehr lesen",
      "View All Products": "Alle Produkte anzeigen",
      "All": "Alle",
      "Create": "Erstellen",
      "Create Post": "Beitrag erstellen",
      "Start Discussion": "Diskussion starten",
      "Phone": "Telefon",
      "Post": "Beitrag",
      "Discussion": "Diskussion",
      "Start New Discussion": "Neue Diskussion starten",
      "Public": "Öffentlich",
      "Private": "Privat",
      "Select Topic": "Thema auswählen",
      "Review Posted Successfully": "Rezension erfolgreich veröffentlicht!",
      "5 Points Earned!": "5 Punkte verdient!",
      "Comments": "Kommentare",
      "Replies": "Antworten",
      "No comments found": "Keine Kommentare gefunden",
      "Reply": "Antworten",
      "Delete": "Löschen",
      "Cancel": "Abbrechen",
      "Are you sure, you want to Delete?": "Bist du sicher, dass du löschen möchtest?",
      "Type your reply": "Gib deine Antwort ein...",
      "Search": "Suchen",
      "Edit": "Bearbeiten",
      "Reward Points": "Belohnungspunkte",
      "Points Earned Till Now!": "Bis jetzt verdiente Punkte",
      "Earn More Rewards": "Mehr Belohnungen verdienen",
      "Earn Reward Points": "Belohnungspunkte verdienen",
      "Earn reward points by posting product review and posting on the discussion": "Verdiene Belohnungspunkte, indem du Produktbewertungen schreibst und an Diskussionen teilnimmst.",
      "About": "Über",
      "Product Review": "Produktbewertung",
      "Your review has been": "Deine Bewertung wurde",
      "posted successfully": "erfolgreich veröffentlicht.",
      "Ingredients": "Zutaten",
      "Write to Review": "Eine Rezension schreiben",
      "Product List": "Produktliste",
      "Yes": "Ja",
      "No": "Nein",
      "Apply": "Anwenden",
      "Clear": "Löschen",
      "Profile": "Profil",
      "Like": "Gefällt mir",
      "View Activity": "Aktivitäten ansehen",
      "View All Blogs": "Alle Blogs anzeigen",
    },
    login: {
      "Email": "E-Mail",
      "Password": "Passwort",
      "Forgot Password": "Passwort vergessen?",
      "Log in": "Anmelden",
      "Dont have an account": "Noch kein Konto?",
      "Sign Up": "Registrieren",
      "OR Login With": "Oder anmelden mit"
    },
    signup: {
      "Create a fee account within few minutes": "Erstelle innerhalb weniger Minuten ein kostenloses Konto.",
      "Have access to a large beauty database": "Erhalte Zugang zu Informationen über alle beauty Produkte.",
      "Read what other beauty specialists say": "Lies, was andere beauty Spezialisten sagen.",
      "List your favorite products": "Erstelle eine Liste deiner Lieblingsprodukte.",
      "Name": "Name",
      "Email": "E-Mail",
      "Mobile Number": "Handynummer",
      "Country": "Land",
      "Select": "Auswählen",
      "Password": "Passwort",
      "Confirm Password": "Passwort bestätigen",
      "Create": "Erstellen",
      "By creating an account you have to agree with our": "Mit der Erstellung eines Kontos stimmst du unseren",
      "terms conditions": "Geschäftsbedingungen zu.",
      "Subscribe for newsletter": "Für den Newsletter anmelden",
      "Already have an account": "Schon ein Konto?",
      "Login": "Anmelden",
      "OR Login With": "Oder anmelden mit",
    },
    ForgetPassword: {
      "Forgot your password": "Passwort vergessen?",
      "Enter your registered email address to get a link to reset your password": "Gib deine registrierte E-Mail-Adresse ein, um einen Link zum Zurücksetzen deines Passworts zu erhalten",
      "Email": "E-Mail",
      "Submit": "Absenden",
      "Back to login": "Zurück zur Anmeldung",
      "Login": "Anmelden",
      "Successful": "Erfolgreich!",
      "We have sent an OTP": "Wir haben einen Bestätigungscode gesendet",
      "to your email address": "an deine E-Mail-Adresse",
      "OK": "Ok",
      "Didnt received yet": "Noch nicht erhalten?",
      "Resend": "Erneut senden",
    },
    Header: {
      "Favorite": "Mein Beauty",
      "Account": "Konto",
      "My Profile": "Mein Profil",
      "Notifications": "Benachrichtigungen",
      "Settings": "Einstellungen",
      "Logout": "Abmelden",
      "Login": "Anmelden",
      "Signup": "Registrieren",
      "All": "Alle",
    },
    Footer: {
      "About Us": "Über uns",
      "Contact Us": "Kontaktiere uns",
      "Terms Conditions": "Geschäftsbedingungen",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Datenschutzrichtlinie",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Alle Rechte vorbehalten."
    },
    contact: {
      "Message": "Nachricht",
      "Message Error": "Bitte gib eine Nachricht ein",
      "Subject": "Betreff",
      "Subject Error": "Bitte gib einen Betreff ein",
      "Email": "E-Mail",
      "Email Error": "Bitte gib eine E-Mail ein",
      "Name": "Name",
      "Name Error": "Bitte gib einen Namen ein"
    },
    Delete: {
      "Alert": "Achtung",
      "Are you sure you want to delete": "Möchtest du wirklich löschen?",
      "Yes": "Ja",
      "No": "Nein",
    },
    Thankyou: {
      "Thank You": "Danke",
      "Our Admin team will verify this request and contact you": "Unser Kundenteam wird diese Anfrage überprüfen und sich bei dir melden",
      "OK": "Ok"
    },
    TimePicker: {
      "Hours": "Stunden",
      "Minuts": "Minuten"
    },
    recommendation: {
      "Our Recommendations": "Unsere Empfehlungen",
      "View all": "Alles anzeigen",
    },
    settings: {
      "Settings": "Einstellungen",
      "Change Password": "Passwort ändern",
      "Newsletter": "Newsletter",
      "Current Password": "Aktuelles Passwort",
      "New Password": "Neues Passwort",
      "Confirm Password": "Passwort bestätigen",
      "Save": "Speichern",
      "Newsletter Notifications": "Newsletter"
    },
    notification: {
      "Home": "Startseite",
      "Notifications": "Benachrichtigungen",
      "Notification not available": "Benachrichtigung nicht verfügbar"
    },
    blog: {
      "Home": "Startseite",
      "Blogs": "Blogs",
      "Latest Blog Post": "Neuester Blogbeitrag",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Bitte erneut versuchen",
      "Loading": "Lädt...",
      "Looks like something went wrong": "Es scheint ein Problem gegeben zu haben!",
      "Error": "Fehler",
      "retry": "Erneut versuchen"
    },
    brand: {
      "Home": "Startseite",
      "Brands": "Marken",
      "No brand available": "Diese Marke ist nicht verfügbar.",
      "Select to search": "Zum Suchen auswählen"
    },
    pagination: {
      "Previous": "Zurück",
      "Next": "Weiter"
    },
    customerDetail: {
      "Follow": "Folgen",
      "Following": "Folge ich",
      "Followers": "Follower",
      "Country": "Land",
      "About Me": "Über mich",
      "View Activity": "Aktivität anzeigen",
      "Hair Style": "Frisur",
      "Skin Tone": "Hautfarbe",
      "Favorite Products": "Meine beauty Produkte",
      "View all": "Alles anzeigen",
      "No favorite available": "Noch keine Lieblingsprodukte vorhanden."
    },
    highRated: {
      "Home": "Startseite",
      "Highest Rated": "Am besten bewertet",
      "Highest Rated Products": "Am besten bewertete Produkte",
      "Load More": "Mehr laden",
      "No high rated products available": "Keine hoch bewerteten Produkte verfügbar"
    },
    instaList: {
      "Home": "Startseite",
      "Instagram posts": "Instagram Beiträge",
    },
    lastViewed: {
      "Home": "Startseite",
      "Last Viewed": "Zuletzt angesehen",
      "Last Viewed Products": "Zuletzt angesehene Produkte",
      "Product not available": "Hier sind noch keine Produkte zu sehen."
    },
    myProfile: {
      "Home": "Startseite",
      "My Profile": "Mein Profil",
      "Change photo": "Foto ändern",
      "Name": "Name",
      "Email": "E-Mail",
      "Mobile Number": "Handynummer",
      "Country": "Land",
      "Hair Style": "Frisur",
      "Select Hair": "Haartyp auswählen",
      "Wavy Hair": "Welliges Haar",
      "Curly Hair": "Lockiges Haar",
      "Straight Hair": "Glattes Haar",
      "Kinky Hair": "Krauses Haar",
      "Instagram": "Instagram",
      "Skin Tone": "Hautfarbe",
      "Alergic Ingredients": "Allergene",
      "Favourite Ingredients": "Lieblingszutaten",
      "About Me": "Über mich",
      "Submit": "Absenden",
      "Select": "Auswählen",
      "EAN Code": "EAN-Code"
    },
    otp: {
      "OTP Confirmation": "OTP Bestätigung",
      "Please enter the verification code sent to your email": "Bitte gib den Verifizierungscode ein, den du per E-Mail erhalten hast.",
      "OTP is required": "OTP ist erforderlich",
      "Submit": "Absenden",
      "Resend OTP": "Code erneut senden",
    },
    wishList: {
      "Home": "Startseite",
      "Favorite": "Meine Beauty",
      "Sort By": "Sortieren nach",
      "Popularity": "Beliebtheit",
      "Low to High": "Niedrig bis hoch",
      "High to Low": "Hoch bis niedrig",
      "Apply": "Anwenden",
      "Clear": "Löschen",
      "Filter By": "Filtern nach",
      "Brand Name": "Markenname",
      "Min Price": "Min. Preis",
      "Max Price": "Max. Preis",
      "Select": "Auswählen",
      "No favorite available": "Du hast noch keine Beauty-Favoriten.",
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Neues Passwort",
      "Confirm Password": "Passwort bestätigen",
      "Submit": "Absenden",
    },
    productCategory: {
      "Home": "Startseite",
      "Product Categories": "Produktkategorien",
    },
    myReview: {
      "My Review": "Meine Bewertung",
      "Write Review": "Bewertung schreiben",
      "review": "Bewertung",
      "Submit": "Absenden",
    },
    productDetail: {
      "Home": "Startseite",
      "Category": "Kategorie",
      "Sub Category": "Unterkategorie",
      "Brands": "Marken",
      "Highest Rated": "Am besten bewertet",
      "Last Viewed": "Zuletzt angesehen",
      "Recommendations": "Empfehlungen",
      "Products": "Produkte",
      "Product Detail": "Produktdetails",
      "USD": "USD",
      "Write Review": "Bewertung schreiben",
      "View Review": "Bewertungen anzeigen",
      "Description": "Beschreibung",
      "Read": "Lesen",
      "Less": "Weniger",
      "More": "Mehr",
      "Ingredient": "Zutaten",
      "Similar Products": "Ähnliche Produkte",
      "View all": "Alle anzeigen",
      "Reviews Ratings": "Bewertungen & Bewertung",
      "No Review Added Yet": "Noch keine Bewertungen hinzugefügt.",
      "Submit": "Absenden",
      "review": "Bewertung",
      "Same Country": "Mein Land",
      "View All ": "Alle anzeigen "
    },
    review: {
      "Reviews": "Bewertungen",
      "Add a Review": "Eine Bewertung hinzufügen",
      "View Review": "Bewertungen anzeigen",
      "Review Rating": "Bewertungen & Bewertung",
      "Write Review": "Bewertung schreiben",
      "Submit": "Absenden",
      "Sort By": "Sortieren nach",
      "Most Recent": "Am neuesten",
      "Top Rating": "Top-Bewertung",
      "Like High to Low": "Likes von hoch bis niedrig",
      "Apply": "Anwenden",
      "Clear": "Löschen",
      "Filter By": "Filtern nach",
      "Review not available": "Bewertungen nicht verfügbar",
      "Click to select": "Zum Auswählen klicken",
      "stars": "Sterne",
      "You have Selected": "Du hast ausgewählt: "
    },
    productList: {
      "Home": "Startseite",
      "Product Categories": "Hauptkategorien",
      "Brands": "Marken",
      "Product Sub Categories": "Kategorien",
      "Product Sub Sub Categories": "Unterkategorien",
      "Products": "Produkte",
      "Sort By": "Sortieren nach",
      "Popularity": "Beliebtheit",
      "Low to High": "Niedrig bis hoch",
      "High to Low": "Hoch bis niedrig",
      "Apply": "Anwenden",
      "Clear": "Löschen",
      "Filter By": "Filtern nach",
      "Brand Name": "Markenname",
      "Select": "Auswählen",
      "Min Price": "Min. Preis",
      "Max Price": "Max. Preis",
      "Product not available": "Produkt nicht verfügbar"
    },
    productSubCategory: {
      "Home": "Startseite",
      "Product Categories": "Hauptkategorien",
      "Product Sub Categories": "Kategorien",
      "Product Sub Sub Categories": "Unterkategorien",
      "Sub category not available": "Unterkategorie nicht verfügbar",
      "Category not available": "Kategorie nicht verfügbar"
    },
    recommendationList: {
      "Home": "Startseite",
      "Our Recommendation": "Unsere Empfehlungen",
      "No product available": "Keine Produkte verfügbar"
    },
    search: {
      "Search": "Suche",
      "Product Categories": "Kategorien",
      "View all": "Alle anzeigen",
      "Brands": "Marken",
      "Products": "Produkte",
      "Filter By": "Filtern nach",
      "Brand Name": "Markenname",
      "Select": "Auswählen",
      "Ingredients": "Zutaten",
      "Ingredient": "Zutat",
      "Include": "Einschließen",
      "Not Include": "Ausschließen",
      "Min Price": "Min. Preis",
      "Max Price": "Max. Preis",
      "Customer Reviews": "Kundenbewertungen",
      "Customer Ratings": "Kundenbewertungen",
      "Apply": "Anwenden",
      "Clear": "Löschen",
      "Product not available": "Produkte nicht verfügbar",
      "Brands not available": "Marken nicht verfügbar",
    },
    similar: {
      "Product Detail": "Produktdetail",
      "Similar Products": "Ähnliche Produkte",
      "Product not available": "Produkte nicht verfügbar"
    },
    activity: {
      "View Activity": "Aktivität anzeigen",
    },
    home: {
      "Product Categories": "Kategorien",
      "View all": "Alle anzeigen",
      "Brands": "Marken",
      "Highest Rated": "Am besten bewertet",
      "Last Viewed Products": "Zuletzt angesehene Produkte",
      "Latest Blog Post": "Neuester Blogbeitrag",
      "Instagram Post": "Instagram Beiträge",
    },
    share: {
      "Share": "Teilen",
      "Copy Link": "Link kopieren",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Erfolgreich in die Zwischenablage kopiert",
    },
    validation: {
      "Please enter": "Bitte gib ein",
      "Please select": "Bitte wähle aus",
      "This field is required": "Dieses Feld ist erforderlich",
      "Please enter valid email": "Bitte gib eine gültige E-Mail-Adresse ein",
      "Please enter valid contact number": "Bitte gib eine gültige Kontaktnummer ein",
      "Please enter valid pin code": "Bitte gib einen gültigen Postleitzahl ein",
      "Please enter valid": "Bitte gib gültig ein",
      "Please enter valid name": "Bitte gib einen gültigen Namen ein",
      "This field must be at least": "Dieses Feld muss mindestens",
      "characters": "Zeichen",
      "Space is not allowed in password": "Im Passwort sind keine Leerzeichen erlaubt",
      "Passwords must match": "Passwörter müssen übereinstimmen",
      "Please enter valid price": "Bitte gib einen gültigen Preis ein",
      "Please enter numeric values only": "Bitte gib nur numerische Werte ein"
    },
    webService: {
      "Server Not Responding": "Server antwortet nicht",
      "Invalid Username or Password": "Ungültiger Benutzername oder Passwort",
      "Bad Response": "Schlechte Antwort",
      "Server Error": "Serverfehler",
    }
  },
  es: {
    new: {
      "Login to Grade my beauty account using your credintials": "Inicia sesión en tu cuenta de GradeMyBeauty con tus credenciales",
      "Create An Account": "Crea una cuenta",
      "OR Continue with": "O continua con:",
      "Already Have An Account": "Ya tienes una cuenta",
      "Please enter following details to sign up": "Por favor, introduce los siguientes detalles para registrarte",
      "Success": "Éxito",
      "OTP has been verified": "El OTP ha sido verificado",
      "successfully": "Con éxito.",
      "Go To Login": "Ir al inicio de sesión",
      "Verify OTP": "Verificar OTP",
      "Verify OTP which we have sent on your email": "Verifica el OTP que hemos enviado a tu correo electrónico.",
      "Verify": "Verificar",
      "Select Country": "Selecciona país",
      "Send": "Enviar",
      "Explore More": "Explorar más",
      "Categories": "Categorías",
      "View All": "View All",
      "View All Categories": "Ver todas las categorías",
      "View All Brands": "Ver todas las marcas",
      "Subscribe to our newsletter": "Suscríbete a nuestro boletín.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Mantente al tanto, suscríbete a nuestro boletín y mantente actualizado.",
      "Subscribe": "Suscribirse",
      "Hot Posts": "Publicaciones populares",
      "No post found": "No se encontraron publicaciones",
      "Follow the Topic": "Seguir el tema",
      "Follow": "Seguir",
      "UserName": "UserName",
      "UnFollow": "Dejar de seguir",
      "No topics found": "No se encontraron temas",
      "People you should follow": "Personas que deberías seguir",
      "No users found": "No se encontraron usuarios",
      "Back": "Volver",
      "Edit Profile": "Editar perfil",
      "alergic ingredient": "Ingredientes alergénicos",
      "favorite ingredient": "Ingredientes favoritos",
      "Products": "Productos",
      "Read More": "Leer más",
      "View All Products": "Ver todos los productos",
      "All": "Todo",
      "Create": "Crear",
      "Create Post": "Crear publicación",
      "Start Discussion": "Iniciar discusión",
      "Phone": "Teléfono",
      "Post": "Publicación",
      "Discussion": "Discusión",
      "Start New Discussion": "Iniciar nueva discusión",
      "Public": "Público",
      "Private": "Privado",
      "Select Topic": "Seleccionar tema",
      "Review Posted Successfully": "Reseña publicada con éxito",
      "5 Points Earned!": "¡5 puntos ganados!",
      "Comments": "Comentarios",
      "Replies": "Respuestas",
      "No comments found": "No se encontraron comentarios",
      "Reply": "Responder",
      "Delete": "Eliminar",
      "Cancel": "Cancelar",
      "Are you sure, you want to Delete?": "¿Estás seguro de que quieres eliminar?",
      "Type your reply": "Escribe tu respuesta...",
      "Search": "Buscar",
      "Edit": "Editar",
      "Reward Points": "Puntos de recompensa",
      "Points Earned Till Now!": "Puntos ganados hasta ahora",
      "Earn More Rewards": "Gana más recompensas",
      "Earn Reward Points": "Gana puntos de recompensa",
      "Earn reward points by posting product review and posting on the discussion": "Gana puntos de recompensa publicando reseñas de productos y participando en discusiones.",
      "About": "Acerca de",
      "Product Review": "Reseña de producto",
      "Your review has been": "Tu reseña ha sido",
      "posted successfully": "publicada con éxito.",
      "Ingredients": "Ingredientes",
      "Write to Review": "Escribir una reseña",
      "Product List": "Lista de productos",
      "Yes": "Sí",
      "No": "No",
      "Apply": "Aplicar",
      "Clear": "Limpiar",
      "Profile": "Perfil",
      "Like": "Me gusta",
      "View Activity": "Ver actividad",
      "View All Blogs": "Ver todos los blogs",
    },
    login: {
      "Email": "Correo electrónico",
      "Password": "Contraseña",
      "Forgot Password": "¿Olvidaste tu contraseña?",
      "Log in": "Iniciar sesión",
      "Dont have an account": "¿No tienes una cuenta?",
      "Sign Up": "Registrarse",
      "OR Login With": "O iniciar sesión con"
    },
    signup: {
      "Create a fee account within few minutes": "Crea una cuenta gratuita en pocos minutos.",
      "Have access to a large beauty database": "Accede a la información sobre todos los productos de beauty.",
      "Read what other beauty specialists say": "Lee lo que otros especialistas en beauty dicen.",
      "List your favorite products": "Crea una lista de tus productos favoritos.",
      "Name": "Nombre",
      "Email": "Correo electrónico",
      "Mobile Number": "Número de móvil",
      "Country": "País",
      "Select": "Seleccionar",
      "Password": "Contraseña",
      "Confirm Password": "Confirmar contraseña",
      "Create": "Crear",
      "By creating an account you have to agree with our": "Al crear una cuenta debes aceptar nuestros",
      "terms conditions": "Términos y Condiciones.",
      "Subscribe for newsletter": "Suscríbete a nuestro boletín",
      "Already have an account": "¿Ya tienes una cuenta?",
      "Login": "Iniciar sesión",
      "OR Login With": "O iniciar sesión con",
    },
    ForgetPassword: {
      "Forgot your password": "¿Olvidaste tu contraseña?",
      "Enter your registered email address to get a link to reset your password": "Ingresa tu dirección de correo electrónico registrada para obtener un enlace para restablecer tu contraseña",
      "Email": "Correo electrónico",
      "Submit": "Enviar",
      "Back to login": "Volver a iniciar sesión",
      "Login": "Iniciar sesión",
      "Successful": "¡Exitoso!",
      "We have sent an OTP": "Hemos enviado un código de confirmación",
      "to your email address": "a tu dirección de correo electrónico",
      "OK": "Aceptar",
      "Didnt received yet": "¿No lo has recibido?",
      "Resend": "Reenviar",
    },
    Header: {
      "Favorite": "Mis beauty",
      "Account": "Cuenta",
      "My Profile": "Mi perfil",
      "Notifications": "Notificaciones",
      "Settings": "Ajustes",
      "Logout": "Cerrar sesión",
      "Login": "Iniciar sesión",
      "Signup": "Registrarse",
      "All": "Todos",
    },
    Footer: {
      "About Us": "Sobre nosotros",
      "Contact Us": "Contáctanos",
      "Terms Conditions": "Términos y Condiciones",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Política de privacidad",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Todos los derechos reservados."
    },
    contact: {
      "Message": "Mensaje",
      "Message Error": "Por favor, introduce un mensaje",
      "Subject": "Asunto",
      "Subject Error": "Por favor, introduce un asunto",
      "Email": "Correo electrónico",
      "Email Error": "Por favor, introduce un correo electrónico",
      "Name": "Nombre",
      "Name Error": "Por favor, introduce un nombre"
    },
    Delete: {
      "Alert": "Alerta",
      "Are you sure you want to delete": "¿Estás seguro de que quieres eliminar?",
      "Yes": "Sí",
      "No": "No",
    },
    Thankyou: {
      "Thank You": "Gracias",
      "Our Admin team will verify this request and contact you": "Nuestro equipo de atención al cliente verificará esta solicitud y te contactará",
      "OK": "Aceptar"
    },
    TimePicker: {
      "Hours": "Horas",
      "Minuts": "Minutos"
    },
    recommendation: {
      "Our Recommendations": "Nuestras recomendaciones",
      "View all": "Ver todo",
    },
    settings: {
      "Settings": "Ajustes",
      "Change Password": "Cambiar contraseña",
      "Newsletter": "Boletín",
      "Current Password": "Contraseña actual",
      "New Password": "Nueva contraseña",
      "Confirm Password": "Confirmar contraseña",
      "Save": "Guardar",
      "Newsletter Notifications": "Boletín"
    },
    notification: {
      "Home": "Inicio",
      "Notifications": "Notificaciones",
      "Notification not available": "Notificación no disponible"
    },
    blog: {
      "Home": "Inicio",
      "Blogs": "Blogs",
      "Latest Blog Post": "Última entrada del blog",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Por favor, intenta de nuevo",
      "Loading": "Cargando...",
      "Looks like something went wrong": "¡Parece que algo salió mal!",
      "Error": "Error",
      "retry": "Reintentar"
    },
    brand: {
      "Home": "Inicio",
      "Brands": "Marcas",
      "No brand available": "Esta marca no está disponible..",
      "Select to search": "Seleccionar para buscar"
    },
    pagination: {
      "Previous": "Anterior",
      "Next": "Siguiente"
    },
    customerDetail: {
      "Follow": "Seguir",
      "Following": "Siguiendo",
      "Followers": "Seguidores",
      "Country": "País",
      "About Me": "Sobre mí",
      "View Activity": "Ver actividad",
      "Hair Style": "Estilo de cabello",
      "Skin Tone": "Tono de piel",
      "Favorite Products": "Mis productos de beauty",
      "View all": "Ver todo",
      "No favorite available": "Aún no tienes productos favoritos."
    },
    highRated: {
      "Home": "Inicio",
      "Highest Rated": "Más valorados",
      "Highest Rated Products": "Productos más valorados",
      "Load More": "Cargar más",
      "No high rated products available": "No hay productos altamente valorados disponibles"
    },
    instaList: {
      "Home": "Inicio",
      "Instagram posts": "Publicaciones de Instagram",
    },
    lastViewed: {
      "Home": "Inicio",
      "Last Viewed": "Vistos recientemente",
      "Last Viewed Products": "Productos vistos recientemente",
      "Product not available": "Aún no hay productos para mostrar aquí."
    },
    myProfile: {
      "Home": "Inicio",
      "My Profile": "Mi perfil",
      "Change photo": "Cambiar foto",
      "Name": "Nombre",
      "Email": "Correo electrónico",
      "Mobile Number": "Número de móvil",
      "Country": "País",
      "Hair Style": "Estilo de cabello",
      "Select Hair": "Seleccionar tipo de cabello",
      "Wavy Hair": "Cabello ondulado",
      "Curly Hair": "Cabello rizado",
      "Straight Hair": "Cabello liso",
      "Kinky Hair": "Cabello afro",
      "Instagram": "Instagram",
      "Skin Tone": "Tono de piel",
      "Alergic Ingredients": "Alérgenos",
      "Favourite Ingredients": "Ingredientes favoritos",
      "About Me": "Sobre mí",
      "Submit": "Enviar",
      "Select": "Seleccionar",
      "EAN Code": "Código EAN"
    },
    otp: {
      "OTP Confirmation": "Confirmación de OTP",
      "Please enter the verification code sent to your email": "Introduce el código de verificación enviado a tu correo electrónico.",
      "OTP is required": "Se requiere el código",
      "Submit": "Enviar",
      "Resend OTP": "Reenviar código",
    },
    wishList: {
      "Home": "Inicio",
      "Favorite": "Mi Beauty",
      "Sort By": "Ordenar por",
      "Popularity": "Popularidad",
      "Low to High": "De menor a mayor",
      "High to Low": "De mayor a menor",
      "Apply": "Aplicar",
      "Clear": "Limpiar",
      "Filter By": "Filtrar por",
      "Brand Name": "Nombre de la marca",
      "Min Price": "Precio mínimo",
      "Max Price": "Precio máximo",
      "Select": "Seleccionar",
      "No favorite available": "Aún no tienes productos favoritos."
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Nueva contraseña",
      "Confirm Password": "Confirmar contraseña",
      "Submit": "Enviar"
    },
    productCategory: {
      "Home": "Inicio",
      "Product Categories": "Categorías de productos",
    },
    myReview: {
      "My Review": "Mi reseña",
      "Write Review": "Escribir reseña",
      "review": "Reseña",
      "Submit": "Enviar"
    },
    productDetail: {
      "Home": "Inicio",
      "Category": "Categoría",
      "Sub Category": "Subcategoría",
      "Brands": "Marcas",
      "Highest Rated": "Más valorado",
      "Last Viewed": "Último visto",
      "Recommendations": "Recomendaciones",
      "Products": "Productos",
      "Product Detail": "Detalles del producto",
      "USD": "USD", // Deze blijft hetzelfde, het is een universele munteenheid.
      "Write Review": "Escribir reseña",
      "View Review": "Ver reseñas",
      "Description": "Descripción",
      "Read": "Leer",
      "Less": "Menos",
      "More": "Más",
      "Ingredient": "Ingredientes",
      "Similar Products": "Productos similares",
      "View all": "Ver todos",
      "Reviews Ratings": "Reseñas y valoraciones",
      "No Review Added Yet": "Todavía no hay reseñas",
      "Submit": "Enviar",
      "review": "reseña",
      "Same Country": "Mi país",
      "View All": "Ver todos"
    },
    review: {
      "Reviews": "Reseñas",
      "Add a Review": "Añadir una reseña",
      "View Review": "Ver reseñas",
      "Review Rating": "Reseñas y valoraciones",
      "Write Review": "Escribir reseña",
      "Submit": "Enviar",
      "Sort By": "Ordenar por",
      "Most Recent": "Más recientes",
      "Top Rating": "Mejor valorado",
      "Like High to Low": "Likes de más a menos",
      "Apply": "Aplicar",
      "Clear": "Borrar",
      "Filter By": "Filtrar por",
      "Review not available": "Reseña no disponible",
      "Click to select": "Haz clic para seleccionar",
      "stars": "estrellas",
      "You have Selected": "Has seleccionado:"
    },
    productList: {
      "Home": "Inicio",
      "Product Categories": "Categorías principales",
      "Brands": "Marcas",
      "Product Sub Categories": "Categorías",
      "Product Sub Sub Categories": "Subcategorías",
      "Products": "Productos",
      "Sort By": "Ordenar por",
      "Popularity": "Popularidad",
      "Low to High": "De menor a mayor",
      "High to Low": "De mayor a menor",
      "Apply": "Aplicar",
      "Clear": "Borrar",
      "Filter By": "Filtrar por",
      "Brand Name": "Nombre de la marca",
      "Select": "Seleccionar",
      "Min Price": "Precio mínimo",
      "Max Price": "Precio máximo",
      "Product not available": "Producto no disponible"
    },
    productSubCategory: {
      "Home": "Inicio",
      "Product Categories": "Categorías principales",
      "Product Sub Categories": "Categorías",
      "Product Sub Sub Categories": "Subcategorías",
      "Sub category not available": "Subcategoría no disponible",
      "Category not available": "Categoría no disponible"
    },
    recommendationList: {
      "Home": "Inicio",
      "Our Recommendation": "Nuestras recomendaciones",
      "No product available": "No hay productos disponibles"
    },
    search: {
      "Search": "Buscar",
      "Product Categories": "Categorías",
      "View all": "Ver todos",
      "Brands": "Marcas",
      "Products": "Productos",
      "Filter By": "Filtrar por",
      "Brand Name": "Nombre de la marca",
      "Select": "Seleccionar",
      "Ingredients": "Ingredientes",
      "Ingredient": "Ingrediente",
      "Include": "Incluir",
      "Not Include": "No incluir",
      "Min Price": "Precio mínimo",
      "Max Price": "Precio máximo",
      "Customer Reviews": "Reseñas de clientes",
      "Customer Ratings": "Valoraciones de clientes",
      "Apply": "Aplicar",
      "Clear": "Borrar",
      "Product not available": "Producto no disponible",
      "Brands not available": "Marcas no disponibles"
    },
    similar: {
      "Product Detail": "Detalles del producto",
      "Similar Products": "Productos similares",
      "Product not available": "Producto no disponible"
    },
    activity: {
      "View Activity": "Ver actividad",
    },
    home: {
      "Product Categories": "Categorías",
      "View all": "Ver todos",
      "Brands": "Marcas",
      "Highest Rated": "Más valorado",
      "Last Viewed Products": "Productos vistos recientemente",
      "Latest Blog Post": "Última entrada del blog",
      "Instagram Post": "Publicaciones de Instagram"
    },
    share: {
      "Share": "Compartir",
      "Copy Link": "Copiar enlace",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Copiado con éxito al portapapeles"
    },
    validation: {
      "Please enter": "Introduce por favor",
      "Please select": "Selecciona por favor",
      "This field is required": "Este campo es obligatorio",
      "Please enter valid email": "Introduce una dirección de correo válida",
      "Please enter valid contact number": "Introduce un número de contacto válido",
      "Please enter valid pin code": "Introduce un código postal válido",
      "Please enter valid": "Introduce válido",
      "Please enter valid name": "Introduce un nombre válido",
      "This field must be at least": "Este campo debe tener al menos",
      "characters": "caracteres",
      "Space is not allowed in password": "No se permite espacio en la contraseña",
      "Passwords must match": "Las contraseñas deben coincidir",
      "Please enter valid price": "Introduce un precio válido",
      "Please enter numeric values only": "Introduce solo valores numéricos"
    },
    webService: {
      "Server Not Responding": "El servidor no responde",
      "Invalid Username or Password": "Nombre de usuario o contraseña inválidos",
      "Bad Response": "Respuesta incorrecta",
      "Server Error": "Error del servidor",
    }
  },
  fi: {
    new: {
      "Login to Grade my beauty account using your credintials": "Kirjaudu GradeMyBeauty-tilillesi käyttäen tunnuksiasi",
      "Create An Account": "Luo tili",
      "OR Continue with": "Tai jatka käyttäen:",
      "Already Have An Account": "Onko sinulla jo tili",
      "Please enter following details to sign up": "Anna seuraavat tiedot rekisteröityäksesi",
      "Success": "Onnistui",
      "OTP has been verified": "OTP on vahvistettu",
      "successfully": "Onnistuneesti.",
      "Go To Login": "Siirry kirjautumiseen",
      "Verify OTP": "Vahvista OTP",
      "Verify OTP which we have sent on your email": "Vahvista OTP, jonka olemme lähettäneet sähköpostiisi.",
      "Verify": "Vahvista",
      "Select Country": "Valitse maa",
      "Send": "Lähetä",
      "Explore More": "Tutustu lisää",
      "Categories": "Kategoriat",
      "View All": "View All",
      "View All Categories": "Katso kaikki kategoriat",
      "View All Brands": "Katso kaikki merkit",
      "Subscribe to our newsletter": "Tilaa uutiskirjeemme.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Pysy kuulolla, tilaa uutiskirjeemme ja pysy ajan tasalla.",
      "Subscribe": "Tilaa",
      "Hot Posts": "Kuumat julkaisut",
      "No post found": "Julkaisuja ei löytynyt",
      "Follow the Topic": "Seuraa aihetta",
      "Follow": "Seuraa",
      "UserName": "UserName",
      "UnFollow": "Lopeta seuraaminen",
      "No topics found": "Aiheita ei löytynyt",
      "People you should follow": "Ihmiset, joita sinun tulisi seurata",
      "No users found": "Käyttäjiä ei löytynyt",
      "Back": "Takaisin",
      "Edit Profile": "Muokkaa profiilia",
      "alergic ingredient": "Allergeenit",
      "favorite ingredient": "Suosikkiaineosat",
      "Products": "Tuotteet",
      "Read More": "Lue lisää",
      "View All Products": "Näytä kaikki tuotteet",
      "All": "Kaikki",
      "Create": "Luo",
      "Create Post": "Luo julkaisu",
      "Start Discussion": "Aloita keskustelu",
      "Phone": "Puhelin",
      "Post": "Julkaisu",
      "Discussion": "Keskustelu",
      "Start New Discussion": "Aloita uusi keskustelu",
      "Public": "Julkinen",
      "Private": "Yksityinen",
      "Select Topic": "Valitse aihe",
      "Review Posted Successfully": "Arvostelu julkaistu onnistuneesti!",
      "5 Points Earned!": "5 pistettä ansaittu!",
      "Comments": "Kommentit",
      "Replies": "Vastaukset",
      "No comments found": "Kommentteja ei löytynyt",
      "Reply": "Vastaa",
      "Delete": "Poista",
      "Cancel": "Peruuta",
      "Are you sure, you want to Delete?": "Oletko varma, että haluat poistaa?",
      "Type your reply": "Kirjoita vastauksesi...",
      "Search": "Hae",
      "Edit": "Muokkaa",
      "Reward Points": "Palkintopisteet",
      "Points Earned Till Now!": "Pisteet ansaittu tähän mennessä",
      "Earn More Rewards": "Ansaitse lisää palkintoja",
      "Earn Reward Points": "Ansaitse palkintopisteitä",
      "Earn reward points by posting product review and posting on the discussion": "Ansaitse palkintopisteitä julkaisemalla tuotearvosteluja ja osallistumalla keskusteluihin.",
      "About": "Tietoja",
      "Product Review": "Tuotearvostelu",
      "Your review has been": "Arvostelusi on",
      "posted successfully": "julkaistu onnistuneesti.",
      "Ingredients": "Ainesosat",
      "Write to Review": "Kirjoita arvostelu",
      "Product List": "Tuoteluettelo",
      "Yes": "Kyllä",
      "No": "Ei",
      "Apply": "Käytä",
      "Clear": "Tyhjennä",
      "Profile": "Profiili",
      "Like": "Tykkää",
      "View Activity": "Katso toimintaa",
      "View All Blogs": "Näytä kaikki blogit",
    },
    login: {
      "Email": "Sähköposti",
      "Password": "Salasana",
      "Forgot Password": "Unohtunut salasana?",
      "Log in": "Kirjaudu sisään",
      "Dont have an account": "Eikö sinulla ole tiliä",
      "Sign Up": "Rekisteröidy",
      "OR Login With": "Tai kirjaudu sisään käyttäen"
    },
    signup: {
      "Create a fee account within few minutes": "Luo ilmainen tili muutamassa minuutissa.",
      "Have access to a large beauty database": "Pääset käsiksi tietokantaan, joka sisältää kaikki beauty tuotteet.",
      "Read what other beauty specialists say": "Lue, mitä muut beauty asiantuntijat sanovat.",
      "List your favorite products": "Luo lista suosikkituotteistasi.",
      "Name": "Nimi",
      "Email": "Sähköposti",
      "Mobile Number": "Matkapuhelinnumero",
      "Country": "Maa",
      "Select": "Valitse",
      "Password": "Salasana",
      "Confirm Password": "Vahvista salasana",
      "Create": "Luo",
      "By creating an account you have to agree with our": "Luomalla tilin hyväksyt",
      "terms conditions": "Käyttöehdot.",
      "Subscribe for newsletter": "Tilaa uutiskirjeemme",
      "Already have an account": "Onko sinulla jo tili?",
      "Login": "Kirjaudu sisään",
      "OR Login With": "Tai kirjaudu sisään käyttäen",
    },
    ForgetPassword: {
      "Forgot your password": "Unohditko salasanasi?",
      "Enter your registered email address to get a link to reset your password": "Syötä rekisteröity sähköpostiosoitteesi saadaksesi linkin salasanan palauttamiseksi",
      "Email": "Sähköposti",
      "Submit": "Lähetä",
      "Back to login": "Takaisin kirjautumiseen",
      "Login": "Kirjaudu",
      "Successful": "Onnistui!",
      "We have sent an OTP": "Olemme lähettäneet vahvistuskoodin",
      "to your email address": "sähköpostiosoitteeseesi",
      "OK": "Ok",
      "Didnt received yet": "Eikö koodi ole saapunut?",
      "Resend": "Pyydä uudelleen."
    },
    Header: {
      "Favorite": "Oma Beauty",
      "Account": "Tili",
      "My Profile": "Oma profiili",
      "Notifications": "Ilmoitukset",
      "Settings": "Asetukset",
      "Logout": "Kirjaudu ulos",
      "Login": "Kirjaudu",
      "Signup": "Rekisteröidy",
      "All": "Kaikki",
    },
    Footer: {
      "About Us": "Tietoa meistä",
      "Contact Us": "Ota yhteyttä",
      "Terms Conditions": "Käyttöehdot",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Tietosuojakäytäntö",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Kaikki oikeudet pidätetään."
    },
    contact: {
      "Message": "Viesti",
      "Message Error": "Syötä viesti",
      "Subject": "Aihe",
      "Subject Error": "Syötä aihe",
      "Email": "Sähköposti",
      "Email Error": "Syötä sähköposti",
      "Name": "Nimi",
      "Name Error": "Syötä nimi"
    },
    Delete: {
      "Alert": "Hälytys",
      "Are you sure you want to delete": "Haluatko varmasti poistaa?",
      "Yes": "Kyllä",
      "No": "Ei",
    },
    Thankyou: {
      "Thank You": "Kiitos",
      "Our Admin team will verify this request and contact you": "Asiakaspalvelumme tarkistaa tämän pyynnön ja ottaa sinuun yhteyttä",
      "OK": "Ok"
    },
    TimePicker: {
      "Hours": "Tunnit",
      "Minuts": "Minuutit"
    },
    recommendation: {
      "Our Recommendations": "Suosituksemme",
      "View all": "Näytä kaikki",
    },
    settings: {
      "Settings": "Asetukset",
      "Change Password": "Vaihda salasana",
      "Newsletter": "Uutiskirje",
      "Current Password": "Nykyinen salasana",
      "New Password": "Uusi salasana",
      "Confirm Password": "Vahvista salasana",
      "Save": "Tallenna",
      "Newsletter Notifications": "Uutiskirje"
    },
    notification: {
      "Home": "Koti",
      "Notifications": "Ilmoitukset",
      "Notification not available": "Ilmoituksia ei ole saatavilla"
    },
    blog: {
      "Home": "Koti",
      "Blogs": "Blogit",
      "Latest Blog Post": "Uusin blogikirjoitus",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Yritä uudelleen",
      "Loading": "Ladataan...",
      "Looks like something went wrong": "Jotain meni pieleen!",
      "Error": "Virhe",
      "retry": "Yritä uudelleen"
    },
    brand: {
      "Home": "Koti",
      "Brands": "Brändit",
      "No brand available": "Tätä brändiä ei ole saatavilla..",
      "Select to search": "Valitse etsiäksesi"
    },
    pagination: {
      "Previous": "Edellinen",
      "Next": "Seuraava"
    },
    customerDetail: {
      "Follow": "Seuraa",
      "Following": "Seuraat",
      "Followers": "Seuraajat",
      "Country": "Maa",
      "About Me": "Tietoja minusta",
      "View Activity": "Näytä toiminta",
      "Hair Style": "Hiustyyli",
      "Skin Tone": "Ihonväri",
      "Favorite Products": "Omat beauty tuotteet",
      "View all": "Näytä kaikki",
      "No favorite available": "Sinulla ei ole vielä suosikkituotteita."
    },
    highRated: {
      "Home": "Koti",
      "Highest Rated": "Korkeimmin arvioidut",
      "Highest Rated Products": "Korkeimmin arvioidut tuotteet",
      "Load More": "Lataa lisää",
      "No high rated products available": "Korkean arvostelun tuotteita ei ole saatavilla"
    },
    instaList: {
      "Home": "Koti",
      "Instagram posts": "Instagram-julkaisut",
    },
    lastViewed: {
      "Home": "Koti",
      "Last Viewed": "Viimeksi katsottu",
      "Last Viewed Products": "Viimeksi katsotut tuotteet",
      "Product not available": "Tuotteita ei ole näytettävissä vielä."
    },
    myProfile: {
      "Home": "Koti",
      "My Profile": "Oma profiili",
      "Change photo": "Vaihda kuva",
      "Name": "Nimi",
      "Email": "Sähköposti",
      "Mobile Number": "Matkapuhelinnumero",
      "Country": "Maa",
      "Hair Style": "Hiustyyli",
      "Select Hair": "Valitse hiustyyppi",
      "Wavy Hair": "Laineikkaat hiukset",
      "Curly Hair": "Kiharat hiukset",
      "Straight Hair": "Suorat hiukset",
      "Kinky Hair": "Afrohiukset",
      "Instagram": "Instagram",
      "Skin Tone": "Ihonväri",
      "Alergic Ingredients": "Allergeenit",
      "Favourite Ingredients": "Suosikkiainesosat",
      "About Me": "Tietoja minusta",
      "Submit": "Lähetä",
      "Select": "Valitse",
      "EAN Code": "EAN-koodi"
    },
    otp: {
      "OTP Confirmation": "OTP Vahvistus",
      "Please enter the verification code sent to your email": "Anna vahvistuskoodi, joka on lähetetty sähköpostiisi.",
      "OTP is required": "OTP on tarpeen",
      "Submit": "Lähetä",
      "Resend OTP": "Lähetä OTP uudelleen",
    },
    wishList: {
      "Home": "Koti",
      "Favorite": "Oma Beauty",
      "Sort By": "Järjestä",
      "Popularity": "Suosio",
      "Low to High": "Matalasta korkeaan",
      "High to Low": "Korkeasta matalaan",
      "Apply": "Hae",
      "Clear": "Tyhjennä",
      "Filter By": "Suodata",
      "Brand Name": "Brändin nimi",
      "Min Price": "Min hinta",
      "Max Price": "Max hinta",
      "Select": "Valitse",
      "No favorite available": "Sinulla ei ole vielä suosikkituotteita.",
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Uusi salasana",
      "Confirm Password": "Vahvista salasana",
      "Submit": "Lähetä"
    },
    productCategory: {
      "Home": "Koti",
      "Product Categories": "Tuotekategoriat",
    },
    myReview: {
      "My Review": "Oma arvostelu",
      "Write Review": "Kirjoita arvostelu",
      "review": "Arvostelu",
      "Submit": "Lähetä"
    },
    productDetail: {
      "Home": "Koti",
      "Category": "Kategoria",
      "Sub Category": "Alakategoria",
      "Brands": "Brändit",
      "Highest Rated": "Korkeimmat arviot",
      "Last Viewed": "Viimeksi katsotut",
      "Recommendations": "Suositukset",
      "Products": "Tuotteet",
      "Product Detail": "Tuotetiedot",
      "USD": "USD",
      "Write Review": "Kirjoita arvostelu",
      "View Review": "Näytä arvostelut",
      "Description": "Kuvaus",
      "Read": "Lue",
      "Less": "Vähemmän",
      "More": "Lisää",
      "Ingredient": "Ainesosat",
      "Similar Products": "Samankaltaiset tuotteet",
      "View all": "Näytä kaikki",
      "Reviews Ratings": "Arvostelut & arvosanat",
      "No Review Added Yet": "Arvosteluja ei ole lisätty vielä",
      "Submit": "Lähetä",
      "review": "Arvostelu",
      "Same Country": "Oma maa",
      "View All ": "Näytä kaikki "
    },
    review: {
      "Reviews": "Arvostelut",
      "Add a Review": "Lisää arvostelu",
      "View Review": "Näytä arvostelut",
      "Review Rating": "Arvostelut & arvosanat",
      "Write Review": "Kirjoita arvostelu",
      "Submit": "Lähetä",
      "Sort By": "Järjestä",
      "Most Recent": "Uusimmat",
      "Top Rating": "Parhaat arviot",
      "Like High to Low": "Tykkäykset korkeasta matalaan",
      "Apply": "Hae",
      "Clear": "Tyhjennä",
      "Filter By": "Suodata",
      "Review not available": "Arvosteluja ei ole saatavilla",
      "Click to select": "Klikkaa valitaksesi",
      "stars": "tähteä",
      "You have Selected": "Olet valinnut: "
    },
    productList: {
      "Home": "Koti",
      "Product Categories": "Pääkategoriat",
      "Brands": "Brändit",
      "Product Sub Categories": "Kategoriat",
      "Product Sub Sub Categories": "Alakategoriat",
      "Products": "Tuotteet",
      "Sort By": "Järjestä",
      "Popularity": "Suosio",
      "Low to High": "Matalasta korkeaan",
      "High to Low": "Korkeasta matalaan",
      "Apply": "Hae",
      "Clear": "Tyhjennä",
      "Filter By": "Suodata",
      "Brand Name": "Brändin nimi",
      "Select": "Valitse",
      "Min Price": "Min hinta",
      "Max Price": "Max hinta",
      "Product not available": "Tuotetta ei ole saatavilla"
    },
    productSubCategory: {
      "Home": "Koti",
      "Product Categories": "Pääkategoriat",
      "Product Sub Categories": "Kategoriat",
      "Product Sub Sub Categories": "Alakategoriat",
      "Sub category not available": "Alakategoriaa ei ole saatavilla",
      "Category not available": "Kategoriaa ei ole saatavilla"
    },
    recommendationList: {
      "Home": "Koti",
      "Our Recommendation": "Suosituksemme",
      "No product available": "Tuotteita ei ole saatavilla"
    },
    search: {
      "Search": "Haku",
      "Product Categories": "Kategoriat",
      "View all": "Näytä kaikki",
      "Brands": "Brändit",
      "Products": "Tuotteet",
      "Filter By": "Suodata",
      "Brand Name": "Brändin nimi",
      "Select": "Valitse",
      "Ingredients": "Ainesosat",
      "Ingredient": "Ainesosa",
      "Include": "Sisältää",
      "Not Include": "Ei sisällä",
      "Min Price": "Min hinta",
      "Max Price": "Max hinta",
      "Customer Reviews": "Asiakasarvostelut",
      "Customer Ratings": "Asiakkaiden arvioinnit",
      "Apply": "Hae",
      "Clear": "Tyhjennä",
      "Product not available": "Tuotteita ei ole saatavilla",
      "Brands not available": "Brändejä ei ole saatavilla",
    },
    similar: {
      "Product Detail": "Tuotetiedot",
      "Similar Products": "Samankaltaiset tuotteet",
      "Product not available": "Tuotteita ei ole saatavilla"
    },
    activity: {
      "View Activity": "Näytä toiminta",
    },
    home: {
      "Product Categories": "Kategoriat",
      "View all": "Näytä kaikki",
      "Brands": "Brändit",
      "Highest Rated": "Korkeimmin arvioidut",
      "Last Viewed Products": "Viimeksi katsotut tuotteet",
      "Latest Blog Post": "Uusin blogikirjoitus",
      "Instagram Post": "Instagram-julkaisut",
    },
    share: {
      "Share": "Jaa",
      "Copy Link": "Kopioi linkki",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Linkki kopioitu onnistuneesti leikepöydälle",
    },
    validation: {
      "Please enter": "Ole hyvä ja syötä",
      "Please select": "Ole hyvä ja valitse",
      "This field is required": "Tämä kenttä on pakollinen",
      "Please enter valid email": "Ole hyvä ja syötä kelvollinen sähköpostiosoite",
      "Please enter valid contact number": "Ole hyvä ja syötä kelvollinen yhteysnumero",
      "Please enter valid pin code": "Ole hyvä ja syötä kelvollinen postinumero",
      "Please enter valid": "Ole hyvä ja syötä kelvollinen",
      "Please enter valid name": "Ole hyvä ja syötä kelvollinen nimi",
      "This field must be at least": "Tämän kentän on oltava vähintään",
      "characters": "merkkiä pitkä",
      "Space is not allowed in password": "Salasanassa ei saa olla välilyöntejä",
      "Passwords must match": "Salasanojen on täsmättävä",
      "Please enter valid price": "Ole hyvä ja syötä kelvollinen hinta",
      "Please enter numeric values only": "Syötä vain numeerisia arvoja"
    },
    webService: {
      "Server Not Responding": "Palvelin ei vastaa",
      "Invalid Username or Password": "Virheellinen käyttäjätunnus tai salasana",
      "Bad Response": "Virheellinen vastaus",
      "Server Error": "Palvelinvirhe",
    }
  },
  fr: {
    new: {
      "Login to Grade my beauty account using your credintials": "Connectez-vous à votre compte GradeMyBeauty avec vos identifiants",
      "Create An Account": "Créer un compte",
      "OR Continue with": "Ou continuez avec :",
      "Already Have An Account": "Vous avez déjà un compte",
      "Please enter following details to sign up": "Veuillez entrer les détails suivants pour vous inscrire",
      "Success": "Succès",
      "OTP has been verified": "Le OTP a été vérifié",
      "successfully": "Avec succès.",
      "Go To Login": "Aller à la connexion",
      "Verify OTP": "Vérifier le OTP",
      "Verify OTP which we have sent on your email": "Vérifiez le OTP que nous avons envoyé sur votre email.",
      "Verify": "Vérifier",
      "Select Country": "Sélectionnez le pays",
      "Send": "Envoyer",
      "Explore More": "Explorer plus",
      "Categories": "Catégories",
      "View All": "View All",
      "View All Categories": "Voir toutes les catégories",
      "View All Brands": "Voir toutes les marques",
      "Subscribe to our newsletter": "Abonnez-vous à notre newsletter.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Restez à l'écoute, abonnez-vous à notre newsletter et restez informé.",
      "Subscribe": "S'abonner",
      "Hot Posts": "Publications populaires",
      "No post found": "Aucun article trouvé",
      "Follow the Topic": "Suivre le sujet",
      "Follow": "Suivre",
      "UserName": "UserName",
      "UnFollow": "Ne plus suivre",
      "No topics found": "Aucun sujet trouvé",
      "People you should follow": "Personnes que vous devriez suivre",
      "No users found": "Aucun utilisateur trouvé",
      "Back": "Retour",
      "Edit Profile": "Modifier le profil",
      "alergic ingredient": "Ingrédients allergènes",
      "favorite ingredient": "Ingrédients favoris",
      "Products": "Produits",
      "Read More": "Lire plus",
      "View All Products": "Voir tous les produits",
      "All": "Tous",
      "Create": "Créer",
      "Create Post": "Créer un post",
      "Start Discussion": "Démarrer une discussion",
      "Phone": "Téléphone",
      "Post": "Post",
      "Discussion": "Discussion",
      "Start New Discussion": "Commencer une nouvelle discussion",
      "Public": "Public",
      "Private": "Privé",
      "Select Topic": "Sélectionner un sujet",
      "Review Posted Successfully": "Critique publiée avec succès !",
      "5 Points Earned!": "5 points gagnés !",
      "Comments": "Commentaires",
      "Replies": "Réponses",
      "No comments found": "Aucun commentaire trouvé",
      "Reply": "Répondre",
      "Delete": "Supprimer",
      "Cancel": "Annuler",
      "Are you sure, you want to Delete?": "Êtes-vous sûr de vouloir supprimer ?",
      "Type your reply": "Tapez votre réponse...",
      "Search": "Recherche",
      "Edit": "Modifier",
      "Reward Points": "Points de récompense",
      "Points Earned Till Now!": "Points accumulés jusqu'à maintenant !",
      "Earn More Rewards": "Gagnez plus de récompenses",
      "Earn Reward Points": "Gagnez des points de récompense",
      "Earn reward points by posting product review and posting on the discussion": "Gagnez des points de récompense en postant des critiques de produits et en participant aux discussions.",
      "About": "À propos",
      "Product Review": "Critique de produit",
      "Your review has been": "Votre critique a été",
      "posted successfully": "publiée avec succès.",
      "Ingredients": "Ingrédients",
      "Write to Review": "Écrire une critique",
      "Product List": "Liste de produits",
      "Yes": "Oui",
      "No": "Non",
      "Apply": "Appliquer",
      "Clear": "Effacer",
      "Profile": "Profil",
      "Like": "Aimer",
      "View Activity": "Voir l'activité",
      "View All Blogs": "Voir tous les blogs",
    },
    login: {
      "Email": "E-mail",
      "Password": "Mot de passe",
      "Forgot Password": "Mot de passe oublié ?",
      "Log in": "Se connecter",
      "Dont have an account": "Tu n'as pas de compte ?",
      "Sign Up": "S'inscrire",
      "OR Login With": "Ou se connecter avec"
    },
    signup: {
      "Create a fee account within few minutes": "Crée un compte gratuitement en quelques minutes.",
      "Have access to a large beauty database": "Accède à des informations sur tous les produits de beauty.",
      "Read what other beauty specialists say": "Lis ce que disent d'autres spécialistes de la beauty.",
      "List your favorite products": "Crée une liste de tes produits préférés.",
      "Name": "Nom",
      "Email": "E-mail",
      "Mobile Number": "Numéro de mobile",
      "Country": "Pays",
      "Select": "Sélectionner",
      "Password": "Mot de passe",
      "Confirm Password": "Confirmer le mot de passe",
      "Create": "Créer",
      "By creating an account you have to agree with our": "En créant un compte, tu acceptes nos",
      "terms conditions": "Termes & Conditions.",
      "Subscribe for newsletter": "S'inscrire à la newsletter",
      "Already have an account": "Tu as déjà un compte ?",
      "Login": "Se connecter",
      "OR Login With": "Ou se connecter avec",
    },
    ForgetPassword: {
      "Forgot your password": "Mot de passe oublié ?",
      "Enter your registered email address to get a link to reset your password": "Entre ton e-mail enregistré pour recevoir un lien de réinitialisation de mot de passe",
      "Email": "E-mail",
      "Submit": "Envoyer",
      "Back to login": "Retour à la connexion",
      "Login": "Se connecter",
      "Successful": "Succès !",
      "We have sent an OTP": "Nous avons envoyé un code de confirmation",
      "to your email address": "à ton adresse e-mail",
      "OK": "Ok",
      "Didnt received yet": "Tu n'as pas reçu le code ?",
      "Resend": "Demander à nouveau.",
    },
    Header: {
      "Favorite": "Mes beauty",
      "Account": "Compte",
      "My Profile": "Mon profil",
      "Notifications": "Notifications",
      "Settings": "Paramètres",
      "Logout": "Se déconnecter",
      "Login": "Se connecter",
      "Signup": "S'inscrire",
      "All": "Tout",
    },
    Footer: {
      "About Us": "À propos de nous",
      "Contact Us": "Nous contacter",
      "Terms Conditions": "Termes & Conditions",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Politique de confidentialité",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Tous droits réservés."
    },
    contact: {
      "Message": "Message",
      "Message Error": "S'il te plaît, entre un message",
      "Subject": "Sujet",
      "Subject Error": "S'il te plaît, entre un sujet",
      "Email": "E-mail",
      "Email Error": "S'il te plaît, entre un e-mail",
      "Name": "Nom",
      "Name Error": "S'il te plaît, entre un nom"
    },
    Delete: {
      "Alert": "Alerte",
      "Are you sure you want to delete": "Es-tu sûr de vouloir supprimer ?",
      "Yes": "Oui",
      "No": "Non",
    },
    Thankyou: {
      "Thank You": "Merci",
      "Our Admin team will verify this request and contact you": "Notre équipe client vérifiera cette demande et te contactera",
      "OK": "Ok"
    },
    TimePicker: {
      "Hours": "Heures",
      "Minuts": "Minutes"
    },
    recommendation: {
      "Our Recommendations": "Nos recommandations",
      "View all": "Voir tout",
    },
    settings: {
      "Settings": "Paramètres",
      "Change Password": "Changer le mot de passe",
      "Newsletter": "Newsletter",
      "Current Password": "Mot de passe actuel",
      "New Password": "Nouveau mot de passe",
      "Confirm Password": "Confirmer le mot de passe",
      "Save": "Enregistrer",
      "Newsletter Notifications": "Newsletter"
    },
    notification: {
      "Home": "Accueil",
      "Notifications": "Notifications",
      "Notification not available": "Notification non disponible"
    },
    blog: {
      "Home": "Accueil",
      "Blogs": "Blogs",
      "Latest Blog Post": "Dernier article de blog",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "S'il te plaît, réessaye",
      "Loading": "Chargement...",
      "Looks like something went wrong": "Il semblerait qu'il y ait eu un problème !",
      "Error": "Erreur",
      "retry": "Réessayer"
    },
    brand: {
      "Home": "Accueil",
      "Brands": "Marques",
      "No brand available": "Cette marque n'est pas disponible..",
      "Select to search": "Sélectionner pour rechercher"
    },
    pagination: {
      "Previous": "Précédent",
      "Next": "Suivant"
    },
    customerDetail: {
      "Follow": "Suivre",
      "Following": "Suivi",
      "Followers": "Abonnés",
      "Country": "Pays",
      "About Me": "À propos de moi",
      "View Activity": "Voir l'activité",
      "Hair Style": "Style de cheveux",
      "Skin Tone": "Teint de peau",
      "Favorite Products": "Mes produits beauty",
      "View all": "Voir tout",
      "No favorite available": "Tu n'as pas encore de produits préférés."
    },
    highRated: {
      "Home": "Accueil",
      "Highest Rated": "Les mieux notés",
      "Highest Rated Products": "Produits les mieux notés",
      "Load More": "Charger plus",
      "No high rated products available": "Aucun produit hautement noté disponible"
    },
    instaList: {
      "Home": "Accueil",
      "Instagram posts": "Publications Instagram",
    },
    lastViewed: {
      "Home": "Accueil",
      "Last Viewed": "Derniers vus",
      "Last Viewed Products": "Derniers produits vus",
      "Product not available": "Pas de produits disponibles."
    },
    myProfile: {
      "Home": "Accueil",
      "My Profile": "Mon profil",
      "Change photo": "Changer la photo",
      "Name": "Nom",
      "Email": "Email",
      "Mobile Number": "Numéro de mobile",
      "Country": "Pays",
      "Hair Style": "Style de cheveux",
      "Select Hair": "Sélectionne le type de cheveux",
      "Wavy Hair": "Cheveux ondulés",
      "Curly Hair": "Cheveux bouclés",
      "Straight Hair": "Cheveux raides",
      "Kinky Hair": "Cheveux crépus",
      "Instagram": "Instagram",
      "Skin Tone": "Teint de peau",
      "Alergic Ingredients": "Allergènes",
      "Favourite Ingredients": "Ingrédients préférés",
      "About Me": "À propos de moi",
      "Submit": "Soumettre",
      "Select": "Sélectionner",
      "EAN Code": "Code EAN"
    },
    otp: {
      "OTP Confirmation": "Confirmation OTP",
      "Please enter the verification code sent to your email": "Entre le code de vérification envoyé à ton email, s'il te plaît.",
      "OTP is required": "Le code est requis",
      "Submit": "Soumettre",
      "Resend OTP": "Renvoyer le code",
    },
    wishList: {
      "Home": "Accueil",
      "Favorite": "Mes beautés",
      "Sort By": "Trier par",
      "Popularity": "Popularité",
      "Low to High": "Du moins cher au plus cher",
      "High to Low": "Du plus cher au moins cher",
      "Apply": "Appliquer",
      "Clear": "Effacer",
      "Filter By": "Filtrer par",
      "Brand Name": "Nom de la marque",
      "Min Price": "Prix min",
      "Max Price": "Prix max",
      "Select": "Sélectionner",
      "No favorite available": "Tu n'as pas encore de produits préférés."
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Nouveau mot de passe",
      "Confirm Password": "Confirmer le mot de passe",
      "Submit": "Soumettre"
    },
    productCategory: {
      "Home": "Accueil",
      "Product Categories": "Catégories de produits",
    },
    myReview: {
      "My Review": "Mon avis",
      "Write Review": "Écrire un avis",
      "review": "Avis",
      "Submit": "Soumettre"
    },
    productDetail: {
      "Home": "Accueil",
      "Category": "Catégorie",
      "Sub Category": "Sous-catégorie",
      "Brands": "Marques",
      "Highest Rated": "Le mieux noté",
      "Last Viewed": "Dernièrement vu",
      "Recommendations": "Recommandations",
      "Products": "Produits",
      "Product Detail": "Détails du produit",
      "USD": "USD",
      "Write Review": "Écrire un avis",
      "View Review": "Voir les avis",
      "Description": "Description",
      "Read": "Lire",
      "Less": "Moins",
      "More": "Plus",
      "Ingredient": "Ingrédients",
      "Similar Products": "Produits similaires",
      "View all": "Voir tout",
      "Reviews Ratings": "Avis & Évaluations",
      "No Review Added Yet": "Aucun avis ajouté pour l'instant",
      "Submit": "Soumettre",
      "review": "Avis",
      "Same Country": "Mon pays",
      "View All ": "Voir tout "
    },
    review: {
      "Reviews": "Avis",
      "Add a Review": "Ajouter un avis",
      "View Review": "Voir les avis",
      "Review Rating": "Évaluations & Avis",
      "Write Review": "Écrire un avis",
      "Submit": "Soumettre",
      "Sort By": "Trier par",
      "Most Recent": "Le plus récent",
      "Top Rating": "Meilleure évaluation",
      "Like High to Low": "Likes du plus élevé au plus bas",
      "Apply": "Appliquer",
      "Clear": "Effacer",
      "Filter By": "Filtrer par",
      "Review not available": "Avis non disponible",
      "Click to select": "Cliquer pour sélectionner",
      "stars": "étoiles",
      "You have Selected": "Vous avez sélectionné : "
    },
    productList: {
      "Home": "Accueil",
      "Product Categories": "Catégories principales",
      "Brands": "Marques",
      "Product Sub Categories": "Catégories",
      "Product Sub Sub Categories": "Sous-catégories",
      "Products": "Produits",
      "Sort By": "Trier par",
      "Popularity": "Popularité",
      "Low to High": "Du moins cher au plus cher",
      "High to Low": "Du plus cher au moins cher",
      "Apply": "Appliquer",
      "Clear": "Effacer",
      "Filter By": "Filtrer par",
      "Brand Name": "Nom de la marque",
      "Select": "Sélectionner",
      "Min Price": "Prix min",
      "Max Price": "Prix max",
      "Product not available": "Produit non disponible"
    },
    productSubCategory: {
      "Home": "Accueil",
      "Product Categories": "Catégories principales",
      "Product Sub Categories": "Catégories",
      "Product Sub Sub Categories": "Sous-catégories",
      "Sub category not available": "Sous-catégorie non disponible",
      "Category not available": "Catégorie non disponible"
    },
    recommendationList: {
      "Home": "Accueil",
      "Our Recommendation": "Nos recommandations",
      "No product available": "Aucun produit disponible"
    },
    search: {
      "Search": "Recherche",
      "Product Categories": "Catégories",
      "View all": "Voir tout",
      "Brands": "Marques",
      "Products": "Produits",
      "Filter By": "Filtrer par",
      "Brand Name": "Nom de la marque",
      "Select": "Sélectionner",
      "Ingredients": "Ingrédients",
      "Ingredient": "Ingrédient",
      "Include": "Inclure",
      "Not Include": "Ne pas inclure",
      "Min Price": "Prix min",
      "Max Price": "Prix max",
      "Customer Reviews": "Avis des clients",
      "Customer Ratings": "Évaluations des clients",
      "Apply": "Appliquer",
      "Clear": "Effacer",
      "Product not available": "Produits non disponibles",
      "Brands not available": "Marques non disponibles",
    },
    similar: {
      "Product Detail": "Détail du produit",
      "Similar Products": "Produits similaires",
      "Product not available": "Produits non disponibles"
    },
    activity: {
      "View Activity": "Voir l'activité",
    },
    home: {
      "Product Categories": "Catégories",
      "View all": "Voir tout",
      "Brands": "Marques",
      "Highest Rated": "Le mieux noté",
      "Last Viewed Products": "Produits vus récemment",
      "Latest Blog Post": "Dernier article de blog",
      "Instagram Post": "Publications Instagram",
    },
    share: {
      "Share": "Partager",
      "Copy Link": "Copier le lien",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Copié avec succès dans le presse-papiers",
    },
    validation: {
      "Please enter": "Veuillez entrer",
      "Please select": "Veuillez sélectionner",
      "This field is required": "Ce champ est obligatoire",
      "Please enter valid email": "Veuillez entrer une adresse e-mail valide",
      "Please enter valid contact number": "Veuillez entrer un numéro de contact valide",
      "Please enter valid pin code": "Veuillez entrer un code postal valide",
      "Please enter valid": "Veuillez entrer un(e) valide",
      "Please enter valid name": "Veuillez entrer un nom valide",
      "This field must be at least": "Ce champ doit contenir au moins",
      "characters": "caractères",
      "Space is not allowed in password": "Les espaces ne sont pas autorisés dans le mot de passe",
      "Passwords must match": "Les mots de passe doivent correspondre",
      "Please enter valid price": "Veuillez entrer un prix valide",
      "Please enter numeric values only": "Veuillez entrer uniquement des valeurs numériques"
    },
    webService: {
      "Server Not Responding": "Serveur non réactif",
      "Invalid Username or Password": "Nom d'utilisateur ou mot de passe incorrect",
      "Bad Response": "Mauvaise réponse",
      "Server Error": "Erreur du serveur",
    }
  },
  hi: {
    new: {
      "Login to Grade my beauty account using your credintials": "अपनी प्रमाणिकताओं का उपयोग करके अपने GradeMyBeauty खाते में लॉग इन करें",
      "Create An Account": "एक खाता बनाएँ",
      "OR Continue with": "या इसके साथ जारी रखें:",
      "Already Have An Account": "पहले से ही एक खाता है",
      "Please enter following details to sign up": "साइन अप करने के लिए कृपया निम्नलिखित विवरण दर्ज करें",
      "Success": "सफलता",
      "OTP has been verified": "OTP सत्यापित किया गया है",
      "successfully": "सफलतापूर्वक।",
      "Go To Login": "लॉग इन पर जाएं",
      "Verify OTP": "OTP सत्यापित करें",
      "Verify OTP which we have sent on your email": "OTP को सत्यापित करें जो हमने आपके ईमेल पर भेजा है।",
      "Verify": "सत्यापित करें",
      "Select Country": "देश चुनें",
      "Send": "भेजें",
      "Explore More": "और खोजें",
      "Categories": "श्रेणियाँ",
      "View All": "View All",
      "View All Categories": "सभी श्रेणियाँ देखें",
      "View All Brands": "सभी ब्रांड देखें",
      "Subscribe to our newsletter": "हमारे न्यूज़लेटर की सदस्यता लें।",
      "Stay tuned, Subscribe to our newsletter and stay updated": "बने रहें, हमारे न्यूज़लेटर की सदस्यता लें और अपडेट रहें।",
      "Subscribe": "सदस्यता लें",
      "Hot Posts": "हॉट पोस्ट",
      "No post found": "कोई पोस्ट नहीं मिली",
      "Follow the Topic": "विषय का अनुसरण करें",
      "Follow": "अनुसरण करें",
      "UserName": "UserName",
      "UnFollow": "अनुसरण बंद करें",
      "No topics found": "कोई विषय नहीं मिला",
      "People you should follow": "लोग जिन्हें आपको अनुसरण करना चाहिए",
      "No users found": "कोई उपयोगकर्ता नहीं मिला",
      "Back": "वापस जाएँ",
      "Edit Profile": "प्रोफ़ाइल संपादित करें",
      "alergic ingredient": "एलर्जेनिक तत्व",
      "favorite ingredient": "पसंदीदा तत्व",
      "Products": "उत्पाद",
      "Read More": "और पढ़ें",
      "View All Products": "सभी उत्पाद देखें",
      "All": "सभी",
      "Create": "बनाएँ",
      "Create Post": "पोस्ट बनाएँ",
      "Start Discussion": "चर्चा शुरू करें",
      "Phone": "फोन",
      "Post": "पोस्ट",
      "Discussion": "चर्चा",
      "Start New Discussion": "नई चर्चा शुरू करें",
      "Public": "सार्वजनिक",
      "Private": "निजी",
      "Select Topic": "विषय चुनें",
      "Review Posted Successfully": "समीक्षा सफलतापूर्वक पोस्ट की गई!",
      "5 Points Earned!": "5 अंक अर्जित!",
      "Comments": "टिप्पणियाँ",
      "Replies": "उत्तर",
      "No comments found": "कोई टिप्पणियाँ नहीं मिलीं",
      "Reply": "उत्तर दें",
      "Delete": "हटाएँ",
      "Cancel": "रद्द करें",
      "Are you sure, you want to Delete?": "क्या आप वाकई में हटाना चाहते हैं?",
      "Type your reply": "अपना उत्तर लिखें...",
      "Search": "खोजें",
      "Edit": "संपादित करें",
      "Reward Points": "पुरस्कार अंक",
      "Points Earned Till Now!": "अब तक अर्जित अंक!",
      "Earn More Rewards": "अधिक पुरस्कार प्राप्त करें",
      "Earn Reward Points": "पुरस्कार अंक प्राप्त करें",
      "Earn reward points by posting product review and posting on the discussion": "उत्पाद समीक्षा पोस्ट करके और चर्चा में पोस्ट करके पुरस्कार अंक प्राप्त करें।",
      "About": "के बारे में",
      "Product Review": "उत्पाद समीक्षा",
      "Your review has been": "आपकी समीक्षा की गई है",
      "posted successfully": "सफलतापूर्वक पोस्ट की गई।",
      "Ingredients": "सामग्री",
      "Write to Review": "समीक्षा लिखें",
      "Product List": "उत्पाद सूची",
      "Yes": "हाँ",
      "No": "नहीं",
      "Apply": "लागू करें",
      "Clear": "साफ़ करें",
      "Profile": "प्रोफ़ाइल",
      "Like": "पसंद करें",
      "View Activity": "गतिविधि देखें",
      "View All Blogs": "सभी ब्लॉग देखें",
    },
    login: {
      "Email": "ईमेल",
      "Password": "पासवर्ड",
      "Forgot Password": "पासवर्ड भूल गए?",
      "Log in": "लॉग इन करें",
      "Dont have an account": "खाता नहीं है?",
      "Sign Up": "साइन अप करें",
      "OR Login With": "या लॉग इन करें इससे"
    },
    signup: {
      "Create a fee account within few minutes": "कुछ मिनटों में मुफ्त खाता बनाएं।",
      "Have access to a large beauty database": "सभी beauty उत्पादों के बारे में जानकारी प्राप्त करें।",
      "Read what other beauty specialists say": "अन्य beauty विशेषज्ञ क्या कहते हैं, उसे पढ़ें।",
      "List your favorite products": "अपने पसंदीदा उत्पादों की सूची बनाएं।",
      "Name": "नाम",
      "Email": "ईमेल",
      "Mobile Number": "मोबाइल नंबर",
      "Country": "देश",
      "Select": "चुनें",
      "Password": "पासवर्ड",
      "Confirm Password": "पासवर्ड पुष्टि करें",
      "Create": "बनाएं",
      "By creating an account you have to agree with our": "खाता बनाने से आप हमारे से सहमत होते हैं",
      "terms conditions": "नियम और शर्तें।",
      "Subscribe for newsletter": "हमारे न्यूजलेटर के लिए सदस्यता लें",
      "Already have an account": "पहले से खाता है?",
      "Login": "लॉग इन करें",
      "OR Login With": "या लॉग इन करें इससे",
    },
    ForgetPassword: {
      "Forgot your password": "आप अपना पासवर्ड भूल गए?",
      "Enter your registered email address to get a link to reset your password": "अपना पंजीकृत ईमेल पता दर्ज करें ताकि आपको पासवर्ड रीसेट करने का लिंक मिल सके",
      "Email": "ईमेल",
      "Submit": "जमा करें",
      "Back to login": "लॉगिन पर वापस जाएं",
      "Login": "लॉगिन",
      "Successful": "सफल!",
      "We have sent an OTP": "हमने एक पुष्टिकरण कोड भेजा है",
      "to your email address": "आपके ईमेल पते पर",
      "OK": "ठीक है",
      "Didnt received yet": "कोड प्राप्त नहीं हुआ?",
      "Resend": "फिर से अनुरोध करें।",
    },
    Header: {
      "Favorite": "मेरा Beauty",
      "Account": "खाता",
      "My Profile": "मेरी प्रोफ़ाइल",
      "Notifications": "सूचनाएँ",
      "Settings": "सेटिंग्स",
      "Logout": "लॉगआउट",
      "Login": "लॉगिन",
      "Signup": "साइन अप",
      "All": "सभी",
    },
    Footer: {
      "About Us": "हमारे बारे में",
      "Contact Us": "हमसे संपर्क करें",
      "Terms Conditions": "नियम और शर्तें",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "गोपनीयता नीति",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. सभी अधिकार सुरक्षित।",
    },
    contact: {
      "Message": "संदेश",
      "Message Error": "कृपया संदेश दर्ज करें",
      "Subject": "विषय",
      "Subject Error": "कृपया विषय दर्ज करें",
      "Email": "ईमेल",
      "Email Error": "कृपया ईमेल दर्ज करें",
      "Name": "नाम",
      "Name Error": "कृपया नाम दर्ज करें"
    },
    Delete: {
      "Alert": "चेतावनी",
      "Are you sure you want to delete": "क्या आप सचमुच हटाना चाहते हैं?",
      "Yes": "हाँ",
      "No": "नहीं",
    },
    Thankyou: {
      "Thank You": "धन्यवाद",
      "Our Admin team will verify this request and contact you": "हमारी ग्राहक सेवा टीम इस अनुरोध की पुष्टि करेगी और आपसे संपर्क करेगी",
      "OK": "ठीक है"
    },
    TimePicker: {
      "Hours": "घंटे",
      "Minuts": "मिनट"
    },
    recommendation: {
      "Our Recommendations": "हमारी सिफारिशें",
      "View all": "सभी देखें",
    },
    settings: {
      "Settings": "सेटिंग्स",
      "Change Password": "पासवर्ड बदलें",
      "Newsletter": "समाचार पत्रिका",
      "Current Password": "वर्तमान पासवर्ड",
      "New Password": "नया पासवर्ड",
      "Confirm Password": "पासवर्ड पुष्टि करें",
      "Save": "सहेजें",
      "Newsletter Notifications": "समाचार पत्रिका अधिसूचनाएं"
    },
    notification: {
      "Home": "होम",
      "Notifications": "सूचनाएं",
      "Notification not available": "सूचना उपलब्ध नहीं है"
    },
    blog: {
      "Home": "होम",
      "Blogs": "ब्लॉग्स",
      "Latest Blog Post": "नवीनतम ब्लॉग पोस्ट",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "कृपया पुनः प्रयास करें",
      "Loading": "लोड हो रहा है...",
      "Looks like something went wrong": "लगता है कुछ गलत हुआ है!",
      "Error": "त्रुटि",
      "retry": "पुनः प्रयास करें"
    },
    brand: {
      "Home": "होम",
      "Brands": "ब्रांड्स",
      "No brand available": "यह ब्रांड उपलब्ध नहीं है।",
      "Select to search": "खोजने के लिए चुनें"
    },
    pagination: {
      "Previous": "पिछला",
      "Next": "अगला"
    },
    customerDetail: {
      "Follow": "अनुसरण करें",
      "Following": "अनुसरण कर रहा है",
      "Followers": "अनुयायी",
      "Country": "देश",
      "About Me": "मेरे बारे में",
      "View Activity": "गतिविधि देखें",
      "Hair Style": "बालों का शैली",
      "Skin Tone": "त्वचा का रंग",
      "Favorite Products": "मेरे beauty उत्पाद",
      "View all": "सभी देखें",
      "No favorite available": "आपके पास कोई पसंदीदा उत्पाद अभी तक नहीं है।"
    },
    highRated: {
      "Home": "मुख्य पृष्ठ",
      "Highest Rated": "सबसे अधिक मूल्यांकित",
      "Highest Rated Products": "सबसे अधिक मूल्यांकित उत्पाद",
      "Load More": "और लोड करें",
      "No high rated products available": "कोई अधिक मूल्यांकित उत्पाद उपलब्ध नहीं है"
    },
    instaList: {
      "Home": "मुख्य पृष्ठ",
      "Instagram posts": "Instagram पोस्ट",
    },
    lastViewed: {
      "Home": "मुख्य पृष्ठ",
      "Last Viewed": "आखिरी बार देखा गया",
      "Last Viewed Products": "अवसान दृष्टिकोण उत्पाद",
      "Product not available": "यहाँ अभी तक कोई उत्पाद प्रदर्शित करने के लिए नहीं है।"
    },
    myProfile: {
      "Home": "मुख्य पृष्ठ",
      "My Profile": "मेरी प्रोफ़ाइल",
      "Change photo": "फ़ोटो बदलें",
      "Name": "नाम",
      "Email": "ईमेल",
      "Mobile Number": "मोबाइल नंबर",
      "Country": "देश",
      "Hair Style": "बालों का शैली",
      "Select Hair": "बाल प्रकार चुनें",
      "Wavy Hair": "लहरीदार बाल",
      "Curly Hair": "घुंघराले बाल",
      "Straight Hair": "सीधे बाल",
      "Kinky Hair": "किंकी बाल",
      "Instagram": "Instagram",
      "Skin Tone": "त्वचा का रंग",
      "Alergic Ingredients": "एलर्जी प्रवृत्त सामग्री",
      "Favourite Ingredients": "पसंदीदा सामग्री",
      "About Me": "मेरे बारे में",
      "Submit": "जमा करें",
      "Select": "चुनें",
      "EAN Code": "EAN कोड"
    },
    otp: {
      "OTP Confirmation": "OTP सत्यापन",
      "Please enter the verification code sent to your email": "कृपया अपने ईमेल पर भेजे गए सत्यापन कोड दर्ज करें।",
      "OTP is required": "कोड आवश्यक है",
      "Submit": "जमा करें",
      "Resend OTP": "कोड पुनः भेजें",
    },
    wishList: {
      "Home": "मुख्य पृष्ठ",
      "Favorite": "मेरे Beauty",
      "Sort By": "क्रम में लगाएं",
      "Popularity": "लोकप्रियता",
      "Low to High": "निम्न से उच्च",
      "High to Low": "उच्च से निम्न",
      "Apply": "लागू करें",
      "Clear": "साफ करें",
      "Filter By": "फ़िल्टर द्वारा",
      "Brand Name": "ब्रांड का नाम",
      "Min Price": "न्यूनतम मूल्य",
      "Max Price": "अधिकतम मूल्य",
      "Select": "चुनें",
      "No favorite available": "आपके पास कोई पसंदीदा उत्पाद अभी तक नहीं है।"
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "नई पासवर्ड",
      "Confirm Password": "पासवर्ड की पुष्टि करें",
      "Submit": "जमा करें"
    },
    productCategory: {
      "Home": "मुख्य पृष्ठ",
      "Product Categories": "उत्पाद श्रेणियाँ",
    },
    myReview: {
      "My Review": "मेरी समीक्षा",
      "Write Review": "समीक्षा लिखें",
      "review": "समीक्षा",
      "Submit": "प्रस्तुत करें"
    },
    productDetail: {
      "Home": "होम",
      "Category": "श्रेणी",
      "Sub Category": "उप श्रेणी",
      "Brands": "ब्रांड्स",
      "Highest Rated": "सर्वाधिक मूल्यांकित",
      "Last Viewed": "अंतिम देखा गया",
      "Recommendations": "सिफारिशें",
      "Products": "उत्पाद",
      "Product Detail": "उत्पाद विवरण",
      "USD": "USD",
      "Write Review": "समीक्षा लिखें",
      "View Review": "समीक्षा देखें",
      "Description": "विवरण",
      "Read": "पढ़ें",
      "Less": "कम",
      "More": "अधिक",
      "Ingredient": "सामग्री",
      "Similar Products": "समान उत्पाद",
      "View all": "सभी देखें",
      "Reviews Ratings": "समीक्षा और मूल्यांकन",
      "No Review Added Yet": "अभी तक कोई समीक्षा जोड़ी नहीं गई",
      "Submit": "प्रस्तुत करें",
      "review": "समीक्षा",
      "Same Country": "मेरा देश",
      "View All ": "सभी देखें "
    },
    review: {
      "Reviews": "समीक्षाएँ",
      "Add a Review": "समीक्षा जोड़ें",
      "View Review": "समीक्षा देखें",
      "Review Rating": "समीक्षा और मूल्यांकन",
      "Write Review": "समीक्षा लिखें",
      "Submit": "प्रस्तुत करें",
      "Sort By": "के अनुसार क्रमबद्ध करें",
      "Most Recent": "सबसे हाल की",
      "Top Rating": "शीर्ष मूल्यांकन",
      "Like High to Low": "पसंद की संख्या अधिक से कम",
      "Apply": "लागू करें",
      "Clear": "साफ करें",
      "Filter By": "द्वारा छानें",
      "Review not available": "समीक्षा उपलब्ध नहीं है",
      "Click to select": "चुनने के लिए क्लिक करें",
      "stars": "तारे",
      "You have Selected": "आपने चुना है: "
    },
    productList: {
      "Home": "होम",
      "Product Categories": "मुख्य श्रेणियाँ",
      "Brands": "ब्रांड्स",
      "Product Sub Categories": "श्रेणियाँ",
      "Product Sub Sub Categories": "उप श्रेणियाँ",
      "Products": "उत्पाद",
      "Sort By": "के अनुसार क्रमबद्ध करें",
      "Popularity": "लोकप्रियता",
      "Low to High": "निम्न से उच्च",
      "High to Low": "उच्च से निम्न",
      "Apply": "लागू करें",
      "Clear": "साफ करें",
      "Filter By": "द्वारा छानें",
      "Brand Name": "ब्रांड का नाम",
      "Select": "चुनें",
      "Min Price": "न्यूनतम मूल्य",
      "Max Price": "अधिकतम मूल्य",
      "Product not available": "उत्पाद उपलब्ध नहीं है"
    },
    productSubCategory: {
      "Home": "होम",
      "Product Categories": "मुख्य श्रेणियाँ",
      "Product Sub Categories": "श्रेणियाँ",
      "Product Sub Sub Categories": "उप श्रेणियाँ",
      "Sub category not available": "उप श्रेणी उपलब्ध नहीं है",
      "Category not available": "श्रेणी उपलब्ध नहीं है"
    },
    recommendationList: {
      "Home": "होम",
      "Our Recommendation": "हमारी सिफारिशें",
      "No product available": "कोई उत्पाद उपलब्ध नहीं है"
    },
    search: {
      "Search": "खोजें",
      "Product Categories": "श्रेणियाँ",
      "View all": "सभी देखें",
      "Brands": "ब्रांड्स",
      "Products": "उत्पाद",
      "Filter By": "द्वारा छानें",
      "Brand Name": "ब्रांड का नाम",
      "Select": "चुनें",
      "Ingredients": "घटक",
      "Ingredient": "घटक",
      "Include": "शामिल करें",
      "Not Include": "शामिल नहीं करें",
      "Min Price": "न्यूनतम मूल्य",
      "Max Price": "अधिकतम मूल्य",
      "Customer Reviews": "ग्राहक समीक्षाएँ",
      "Customer Ratings": "ग्राहक मूल्यांकन",
      "Apply": "लागू करें",
      "Clear": "साफ करें",
      "Product not available": "उत्पाद उपलब्ध नहीं है",
      "Brands not available": "ब्रांड्स उपलब्ध नहीं हैं",
    },
    similar: {
      "Product Detail": "उत्पाद विवरण",
      "Similar Products": "समान उत्पाद",
      "Product not available": "उत्पाद उपलब्ध नहीं है"
    },
    activity: {
      "View Activity": "गतिविधि देखें",
    },
    home: {
      "Product Categories": "श्रेणियाँ",
      "View all": "सभी देखें",
      "Brands": "ब्रांड्स",
      "Highest Rated": "सर्वाधिक मूल्यांकित",
      "Last Viewed Products": "अंतिम देखे गए उत्पाद",
      "Latest Blog Post": "नवीनतम ब्लॉग पोस्ट",
      "Instagram Post": "Instagram पोस्ट",
    },
    share: {
      "Share": "साझा करें",
      "Copy Link": "लिंक कॉपी करें",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "सफलतापूर्वक क्लिपबोर्ड पर कॉपी किया गया",
    },
    validation: {
      "Please enter": "कृपया प्रविष्ट करें",
      "Please select": "कृपया चुनें",
      "This field is required": "यह फ़ील्ड आवश्यक है",
      "Please enter valid email": "कृपया मान्य ईमेल पता दर्ज करें",
      "Please enter valid contact number": "कृपया मान्य संपर्क नंबर दर्ज करें",
      "Please enter valid pin code": "कृपया मान्य पिन कोड दर्ज करें",
      "Please enter valid": "कृपया मान्य दर्ज करें",
      "Please enter valid name": "कृपया मान्य नाम दर्ज करें",
      "This field must be at least": "इस फ़ील्ड में कम से कम होना चाहिए",
      "characters": "वर्ण",
      "Space is not allowed in password": "पासवर्ड में स्थान अनुमति नहीं है",
      "Passwords must match": "पासवर्ड मेल खाने चाहिए",
      "Please enter valid price": "कृपया मान्य मूल्य दर्ज करें",
      "Please enter numeric values only": "कृपया केवल संख्यात्मक मान दर्ज करें"
    },
    webService: {
      "Server Not Responding": "सर्वर प्रतिसाद नहीं दे रहा है",
      "Invalid Username or Password": "अमान्य उपयोगकर्ता नाम या पासवर्ड",
      "Bad Response": "बुरी प्रतिक्रिया",
      "Server Error": "सर्वर त्रुटि",
    }
  },
  it: {
    new: {
      "Login to Grade my beauty account using your credintials": "Accedi al tuo account GradeMyBeauty utilizzando le tue credenziali",
      "Create An Account": "Crea un account",
      "OR Continue with": "Oppure continua con:",
      "Already Have An Account": "Hai già un account",
      "Please enter following details to sign up": "Inserisci i seguenti dettagli per registrarti",
      "Success": "Successo",
      "OTP has been verified": "L'OTP è stato verificato",
      "successfully": "Con successo.",
      "Go To Login": "Vai al login",
      "Verify OTP": "Verifica OTP",
      "Verify OTP which we have sent on your email": "Verifica l'OTP che abbiamo inviato alla tua email.",
      "Verify": "Verifica",
      "Select Country": "Seleziona il paese",
      "Send": "Invia",
      "Explore More": "Esplora di più",
      "Categories": "Categorie",
      "View All": "View All",
      "View All Categories": "Visualizza tutte le categorie",
      "View All Brands": "Visualizza tutti i marchi",
      "Subscribe to our newsletter": "Iscriviti alla nostra newsletter.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Resta aggiornato, iscriviti alla nostra newsletter e rimani informato.",
      "Subscribe": "Iscriviti",
      "Hot Posts": "Post popolari",
      "No post found": "Nessun post trovato",
      "Follow the Topic": "Segui l'argomento",
      "Follow": "Segui",
      "UserName": "UserName",
      "UnFollow": "Smetti di seguire",
      "No topics found": "Nessun argomento trovato",
      "People you should follow": "Persone che dovresti seguire",
      "No users found": "Nessun utente trovato",
      "Back": "Indietro",
      "Edit Profile": "Modifica profilo",
      "alergic ingredient": "Ingredienti allergenici",
      "favorite ingredient": "Ingredienti preferiti",
      "Products": "Prodotti",
      "Read More": "Leggi di più",
      "View All Products": "Visualizza tutti i prodotti",
      "All": "Tutti",
      "Create": "Crea",
      "Create Post": "Crea post",
      "Start Discussion": "Inizia una discussione",
      "Phone": "Telefono",
      "Post": "Post",
      "Discussion": "Discussione",
      "Start New Discussion": "Inizia una nuova discussione",
      "Public": "Pubblico",
      "Private": "Privato",
      "Select Topic": "Seleziona l'argomento",
      "Review Posted Successfully": "Recensione pubblicata con successo!",
      "5 Points Earned!": "5 punti guadagnati!",
      "Comments": "Commenti",
      "Replies": "Risposte",
      "No comments found": "Nessun commento trovato",
      "Reply": "Rispondi",
      "Delete": "Elimina",
      "Cancel": "Annulla",
      "Are you sure, you want to Delete?": "Sei sicuro di voler eliminare?",
      "Type your reply": "Digita la tua risposta...",
      "Search": "Cerca",
      "Edit": "Modifica",
      "Reward Points": "Punti premio",
      "Points Earned Till Now!": "Punti guadagnati fino ad ora!",
      "Earn More Rewards": "Guadagna più premi",
      "Earn Reward Points": "Guadagna punti premio",
      "Earn reward points by posting product review and posting on the discussion": "Guadagna punti premio pubblicando recensioni di prodotti e partecipando alle discussioni.",
      "About": "Informazioni",
      "Product Review": "Recensione del prodotto",
      "Your review has been": "La tua recensione è stata",
      "posted successfully": "pubblicata con successo.",
      "Ingredients": "Ingredienti",
      "Write to Review": "Scrivi una recensione",
      "Product List": "Elenco dei prodotti",
      "Yes": "Sì",
      "No": "No",
      "Apply": "Applica",
      "Clear": "Cancella",
      "Profile": "Profilo",
      "Like": "Mi piace",
      "View Activity": "Visualizza attività",
      "View All Blogs": "Visualizza tutti i blog",
    },
    login: {
      "Email": "E-mail",
      "Password": "Password",
      "Forgot Password": "Hai dimenticato la password?",
      "Log in": "Accedi",
      "Dont have an account": "Non hai un account?",
      "Sign Up": "Iscriviti",
      "OR Login With": "O accedi con"
    },
    signup: {
      "Create a fee account within few minutes": "Crea un account gratuito in pochi minuti.",
      "Have access to a large beauty database": "Accedi alle informazioni su tutti i prodotti di beauty.",
      "Read what other beauty specialists say": "Leggi cosa dicono gli altri specialisti della beauty.",
      "List your favorite products": "Crea una lista dei tuoi prodotti preferiti.",
      "Name": "Nome",
      "Email": "E-mail",
      "Mobile Number": "Numero di cellulare",
      "Country": "Paese",
      "Select": "Seleziona",
      "Password": "Password",
      "Confirm Password": "Conferma password",
      "Create": "Crea",
      "By creating an account you have to agree with our": "Creando un account devi accettare i nostri",
      "terms conditions": "Termini e Condizioni.",
      "Subscribe for newsletter": "Iscriviti alla nostra newsletter",
      "Already have an account": "Hai già un account?",
      "Login": "Accedi",
      "OR Login With": "O accedi con",
    },
    ForgetPassword: {
      "Forgot your password": "Hai dimenticato la password?",
      "Enter your registered email address to get a link to reset your password": "Inserisci la tua e-mail registrata per ricevere un link per reimpostare la password",
      "Email": "E-mail",
      "Submit": "Invia",
      "Back to login": "Torna all'accesso",
      "Login": "Accedi",
      "Successful": "Operazione riuscita!",
      "We have sent an OTP": "Abbiamo inviato un codice di conferma",
      "to your email address": "al tuo indirizzo e-mail",
      "OK": "Ok",
      "Didnt received yet": "Non hai ricevuto il codice?",
      "Resend": "Richiedi di nuovo.",
    },
    Header: {
      "Favorite": "I miei Beauty",
      "Account": "Account",
      "My Profile": "Il mio profilo",
      "Notifications": "Notifiche",
      "Settings": "Impostazioni",
      "Logout": "Esci",
      "Login": "Accedi",
      "Signup": "Iscriviti",
      "All": "Tutto",
    },
    Footer: {
      "About Us": "Chi siamo",
      "Contact Us": "Contattaci",
      "Terms Conditions": "Termini e Condizioni",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Informativa sulla privacy",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Tutti i diritti riservati."
    },
    contact: {
      "Message": "Messaggio",
      "Message Error": "Inserisci il messaggio",
      "Subject": "Oggetto",
      "Subject Error": "Inserisci l'oggetto",
      "Email": "Email",
      "Email Error": "Inserisci l'email",
      "Name": "Nome",
      "Name Error": "Inserisci il nome"
    },
    Delete: {
      "Alert": "Avviso",
      "Are you sure you want to delete": "Sei sicuro di voler eliminare?",
      "Yes": "Sì",
      "No": "No",
    },
    Thankyou: {
      "Thank You": "Grazie",
      "Our Admin team will verify this request and contact you": "Il nostro team di assistenza verificherà questa richiesta e ti contatterà",
      "OK": "Ok"
    },
    TimePicker: {
      "Hours": "Ore",
      "Minuts": "Minuti"
    },
    recommendation: {
      "Our Recommendations": "Le nostre raccomandazioni",
      "View all": "Visualizza tutto",
    },
    settings: {
      "Settings": "Impostazioni",
      "Change Password": "Cambia password",
      "Newsletter": "Newsletter",
      "Current Password": "Password attuale",
      "New Password": "Nuova password",
      "Confirm Password": "Conferma password",
      "Save": "Salva",
      "Newsletter Notifications": "Newsletter"
    },
    notification: {
      "Home": "Home",
      "Notifications": "Notifiche",
      "Notification not available": "Notifica non disponibile"
    },
    blog: {
      "Home": "Home",
      "Blogs": "Blog",
      "Latest Blog Post": "Ultimo post sul blog",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Riprova",
      "Loading": "Caricamento...",
      "Looks like something went wrong": "Sembra che qualcosa sia andato storto!",
      "Error": "Errore",
      "retry": "Riprova"
    },
    brand: {
      "Home": "Home",
      "Brands": "Marchi",
      "No brand available": "Questo marchio non è disponibile..",
      "Select to search": "Seleziona per cercare"
    },
    pagination: {
      "Previous": "Precedente",
      "Next": "Successivo"
    },
    customerDetail: {
      "Follow": "Segui",
      "Following": "Stai seguendo",
      "Followers": "Follower",
      "Country": "Paese",
      "About Me": "Su di me",
      "View Activity": "Visualizza attività",
      "Hair Style": "Stile di capelli",
      "Skin Tone": "Tonalità della pelle",
      "Favorite Products": "I miei prodotti beauty",
      "View all": "Visualizza tutto",
      "No favorite available": "Non hai ancora prodotti preferiti."
    },
    highRated: {
      "Home": "Home",
      "Highest Rated": "Più votati",
      "Highest Rated Products": "Prodotti più votati",
      "Load More": "Carica altro",
      "No high rated products available": "Non ci sono prodotti con voti elevati disponibili"
    },
    instaList: {
      "Home": "Home",
      "Instagram posts": "Post su Instagram",
    },
    lastViewed: {
      "Home": "Home",
      "Last Viewed": "Ultima visualizzazione",
      "Last Viewed Products": "Prodotti visti di recente",
      "Product not available": "Non ci sono prodotti da visualizzare qui ancora."
    },
    myProfile: {
      "Home": "Home",
      "My Profile": "Il mio profilo",
      "Change photo": "Cambia foto",
      "Name": "Nome",
      "Email": "Email",
      "Mobile Number": "Numero di cellulare",
      "Country": "Paese",
      "Hair Style": "Stile di capelli",
      "Select Hair": "Seleziona tipo di capelli",
      "Wavy Hair": "Capelli ondulati",
      "Curly Hair": "Capelli ricci",
      "Straight Hair": "Capelli lisci",
      "Kinky Hair": "Capelli crespi",
      "Instagram": "Instagram",
      "Skin Tone": "Tonalità della pelle",
      "Alergic Ingredients": "Allergeni",
      "Favourite Ingredients": "Ingredienti preferiti",
      "About Me": "Su di me",
      "Submit": "Invia",
      "Select": "Seleziona",
      "EAN Code": "Codice EAN"
    },
    otp: {
      "OTP Confirmation": "Verifica OTP",
      "Please enter the verification code sent to your email": "Inserisci il codice di verifica inviato alla tua email.",
      "OTP is required": "Il codice è richiesto",
      "Submit": "Invia",
      "Resend OTP": "Rinvia codice",
    },
    wishList: {
      "Home": "Home",
      "Favorite": "La mia Beauty",
      "Sort By": "Ordina per",
      "Popularity": "Popolarità",
      "Low to High": "Da basso ad alto",
      "High to Low": "Da alto a basso",
      "Apply": "Applica",
      "Clear": "Annulla",
      "Filter By": "Filtra per",
      "Brand Name": "Nome del marchio",
      "Min Price": "Prezzo min",
      "Max Price": "Prezzo max",
      "Select": "Seleziona",
      "No favorite available": "Non hai ancora prodotti preferiti."
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Nuova password",
      "Confirm Password": "Conferma password",
      "Submit": "Invia"
    },
    productCategory: {
      "Home": "Home",
      "Product Categories": "Categorie di prodotti",
    },
    myReview: {
      "My Review": "La mia recensione",
      "Write Review": "Scrivi una recensione",
      "review": "recensione",
      "Submit": "Invia"
    },
    productDetail: {
      "Home": "Home",
      "Category": "Categoria",
      "Sub Category": "Sottocategoria",
      "Brands": "Marchi",
      "Highest Rated": "Più votati",
      "Last Viewed": "Ultima visualizzazione",
      "Recommendations": "Raccomandazioni",
      "Products": "Prodotti",
      "Product Detail": "Dettagli del prodotto",
      "USD": "USD",
      "Write Review": "Scrivi una recensione",
      "View Review": "Vedi recensioni",
      "Description": "Descrizione",
      "Read": "Leggi",
      "Less": "Meno",
      "More": "Più",
      "Ingredient": "Ingredienti",
      "Similar Products": "Prodotti simili",
      "View all": "Visualizza tutto",
      "Reviews Ratings": "Recensioni e valutazioni",
      "No Review Added Yet": "Nessuna recensione aggiunta ancora",
      "Submit": "Invia",
      "review": "recensione",
      "Same Country": "Il mio paese",
      "View All ": "Visualizza tutto "
    },
    review: {
      "Reviews": "Recensioni",
      "Add a Review": "Aggiungi una recensione",
      "View Review": "Vedi recensioni",
      "Review Rating": "Valutazioni e recensioni",
      "Write Review": "Scrivi una recensione",
      "Submit": "Invia",
      "Sort By": "Ordina per",
      "Most Recent": "Più recenti",
      "Top Rating": "Migliori valutazioni",
      "Like High to Low": "Mi piace dal più alto al più basso",
      "Apply": "Applica",
      "Clear": "Cancella",
      "Filter By": "Filtra per",
      "Review not available": "Recensioni non disponibili",
      "Click to select": "Clicca per selezionare",
      "stars": "stelle",
      "You have Selected": "Hai selezionato: "
    },
    productList: {
      "Home": "Home",
      "Product Categories": "Categorie principali",
      "Brands": "Marchi",
      "Product Sub Categories": "Categorie",
      "Product Sub Sub Categories": "Sottocategorie",
      "Products": "Prodotti",
      "Sort By": "Ordina per",
      "Popularity": "Popolarità",
      "Low to High": "Dal più basso al più alto",
      "High to Low": "Dal più alto al più basso",
      "Apply": "Applica",
      "Clear": "Cancella",
      "Filter By": "Filtra per",
      "Brand Name": "Nome del marchio",
      "Select": "Seleziona",
      "Min Price": "Prezzo minimo",
      "Max Price": "Prezzo massimo",
      "Product not available": "Prodotto non disponibile"
    },
    productSubCategory: {
      "Home": "Home",
      "Product Categories": "Categorie principali",
      "Product Sub Categories": "Categorie",
      "Product Sub Sub Categories": "Sottocategorie",
      "Sub category not available": "Sottocategoria non disponibile",
      "Category not available": "Categoria non disponibile"
    },
    recommendationList: {
      "Home": "Home",
      "Our Recommendation": "Le nostre raccomandazioni",
      "No product available": "Nessun prodotto disponibile"
    },
    search: {
      "Search": "Cerca",
      "Product Categories": "Categorie",
      "View all": "Visualizza tutto",
      "Brands": "Marchi",
      "Products": "Prodotti",
      "Filter By": "Filtra per",
      "Brand Name": "Nome del marchio",
      "Select": "Seleziona",
      "Ingredients": "Ingredienti",
      "Ingredient": "Ingrediente",
      "Include": "Includi",
      "Not Include": "Non includere",
      "Min Price": "Prezzo minimo",
      "Max Price": "Prezzo massimo",
      "Customer Reviews": "Recensioni dei clienti",
      "Customer Ratings": "Valutazioni dei clienti",
      "Apply": "Applica",
      "Clear": "Cancella",
      "Product not available": "Prodotti non disponibili",
      "Brands not available": "Marchi non disponibili"
    },
    similar: {
      "Product Detail": "Dettaglio del prodotto",
      "Similar Products": "Prodotti simili",
      "Product not available": "Prodotti non disponibili"
    },
    activity: {
      "View Activity": "Visualizza attività"
    },
    home: {
      "Product Categories": "Categorie",
      "View all": "Visualizza tutto",
      "Brands": "Marchi",
      "Highest Rated": "Più votati",
      "Last Viewed Products": "Ultimi prodotti visualizzati",
      "Latest Blog Post": "Ultimo post sul blog",
      "Instagram Post": "Post su Instagram"
    },
    share: {
      "Share": "Condividi",
      "Copy Link": "Copia link",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Copiato con successo negli appunti"
    },
    validation: {
      "Please enter": "Inserisci",
      "Please select": "Seleziona",
      "This field is required": "Questo campo è obbligatorio",
      "Please enter valid email": "Inserisci un indirizzo email valido",
      "Please enter valid contact number": "Inserisci un numero di contatto valido",
      "Please enter valid pin code": "Inserisci un CAP valido",
      "Please enter valid": "Inserisci un valido",
      "Please enter valid name": "Inserisci un nome valido",
      "This field must be at least": "Questo campo deve avere almeno",
      "characters": "caratteri",
      "Space is not allowed in password": "Lo spazio non è consentito nella password",
      "Passwords must match": "Le password devono coincidere",
      "Please enter valid price": "Inserisci un prezzo valido",
      "Please enter numeric values only": "Inserisci solo valori numerici"
    },
    webService: {
      "Server Not Responding": "Il server non risponde",
      "Invalid Username or Password": "Nome utente o password non validi",
      "Bad Response": "Risposta errata",
      "Server Error": "Errore del server"
    }
  },
  no: {
    new: {
      "Login to Grade my beauty account using your credintials": "Logg inn på din GradeMyBeauty-konto med dine legitimasjoner",
      "Create An Account": "Opprett en konto",
      "OR Continue with": "Eller fortsett med:",
      "Already Have An Account": "Har du allerede en konto",
      "Please enter following details to sign up": "Vennligst oppgi følgende detaljer for å registrere deg",
      "Success": "Suksess",
      "OTP has been verified": "OTP er verifisert",
      "successfully": "Vellykket.",
      "Go To Login": "Gå til innlogging",
      "Verify OTP": "Verifiser OTP",
      "Verify OTP which we have sent on your email": "Verifiser OTP som vi har sendt til din e-post.",
      "Verify": "Verifiser",
      "Select Country": "Velg land",
      "Send": "Send",
      "Explore More": "Utforsk mer",
      "Categories": "Kategorier",
      "View All": "View All",
      "View All Categories": "Se alle kategorier",
      "View All Brands": "Se alle merker",
      "Subscribe to our newsletter": "Abonner på vårt nyhetsbrev.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "Hold deg oppdatert, abonner på vårt nyhetsbrev og hold deg oppdatert.",
      "Subscribe": "Abonner",
      "Hot Posts": "Populære innlegg",
      "No post found": "Ingen innlegg funnet",
      "Follow the Topic": "Følg emnet",
      "Follow": "Følg",
      "UserName": "UserName",
      "UnFollow": "Slutt å følge",
      "No topics found": "Ingen emner funnet",
      "People you should follow": "Personer du bør følge",
      "No users found": "Ingen brukere funnet",
      "Back": "Tilbake",
      "Edit Profile": "Rediger profil",
      "alergic ingredient": "Allergifremkallende ingrediens",
      "favorite ingredient": "Favorittingredienser",
      "Products": "Produkter",
      "Read More": "Les mer",
      "View All Products": "Se alle produkter",
      "All": "Alle",
      "Create": "Opprett",
      "Create Post": "Opprett innlegg",
      "Start Discussion": "Start diskusjon",
      "Phone": "Telefon",
      "Post": "Innlegg",
      "Discussion": "Diskusjon",
      "Start New Discussion": "Start en ny diskusjon",
      "Public": "Offentlig",
      "Private": "Privat",
      "Select Topic": "Velg emne",
      "Review Posted Successfully": "Anmeldelse postet vellykket!",
      "5 Points Earned!": "5 poeng opptjent!",
      "Comments": "Kommentarer",
      "Replies": "Svar",
      "No comments found": "Ingen kommentarer funnet",
      "Reply": "Svar",
      "Delete": "Slett",
      "Cancel": "Avbryt",
      "Are you sure, you want to Delete?": "Er du sikker på at du vil slette?",
      "Type your reply": "Skriv svaret ditt...",
      "Search": "Søk",
      "Edit": "Rediger",
      "Reward Points": "Belønningspoeng",
      "Points Earned Till Now!": "Poeng opptjent til nå!",
      "Earn More Rewards": "Tjen flere belønninger",
      "Earn Reward Points": "Tjen belønningspoeng",
      "Earn reward points by posting product review and posting on the discussion": "Tjen belønningspoeng ved å poste produktanmeldelser og delta i diskusjoner.",
      "About": "Om",
      "Product Review": "Produktanmeldelse",
      "Your review has been": "Din anmeldelse har blitt",
      "posted successfully": "postet vellykket.",
      "Ingredients": "Ingredienser",
      "Write to Review": "Skriv en anmeldelse",
      "Product List": "Produktliste",
      "Yes": "Ja",
      "No": "Nei",
      "Apply": "Bruk",
      "Clear": "Tøm",
      "Profile": "Profil",
      "Like": "Lik",
      "View Activity": "Se aktivitet",
      "View All Blogs": "Se alle blogger",
    },
    login: {
      "Email": "E-post",
      "Password": "Passord",
      "Forgot Password": "Glemt passord?",
      "Log in": "Logg inn",
      "Dont have an account": "Har du ikke en konto",
      "Sign Up": "Registrer deg",
      "OR Login With": "Eller logg inn med"
    },
    signup: {
      "Create a fee account within few minutes": "Opprett en gratis konto på noen få minutter.",
      "Have access to a large beauty database": "Få tilgang til informasjon om alle beauty produkter.",
      "Read what other beauty specialists say": "Les hva andre beauty spesialister sier.",
      "List your favorite products": "Lag en liste over dine favorittprodukter.",
      "Name": "Navn",
      "Email": "E-post",
      "Mobile Number": "Mobilnummer",
      "Country": "Land",
      "Select": "Velg",
      "Password": "Passord",
      "Confirm Password": "Bekreft passord",
      "Create": "Opprett",
      "By creating an account you have to agree with our": "Ved å opprette en konto må du være enig i våre",
      "terms conditions": "Vilkår & betingelser.",
      "Subscribe for newsletter": "Abonner på nyhetsbrevet vårt",
      "Already have an account": "Har du allerede en konto?",
      "Login": "Logg inn",
      "OR Login With": "Eller logg inn med",
    },
    ForgetPassword: {
      "Forgot your password": "Glemt passordet ditt?",
      "Enter your registered email address to get a link to reset your password": "Skriv inn din registrerte e-postadresse for å få en lenke til å tilbakestille passordet ditt",
      "Email": "E-post",
      "Submit": "Send inn",
      "Back to login": "Tilbake til innlogging",
      "Login": "Logg inn",
      "Successful": "Vellykket!",
      "We have sent an OTP": "Vi har sendt en bekreftelseskode",
      "to your email address": "til e-postadressen din",
      "OK": "Ok",
      "Didnt received yet": "Har du ikke mottatt koden?",
      "Resend": "Be om igjen.",
    },
    Header: {
      "Favorite": "Min Beauty",
      "Account": "Konto",
      "My Profile": "Min profil",
      "Notifications": "Varsler",
      "Settings": "Innstillinger",
      "Logout": "Logg ut",
      "Login": "Logg inn",
      "Signup": "Registrer deg",
      "All": "Alle",
    },
    Footer: {
      "About Us": "Om oss",
      "Contact Us": "Kontakt oss",
      "Terms Conditions": "Vilkår & betingelser",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "Personvernpolicy",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. Alle rettigheter forbeholdt."
    },
    contact: {
      "Message": "Melding",
      "Message Error": "Vennligst skriv inn melding",
      "Subject": "Emne",
      "Subject Error": "Vennligst skriv inn emne",
      "Email": "E-post",
      "Email Error": "Vennligst skriv inn e-post",
      "Name": "Navn",
      "Name Error": "Vennligst skriv inn navn"
    },
    Delete: {
      "Alert": "Varsel",
      "Are you sure you want to delete": "Er du sikker på at du vil slette?",
      "Yes": "Ja",
      "No": "Nei",
    },
    Thankyou: {
      "Thank You": "Takk",
      "Our Admin team will verify this request and contact you": "Vårt kundeteam vil bekrefte denne forespørselen og kontakte deg",
      "OK": "Ok"
    },
    TimePicker: {
      "Hours": "Timer",
      "Minuts": "Minutter"
    },
    recommendation: {
      "Our Recommendations": "Våre anbefalinger",
      "View all": "Se alle",
    },
    settings: {
      "Settings": "Innstillinger",
      "Change Password": "Endre passord",
      "Newsletter": "Nyhetsbrev",
      "Current Password": "Nåværende passord",
      "New Password": "Nytt passord",
      "Confirm Password": "Bekreft passord",
      "Save": "Lagre",
      "Newsletter Notifications": "Nyhetsbrev"
    },
    notification: {
      "Home": "Hjem",
      "Notifications": "Varsler",
      "Notification not available": "Varsel ikke tilgjengelig"
    },
    blog: {
      "Home": "Hjem",
      "Blogs": "Blogger",
      "Latest Blog Post": "Siste blogginnlegg",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "Vennligst prøv igjen",
      "Loading": "Laster...",
      "Looks like something went wrong": "Noe gikk galt!",
      "Error": "Feil",
      "retry": "Prøv igjen"
    },
    brand: {
      "Home": "Hjem",
      "Brands": "Merker",
      "No brand available": "Dette merket er ikke tilgjengelig..",
      "Select to search": "Velg for å søke"
    },
    pagination: {
      "Previous": "Forrige",
      "Next": "Neste"
    },
    customerDetail: {
      "Follow": "Følg",
      "Following": "Følger",
      "Followers": "Følgere",
      "Country": "Land",
      "About Me": "Om meg",
      "View Activity": "Se aktivitet",
      "Hair Style": "Hårstil",
      "Skin Tone": "Hudtone",
      "Favorite Products": "Mine beauty produkter",
      "View all": "Se alle",
      "No favorite available": "Du har ingen favorittprodukter ennå."
    },
    highRated: {
      "Home": "Hjem",
      "Highest Rated": "Høyest vurdert",
      "Highest Rated Products": "Høyest vurderte produkter",
      "Load More": "Last mer",
      "No high rated products available": "Ingen høyt vurderte produkter tilgjengelige"
    },
    instaList: {
      "Home": "Hjem",
      "Instagram posts": "Instagram innlegg",
    },
    lastViewed: {
      "Home": "Hjem",
      "Last Viewed": "Sist sett",
      "Last Viewed Products": "Sist viste produkter",
      "Product not available": "Ingen produkter å vise her ennå."
    },
    myProfile: {
      "Home": "Hjem",
      "My Profile": "Min profil",
      "Change photo": "Endre bilde",
      "Name": "Navn",
      "Email": "E-post",
      "Mobile Number": "Mobilnummer",
      "Country": "Land",
      "Hair Style": "Hårstil",
      "Select Hair": "Velg hårtype",
      "Wavy Hair": "Bølgete hår",
      "Curly Hair": "Krøllete hår",
      "Straight Hair": "Rett hår",
      "Kinky Hair": "Kinky hår",
      "Instagram": "Instagram",
      "Skin Tone": "Hudtone",
      "Alergic Ingredients": "Allergener",
      "Favourite Ingredients": "Favorittingredienser",
      "About Me": "Om meg",
      "Submit": "Send inn",
      "Select": "Velg",
      "EAN Code": "EAN-kode"
    },
    otp: {
      "OTP Confirmation": "OTP-bekreftelse",
      "Please enter the verification code sent to your email": "Vennligst skriv inn bekreftelseskoden sendt til din e-post.",
      "OTP is required": "Kode er nødvendig",
      "Submit": "Send inn",
      "Resend OTP": "Send kode på nytt",
    },
    wishList: {
      "Home": "Hjem",
      "Favorite": "Min Beauty",
      "Sort By": "Sorter etter",
      "Popularity": "Popularitet",
      "Low to High": "Lav til høy",
      "High to Low": "Høy til lav",
      "Apply": "Bruk",
      "Clear": "Tøm",
      "Filter By": "Filtrer etter",
      "Brand Name": "Merkenavn",
      "Min Price": "Min pris",
      "Max Price": "Maks pris",
      "Select": "Velg",
      "No favorite available": "Du har ingen favorittprodukter ennå.",
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "Nytt passord",
      "Confirm Password": "Bekreft passord",
      "Submit": "Send inn"
    },
    productCategory: {
      "Home": "Hjem",
      "Product Categories": "Produktkategorier",
    },
    myReview: {
      "My Review": "Min anmeldelse",
      "Write Review": "Skriv anmeldelse",
      "review": "Anmeldelse",
      "Submit": "Send inn"
    },
    productDetail: {
      "Home": "Hjem",
      "Category": "Kategori",
      "Sub Category": "Underkategori",
      "Brands": "Merker",
      "Highest Rated": "Høyest vurdert",
      "Last Viewed": "Sist sett",
      "Recommendations": "Anbefalinger",
      "Products": "Produkter",
      "Product Detail": "Produktdetaljer",
      "USD": "USD",
      "Write Review": "Skriv anmeldelse",
      "View Review": "Se anmeldelser",
      "Description": "Beskrivelse",
      "Read": "Les",
      "Less": "Mindre",
      "More": "Mer",
      "Ingredient": "Ingredienser",
      "Similar Products": "Lignende produkter",
      "View all": "Se alle",
      "Reviews Ratings": "Anmeldelser & Vurderinger",
      "No Review Added Yet": "Ingen anmeldelser lagt til ennå",
      "Submit": "Send inn",
      "review": "Anmeldelse",
      "Same Country": "Mitt land",
      "View All ": "Se alle "
    },
    review: {
      "Reviews": "Anmeldelser",
      "Add a Review": "Legg til en anmeldelse",
      "View Review": "Se anmeldelser",
      "Review Rating": "Anmeldelser & Vurderinger",
      "Write Review": "Skriv anmeldelse",
      "Submit": "Send inn",
      "Sort By": "Sorter etter",
      "Most Recent": "Mest nylig",
      "Top Rating": "Topp vurdering",
      "Like High to Low": "Lik fra høy til lav",
      "Apply": "Bruk",
      "Clear": "Tøm",
      "Filter By": "Filtrer etter",
      "Review not available": "Anmeldelse ikke tilgjengelig",
      "Click to select": "Klikk for å velge",
      "stars": "Stjerner",
      "You have Selected": "Du har valgt: "
    },
    productList: {
      "Home": "Hjem",
      "Product Categories": "Hovedkategorier",
      "Brands": "Merker",
      "Product Sub Categories": "Kategorier",
      "Product Sub Sub Categories": "Underkategorier",
      "Products": "Produkter",
      "Sort By": "Sorter etter",
      "Popularity": "Popularitet",
      "Low to High": "Lav til høy",
      "High to Low": "Høy til lav",
      "Apply": "Bruk",
      "Clear": "Tøm",
      "Filter By": "Filtrer etter",
      "Brand Name": "Merkenavn",
      "Select": "Velg",
      "Min Price": "Min pris",
      "Max Price": "Maks pris",
      "Product not available": "Produkt ikke tilgjengelig"
    },
    productSubCategory: {
      "Home": "Hjem",
      "Product Categories": "Hovedkategorier",
      "Product Sub Categories": "Kategorier",
      "Product Sub Sub Categories": "Underkategorier",
      "Sub category not available": "Underkategori ikke tilgjengelig",
      "Category not available": "Kategori ikke tilgjengelig"
    },
    recommendationList: {
      "Home": "Hjem",
      "Our Recommendation": "Våre anbefalinger",
      "No product available": "Ingen produkter tilgjengelig"
    },
    search: {
      "Search": "Søk",
      "Product Categories": "Kategorier",
      "View all": "Se alle",
      "Brands": "Merker",
      "Products": "Produkter",
      "Filter By": "Filtrer etter",
      "Brand Name": "Merkenavn",
      "Select": "Velg",
      "Ingredients": "Ingredienser",
      "Ingredient": "Ingrediens",
      "Include": "Inkluder",
      "Not Include": "Ikke inkluder",
      "Min Price": "Min pris",
      "Max Price": "Maks pris",
      "Customer Reviews": "Kundeanmeldelser",
      "Customer Ratings": "Kundevurderinger",
      "Apply": "Bruk",
      "Clear": "Tøm",
      "Product not available": "Produkt ikke tilgjengelig",
      "Brands not available": "Merker ikke tilgjengelig",
    },
    similar: {
      "Product Detail": "Produktdetalj",
      "Similar Products": "Lignende produkter",
      "Product not available": "Produkt ikke tilgjengelig"
    },
    activity: {
      "View Activity": "Se aktivitet",
    },
    home: {
      "Product Categories": "Kategorier",
      "View all": "Se alle",
      "Brands": "Merker",
      "Highest Rated": "Høyest vurdert",
      "Last Viewed Products": "Sist sett produkter",
      "Latest Blog Post": "Siste blogginnlegg",
      "Instagram Post": "Instagram-innlegg",
    },
    share: {
      "Share": "Del",
      "Copy Link": "Kopier lenke",
      "Facebook": "Facebook",
      "Whatsapp": "WhatsApp",
      "Instagram": "Instagram",
      "Successfully copy to clipboard": "Vellykket kopiert til utklippstavlen",
    },
    validation: {
      "Please enter": "Vennligst skriv inn",
      "Please select": "Vennligst velg",
      "This field is required": "Dette feltet er nødvendig",
      "Please enter valid email": "Vennligst skriv inn en gyldig e-postadresse",
      "Please enter valid contact number": "Vennligst skriv inn et gyldig telefonnummer",
      "Please enter valid pin code": "Vennligst skriv inn en gyldig postkode",
      "Please enter valid": "Vennligst skriv inn gyldig",
      "Please enter valid name": "Vennligst skriv inn et gyldig navn",
      "This field must be at least": "Dette feltet må være minst",
      "characters": "tegn",
      "Space is not allowed in password": "Mellomrom er ikke tillatt i passordet",
      "Passwords must match": "Passordene må samsvare",
      "Please enter valid price": "Vennligst skriv inn en gyldig pris",
      "Please enter numeric values only": "Vennligst skriv kun inn numeriske verdier"
    },
    webService: {
      "Server Not Responding": "Serveren svarer ikke",
      "Invalid Username or Password": "Ugyldig brukernavn eller passord",
      "Bad Response": "Dårlig respons",
      "Server Error": "Serverfeil",
    }
  },
  ar: {
    new: {
      "Login to Grade my beauty account using your credintials": "تسجيل الدخول إلى حسابك في GradeMyBeauty باستخدام بيانات اعتمادك",
      "Create An Account": "إنشاء حساب",
      "OR Continue with": "أو تابع مع:",
      "Already Have An Account": "هل لديك حساب بالفعل",
      "Please enter following details to sign up": "يرجى إدخال التفاصيل التالية للتسجيل",
      "Success": "نجاح",
      "OTP has been verified": "تم التحقق من OTP",
      "successfully": "بنجاح.",
      "Go To Login": "اذهب إلى تسجيل الدخول",
      "Verify OTP": "تحقق من OTP",
      "Verify OTP which we have sent on your email": "تحقق من OTP الذي أرسلناه إلى بريدك الإلكتروني.",
      "Verify": "تحقق",
      "Select Country": "اختر البلد",
      "Send": "إرسال",
      "Explore More": "استكشف المزيد",
      "Categories": "الفئات",
      "View All": "View All",
      "View All Categories": "عرض جميع الفئات",
      "View All Brands": "عرض جميع العلامات التجارية",
      "Subscribe to our newsletter": "اشترك في نشرتنا الإخبارية.",
      "Stay tuned, Subscribe to our newsletter and stay updated": "تابعونا، اشترك في نشرتنا الإخبارية وابق على اطلاع.",
      "Subscribe": "اشتراك",
      "Hot Posts": "المنشورات الساخنة",
      "No post found": "لم يتم العثور على منشورات",
      "Follow the Topic": "تابع الموضوع",
      "Follow": "تابع",
      "UserName": "UserName",
      "UnFollow": "إلغاء المتابعة",
      "No topics found": "لم يتم العثور على مواضيع",
      "People you should follow": "الأشخاص الذين يجب أن تتابعهم",
      "No users found": "لم يتم العثور على مستخدمين",
      "Back": "العودة",
      "Edit Profile": "تعديل الملف الشخصي",
      "alergic ingredient": "مكونات مسببة للحساسية",
      "favorite ingredient": "المكونات المفضلة",
      "Products": "المنتجات",
      "Read More": "اقرأ المزيد",
      "View All Products": "عرض جميع المنتجات",
      "All": "الكل",
      "Create": "إنشاء",
      "Create Post": "إنشاء منشور",
      "Start Discussion": "ابدأ نقاشًا",
      "Phone": "الهاتف",
      "Post": "منشور",
      "Discussion": "نقاش",
      "Start New Discussion": "ابدأ نقاشًا جديدًا",
      "Public": "عام",
      "Private": "خاص",
      "Select Topic": "اختر موضوعًا",
      "Review Posted Successfully": "تم نشر المراجعة بنجاح!",
      "5 Points Earned!": "5 نقاط مكتسبة!",
      "Comments": "التعليقات",
      "Replies": "الردود",
      "No comments found": "لم يتم العثور على تعليقات",
      "Reply": "رد",
      "Delete": "حذف",
      "Cancel": "إلغاء",
      "Are you sure, you want to Delete?": "هل أنت متأكد أنك تريد الحذف؟",
      "Type your reply": "اكتب ردك...",
      "Search": "بحث",
      "Edit": "تعديل",
      "Reward Points": "نقاط المكافأة",
      "Points Earned Till Now!": "النقاط المكتسبة حتى الآن!",
      "Earn More Rewards": "اكسب المزيد من المكافآت",
      "Earn Reward Points": "اكسب نقاط المكافأة",
      "Earn reward points by posting product review and posting on the discussion": "اكسب نقاط المكافأة من خلال نشر مراجعة المنتج والمشاركة في النقاش",
      "About": "حول",
      "Product Review": "مراجعة المنتج",
      "Your review has been": "تمت مراجعتك",
      "posted successfully": "نشر بنجاح.",
      "Ingredients": "المكونات",
      "Write to Review": "اكتب مراجعة",
      "Product List": "قائمة المنتجات",
      "Yes": "نعم",
      "No": "لا",
      "Apply": "تطبيق",
      "Clear": "مسح",
      "Profile": "الملف الشخصي",
      "Like": "أعجبني",
      "View Activity": "عرض النشاط",
      "View All Blogs": "عرض جميع المدونات",
    },
    login: {
      "Email": "البريد الإلكتروني",
      "Password": "كلمة السر",
      "Forgot Password": "هل نسيت كلمة المرور؟",
      "Log in": "تسجيل الدخول",
      "Dont have an account": "ليس لديك حساب؟",
      "Sign Up": "إنشاء حساب",
      "OR Login With": "أو تسجيل الدخول باستخدام"
    },
    signup: {
      "Create a fee account within few minutes": "أنشئ حسابًا مجانيًا في غضون دقائق قليلة.",
      "Have access to a large beauty database": "احصل على الوصول إلى معلومات حول جميع منتجات beauty.",
      "Read what other beauty specialists say": "اقرأ ما يقوله المتخصصين الآخرين في beauty.",
      "List your favorite products": "أنشئ قائمة بمنتجاتك المفضلة.",
      "Name": "الاسم",
      "Email": "البريد الإلكتروني",
      "Mobile Number": "رقم الجوال",
      "Country": "البلد",
      "Select": "اختر",
      "Password": "كلمة السر",
      "Confirm Password": "تأكيد كلمة السر",
      "Create": "إنشاء",
      "By creating an account you have to agree with our": "بإنشاء حساب، يجب أن توافق على",
      "terms conditions": "الشروط والأحكام.",
      "Subscribe for newsletter": "اشترك في النشرة الإخبارية",
      "Already have an account": "هل لديك حساب بالفعل؟",
      "Login": "تسجيل الدخول",
      "OR Login With": "أو تسجيل الدخول باستخدام",
    },
    ForgetPassword: {
      "Forgot your password": "نسيت كلمة المرور الخاصة بك؟",
      "Enter your registered email address to get a link to reset your password": "أدخل عنوان البريد الإلكتروني المسجل للحصول على رابط لإعادة تعيين كلمة المرور",
      "Email": "البريد الإلكتروني",
      "Submit": "إرسال",
      "Back to login": "الرجوع لتسجيل الدخول",
      "Login": "تسجيل الدخول",
      "Successful": "نجحت العملية!",
      "We have sent an OTP": "لقد أرسلنا رمز التأكيد",
      "to your email address": "إلى عنوان بريدك الإلكتروني",
      "OK": "حسنًا",
      "Didnt received yet": "لم تستلم بعد؟",
      "Resend": "إعادة الإرسال",
    },
    Header: {
      "Favorite": "Beauty المفضلة",
      "Account": "الحساب",
      "My Profile": "ملفي الشخصي",
      "Notifications": "الإشعارات",
      "Settings": "الإعدادات",
      "Logout": "تسجيل الخروج",
      "Login": "تسجيل الدخول",
      "Signup": "تسجيل",
      "All": "الكل",
    },
    Footer: {
      "About Us": "عنا",
      "Contact Us": "اتصل بنا",
      "Terms Conditions": "الشروط والأحكام",
      "Follow us on": "Follow us on : ",
      "Privacy Policy": "سياسة الخصوصية",
      "Grade My Beauty All rights reserved": "GradeMyBeauty. جميع الحقوق محفوظة.",
    },
    contact: {
      "Message": "الرسالة",
      "Message Error": "الرجاء إدخال الرسالة",
      "Subject": "الموضوع",
      "Subject Error": "الرجاء إدخال الموضوع",
      "Email": "البريد الإلكتروني",
      "Email Error": "الرجاء إدخال البريد الإلكتروني",
      "Name": "الاسم",
      "Name Error": "الرجاء إدخال الاسم",
    },
    Delete: {
      "Alert": "تنبيه",
      "Are you sure you want to delete": "هل أنت متأكد أنك تريد حذف؟",
      "Yes": "نعم",
      "No": "لا",
    },
    Thankyou: {
      "Thank You": "شكراً لك",
      "Our Admin team will verify this request and contact you": "سيقوم فريق العملاء بالتحقق من هذا الطلب والتواصل معك",
      "OK": "حسنًا",
    },
    TimePicker: {
      "Hours": "الساعات",
      "Minuts": "الدقائق",
    },
    recommendation: {
      "Our Recommendations": "توصياتنا",
      "View all": "عرض الكل",
    },
    settings: {
      "Settings": "الإعدادات",
      "Change Password": "تغيير كلمة المرور",
      "Newsletter": "النشرة الإخبارية",
      "Current Password": "كلمة المرور الحالية",
      "New Password": "كلمة المرور الجديدة",
      "Confirm Password": "تأكيد كلمة المرور",
      "Save": "حفظ",
      "Newsletter Notifications": "النشرة الإخبارية",
    },
    notification: {
      "Home": "الرئيسية",
      "Notifications": "الإشعارات",
      "Notification not available": "الإشعار غير متوفر",
    },
    blog: {
      "Home": "الرئيسية",
      "Blogs": "المدونات",
      "Latest Blog Post": "آخر مقالات المدونة",
    },

    Community: {
      "Community": "Community",
      "title": "Title",
      "description": "Description",
      "topic": "Topic",
    },
    logout: {
      "Yes, Log out": "Yes, Log out",
      "Log out!": "Log out!",
      "Are you sure, you want to logout?": "Are you sure, you want to logout?",
      "No": "No"
    },
    error: {
      "Please Retry": "الرجاء المحاولة مرة أخرى",
      "Loading": "جار التحميل...",
      "Looks like something went wrong": "يبدو أن شيئًا ما قد حدث بطريق الخطأ!",
      "Error": "خطأ",
      "retry": "إعادة المحاولة",
    },
    brand: {
      "Home": "الرئيسية",
      "Brands": "العلامات التجارية",
      "No brand available": "هذه العلامة التجارية غير متوفرة..",
      "Select to search": "اختر للبحث",
    },
    pagination: {
      "Previous": "السابق",
      "Next": "التالي",
    },
    customerDetail: {
      "Follow": "تتبع",
      "Following": "يتم التتبع",
      "Followers": "المتابعون",
      "Country": "الدولة",
      "About Me": "عني",
      "View Activity": "عرض النشاط",
      "Hair Style": "نمط الشعر",
      "Skin Tone": "لون البشرة",
      "Favorite Products": "beauty المنتجات",
      "View all": "عرض الكل",
      "No favorite available": "ليس لديك أي منتجات مفضلة حتى الآن.",
    },
    highRated: {
      "Home": "الرئيسية",
      "Highest Rated": "الأكثر تقييمًا",
      "Highest Rated Products": "المنتجات الأكثر تقييمًا",
      "Load More": "تحميل المزيد",
      "No high rated products available": "لا توجد منتجات ذات تقييم عالي متاحة",
    },
    instaList: {
      "Home": "الرئيسية",
      "Instagram posts": "منشورات إنستغرام",
    },
    lastViewed: {
      "Home": "الرئيسية",
      "Last Viewed": "المشاهدة الأخيرة",
      "Last Viewed Products": "المنتجات المشاهدة مؤخراً",
      "Product not available": "لا توجد منتجات لعرضها هنا بعد.",
    },
    myProfile: {
      "Home": "الرئيسية",
      "My Profile": "ملفي الشخصي",
      "Change photo": "تغيير الصورة",
      "Name": "الاسم",
      "Email": "البريد الإلكتروني",
      "Mobile Number": "رقم الهاتف المحمول",
      "Country": "الدولة",
      "Hair Style": "نمط الشعر",
      "Select Hair": "اختر نوع الشعر",
      "Wavy Hair": "شعر متموج",
      "Curly Hair": "شعر مجعد",
      "Straight Hair": "شعر مستقيم",
      "Kinky Hair": "شعر كنكي",
      "Instagram": "إنستغرام",
      "Skin Tone": "لون البشرة",
      "Alergic Ingredients": "المكونات المسببة للحساسية",
      "Favourite Ingredients": "المكونات المفضلة",
      "About Me": "عني",
      "Submit": "إرسال",
      "Select": "اختر",
      "EAN Code": "رمز EAN"
    },
    otp: {
      "OTP Confirmation": "تأكيد OTP",
      "Please enter the verification code sent to your email": "يرجى إدخال رمز التحقق الذي تم إرساله إلى بريدك الإلكتروني.",
      "OTP is required": "الرمز مطلوب",
      "Submit": "إرسال",
      "Resend OTP": "إعادة إرسال الرمز",
    },
    wishList: {
      "Home": "الرئيسية",
      "Favorite": "Beauty المفضلة",
      "Sort By": "ترتيب حسب",
      "Popularity": "الشهرة",
      "Low to High": "منخفض إلى مرتفع",
      "High to Low": "مرتفع إلى منخفض",
      "Apply": "تطبيق",
      "Clear": "مسح",
      "Filter By": "تصفية حسب",
      "Brand Name": "اسم العلامة التجارية",
      "Min Price": "السعر الأدنى",
      "Max Price": "السعر الأقصى",
      "Select": "اختر",
      "No favorite available": "ليس لديك أي منتجات Beauty المفضلة بعد.",
    },
    resetPassword: {
      "Reset Password": "Reset password",
      "New Password": "كلمة مرور جديدة",
      "Confirm Password": "تأكيد كلمة المرور",
      "Submit": "إرسال"
    },
    productCategory: {
      "Home": "الرئيسية",
      "Product Categories": "فئات المنتجات",
    },
    myReview: {
      "My Review": "تقييمي",
      "Write Review": "كتابة تقييم",
      "review": "تقييم",
      "Submit": "إرسال"
    },
    productDetail: {
      "Home": "الرئيسية",
      "Category": "فئة",
      "Sub Category": "فئة فرعية",
      "Brands": "العلامات التجارية",
      "Highest Rated": "الأعلى تقييما",
      "Last Viewed": "شوهد آخر مرة",
      "Recommendations": "التوصيات",
      "Products": "المنتجات",
      "Product Detail": "تفاصيل المنتج",
      "USD": "دولار أمريكي",
      "Write Review": "كتابة تقييم",
      "View Review": "عرض التقييمات",
      "Description": "الوصف",
      "Read": "اقرأ",
      "Less": "أقل",
      "More": "المزيد",
      "Ingredient": "المكونات",
      "Similar Products": "منتجات مشابهة",
      "View all": "عرض الكل",
      "Reviews Ratings": "التقييمات والمراجعات",
      "No Review Added Yet": "لم يتم إضافة أي تقييمات بعد",
      "Submit": "إرسال",
      "review": "تقييم",
      "Same Country": "بلدي",
      "View All ": "عرض الكل"
    },
    review: {
      "Reviews": "التقييمات",
      "Add a Review": "إضافة تقييم",
      "View Review": "عرض التقييمات",
      "Review Rating": "التقييمات والمراجعات",
      "Write Review": "كتابة تقييم",
      "Submit": "إرسال",
      "Sort By": "ترتيب حسب",
      "Most Recent": "الأحدث",
      "Top Rating": "أعلى تقييم",
      "Like High to Low": "الإعجاب من الأعلى إلى الأقل",
      "Apply": "تطبيق",
      "Clear": "مسح",
      "Filter By": "تصفية حسب",
      "Review not available": "التقييمات غير متوفرة",
      "Click to select": "انقر للإختيار",
      "stars": "نجوم",
      "You have Selected": "لقد اخترت: "
    },
    productList: {
      "Home": "الرئيسية",
      "Product Categories": "الفئات الرئيسية",
      "Brands": "العلامات التجارية",
      "Product Sub Categories": "الفئات الفرعية",
      "Product Sub Sub Categories": "فئات فرعية فرعية",
      "Products": "المنتجات",
      "Sort By": "ترتيب حسب",
      "Popularity": "الشهرة",
      "Low to High": "منخفض إلى مرتفع",
      "High to Low": "مرتفع إلى منخفض",
      "Apply": "تطبيق",
      "Clear": "مسح",
      "Filter By": "تصفية حسب",
      "Brand Name": "اسم العلامة التجارية",
      "Select": "اختر",
      "Min Price": "السعر الأدنى",
      "Max Price": "السعر الأقصى",
      "Product not available": "المنتج غير متوفر"
    },
    productSubCategory: {
      "Home": "الرئيسية",
      "Product Categories": "الفئات الرئيسية",
      "Product Sub Categories": "الفئات الفرعية",
      "Product Sub Sub Categories": "فئات فرعية فرعية",
      "Sub category not available": "الفئة الفرعية غير متوفرة",
      "Category not available": "الفئة غير متوفرة"
    },
    recommendationList: {
      "Home": "الرئيسية",
      "Our Recommendation": "توصياتنا",
      "No product available": "لا توجد منتجات متاحة"
    },
    search: {
      "Search": "بحث",
      "Product Categories": "التصنيفات",
      "View all": "عرض الكل",
      "Brands": "العلامات التجارية",
      "Products": "المنتجات",
      "Filter By": "تصفية بواسطة",
      "Brand Name": "اسم العلامة التجارية",
      "Select": "اختر",
      "Ingredients": "المكونات",
      "Ingredient": "مكون",
      "Include": "تضمين",
      "Not Include": "لا تضمين",
      "Min Price": "السعر الأدنى",
      "Max Price": "السعر الأقصى",
      "Customer Reviews": "آراء العملاء",
      "Customer Ratings": "تقييمات العملاء",
      "Apply": "تطبيق",
      "Clear": "مسح",
      "Product not available": "المنتج غير متاح",
      "Brands not available": "العلامات التجارية غير متاحة",
    },
    similar: {
      "Product Detail": "تفاصيل المنتج",
      "Similar Products": "منتجات مماثلة",
      "Product not available": "المنتج غير متاح"
    },
    activity: {
      "View Activity": "عرض النشاط",
    },
    home: {
      "Product Categories": "التصنيفات",
      "View all": "عرض الكل",
      "Brands": "العلامات التجارية",
      "Highest Rated": "الأعلى تقييمًا",
      "Last Viewed Products": "آخر المنتجات المشاهدة",
      "Latest Blog Post": "آخر مقالات المدونة",
      "Instagram Post": "منشورات إنستغرام",
    },
    share: {
      "Share": "مشاركة",
      "Copy Link": "نسخ الرابط",
      "Facebook": "فيسبوك",
      "Whatsapp": "واتساب",
      "Instagram": "إنستغرام",
      "Successfully copy to clipboard": "تم النسخ بنجاح إلى الحافظة",
    },
    validation: {
      "Please enter": "الرجاء إدخال",
      "Please select": "الرجاء اختيار",
      "This field is required": "هذا الحقل مطلوب",
      "Please enter valid email": "الرجاء إدخال عنوان بريد إلكتروني صحيح",
      "Please enter valid contact number": "الرجاء إدخال رقم اتصال صحيح",
      "Please enter valid pin code": "الرجاء إدخال رمز PIN صحيح",
      "Please enter valid": "الرجاء إدخال صحيح",
      "Please enter valid name": "الرجاء إدخال اسم صحيح",
      "This field must be at least": "يجب أن يكون هذا الحقل على الأقل",
      "characters": "أحرف",
      "Space is not allowed in password": "المسافات غير مسموح بها في كلمة المرور",
      "Passwords must match": "يجب أن تتطابق كلمات المرور",
      "Please enter valid price": "الرجاء إدخال سعر صحيح",
      "Please enter numeric values only": "الرجاء إدخال قيم رقمية فقط"
    },
    webService: {
      "Server Not Responding": "الخادم لا يستجيب",
      "Invalid Username or Password": "اسم المستخدم أو كلمة المرور غير صحيحة",
      "Bad Response": "رد غير جيد",
      "Server Error": "خطأ في الخادم",
    }
  },
};

export default messages;