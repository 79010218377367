<template>
  <div>
    <Header />
    <div class="home-slider pb-none">
      <div class="container p-l-r">
        <div class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row h-100">
                <div class="col-md-4 col-sm-4 my-auto order-lg-1 order-2">
                  <div class="round-box mt-0 mobile-step">
                    <div class="d-flex flex-row align-items-center">
                      <div class="mr-3">
                        <img
                          :src="userDetail.image ? userDetail.image : profilePlaceholder"
                          class=""
                          style="border-radius: 100px"
                          width="120"
                          height="120"
                        />
                      </div>
                      <div>
                        <span class="cate-title fs-20">{{ userDetail.name }}</span
                        ><br />
                        <span class="cate-name">{{ userDetail.expert }} </span><br />
                        <span class="followers pt-2"
                          >{{ userDetail.follower_count }}
                          {{ $t("customerDetail.Followers") }}</span
                        ><br />
                        <span
                          class="cate-name pt-2"
                          data-bs-target="#RewardPointModal"
                          data-bs-toggle="modal"
                          style="cursor: pointer"
                        >
                          <img :src="imgRewards" width="22" height="22" class="mr-2" />
                          {{ $t("new.Reward Points") }}</span
                        >
                        <span class="count">{{ userDetail.reward_points }}</span>
                      </div>
                    </div>
                    <hr style="border-color: #cacaca" />

                    <div class="post-title fs-20">{{ $t("new.About") }}</div>
                    <p class="mb-0">
                      {{ userDetail.about_us ? userDetail.about_us : "-" }}
                    </p>
                  </div>
                </div>
                <div class="col-md-8 col-sm-8 my-auto order-lg-2 order-1">
                  <img :src="imgCommunity" class="img-fluid" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="community-page">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8">
            <div class="card-box mb-3">
              <div class="d-flex justify-content-between align-items-center">
                <div class="user d-flex flex-row align-items-center">
                  <div class="mr-3">
                    <img
                      :src="imgUser"
                      class="img-fluid"
                      style="border-radius: 100px"
                      width="45"
                      height="45"
                    />
                  </div>
                  <div>
                    <span class="client-name">Alec Whitten</span><br />
                    <span class="dates">17 Jan 2022</span>
                  </div>
                </div>

                <div class="user d-flex flex-row align-items-center">
                  <div class="blue-badge"><a href="javascript:void(0)">Post</a></div>
                  <div>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      ><img :src="imgDots" width="24" height="24"
                    /></a>
                    <div class="dropdown-menu">
                      <ul>
                        <li class="mb-1">
                          <a href="javascript:void(0)"><i class="fa fa-edit"></i> Edit</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)"
                            ><i class="fa fa-trash"></i> Delete</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-3">
                <img
                  :src="imgPostImg"
                  class="img-fluid"
                  width="100%"
                  style="border-radius: 3px"
                />
              </div>
              <div class="category-title">Treat your hair</div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the
                1500
              </p>
              <hr style="border-color: #cacaca" />
              <div class="comments-section">
                <span class="mr-4"
                  ><img :src="imgLike" width="24" height="24" /> &nbsp;
                  <a href="javascript:void(0)">Like</a></span
                >
                <span class="mr-4"
                  ><img :src="imgComments" width="24" height="24" /> &nbsp;
                  <a href="javascript:void(0)">Comments</a></span
                >
                <span
                  ><img :src="imgShare" width="24" height="24" /> &nbsp;
                  <a href="javascript:void(0)">Share</a></span
                >
              </div>
            </div>
            <div class="card-box mb-3">
              <div class="d-flex justify-content-between align-items-center">
                <div class="user d-flex flex-row align-items-center">
                  <div class="mr-3">
                    <img
                      :src="imgUser"
                      class="img-fluid"
                      style="border-radius: 100px"
                      width="45"
                      height="45"
                    />
                  </div>
                  <div>
                    <span class="client-name mr-3">Harry Wilson</span>
                    <span class="purple-badge"
                      ><a href="javascript:void(0)"
                        ><img :src="imgVerifiedb" /> Brand
                      </a></span
                    >
                    <br />
                    <span class="dates">17 Jan 2022</span>
                  </div>
                </div>

                <div class="user d-flex flex-row align-items-center">
                  <div class="blue-badge">
                    <a href="javascript:void(0)">Product Review</a>
                  </div>

                  <div>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      ><img :src="imgDots" width="24" height="24"
                    /></a>
                    <div class="dropdown-menu">
                      <ul>
                        <li class="mb-1">
                          <a href="javascript:void(0)"><i class="fa fa-edit"></i> Edit</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)"
                            ><i class="fa fa-trash"></i> Delete</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-3">
                <img
                  :src="imgPostImg"
                  class="img-fluid"
                  width="100%"
                  style="border-radius: 3px"
                />
              </div>
              <div class="category-title">Best product I have ever used</div>
              <div class="py-2 pre-review">
                Product Review

                <span class="fa fa-star checked"></span>
                <span class="fa fa-star checked"></span>
                <span class="fa fa-star checked"></span>
                <span class="fa fa-star checked"></span>
                <span class="fa fa-star unchecked"></span>
              </div>
              <p class="mb-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the
                1500
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <div class="create-post-btn mb-4">
              <button class="btn btn-lg">+ Create A Post</button>
            </div>

            <FollowTheTopic />

            <HotPosts />
          </div>
        </div>
      </div>
    </div> -->

    <!-- Reward Point -->
    <div
      class="modal fade"
      id="RewardPointModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 380px"
      >
        <div class="modal-content">
          <div class="modal-header" style="display: inline-block; text-align: center">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ $t("new.Reward Points") }}
            </h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img :src="imgClosebtn" width="20" height="20"
              /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex">
              <div>
                <img :src="imgRewards" width="80" height="80" class="mr-2" />
              </div>
              <div class="my-auto">
                <h4 style="color: #101828; font-size: 14px">
                  {{ $t("new.Reward Points") }}
                </h4>
                <h6 style="color: #00ba75">
                  {{ userDetail.reward_points }} {{ $t("new.Points Earned Till Now!") }}
                </h6>
              </div>
            </div>
            <hr />
            <div class="erp">{{ $t("new.Earn Reward Points") }}</div>
            <p class="erpoints">
              {{
                $t(
                  "new.Earn reward points by posting product review and posting on the discussion"
                )
              }}
            </p>
            <a
              href="javascript:void(0)"
              data-bs-dismiss="modal"
              v-on:click="$router.push('/community')"
              class="earnmprerewards-btn"
              >{{ $t("new.Earn More Rewards") }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script setup>
import imgRewards from "../../../public/static/img/rewards.svg";
import imgCommunity from "../../../public/static/img/community-banner.svg";
import imgClosebtn from "../../../public/static/img/close-btn.svg";
import profilePlaceholder from "../../../public/static/img/profilePlaceholder.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import $ from "jquery";
export default {
  name: "CommunityProfilePage",
  components: { Header, Footer },
  data() {
    return {
      uuid: this.$route.params.id,
      userDetail: { country: {} },
    };
  },
  computed: {
    user() {
      return this.$storeService.getters.getUser;
    },
  },

  watch: {
    user(newValue) {
      console.log(newValue);
      if (localStorage.getItem("access_token")) {
        this.getUserDetail();
      }
    },
  },
  mounted() {
    this.getMetaContent();
    this.getUserDetail();
  },
  methods: {
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/11",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    getUserDetail() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/customer/detail/" + this.uuid,
        })
        .then((res) => {
          this.userDetail = res;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    follow(id) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.followUser(id);
      }
    },
    followUser() {
      var obj = {};
      obj.to_id = this.userDetail.id;
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/follow/user",
          _body: obj,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.getUserDetail();
        });
    },
  },
};
</script>
