<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style scoped>
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
</style>

<script>
export default {
  name: "App",
};

</script>