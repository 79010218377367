<template>
  <div>
    <Header />
    <div class="home-slider">
      <div class="container">
        <div class="row h-100">
          <div class="col-md-4 col-sm-4 col-12 pt-2">
            <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-text">
              <img :src="imgBackarrow" width="25" height="25" /> {{ $t("new.Back") }}
            </a>
          </div>
          <div class="col-md-4 col-sm-4 col-12 my-auto pt-15">
            <h2 class="h2-title">{{ $t("brand.Brands") }}</h2>
          </div>
          <div class="col-md-4 col-sm-4 float-end pt-15">
            <select
              class="custom-select white-bg w-100"
              @change="getBrandList(0)"
              v-model="searchObj.filter"
            >
              <option value="">{{ $t("brand.Select to search") }}</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="E">E</option>
              <option value="F">F</option>
              <option value="G">G</option>
              <option value="H">H</option>
              <option value="I">I</option>
              <option value="J">J</option>
              <option value="K">K</option>
              <option value="L">L</option>
              <option value="M">M</option>
              <option value="N">N</option>
              <option value="O">O</option>
              <option value="P">P</option>
              <option value="Q">Q</option>
              <option value="R">R</option>
              <option value="S">S</option>
              <option value="T">T</option>
              <option value="U">U</option>
              <option value="V">V</option>
              <option value="W">W</option>
              <option value="X">X</option>
              <option value="Y">Y</option>
              <option value="Z">Z</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <section class="brands-section" style="min-height: 650px">
      <div class="container">
        <div class="row">
          <div
            class="col-md-3 col-sm-3 col-6 mb-3 posRel text-center"
            style="cursor: pointer"
            v-for="(data, index) in brandList"
            :key="index"
            v-on:click="$router.push('/brand-detail/' + data.slug)"
          >
            <img
              :src="data.image ? data.image : brandPlaceholder1"
              class="img-fluid custom-width"
            />
            <div class="brand-name" v-if="!data.image">{{ data.name }}</div>
          </div>
          <ErrorComponent @retry="getBrandList(0)" ref="errorComponent" />
        </div>
        <div class="row mx-0 mb-4">
          <div class="col-lg-12 mt-5">
            <div class="page-bottom-pagination">
              <Pagination
                @page-change="pageChange"
                @items-per-page-change="itemsPerPageChange"
                ref="listPagination"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script setup>
//import brandPlaceholder from "../../../public/static/img/brandPlaceholder.png";
import brandPlaceholder1 from "../../../public/static/img/brandPlaceholderBlank.svg";
import imgBackarrow from "../../../public/static/img/back-arrow.svg";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination.vue";
import $ from "jquery";
export default {
  name: "BrandsPage",
  components: { Header, Footer, ErrorComponent, Pagination },
  data() {
    return {
      loader: false,
      brandList: [],
      itemsPerPage: "12",
      keyword: this.$route.query.keyword,
      searchObj: {
        offset: "",
        keyword: "",
        filter: "",
      },
    };
  },
  mounted() {
    this.getBrandList(0);
    this.getMetaContent();
  },
  methods: {
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/4",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getBrandList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getBrandList(0);
    },
    getBrandList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateListLoader(true);
      }
      this.brandList = [];

      this.searchObj.offset = offset;
      this.searchObj.limit = this.itemsPerPage;
      if (this.keyword) {
        this.searchObj.keyword = this.keyword;
      }
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/brand/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            res.list[i].name = this.$helperService.getName(res.list[i].name);
          }
          this.brandList = res.list;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, this.itemsPerPage);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              this.$i18n.t("brand.No brand available")
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>

