<template>
  <div>
    <Header />
    <div class="profile-page">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-8 mx-auto">
            <div class="sm-card-box">
              <a href="javascript:void(0)" v-on:click="$router.push('/home')" class="back-text pt-1 ms-0">
                <img :src="imgBackarrow" width="25" height="25" /> {{ $t("new.Back") }}
              </a>
              <h1 class="ms-5 ps-5">{{ $t("settings.Change Password") }}</h1>
              <Form @submit="save">
                <div class="login-form">
                  <div class="form-group">
                    <label for="">{{ $t("settings.Current Password") }}</label>
                    <div class="input-group">
                      <Field
                        :type="oldPasswordFieldType"
                        id="Cuurent_password"
                        class="form-control border-end-0"
                        name="Cuurent_password"
                        :placeholder="$t('settings.Current Password')"
                        v-model="detail.old_password"
                        v-on:keydown.space="$event.preventDefault()"
                        :rules="
                          'required:' + $t('settings.Current Password') + '|password'
                        "
                        :validateOnInput="true"
                      />

                      <span
                        class="input-group-text bg-white"
                        id="basic-addon2"
                        style="border-radius: 0px 8px 8px 0px; height: 50px; margin-left: -3px; border-color: #eee;"
                      >
                        <a class="psw-icon" v-on:click="switchVisibilityOld"
                          ><img :src="imageOld" width="20"
                        /></a>
                      </span>
                    </div>
                    <ErrorMessage name="Cuurent_password" class="validation-msg" />
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("settings.New Password") }}</label>
                    <div class="input-group">
                      <Field
                        :type="newPasswordFieldType"
                        id="new_password"
                        class="form-control border-end-0"
                        name="new_password"
                        :placeholder="$t('settings.New Password')"
                        v-model="detail.new_password"
                        v-on:keydown.space="$event.preventDefault()"
                        :rules="
                          'required:' +
                          $t('settings.New Password') +
                          '|minLength:6|password'
                        "
                        :validateOnInput="true"
                      />

                      <span
                        class="input-group-text bg-white"
                        id="basic-addon2"
                        style="border-radius: 0px 8px 8px 0px; height: 50px; margin-left: -3px; border-color: #eee;"
                      >
                        <a class="psw-icon" v-on:click="switchVisibilityNew"
                          ><img :src="imageNew" width="20"
                        /></a>
                      </span>
                    </div>
                    <ErrorMessage name="new_password" class="validation-msg" />
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("settings.Confirm Password") }}</label>
                    <div class="input-group">
                      <Field
                        :type="passwordFieldType"
                        id="confirm_password"
                        class="form-control border-end-0"
                        name="confirm_password"
                        :placeholder="$t('settings.Confirm Password')"
                        v-on:keydown.space="$event.preventDefault()"
                        :rules="
                          'required:' +
                          $t('settings.Confirm Password') +
                          '|confirmed:@new_password'
                        "
                        :validateOnInput="true"
                      />

                      <span
                        class="input-group-text bg-white"
                        id="basic-addon2"
                        style="border-radius: 0px 8px 8px 0px; height: 50px; margin-left: -3px; border-color: #eee;"
                      >
                        <a v-on:click="switchVisibility" class="psw-icon"
                          ><img :src="image" width="20"
                        /></a>
                      </span>
                    </div>
                    <ErrorMessage name="confirm_password" class="validation-msg" />
                  </div>
                  <div class="text-center pt-3">
                    <button type="submit" id="save-button" class="fill-btn">Save</button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import imgBackarrow from "../../../public/static/img/back-arrow.svg";
import imgLock from "../../../public/static/img/hide.png";
import imgShow from "../../../public/static/img/show.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ChangePasswordPage",
  components: { Header, Footer, Form, Field, ErrorMessage },
  data() {
    return {
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      oldPasswordFieldType: "password",
      image: imgShow,
      imageNew: imgShow,
      imageOld: imgShow,
      detail: {},
    };
  },
  mounted() {
    this.detail.id = localStorage.getItem("userId");
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image = this.image === imgShow ? imgLock : imgShow;
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew = this.imageNew === imgShow ? imgLock : imgShow;
    },
    switchVisibilityOld() {
      this.oldPasswordFieldType =
        this.oldPasswordFieldType === "password" ? "text" : "password";
      this.imageOld = this.imageOld === imgShow ? imgLock : imgShow;
    },
    save() {
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "reset/password",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.$router.push("/home");
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });});
    },
  },
};
</script>

