<template>
  <div>
    <Header />
    <div class="home-slider">
      <div class="container p-l-r">
        <div class="col-md-12 col-sm-12">
          <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-text">
            <img :src="imgBackarrow" width="25" height="25" /> {{ $t("new.Back") }}
          </a>
        </div>
        <div class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row h-100">
                <div class="col-md-4 col-sm-4 my-auto order-md-1 order-2">
                  <div class="round-box mobile-step">
                    <a
                      href="javascript:void(0)"
                      class="posAbs"
                      style="top: 15px; right: 20px"
                    >
                      <img
                        :src="imgHeart"
                        v-if="info.is_favorite == '0'"
                        v-on:click="addFavorite"
                        width="20"
                        height="20"
                      />
                      <img
                        v-if="info.is_favorite == '1'"
                        v-on:click="addFavorite"
                        :src="imgFillHeart"
                        width="20"
                        height="20"
                      />
                    </a>
                    <h1>{{ info.name }}</h1>
                    <p>{{ info.description }}</p>
                    <button
                      class="btn button"
                      v-on:click="$router.push('/brand/' + info.slug)"
                    >
                      {{ $t("recommendation.View all") }}
                      <img :src="imgWhitearrow" width="20" height="20" />
                    </button>
                  </div>
                </div>
                <div class="col-md-8 col-sm-8 my-auto 0rder-md-2 order-1">
                  <img
                    :src="info.image ? info.image : bannerPlaceholder"
                    class="left-img-curve"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="highest-rated-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 py-4">
            <h2 class="h2-title">{{ $t("new.Products") }}</h2>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-4 col-sm-4 mb-4"
            v-for="(data, index) in productList"
            :key="index"
            v-on:click="$router.push('/product/' + data.slug)"
          >
            <div class="white-box text-center">
              <div>
                <img
                  :src="data.image ? data.image : productPlaceholder"
                  class="img-fluid"
                />
              </div>
              <div class="my-auto">
                <div class="product-title pt-3 pb-2">{{ data.name }}</div>
                <div class="d-flex justify-content-center">
                  <star-rating
                    id="expert-list"
                    class="mb-1 text-center"
                    :increment="0.5"
                    :rating="data.avg_rating"
                    :show-rating="false"
                    :star-size="15"
                    :read-only="true"
                  ></star-rating
                  ><span class="font-14 ps-2 pt-1"
                    >({{ data.number_of_ratings ? data.number_of_ratings : 0 }})</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorComponent @retry="getProductList(0)" ref="errorComponent" />

        <div class="row mx-0 mb-4">
          <div class="col-lg-12 mt-5">
            <div class="page-bottom-pagination">
              <Pagination
                @page-change="pageChange"
                @items-per-page-change="itemsPerPageChange"
                ref="listPagination"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <Blog />
    <Footer />
  </div>
</template>

<script setup>
import imgWhitearrow from "../../../public/static/img/white-arrow.svg";
import imgBackarrow from "../../../public/static/img/back-arrow.svg";
import bannerPlaceholder from "../../../public/static/img/banner.png";
import imgHeart from "../../../public/static/img/heart.png";
import imgFillHeart from "../../../public/static/img/fill-heart.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Blog from "@/components/Blog";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination.vue";
import StarRating from "vue-star-rating";
import $ from "jquery";
export default {
  name: "BrandsPage",
  components: { Header, Footer, Blog, ErrorComponent, Pagination, StarRating },

  data() {
    return {
      count: "",
      loader: false,
      productList: [],
      itemsPerPage: "12",
      slug: this.$route.params.slug,
      brandAllList: [],
      filterList: [],
      offset: 0,
      searchObj: {
        keyword: "",
        offset: 0,
        slug: this.$route.params.slug,
        brands: [],
        to_price: 0,
        from_price: 0,
        order_by: "",
        sort_by: "",
        brand_id: "",
        categorie_id: "",
        sub_categorie_id: "",
        sub_sub_categorie_id: "",
      },
      mainCategoryList: [],
      categoryList: [],
      subCategoryList: [],
      activeColor5: "",
      activeColor4: "",
      activeColor3: "",
      activeColor2: "",
      activeColor1: "",
      activeColor0: "",
      info: {},
    };
  },

  watch: {
    $route(to, from) {
      if (to.fullPath == from.fullPath) {
        this.productList = [];
        this.slug = this.$route.query.slug;
        this.getProductList(0);
        this.getFilterList();
        this.getAllBrandList();
        this.getMainCategoryList();
        document.documentElement.scrollTop = 0;
      }
    },
  },

  mounted() {
    this.getMetaContent();
    this.getProductList(0);
  },

  methods: {
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/7",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    setRating(rating) {
      this.searchObj.rating = rating;
      if (rating == 0) {
        this.activeColor0 = "#ffc107";
        this.activeColor1 = "";
        this.activeColor2 = "";
        this.activeColor3 = "";
        this.activeColor4 = "";
        this.activeColor5 = "";
      } else if (rating == 1) {
        this.activeColor0 = "";
        this.activeColor1 = "#ffc107";
        this.activeColor2 = "";
        this.activeColor3 = "";
        this.activeColor4 = "";
        this.activeColor5 = "";
      } else if (rating == 2) {
        this.activeColor0 = "";
        this.activeColor1 = "";
        this.activeColor2 = "#ffc107";
        this.activeColor3 = "";
        this.activeColor4 = "";
        this.activeColor5 = "";
      } else if (rating == 3) {
        this.activeColor0 = "";
        this.activeColor1 = "";
        this.activeColor2 = "";
        this.activeColor3 = "#ffc107";
        this.activeColor4 = "";
        this.activeColor5 = "";
      } else if (rating == 4) {
        this.activeColor0 = "";
        this.activeColor1 = "";
        this.activeColor2 = "";
        this.activeColor3 = "";
        this.activeColor4 = "#ffc107";
        this.activeColor5 = "";
      } else if (rating == 5) {
        this.activeColor0 = "";
        this.activeColor1 = "";
        this.activeColor2 = "";
        this.activeColor3 = "";
        this.activeColor4 = "";
        this.activeColor5 = "#ffc107";
      }
      this.getProductList(0);
    },
    getFilterList() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/brand/attributes/" + this.slug,
          _body: this.searchObj,
        })
        .then((res) => {
          this.isLoader = false;
          this.filterList = res.list;
          this.isLoader = false;

          for (var i = 0; i < this.filterList.length; i++) {
            this.filterList[i].selected_id = "";
          }
        })
        .catch(() => {
          this.isLoader = false;
        });
    },
    getAllBrandList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/brand/list",
        })
        .then((res) => {
          this.brandAllList = res;
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getProductList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getProductList(0);
    },
    gotoPage: function (data, event) {
      var action = event.target.getAttribute("data-name");
      if (action && action == "FAV") {
        this.addFavorite(data);
      } else {
        this.$router.push("/product/" + data.slug);
      }
    },
    addFavorite() {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        var obj = {};
        obj.brand_id = this.info.id;
        this.$api
          .webRequest({
            _method: "POST",
            _action: "/brand-favourite",
            _body: obj,
          })
          .then((res) => {
            this.$toast.success(res.message, { position: "top-right" });
            if (this.info.is_favorite == "1") {
              this.info.is_favorite = "0";
            } else {
              this.info.is_favorite = "1";
            }
          });
      }
    },
    getProductList(offset) {
      this.productList = [];
      this.$refs.errorComponent.updateListLoader(true);
      this.loader = true;
      this.searchObj.offset = offset;
      this.searchObj.limit = this.itemsPerPage;
      this.searchObj.slug = this.$route.params.slug;

      if (this.searchObj.to_price == 0) {
        this.searchObj.to_price = "";
      } else {
        this.searchObj.to_price = parseInt(this.searchObj.to_price);
      }

      if (this.searchObj.from_price == 0) {
        this.searchObj.from_price = "";
      } else {
        this.searchObj.from_price = parseInt(this.searchObj.from_price);
      }

      var categorie_array = [];
      categorie_array = $("input[name='mainCategory']:checked")
        .map(function () {
          return this.value;
        })
        .get();
      this.searchObj.categorie_ids = categorie_array;

      var sub_categorie_array = [];
      sub_categorie_array = $("input[name='category']:checked")
        .map(function () {
          return this.value;
        })
        .get();
      this.searchObj.sub_categorie_ids = sub_categorie_array;

      var sub_sub_categorie_array = [];
      sub_sub_categorie_array = $("input[name='subCategory']:checked")
        .map(function () {
          return this.value;
        })
        .get();
      this.searchObj.sub_sub_categorie_ids = sub_sub_categorie_array;

      var student_array = [];
      student_array = $("input[name='dashboardStudent']:checked")
        .map(function () {
          return this.value;
        })
        .get();
      this.searchObj.attribute_master_ids = student_array;

      if (this.searchObj.slug) {
        this.$api
          .webRequest({
            _method: "POST",
            _action: "/product/by/brand-slug-web/" + this.searchObj.slug,
            _body: this.searchObj,
          })
          .then((res) => {
            this.loader = false;
            this.count = res.count;
            for (var i in res.list) {
              res.list[i].name = this.$helperService.getName(res.list[i].name);
              this.productList.push(res.list[i]);
            }
            this.info = res.info;
            this.offset = res.next_offset;

            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(
                res,
                this.$i18n.t("productList.Product not available")
              );
              this.$refs.errorComponent.updateListLoader(false);
            }

            if (offset == 0) {
              if (this.$refs.listPagination) {
                if (res.count != null && res.count > 0) {
                  this.$refs.listPagination.setTotalCount(res.count, 12);
                } else {
                  this.$refs.listPagination.setTotalCount(0);
                }
              }
            }

            this.loader = false;
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateListLoader(false);
            }
          });
      }
    },
    clearFilter() {
      this.searchObj.to_price = "";
      this.searchObj.from_price = "";
      this.getProductList(0);
    },
    getMainCategoryList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/top-menu/category/listv2",
          _body: { category_id: 0 },
        })
        .then((res) => {
          this.mainCategoryList = res;
        })
        .catch(() => {});
    },
    getSubCategoryList() {
      var sub_categorie_array = [];
      sub_categorie_array = $("input[name='category']:checked")
        .map(function () {
          return this.value;
        })
        .get();
      this.searchObj.sub_categorie_ids = sub_categorie_array;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/sub-category/list",
          _body: { category_id: this.searchObj.sub_categorie_ids },
        })
        .then((res) => {
          this.subCategoryList = res.list;
          this.getProductList(0);
        })
        .catch(() => {});
    },

    getCategoryList() {
      var categorie_array = [];
      categorie_array = $("input[name='mainCategory']:checked")
        .map(function () {
          return this.value;
        })
        .get();
      this.searchObj.categorie_ids = categorie_array;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/sub-category/list",
          _body: { category_id: this.searchObj.categorie_ids },
        })
        .then((res) => {
          this.categoryList = res.list;
          this.getProductList(0);
        })
        .catch(() => {});
    },
  },
};
</script>
