<template>
  <header>
    <div class="container top-spacer">
      <div class="row h-100">
        <div class="col-lg-6 col-sm-3 my-auto text-left">
          <a
            class="navbar-brand"
            href="javascript:void(0)"
            v-on:click="$router.push('/home')"
          >
            <img :src="imgUrl" width="220" height="45" />
          </a>
        </div>
        <div class="col-lg-6 col-sm-9 my-auto">
          <div class="d-flex justify-content-between float-end">
            <div class="d-flex justify-content-between gap-3 align-items-center">
              <div class="autocomplete">
                <div class="form-group has-search-right d-none d-sm-block">
                  <a
                    href="javascript:void(0)"
                    class="form-control-feedback"
                    v-if="!keyword"
                    ><img :src="imgSearch"
                  /></a>
                  <a
                    href="javascript:void(0)"
                    class="form-control-feedback text-dark"
                    v-if="keyword"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-lg"
                      viewBox="0 0 16 16"
                      @click="clearAll()"
                    >
                      <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
                      /></svg
                  ></a>
                  <input
                    class="autocomplete-input form-control border-end-0"
                    :placeholder="$t('search.Search')"
                    aria-label="Search"
                    v-model="keyword"
                    v-on:keyup="getSearch()"
                    v-on:keyup.enter="gotoPage()"
                  />
                </div>
                <ul
                  v-if="searchList.length && keyword"
                  class="autocomplete-results vertical-scroll"
                >
                  <li
                    v-for="(result, index) in searchList"
                    :key="index"
                    class="autocomplete-result"
                    v-on:click="goToSearch(result)"
                  >
                    {{ result.name }}
                  </li>
                </ul>
                <ul
                  v-if="!searchList.length && keyword"
                  class="autocomplete-results vertical-scroll"
                >
                  <li class="autocomplete-result">
                    {{ $t("productList.Product not available") }}
                  </li>
                </ul>
              </div>
              <div class="profile-mobile d-none d-sm-flex align-items-center">
                <span v-if="!token"
                  ><a href="#signupModal" data-bs-toggle="modal" class="text-nowrap">{{
                    $t("login.Sign Up")
                  }}</a></span
                >
                <span v-if="!token" class="login-bg"
                  ><a href="#loginModal" id="openloginModal" data-bs-toggle="modal">{{
                    $t("login.Log in")
                  }}</a></span
                >
                <div
                  class="dropdown ml-3"
                  v-if="token"
                  style="display: inline-block; margin-left: 15px"
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="javascript:void(0)"
                    id="navbarDropdownMenuLink"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                  >
                    <img
                      :src="userDetail.image ? userDetail.image : profilePlaceholder"
                      width="45"
                      height="45"
                      class="rounded-circle"
                    />
                  </a>

                  <div
                    class="dropdown-menu mt-2"
                    style="
                      border-radius: 0;
                      overflow-y: none;
                      height: auto;
                      transform: none !important;
                    "
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <div class="row profile-section">
                      <div class="col-md-3 my-auto">
                        <img
                          :src="userDetail.image ? userDetail.image : profilePlaceholder"
                          width="55"
                          height="55"
                          class="rounded-circle"
                        />
                      </div>
                      <div class="col-md-9">
                        <div class="user-name">{{ userDetail.name }}</div>
                        <div class="sm-text">{{ userDetail.email }}</div>
                        <div v-if="userDetail.is_verified == 'Y'">
                          <span class="green-badge">
                            <a href="javascript:void(0)">
                              <img :src="imgVerifiedg" /> {{ $t("login.Verified") }}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr style="opacity: 0.1" class="my-2" />
                    <div>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        v-on:click="$router.push('/profile')"
                        ><img :src="imgProfiles" width="24" height="24" class="me-2" />
                        {{ $t("Header.My Profile") }}
                        <span class="float-end"
                          ><img :src="imgNxtArrow" width="22" height="22" /></span
                      ></a>
                    </div>
                    <hr style="opacity: 0.1" class="my-2" />
                    <div>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        v-on:click="$router.push('/favourite')"
                        ><img :src="imgWishlist" width="24" height="24" class="me-2" />
                        {{ $t("Header.Favorite") }}
                        <span class="float-end"
                          ><img :src="imgNxtArrow" width="22" height="22" /></span
                      ></a>
                    </div>
                    <hr style="opacity: 0.1" class="my-2" />
                    <div>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        v-on:click="$router.push('/change-password')"
                        ><img :src="imgsettings" width="22" height="22" class="me-2" />
                        {{ $t("settings.Change Password") }}
                        <span class="float-end"
                          ><img :src="imgNxtArrow" width="22" height="22" /></span
                      ></a>
                    </div>
                    <hr style="opacity: 0.1" class="my-2" />
                    <div>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        v-on:click="$router.push('/terms-conditions?mobile=N')"
                        ><img :src="imgtc" width="22" height="22" class="me-2" />
                        {{ $t("signup.terms conditions") }}
                        <span class="float-end"
                          ><img :src="imgNxtArrow" width="22" height="22" /></span
                      ></a>
                    </div>
                    <hr style="opacity: 0.1" class="my-2" />
                    <div>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        v-on:click="$router.push('/about-us?mobile=N')"
                        ><img :src="imginfoa" width="22" height="22" class="me-2" />
                        {{ $t("Footer.About Us") }}
                        <span class="float-end"
                          ><img :src="imgNxtArrow" width="22" height="22" /></span
                      ></a>
                    </div>
                    <hr style="opacity: 0.1" class="my-2" />
                    <div>
                      <a
                        class="dropdown-item pt-2"
                        href="#logoutModal"
                        data-bs-toggle="modal"
                      >
                        <img :src="imglogout" width="24" height="24" class="me-2" />
                        {{ $t("Header.Logout") }}
                        <span class="float-end"
                          ><img :src="imgNxtArrow" width="22" height="22"
                        /></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="nav-section">
        <div class="">
          <!-- container -->
          <nav class="navbar navbar-expand-lg navbar-light bg-white py-0">
            <div class="collapse navbar-collapse" id="navbarCollapse">
              <ul class="navbar-nav">
                <li
                  class="nav-item dropdown mega-dropdown position-static"
                  @mouseover="mouseHover()"
                  v-for="(data, index) in categoryList"
                  :key="index"
                >
                  <a
                    class="nav-link"
                    href="javascript:void(0)"
                    v-on:click="openPage('/categories/' + data.slug)"
                    data-bs-toggle="dropdown"
                    id="navbarDropdown"
                    role="button"
                    >{{ data.name }}
                  </a>
                  <div class="dropdown-menu megamenu mt-2">
                    <div class="mega-menu-wrap">
                      <div class="row">
                        <div
                          class="col-md-2"
                          v-for="(item, index) in data.child_list"
                          :key="index"
                        >
                          <div class="nav-cat-name">
                            <p
                              class="cat-item"
                              v-on:click="openPage('/sub-categories/' + item.slug)"
                            >
                              <span class="cursor-pointer"> {{ item.name }}</span>
                            </p>
                            <div class="item-list">
                              <a
                                href="javascript:void(0)"
                                class="item-name"
                                v-for="(child, a) in item.sub_child_list"
                                :key="a"
                                v-on:click="openPage('/category/' + child.slug)"
                              >
                                <span>{{ child.name }}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <!-- <li class="nav-item">
                  <a
                    class="nav-link"
                    href="javascript:void(0)"
                    v-on:click="$router.push('/main-categories')"
                    >All Categories</a
                  >
                </li> -->
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="javascript:void(0)"
                    v-on:click="$router.push('/brands')"
                    >{{ $t("brand.Brands") }}</a
                  >
                </li>
                <li class="nav-item" v-on:click="openBlog()">
                  <a class="nav-link" href="javascript:void(0)">{{ $t("blog.Blogs") }}</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="javascript:void(0)"
                    v-on:click="$router.push('/community')"
                    >{{ $t("Community.Community") }}</a
                  >
                </li>
              </ul>
              <div class="text-end ml-auto d-flex justify-content-between">
                <div class="d-none d-sm-block none-b" style="display: inherit !important">
                  <div class="currency">
                    <ul class="navbar-nav">
                      <li class="nav-item dropdown me-2">
                        <a
                          class="nav-link dropdown-toggle"
                          href="javascript:void(0)"
                          id="selectpicker"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          ><span class="flag-icon">
                            <img
                              :src="lang_flag ? lang_flag : '/static/img/placeholder.png'"
                              style="width: 20px; height: 20px; margin-left: -5px"
                          /></span>
                          {{ lang }}</a
                        >
                        <div class="dropdown-menu" aria-labelledby="selectpicker">
                          <a
                            class="dropdown-item"
                            href="javascript:void(0)"
                            v-for="item in languageList"
                            :key="item.id"
                            @click="saveLanguage(item)"
                            :class="lang == item.code ? 'active' : ''"
                            ><span class="flag-ico"
                              ><img
                                :src="
                                  item.flag ? item.flag : '/static/img/placeholder.png'
                                "
                                style="width: 20px; height: 20px; margin-left: -5px"
                              />
                            </span>
                            {{ item.code }}
                          </a>
                        </div>
                      </li>

                      <li class="nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="javascript:void(0)"
                          id="selectpicker"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {{ symbol }}</a
                        >
                        <div class="dropdown-menu" aria-labelledby="selectpicker">
                          <a
                            class="dropdown-item"
                            href="javascript:void(0)"
                            v-for="item in currencyList"
                            :key="item.id"
                            @click="selectCurrency(item)"
                            :class="symbol == item.title ? 'active' : ''"
                          >
                            {{ item.title }}</a
                          >
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <!-- For Mobile menu panel -->
      <div class="d-block d-sm-none nav-section">
        <span class="search-icon"
          ><a href="javascript:void(0)"
            ><img :src="imgSearchm" width="24" height="24" /></a
        ></span>
        <button class="openbtn" onclick="openNav()">
          <img :src="imgMenu" width="24" height="24" />
        </button>
      </div>
      <div id="mySidepanel" class="sidepanel d-block d-sm-none">
        <div>
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                id="selectpicker"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="flag-icon">
                  <img
                    :src="lang_flag ? lang_flag : '/static/img/placeholder.png'"
                    style="width: 20px; height: 20px; margin-left: -5px"
                /></span>
                {{ lang }}</a
              >
              <div class="dropdown-menu" aria-labelledby="dropdown09">
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  v-for="item in languageList"
                  :key="item.id"
                  @click="saveLanguage(item)"
                  :class="lang == item.code ? 'active' : ''"
                  ><span class="flag-ico"
                    ><img
                      :src="item.flag ? item.flag : '/static/img/placeholder.png'"
                      style="width: 20px; height: 20px; margin-left: -5px"
                    />
                  </span>
                  {{ item.code }}</a
                >
              </div>
            </li>
            <li class="nav-item dropdown">
              <div class="currency" style="position: absolute; top: -63px; right: 80px">
                <select
                  class="select-option cursor-pointer"
                  v-model="symbol"
                  @change="selectCurrency()"
                >
                  <option
                    class="cursor-pointer"
                    :value="item.title"
                    v-for="item in currencyList"
                    :key="item.id"
                  >
                    {{ item.title }}
                  </option>
                </select>
              </div>
            </li>
          </ul>
        </div>

        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">
          <img :src="imgXclose" width="24" height="24" class="" />
        </a>
        <ul class="navbar-nav pt-4 vertical-scroll">
          <li class="nav-item active">
            <a
              class="nav-link"
              href="javascript:void(0)"
              v-on:click="$router.push('/home')"
              >{{ $t("blog.Home") }}</a
            >
          </li>
          <li
            class="nav-item dropdown mega-dropdown position-static"
            @mouseover="mouseHover()"
            v-for="(data, index) in categoryList"
            :key="index"
          >
            <a
              class="nav-link"
              href="javascript:void(0)"
              v-on:click="openPage('/sub-categories/' + data.slug)"
              data-bs-toggle="dropdown"
              id="navbarDropdown"
              role="button"
              >{{ data.name }}
            </a>
            <div class="dropdown-menu megamenu mt-2">
              <div class="mega-menu-wrap">
                <div class="row">
                  <div
                    class="col-md-2"
                    v-for="(item, index) in data.child_list"
                    :key="index"
                  >
                    <div class="nav-cat-name">
                      <p class="cat-item" v-on:click="openPage('/category/' + item.slug)">
                        <span class="cursor-pointer"> {{ item.name }}</span>
                      </p>
                      <div class="item-list">
                        <a
                          href="javascript:void(0)"
                          class="item-name"
                          v-for="(child, a) in item.sub_child_list"
                          :key="a"
                          v-on:click="openPage('/category/' + child.slug)"
                        >
                          <span>{{ child.name }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              v-on:click="$router.push('/main-categories')"
              >All Categories</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              v-on:click="$router.push('/brands')"
              >{{ $t("brand.Brands") }}</a
            >
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              v-on:click="$router.push('/blogs')"
              >{{ $t("blog.Blogs") }}</a
            >
          </li> -->
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              v-on:click="$router.push('/community')"
              >{{ $t("Community.Community") }}</a
            >
          </li>
        </ul>
        <hr />
        <div class="profile-mobile">
          <span
            ><a href="#signupModal" data-bs-toggle="modal">{{
              $t("login.Sign Up")
            }}</a></span
          >
          <span class="login-bg p-0">
            <a href="#loginModal" data-bs-toggle="modal">{{
              $t("login.Log in")
            }}</a></span
          >
        </div>
      </div>
    </div>
  </header>
  <div class="empty-space"></div>
  <!-- Logout Modal -->
  <div
    class="modal fade"
    id="logoutModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div
          class="modal-header border-0 p-0"
          style="display: inline-block; text-align: center"
        >
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"
              ><img :src="imgClosebtn" width="20" height="20"
            /></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="login-form col-md-12 col-sm-12 text-center">
              <img :src="imgCaution" width="90" height="90" />
              <h5 class="modal-title" id="exampleModalLongTitle">
                {{ $t("logout.Log out!") }}
              </h5>
              <p class="py-2">{{ $t("logout.Are you sure, you want to logout?") }}</p>
              <button
                type="button"
                name="Yes, Log out"
                value="Yes, Log out"
                class="gery-fill-btn"
                v-on:click="logout()"
              >
                {{ $t("logout.Yes, Log out") }}
              </button>
              <button type="button" data-bs-dismiss="modal" class="fill-btn">
                {{ $t("logout.No") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import imgMenu from "../../public/static/img/menu.svg";
import imgXclose from "../../public/static/img/xclose.svg";
import imgUrl from "../../public/static/img/logo.svg";
import imgSearch from "../../public/static/img/search.svg";
import imgSearchm from "../../public/static/img/mob-search.svg";
import imgsettings from "../../public/static/img/settings.svg";
import imgtc from "../../public/static/img/tc.svg";
import imginfoa from "../../public/static/img/infoa.svg";
import imglogout from "../../public/static/img/logout.svg";
import imgNxtArrow from "../../public/static/img/arrow-right.svg";
import imgVerifiedg from "../../public/static/img/verified-green.svg";
import imgWishlist from "../../public/static/img/fill-heart.png";
import imgProfiles from "../../public/static/img/profile-user.png";
import profilePlaceholder from "../../public/static/img/profilePlaceholder.png";
import imgCaution from "../../public/static/img/caution.svg";
import imgClosebtn from "../../public/static/img/close-btn.svg";
</script>

<script>
import moment from "moment";
import $ from "jquery";
export default {
  name: "HeaderPage",

  data() {
    return {
      role: "",
      token: localStorage.getItem("access_token"),
      currentTab: location.pathname.replace("/", ""),
      categoryList: [],
      SubcategoryList: [],
      type: "y",
      lang: "en",
      symbol: "USD",
      searchList: [],
      languageList: [],
      currencyList: [],
      keyword: "",
      userDetail: "",
      date: "",
    };
  },

  computed: {
    user() {
      return this.$storeService.getters.getUser;
    },
    openLoginPopupFlag() {
      return this.$storeService.getters.getOpenLoginPopupFlag;
    },
  },

  watch: {
    user(newValue) {
      this.someFunction(newValue);
    },
    openLoginPopupFlag(newValue) {
      this.openLoginPopup(newValue);
    },
  },
  mounted() {
    if (localStorage.getItem("categoryList")) {
      this.categoryList = JSON.parse(localStorage.getItem("categoryList"));
    }
    if (!localStorage.getItem("access_token")) {
      this.$storeService.commit("setUser", null);
    } else {
      this.getMe();
    }
    var d = new Date();
    var toda = moment(d).format("DD MMM YYYY");
    if (!localStorage.getItem("today_date")) {
      localStorage.setItem("today_date", toda);
      this.getCategories();
    } else {
      if (localStorage.getItem("today_date") != toda) {
        this.getCategories();
      } else {
        if (localStorage.getItem("categoryList")) {
          this.categoryList = JSON.parse(localStorage.getItem("categoryList"));
          console.log(this.categoryList);
        } else {
          this.getCategories();
        }
      }
    }
    // this.getCategories();

    this.getlanguageList();
    this.$i18n.locale = localStorage.getItem("language");
    this.lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en";
    this.symbol = localStorage.getItem("symbol") ? localStorage.getItem("symbol") : "USD";
    this.lang_flag = localStorage.getItem("lang_flag")
      ? localStorage.getItem("lang_flag")
      : "https://beautyapi.winayak.com/img/1690184355.png";
    if (!localStorage.getItem("symbol")) {
      localStorage.setItem("symbol", "USD");
    }
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    if (!localStorage.getItem("lang_flag")) {
      localStorage.setItem(
        "lang_flag",
        "https://beautyapi.winayak.com/img/1690184355.png"
      );
    }
  },

  methods: {
    openLoginPopup(newValue) {
      console.log(newValue);
      if (newValue) {
        document.getElementById("openloginModal").click();
        this.$storeService.commit("setOpenLoginPopupFlag", false);
      }
    },
    someFunction() {
      this.token = localStorage.getItem("access_token");
      this.getMe();
    },
    getMe() {
      if (localStorage.getItem("access_token")) {
        this.$api
          .webRequest({
            _method: "GET",
            _action: "users/me",
          })
          .then((res) => {
            if (res) {
              this.userDetail = res;
            }
          })
          .catch(() => {});
      }
    },
    saveLanguage(item) {
      this.lang = item.code;
      this.lang_flag = item.flag;
      localStorage.setItem("lang_flag", this.lang_flag);
      localStorage.setItem("language", this.lang);
      this.$i18n.locale = this.lang;
      var abc = this.addReplaceLangCode(this.lang.toLowerCase());
      if (abc) {
        document.location.href = abc;
      }
    },
    mouseHover() {
      $(".dropdown.mega-dropdown").removeClass("hideMegamenu");
    },
    openPage(url) {
      $(".dropdown.mega-dropdown").addClass("hideMegamenu");
      this.$router.push(url);
    },
    addReplaceLangCode(langCode) {
      var a = document.createElement("a");
      a.href = document.location.href;

      var paths = a.pathname.split("/");
      paths.shift();

      if (langCode == paths[0]) {
        return null;
      }

      if (paths[0].length == 2) {
        paths[0] = langCode;
      } else {
        paths.unshift(langCode);
      }

      console.log(paths);
      return (
        a.protocol +
        "//" +
        a.host +
        "/" +
        paths.join("/") +
        (a.search != "" ? a.search : "") +
        (a.hash != "" ? a.hash : "")
      );
    },

    getlanguageList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/language/all",
        })
        .then((res) => {
          this.languageList = res;
          this.getCurrencyList();
        })
        .catch(() => {});
    },

    getCurrencyList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/currency/all",
        })
        .then((res) => {
          this.currencyList = res;
        })
        .catch(() => {});
    },
    clearAll() {
      this.searchList = [];
      this.keyword = "";
    },
    gotoPage() {
      this.$router.push("/search-result/" + this.keyword);
    },
    getSearch() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/search/list-web",
          _body: { keyword: this.keyword },
          _hide_loader: true,
        })
        .then((res) => {
          this.searchList = res.list;
        })
        .catch(() => {});
    },
    goToSearch(data) {
      if (data.type == "CATEGORY") {
        this.$router.push("/main-categories");
      }
      if (data.type == "BRAND") {
        this.$router.push("/brands");
      }
      if (data.type == "PRODUCT") {
        this.$router.push("/product/" + data.slug);
      }
    },

    showDropdown() {
      var dropdownId = document.getElementById("dropdownMenuLink");
      if (dropdownId) {
        if (dropdownId.className.includes("show")) {
          dropdownId.classList.remove("show");
        } else {
          dropdownId.classList.add("show");
        }
      }
    },
    getCategories() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/top-menu/category/listv2",
        })
        .then((res) => {
          this.categoryList = res;
          localStorage.setItem("categoryList", JSON.stringify(this.categoryList));
        })
        .catch(() => {});
    },
    getSubCategories(id) {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/sub-category/list?category_id=" + id + "&is_featured=1",
        })
        .then((res) => {
          this.SubcategoryList = res;
        })
        .catch(() => {});
    },
    logout() {
      localStorage.clear();
      localStorage.setItem("language", "en");
      localStorage.setItem("symbol", "USD");
      this.$i18n.locale = localStorage.getItem("language");
      this.lang = localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "en";
      this.symbol = localStorage.getItem("symbol")
        ? localStorage.getItem("symbol")
        : "USD";
      this.lang_flag = localStorage.getItem("lang_flag")
        ? localStorage.getItem("lang_flag")
        : "https://beautyapi.winayak.com/img/1690184355.png";
      if (!localStorage.getItem("symbol")) {
        localStorage.setItem("symbol", "USD");
      }
      if (!localStorage.getItem("language")) {
        localStorage.setItem("language", "en");
      }

      if (!localStorage.getItem("lang_flag")) {
        localStorage.setItem(
          "lang_flag",
          "https://beautyapi.winayak.com/img/1690184355.png"
        );
      }
      window.location = "/home";
      this.token = null;
      // this.$api
      //   .webRequest({
      //     _method: "POST",
      //     _action: "/unregister/device/token",
      //   })
      //   .then((res) => {
      //     if (res) {
      //       localStorage.clear();
      //       window.location = "/home";
      //       this.token = null;
      //     }
      //   })
      //   .catch(() => {});
    },
    selectLang() {
      this.$i18n.locale = this.lang;
      localStorage.setItem("language", this.lang);
      var abc = this.addReplaceLangCode(this.lang.toLowerCase());
      if (abc) {
        document.location.href = abc;
      }
    },
    selectCurrency(item) {
      localStorage.setItem("symbol", item.title);
      location.reload();
    },
    openBlog() {
      // window.open("http://localhost/blog/");
      window.open("https://blog.grademybeauty.com/");
      // this.$api
      //   .webRequest({
      //     _method: "GET",
      //     _action: "/blog",
      //   })
      //   .then((res) => {
      //     this.blogList = res.list;
      //   })
      //   .catch(() => {});
    },
  },
};
</script>
