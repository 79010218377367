import {
    createWebHistory,
    createRouter
} from "vue-router";
import Home from "@/Views/Home/home.vue";
import MainCategory from "@/Views/Category/mainCategory.vue";
import Category from "@/Views/Category/category.vue";
import SubCategory from "@/Views/Category/category-details.vue";
import Brands from "@/Views/Brands/brands.vue";
import BrandDetail from "@/Views/Brands/brand-detail.vue";
import Community from "@/Views/Community/community.vue";
import CommunityDetail from "@/Views/Community/detail.vue";
import CommunityProfile from "@/Views/CommunityProfile/community-profile.vue";
import ProductDetails from "@/Views/ProductDetails/product-details.vue";
import ProductList from "@/Views/ProductList/product-list.vue";
import Profile from "@/Views/Profile/profile.vue";
import EditProfile from "@/Views/EditProfile/edit-profile.vue";
import ContactUs from "@/Views/ContactUs/contact-us.vue";
import ChangePassword from "@/Views/ChangePassword/change-password.vue";
import AboutUs from "@/Views/AboutUs/about-us.vue";
import PrivacyPolicy from "@/Views/PrivacyPolicy/privacy-policy.vue";
import TermsConditions from "@/Views/TermsConditions/terms-conditions.vue";
import Favourite from "@/Views/Favourite/favourite.vue";
import ViewActivity from "@/Views/ViewActivity/view-activity.vue";
import UserProfileFollow from "@/Views/UserProfileFollow/user-profile-follow.vue";
import ResetPasswordPage from "@/Views/ChangePassword/reset-password.vue";

const routes = [{
        path: "/",
        redirect: "/home",
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
    },
    {
        path: "/main-categories",
        name: "MainCategory",
        component: MainCategory,
    },
    {
        path: "/categories/:slug",
        name: "Category",
        component: Category,
    },
    {
        path: "/sub-categories/:slug",
        name: "SubCategory",
        component: SubCategory,
    },
    {
        path: "/brands",
        name: "Brands",
        component: Brands,
    },
    {
        path: "/brand-detail/:slug",
        name: "BrandDetail",
        component: BrandDetail,
    },
    {
        path: "/community",
        name: "Community",
        component: Community,
    },
    {
        path: "/community-info/:slug",
        name: "CommunityDetail",
        component: CommunityDetail,
    },
    {
        path: "/user-info/:id",
        name: "CommunityProfile",
        component: CommunityProfile,
    },
    {
        path: "/product/:slug",
        name: "ProductDetails",
        component: ProductDetails,
    },
    {
        path: "/:type/:slug",
        name: "ProductList",
        component: ProductList,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/edit-profile",
        name: "EditProfile",
        component: EditProfile,
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
    },
    {
        path: "/reset-password",
        name: "ResetPasswordPage",
        component: ResetPasswordPage,
    },
    {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/terms-conditions",
        name: "TermsConditions",
        component: TermsConditions,
    },
    {
        path: "/favourite",
        name: "Favourite",
        component: Favourite,
    },
    {
        path: "/view-activity",
        name: "ViewActivity",
        component: ViewActivity,
    },
    {
        path: "/user-profile/:id",
        name: "UserProfileFollow",
        component: UserProfileFollow,
    },
];

var lang = localStorage.getItem("language") ? localStorage.getItem("language").toLocaleLowerCase() : "en";
const router = createRouter({
    history: createWebHistory('/' + lang),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('access_token') == null) {
            next({
                path: '/home',
                params: {
                    nextUrl: to.fullPath
                }
            })
        } else {
            next()
        }
    } else {
        next()
    }
});

export default router;