<template>
  <div
    class="modal fade"
    id="ShareModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-bs-backdrop="static"
    v-show="isShowModal"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 560px"
    >
      <div class="modal-content">
        <div class="modal-header" style="display: inline-block; text-align: center">
          <h5 class="modal-title" id="exampleModalLongTitle">{{ $t("share.Share") }}</h5>
          <button
            type="button"
            class="close"
            id="sign-close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><img src="/static/img/close-btn.svg" width="20" height="20"
            /></span>
          </button>
        </div>
        <div class="modal-body row">
          <ShareNetwork
            class="col-md-4 col-sm-4"
            v-for="network in networks"
            :network="network.network"
            :key="network.network"
            :style="{ backgroundColor: network.color }"
            :url="sharing.url"
            :title="sharing.title"
            :description="sharing.description"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
            :twitterUser="sharing.twitterUser"
          >
            <i :class="network.icon"></i>
            <span>{{ network.name }}</span>
          </ShareNetwork>
          <div class="col-12 mt-2">
            <button
              v-on:click="copyToClipboard()"
              class="btn btn-outline-secondary w-100"
            >
              {{ $t("share.Copy Link") }}
              <span class="float-end"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-copy align-baseline"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                  /></svg
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a data-bs-toggle="modal" href="#ShareModal" id="shareModalLink" />
</template>

<script>
import ShareNetwork from "./share-network";
export default {
  name: "ShareModal",
  components: {
    ShareNetwork,
  },
  data() {
    return {
      isShowModal: false,
      sharing: {
        url: "https://news.vuejs.org/issues/180",
        title: "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
      },
      networks: [
        {
          network: "facebook",
          name: this.$i18n.t("share.Facebook"),
          icon: "fa fah fa-lg fa-facebook-f",
          color: "#1877f2",
        },
        {
          network: "whatsapp",
          name: this.$i18n.t("share.Whatsapp"),
          icon: "fa fah fa-lg fa-whatsapp",
          color: "#25d366",
        },
        {
          network: "instagram",
          name: this.$i18n.t("share.Instagram"),
          icon: "fa fah fa-lg fa-instagram",
          color: "#d63384",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    showModal(url, title, description) {
      console.log("object", url);
      this.isShowModal = true;
      this.sharing.title = title;
      this.sharing.url = url;
      this.sharing.description = description;
      document.getElementById("shareModalLink").click();
    },
    closeModal() {
      this.isShowModal = false;
      this.removeLoader();
    },
    onClick() {
      this.$emit("remove", this.obj);
      this.showLoader = true;
    },
    removeLoader() {
      this.showLoader = false;
    },
    copyToClipboard() {
      let $this = this;
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(this.sharing.url).then(function () {
          $this.$toast.success($this.$i18n.t("share.Successfully copy to clipboard"), {
            position: "top-right",
          });
        });
      }
    },
  },
};
</script>

<style>
h1 {
  text-align: center;
  margin: 50px 0 80px;
}
.share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}
a[class^="share-network-"] {
  flex: none;
  color: #ffffff;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
  text-decoration: none;
  width: 30%;
  margin: 0 auto;
  padding: 0;
}
a[class^="share-network-"] .fah {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  flex: 0 1 auto;
}
a[class^="share-network-"] span {
  padding: 0 10px;
  flex: 1 1 0%;
  font-weight: 500;
}
</style>