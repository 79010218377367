<template>
  <div>
    <Header />
    <div class="profile-page">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-8 mx-auto">
            <div class="sm-card-box">
              <h1 class="ms-5 ps-5">{{ $t("resetPassword.Reset Password") }}</h1>
              <Form @submit="resetPassword()">
                <div class="login-form">
                  <div class="form-group">
                    <label for="">{{ $t("resetPassword.New Password") }}</label>
                    <div class="input-group mb-3">
                      <Field
                        :type="newPasswordFieldType"
                        id="new_password"
                        class="form-control border-end-0"
                        name="new_password"
                        :placeholder="$t('resetPassword.New Password')"
                        v-model="obj.password"
                        v-on:keydown.space="$event.preventDefault()"
                        :rules="
                          'required:' +
                          $t('resetPassword.New Password') +
                          '|minLength:6|password'
                        "
                        :validateOnInput="true"
                      />

                      <span
                        class="input-group-text bg-white"
                        id="basic-addon2"
                        style="border-radius: 0px 8px 8px 0px"
                      >
                        <a class="psw-icon" v-on:click="switchVisibilityNew"
                          ><img :src="imageNew" width="20"
                        /></a>
                      </span>
                    </div>
                    <ErrorMessage name="new_password" class="validation-msg" />
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("settings.Confirm Password") }}</label>
                    <div class="input-group mb-3">
                      <Field
                        :type="oldPasswordFieldType"
                        id="confirm_password"
                        class="form-control border-end-0"
                        name="confirm_password"
                        :placeholder="$t('settings.Confirm Password')"
                        v-on:keydown.space="$event.preventDefault()"
                        :rules="
                          'required:' +
                          $t('settings.Confirm Password') +
                          '|confirmed:@new_password'
                        "
                        :validateOnInput="true"
                      />

                      <span
                        class="input-group-text bg-white"
                        id="basic-addon2"
                        style="border-radius: 0px 8px 8px 0px"
                      >
                        <a v-on:click="switchVisibilityOld" class="psw-icon"
                          ><img :src="imageOld" width="20"
                        /></a>
                      </span>
                    </div>
                    <ErrorMessage name="confirm_password" class="validation-msg" />
                  </div>
                  <div class="text-center pt-3">
                    <button type="submit" id="submit-button" class="fill-btn">Save</button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import imgShow from "../../../public/static/img/show.png";
import imgHide from "../../../public/static/img/hide.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ResetPasswordPage",
  components: { Header, Footer, Form, Field, ErrorMessage },
  data() {
    return {
      newPasswordFieldType: "password",
      oldPasswordFieldType: "password",
      imageNew: imgShow,
      imageOld: imgShow,
      obj:{}
    };
  },
  mounted() {},
  methods: {
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew = this.imageNew === imgShow ? imgHide : imgShow;
    },
    switchVisibilityOld() {
      this.oldPasswordFieldType =
        this.oldPasswordFieldType === "password" ? "text" : "password";
      this.imageOld = this.imageOld === imgShow ? imgHide : imgShow;
    },
    resetPassword() {
      this.obj.email = localStorage.getItem("email");
      this.obj.token = localStorage.getItem("token");
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "/set/password",
          _body: this.obj,
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.$router.push("/home");
        })
        .catch(() => {});
    },
  },
};
</script>

