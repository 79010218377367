<template>
  <div>
    <Header v-if="mobile == 'N'" />
    <div class="profile-page">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10 mx-auto">
            <div class="sm-card-box">
              <h1>{{ detail.title }}</h1>
              <div v-html="detail.description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="mobile == 'N'" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "TermsAndConditions",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      detail: {},
      mobile: this.$route.query.mobile,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .webRequest({
            _method: "GET",
          _action: "/cms/privacy",
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
  },
};
</script>

