<template>
  <section class="newsletter-section">
    <div class="container">
      <div class="black-box">
        <div class="row h-100">
          <div class="col-lg-6 col-sm-4">
            <img :src="imgEyedark" class="img-fluid" style="border-radius: 10px" />
          </div>
          <div class="col-lg-6 col-sm-8 my-auto">
            <h5>{{ $t("new.Subscribe to our newsletter") }}</h5>
            <p>
              {{ $t("new.Stay tuned, Subscribe to our newsletter and stay updated") }}
            </p>
            <Form @submit="update()">
              <div class="custom-search">
                <label for="email" class="">{{ $t("myProfile.Email") }}</label>
                <Field
                  type="email"
                  id="email"
                  class="custom-search-input"
                  name="email"
                  v-model="email"
                  :rules="'required:' + $t('myProfile.Email')"
                  :validateOnInput="true"
                />
                <ErrorMessage name="email" class="validation-msg" />
                <button class="custom-search-botton" type="submit" id="submit-button">
                  {{ $t("new.Subscribe") }}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import imgEyedark from "../../public/static/img/eyedark.png";
</script>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "NewsLetter",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      email: "",
    };
  },

  mounted() {},

  methods: {
    update() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/news-letter",
          _body: { email: this.email },
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.email = "";
        })
        .catch(() => {});
    },
  },
};
</script>
