<template>
  <section class="blogs-section">
    <div class="container" v-if="blogList.length > 0">
      <div class="row">
        <div class="col-md-12 col-sm-12 py-4 pb-none">
          <h2 class="h2-title">{{ $t("blog.Latest Blog Post") }}</h2>
        </div>
      </div>
      <div class="d-none d-sm-block">
        <div class="row">
          <div
            class="col-md-4 col-sm-4 blog-img-fixed"
            style="cursor: pointer"
            v-for="(data, index) in blogList"
            :key="index"
            v-on:click="openBlog(data)"
          >
            <img
              :src="
                data.image
                  ? data.image
                  : 'https://cdn.pixabay.com/photo/2017/10/10/21/47/laptop-2838921_960_720.jpg'
              "
              class="img-fluid"
            />
            <h4>{{ data.title }}</h4>
            <a href="javascript:void(0)" class="category">{{ data.category }}</a>
          </div>
        </div>
      </div>
      <!-- Mobile Highest Rated slider here -->
      <div class="d-block d-sm-none">
        <div class="row blogs-slider">
          <div class="col-md-4 col-sm-4" v-for="(data, index) in blogList" :key="index">
            <img
              :src="
                data.image
                  ? data.image
                  : 'https://cdn.pixabay.com/photo/2017/10/10/21/47/laptop-2838921_960_720.jpg'
              "
              class="img-fluid"
            />
            <h4>{{ data?.title ? data.title : "-" }}</h4>
            <a href="javascript:void(0)" class="category">Hair</a>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-sm-12 pt-15 txt-right">
        <a href="javascript:void(0)" v-on:click="viewAll()"
          >{{ $t("new.View All Blogs") }}
          <img :src="imgGarrow" width="20" height="20" style="width: auto"
        /></a>
      </div>
    </div>
  </section>
</template>
<script setup>
import imgGarrow from "../../public/static/img/green-arrow.svg";
</script>
<script>
export default {
  name: "BlogView",
  components: {},
  data() {
    return {
      blogList: [],
    };
  },

  mounted() {
    // this.getBlogList();
    // const wordpressSiteUrl = "http://localhost/blog";
    const wordpressSiteUrl = "https://blog.grademybeauty.com/";
    const endpoint = `${wordpressSiteUrl}/wp-json/wp/v2/posts`;
    fetch(endpoint)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to retrieve posts");
        }
      })
      .then((posts) => {
        console.log(posts);
        posts.forEach((post) => {
          this.setPost(post);
        });
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  },

  methods: {
    setPost(post) {
      var obj = {
        title: post.title.rendered,
        link: post.link,
      };
      this.getImage(post, obj);
    },
    getBlogList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/blog",
        })
        .then((res) => {
          this.blogList = res.list;
        })
        .catch(() => {});
    },
    openBlog(data) {
      window.open(data.link);
      // this.$api
      //   .webRequest({
      //     _method: "GET",
      //     _action: "/blog",
      //   })
      //   .then((res) => {
      //     this.blogList = res.list;
      //   })
      //   .catch(() => {});
    },
    getCategory(post, obj) {
      // const wordpressSiteUrl = "http://localhost/blog";
      const wordpressSiteUrl = "https://blog.grademybeauty.com/";
      const endpoint = `${wordpressSiteUrl}/wp-json/wp/v2/categories`;
      fetch(endpoint)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to retrieve posts");
          }
        })
        .then((categories) => {
          categories.forEach((category) => {
            if (category.id == post.categories[0]) {
              obj.category = category.name;
              this.blogList.push(obj);
            }
          });
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    },
    getImage(post, obj) {
      // const wordpressSiteUrl = "http://localhost/blog";
      const wordpressSiteUrl = "https://blog.grademybeauty.com/";
      const endpoint = `${wordpressSiteUrl}/wp-json/wp/v2/media/` + post.featured_media;
      fetch(endpoint)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to retrieve posts");
          }
        })
        .then((res) => {
          obj.image = res.guid.rendered;
          this.getCategory(post, obj);
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    },
    viewAll() {
      window.open("https://blog.grademybeauty.com/");
    },
  },
};
</script>
