<template>
  <div>
    <!-- Hot Posts -->
    <div class="post-title py-4">
      <img :src="imgHot" />&nbsp; {{ $t("new.Hot Posts") }}
    </div>
    <div class="hot-posts">
      <div
        class="row h-100 mb-3"
        style="cursor: pointer"
        v-for="(data, index) in list"
        :key="index"
        v-on:click="$router.push('/community-info/' + data.slug)"
      >
        <div class="col-md-3 col-sm-3 col-4">
          <img :src="data.image ? data.image : placeholder" class="img-fluid" />
        </div>
        <div class="col-md-9 col-sm-9 col-8">
          <div class="hot-title">{{ data.title }}</div>
          <p class="mb-0">{{ data.description }}</p>
        </div>
      </div>
      <div class="row h-100 mb-3" v-if="list.length < 1">
        <strong>{{ $t("new.No post found") }}</strong>
      </div>
    </div>
  </div>
</template>

<script setup>
import imgHot from "../../public/static/img/hot.svg";
import placeholder from "../../public/static/img/hotpost-placeholder.png";
</script>

<script>
export default {
  name: "HotPosts",
  data() {
    return {
      list: [],
      searchObj: {
        keyword: "",
        offset: 0,
        limit: 5,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.list = [];
      this.isLoader = true;

      this.$api
        .webRequest({
          _method: "GET",
          _action: "hotpost/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.isLoader = false;
          this.list = res.list;
        })
        .catch(() => {
          this.isLoader = false;
        });
    },
  },
};
</script>
