<template>
  <div>
    <Header />
    <section class="community-page">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <!-- Nav pills -->
            <ul class="nav nav-pills">
              <li class="nav-item" @click="setType('WISH')">
                <a class="nav-link active" data-bs-toggle="pill" href="#favourite">{{
                  $t("Header.Favorite")
                }}</a>
              </li>
              <li class="nav-item" @click="setType('SCAN')">
                <a
                  class="nav-link"
                  data-bs-toggle="pill"
                  href="#recentlyscanned"
                  style="width: auto"
                  >{{ $t("ForgetPassword.Recently Scanned") }}</a
                >
              </li>

              <li class="nav-item" @click="setType('BRAND')">
                <a
                  class="nav-link"
                  data-bs-toggle="pill"
                  href="#brandfav"
                  style="width: auto"
                  >{{ $t("Header.Favorite") }} {{ $t("productList.Brands") }}</a
                >
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content mt-4">
              <div class="tab-pane active" id="favourite">
                <section class="highest-rated-section">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 py-4 pb-none">
                        <h2 class="h2-title" style="text-align: left">
                          {{ $t("new.Products") }}
                        </h2>
                      </div>
                    </div>
                    <div>
                      <div class="row h-100">
                        <div
                          class="col-md-4 col-sm-4 mb-4 text-center"
                          v-for="(data, index) in wishList"
                          :key="index"
                          v-on:click="gotoPage('RATED', data, $event)"
                        >
                          <div class="white-box">
                            <div>
                              <img
                                :src="data.image ? data.image : productPlaceholder2"
                                class="img-contain"
                              />
                            </div>
                            <div class="product-title text-center pt-2">
                              {{ data.name }}
                            </div>
                            <div class="pt-0 pre-review">
                              <star-rating
                                id="expert-list"
                                class="mb-1 text-center"
                                :increment="0.5"
                                :rating="data.avg_rating"
                                :show-rating="false"
                                :star-size="15"
                                :read-only="true"
                              ></star-rating>
                            </div>
                            <a href="javascript:void(0)" class="posAbs">
                              <img
                                :src="imgHeart"
                                data-name="FAV"
                                v-if="data.is_favorite == '0'"
                                width="20"
                                height="20"
                              />
                              <img
                                data-name="FAV"
                                v-if="data.is_favorite == '1'"
                                :src="imgFillHeart"
                                width="20"
                                height="20"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="tab-pane" id="recentlyscanned">
                <section class="highest-rated-section">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 py-4 pb-none">
                        <h2 class="h2-title" style="text-align: left">
                          {{ $t("new.Products") }}
                        </h2>
                      </div>
                    </div>
                    <div>
                      <div class="row h-100">
                        <div
                          class="col-md-4 col-sm-4 mb-4 text-center"
                          v-for="(data, index) in wishList"
                          :key="index"
                          v-on:click="gotoPage('RATED', data, $event)"
                        >
                          <div class="white-box">
                            <div>
                              <img
                                :src="data.image ? data.image : productPlaceholder2"
                                class="img-contain"
                              />
                            </div>
                            <div class="product-title text-center pt-2">
                              {{ data.name }}
                            </div>
                            <div class="py-2 pre-review">
                              <star-rating
                                id="expert-list"
                                class="mb-1 text-center"
                                :increment="0.5"
                                :rating="data.avg_rating"
                                :show-rating="false"
                                :star-size="15"
                                :read-only="true"
                              ></star-rating>
                            </div>
                            <a href="javascript:void(0)" class="posAbs">
                              <img
                                :src="imgHeart"
                                data-name="FAV"
                                v-if="data.is_favorite == '0'"
                                width="20"
                                height="20"
                              />
                              <img
                                data-name="FAV"
                                v-if="data.is_favorite == '1'"
                                :src="imgFillHeart"
                                width="20"
                                height="20"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="tab-pane" id="brandfav">
                <section class="brands-section">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 py-4 pb-none">
                        <h2 class="h2-title" style="text-align: left">
                          {{ $t("productList.Brands") }}
                        </h2>
                      </div>
                    </div>
                    <div>
                      <div class="row h-100">
                        <div
                          class="col-md-3 col-sm-3 col-6 mb-3 posRel text-center"
                          style="cursor: pointer"
                          v-for="(data, index) in wishList"
                          :key="index"
                          v-on:click="$router.push('/brand-detail/' + data.slug)"
                        >
                          <img
                            :src="data.image ? data.image : brandPlaceholder1"
                            class="img-fluid custom-width"
                          />
                          <div class="brand-name" v-if="!data.image">{{ data.name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <ErrorComponent @retry="getwishList(0)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <Recommendation />
    <Footer />
  </div>
</template>

<script setup>
import imgHeart from "../../../public/static/img/heart.png";
import imgFillHeart from "../../../public/static/img/fill-heart.png";
import productPlaceholder2 from "../../../public/static/img/ProductPlaceholder2.png";
import brandPlaceholder1 from "../../../public/static/img/brandPlaceholderBlank.svg";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Recommendation from "@/components/Recommendation";
import ErrorComponent from "@/components/ErrorComponent";
import StarRating from "vue-star-rating";
export default {
  name: "FavouritePage",
  components: { Header, Footer, ErrorComponent, StarRating, Recommendation },
  data() {
    return {
      loader: false,
      itemsPerPage: "12",
      wishList: [],
      searchObj: {
        keyword: "",
        offset: "",
      },
      type: "WISH",
    };
  },
  mounted() {
    if (localStorage.getItem("access_token")) {
      this.getWishList(0);
    } else {
      window.location = "/home";
    }
  },
  methods: {
    setType(type) {
      this.type = type;
      this.getWishList(0);
    },
    pageChange(offset) {
      this.getWishList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getWishList(0);
    },
    addFavorite(data) {
      var obj = {};
      obj.product_id = data.id;
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/favourite",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getWishList(0);
        });
    },
    gotoPage: function (type, data, event) {
      var action = event.target.getAttribute("data-name");
      if (action && action == "FAV") {
        this.addFavorite(type, data);
      } else {
        this.$router.push("/product/" + data.slug);
      }
    },
    getWishList(offset) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateListLoader(true);
      }
      this.wishList = [];

      if (offset) {
        this.sortObj.offset = offset;
        this.sortObj.limit = this.itemsPerPage;
      }

      var url = "";
      var method = "";
      if (this.type == "SCAN") {
        url = "scan/recent";
        method = "GET";
      } else if (this.type == "BRAND") {
        url = "all/brand/favourite-web";
        method = "POST";
      } else {
        url = "all/favourite-web";
        method = "POST";
      }
      this.$api
        .webRequest({
          _method: method,
          _action: url,
          _body: this.sortObj,
        })
        .then((res) => {
          this.loader = true;
          for (var i in res.list) {
            res.list[i].name = this.$helperService.getName(res.list[i].name);
          }
          this.wishList = res.list;

          if (this.$refs.errorComponent) {
            if (this.type == "SCAN") {
              this.$refs.errorComponent.setData(
                res,
                this.$i18n.t("wishList.No recent scan available")
              );
            } else if (this.type == "BRAND") {
              this.$refs.errorComponent.setData(
                res,
                this.$i18n.t("brand.No brand available")
              );
            } else {
              this.$refs.errorComponent.setData(
                res,
                this.$i18n.t("wishList.No favorite available")
              );
            }
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>

<style>
.vue-star-rating {
  text-align: left !important;
}
</style>