<template>
  <div>
    <div class="post-title py-4">
      <img :src="imgPeople" />&nbsp; {{ $t("new.People you should follow") }}
    </div>
    <div class="sm-card mb-3" v-for="(data, index) in list" :key="index">
      <div class="row h-100">
        <div class="col-md-2 col-sm-3 col-2">
          <img
            :src="data.image ? data.image : profilePlaceholder"
            width="50"
            height="50"
          />
        </div>
        <div
          class="col-md-7 col-sm-7 col-6 pl25"
          style="cursor: pointer"
          v-on:click="$router.push('/user-profile/' + data.uuid)"
        >
          <span class="cate-title">{{ data.name }}</span
          ><br />
          <span class="cate-name">{{ data.expert ? data.expert : "-" }}</span
          ><br />
          <span class="followers"
            >{{ data.follower_count ? data.follower_count : 0 }}
            {{ $t("customerDetail.Followers") }}</span
          >
        </div>
        <div
          class="col-md-3 col-sm-3 col-4 my-auto"
          v-if="data.is_follow == 0"
          v-on:click="follow(data.id)"
        >
          <a href="javascript:void(0)" class="follow-btn">+ {{ $t("new.Follow") }}</a>
        </div>
        <div
          class="col-md-3 col-sm-3 col-4 my-auto"
          v-if="data.is_follow == 1"
          v-on:click="follow(data.id)"
        >
          <a href="javascript:void(0)" class="unfollow-btn">{{ $t("new.UnFollow") }}</a>
        </div>
      </div>
    </div>
    <div class="sm-card mb-3" v-if="list.length < 1">
      <strong>{{ $t("new.No users found") }}</strong>
    </div>
  </div>
</template>

<script setup>
import imgPeople from "../../public/static/img/people.svg";
import profilePlaceholder from "../../public/static/img/profilePlaceholder.png";
</script>

<script>
export default {
  name: "PeopleYouShouldFollow",
  data() {
    return {
      list: [],
      searchObj: {
        keyword: "",
        offset: 0,
        limit: 5,
        // is_verified: "Y",
      },
    };
  },
  computed: {
    user() {
      return this.$storeService.getters.getUser;
    },
    keyword() {
      return this.$storeService.getters.getKeyword;
    },
  },

  watch: {
    user(newValue) {
      console.log(newValue);
      if (localStorage.getItem("access_token")) {
        this.getList();
      }
    },

    keyword(newValue) {
      this.searchObj.keyword = newValue;
      this.getList();
    },
  },

  mounted() {
    this.getList();
  },
  methods: {
    follow(id) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.followUser(id);
      }
    },
    followUser(id) {
      var obj = {};
      obj.to_id = id;
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/follow/user",
          _body: obj,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.getList();
        });
    },
    getList() {
      this.list = [];
      this.$api
        .webRequest({
          _method: "GET",
          _action: "customer/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.list = res.list;
        })
        .catch(() => {});
    },
  },
};
</script>
