<template>
  <div>
    <Header />
    <div class="community-page">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 pb-4">
            <h2 class="h2-title" style="text-align: left">
              {{ $t("Community.Community") }}
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-sm-8">
            <ul class="nav nav-pills">
              <li class="nav-item" v-on:click="setTopic()">
                <a class="nav-link active" data-bs-toggle="pill" href="#all">{{
                  $t("new.All")
                }}</a>
              </li>
              <li
                class="nav-item"
                v-for="(data, index) in topicList"
                :key="index"
                v-on:click="setTopic(data.id)"
              >
                <a class="nav-link" data-bs-toggle="pill" href="#">{{ data.title }}</a>
              </li>
            </ul>
            <div class="d-block d-sm-none">
              <div class="create-post-btn mb-4 mt-4">
                <button
                  class="btn btn-lg dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ $t("new.Create") }} &nbsp;<i class="fa fa-angle-down"></i>
                </button>
                <div class="dropdown-menu">
                  <ul>
                    <li class="mb-1" v-on:click="setType('POST')">
                      <a href="javascript:void(0)"
                        >{{ $t("new.Create Post") }}
                        <i class="fa fa-angle-right float-end" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li class="border-0" v-on:click="setType('DISCUSSION')">
                      <a href="javascript:void(0)"
                        >{{ $t("new.Start Discussion") }}
                        <i class="fa fa-angle-right float-end" aria-hidden="true"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <form name="" action="">
                  <div class="form-group has-search-right">
                    <a href="javascript:void(0)" class="form-control-feedback">
                      <img :src="imgSearch"
                    /></a>
                    <input
                      type="text"
                      class="form-control w-100"
                      :placeholder="$t('new.Search')"
                    />
                  </div>
                </form>
              </div>
            </div>
            <!-- Tab panes -->
            <div class="tab-content mt-4">
              <div class="tab-pane active" id="msgList12">
                <div
                  class="card-box mb-3"
                  v-for="(data, index) in list"
                  :key="index"
                  style="cursor: pointer"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <div
                      class="user d-flex flex-row align-items-center"
                      v-on:click="$router.push('/user-info/' + data.userInfo.uuid)"
                    >
                      <div class="mr-3">
                        <img
                          :src="
                            data.userInfo.image ? data.userInfo.image : profilePlaceholder
                          "
                          class=""
                          style="border-radius: 100px"
                          width="45"
                          height="45"
                        />
                      </div>
                      <div>
                        <span class="client-name">{{ data.userInfo.name }}</span
                        ><br v-if="data.userInfo.is_verified == 'Y'" />
                        <span class="green-badge" v-if="data.userInfo.is_verified == 'Y'"
                          ><a href="javascript:void(0)"
                            ><img :src="imgVerifiedg" /> {{ $t("login.Verified") }}
                          </a></span
                        >
                        <br />
                        <span class="dates">{{ data.created_at }}</span>
                      </div>
                    </div>

                    <div class="user d-flex flex-row align-items-center">
                      <div class="blue-badge" v-if="data.type == 'POST'">
                        <a href="javascript:void(0)">{{ $t("new.Post") }}</a>
                      </div>
                      <div class="yellow-badge" v-if="data.type == 'DISCUSSION'">
                        <a href="javascript:void(0)">{{ $t("new.Discussion") }}</a>
                      </div>
                      <div v-if="user_id == data.user_id">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          ><img :src="imgDots" width="24" height="24"
                        /></a>
                        <div class="dropdown-menu">
                          <ul>
                            <li class="mb-1">
                              <a href="javascript:void(0)" v-on:click="editPost(data)"
                                ><i class="fa fa-edit"></i> {{ $t("new.Edit") }}</a
                              >
                            </li>
                            <li>
                              <a
                                href="#DeleteModal"
                                data-bs-toggle="modal"
                                v-on:click="setId(data.id)"
                                ><i class="fa fa-trash"></i> {{ $t("new.Delete") }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="my-3"
                    v-on:click="$router.push('/community-info/' + data.slug)"
                  >
                    <img
                      :src="data.image ? data.image : placeholder"
                      class="img-fluid comm-banner"
                    />
                  </div>
                  <div v-on:click="$router.push('/community-info/' + data.slug)">
                    <div class="category-title">{{ data.title }}</div>
                    <p>{{ data.description }}</p>
                    <hr style="border-color: #cacaca" />
                  </div>
                  <div class="comments-section" v-if="data.type == 'POST'">
                    <span class="mr-4" v-on:click="setLike(data, data.is_like)">
                      <img
                        :src="imgLike"
                        width="24"
                        height="24"
                        v-if="data.is_like == 0"
                      />
                      <img
                        :src="imgLikeAct"
                        width="24"
                        height="24"
                        v-if="data.is_like == 1"
                      />
                      &nbsp;
                      <a href="javascript:void(0)"
                        >{{ $t("new.Like") }} ({{
                          data.like_count < 1 ? 0 : data.like_count
                        }})</a
                      ></span
                    >
                    <span class="mr-4" v-on:click="getComment(0, 'COMMENT', data.id)"
                      ><img :src="imgComments" width="24" height="24" /> &nbsp;
                      <a
                        href="javascript:void(0)"
                        data-bs-toggle="modal"
                        data-bs-target="#RepliesModal"
                        >{{ $t("new.Comments") }} ({{ data.reply_count }})</a
                      ></span
                    >
                    <span
                      v-on:click="showSharePopup(data.slug, data.title, data.description)"
                      ><img :src="imgShare" width="24" height="24" /> &nbsp;
                      <a href="javascript:void(0)">{{ $t("share.Share") }}</a></span
                    >
                  </div>

                  <div
                    class="comments-section"
                    v-if="data.type == 'DISCUSSION' && data.reply_count > 0"
                  >
                    <span>
                      <a
                        href="javascript:void(0)"
                        data-bs-toggle="modal"
                        data-bs-target="#RepliesModal"
                        v-on:click="getComment(0, 'REPLY', data.id)"
                        ><img :src="imgReplay" width="24" height="24" />&nbsp;
                        {{ data.reply_count }}+ {{ $t("new.Replies") }}
                      </a></span
                    >
                  </div>
                  <div class="d-none d-sm-block" v-if="data.type == 'DISCUSSION'">
                    <div class="custom-search w-100 mt-3">
                      <input
                        type="text"
                        class="custom-search-input"
                        :placeholder="$t('new.Type your reply')"
                        style="background: #f3f3f3; border: 0"
                        v-model="data.comment"
                      />
                      <button
                        class="custom-search-botton"
                        style="margin-top: 0"
                        type="button"
                        :disabled="!data.comment"
                        :id="'reply_' + data.id"
                        v-on:click="sendReply(data)"
                      >
                        {{ $t("new.Reply") }}
                      </button>
                    </div>
                  </div>
                </div>

                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4">
            <div class="create-post-btn mb-4 d-none d-sm-block">
              <button
                class="btn btn-lg dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ $t("new.Create") }} &nbsp;<i class="fa fa-angle-down"></i>
              </button>
              <div class="dropdown-menu">
                <ul>
                  <li class="mb-1" v-on:click="setType('POST')">
                    <a href="javascript:void(0)"
                      >{{ $t("new.Create Post") }}
                      <i class="fa fa-angle-right float-end" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li class="border-0" v-on:click="setType('DISCUSSION')">
                    <a href="javascript:void(0)"
                      >{{ $t("new.Start Discussion") }}
                      <i class="fa fa-angle-right float-end" aria-hidden="true"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-none d-sm-block">
              <div class="form-group has-search-right">
                <a href="javascript:void(0)" class="form-control-feedback"
                  ><img :src="imgSearch"
                /></a>
                <input
                  type="text"
                  class="form-control w-100"
                  :placeholder="$t('new.Search')"
                  id="customer-name"
                  v-model="keyword"
                  v-on:keyup="setKeyword()"
                />
              </div>
            </div>

            <PeopleYouShouldFollow />

            <FollowTheTopic />

            <HotPosts />
          </div>
        </div>
      </div>
    </div>

    <!-- Create a Post -->
    <div
      class="modal fade"
      id="createPostModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 600px"
      >
        <div class="modal-content">
          <div class="modal-header" style="display: inline-block; text-align: center">
            <h5 class="modal-title" id="exampleModalLongTitle" v-if="post.type == 'POST'">
              {{ $t("new.Create Post") }}
            </h5>
            <h5 class="modal-title" id="exampleModalLongTitle" v-else>
              {{ $t("new.Start New Discussion") }}
            </h5>
            <button
              type="button"
              class="close"
              id="close-post"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img :src="imgClosebtn" width="20" height="20"
              /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="login-form col-md-12 col-sm-12">
                <Form @submit="update()">
                  <div class="form-label-group" v-if="post.type == 'POST'">
                    <a
                      href="javascript:void(0)"
                      class="private-btn"
                      v-on:click="setPostType('PRIVATE')"
                      v-if="post.post_type == 'PUBLIC'"
                    >
                      <img :src="imgPrivate" width="20" height="20" />

                      {{ $t("new.Private") }}
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="public-btnAct"
                      v-on:click="setPostType('PUBLIC')"
                      v-if="post.post_type == 'PUBLIC'"
                    >
                      <img :src="imgPublicAct" width="20" height="20" />
                      {{ $t("new.Public") }}
                    </a>

                    <a
                      href="javascript:void(0)"
                      class="private-btnAct"
                      v-on:click="setPostType('PRIVATE')"
                      v-if="post.post_type == 'PRIVATE'"
                    >
                      <img :src="imgPrivateAct" width="20" height="20" />
                      {{ $t("new.Private") }}
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="public-btn"
                      v-on:click="setPostType('PUBLIC')"
                      v-if="post.post_type == 'PRIVATE'"
                    >
                      <img :src="imgPublic" width="20" height="20" />
                      {{ $t("new.Public") }}
                    </a>
                  </div>
                  <div class="form-label-group">
                    <Field
                      type="text"
                      id="title"
                      class="form-control"
                      name="title"
                      :placeholder="$t('Community.title')"
                      v-model="post.title"
                      :rules="'required:' + $t('Community.title')"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="title" class="validation-msg" />
                  </div>
                  <div class="form-label-group">
                    <Field
                      type="textarea"
                      name="description"
                      placeholder="t('Community.description')"
                      v-model="post.description"
                      :rules="'required:' + $t('Community.description')"
                      :validateOnInput="true"
                    >
                      <textarea
                        type="text"
                        class="form-control"
                        style="height: 100px; background: #f4f4f4; border: 0"
                        rows="4"
                        v-model="post.description"
                        v-bind="field"
                      />
                    </Field>
                    <ErrorMessage name="description" class="validation-msg" />
                  </div>

                  <div class="form-label-group">
                    <Field
                      v-slot="{ field }"
                      name="topic"
                      :rules="'required:' + $t('Community.topic') + ',true'"
                      :validateOnInput="true"
                      v-model="post.topic_id"
                    >
                      <select
                        v-bind="field"
                        class="form-select form-control"
                        aria-label="Default select example"
                      >
                        <option value="">{{ $t("new.Select Topic") }}</option>
                        <option
                          class="cursor-pointer"
                          :value="item.id"
                          v-for="item in topicList"
                          :key="item.id"
                        >
                          {{ item.title }}
                        </option>
                      </select>
                    </Field>
                    <ErrorMessage name="topic" class="validation-msg" />
                  </div>
                  <div class="form-label-group">
                    <input
                      type="file"
                      name="file"
                      class="form-control pl-3"
                      placeholder="Upload"
                      required="required"
                      @change="selectedFile($event)"
                    />
                  </div>

                  <div class="form-label-group">
                    <button type="submit" id="submit-button" class="btn">
                      {{ $t("new.Post") }}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
        </div>
      </div>
    </div>

    <!-- Rewards Modal -->
    <div
      class="modal fade"
      id="rewardModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 560px"
      >
        <div class="modal-content">
          <div
            class="modal-header p-0 border-0"
            style="display: inline-block; text-align: center"
          >
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img :src="imgClosebtn" width="20" height="20"
              /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 col-sm-12 text-center pt-2">
                <img :src="imgRewards" width="105" height="105" />
                <h3 class="pt-3" style="color: #000">
                  {{ $t("new.Review Posted Successfully") }}
                </h3>
                <h3 class="pt-2" style="color: #00ba75">
                  {{ $t("new.5 Points Earned!") }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a data-bs-toggle="modal" href="#createPostModal" id="createPostModalLink" />
    <a data-bs-toggle="modal" href="#rewardModal" id="rewardModalLink" />

    <!-- Replies Modal -->
    <div
      class="modal fade"
      id="RepliesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
        style="max-width: 560px"
      >
        <div class="modal-content">
          <div class="modal-header" style="display: inline-block; text-align: center">
            <h5
              v-if="commentType == 'REPLY'"
              class="modal-title"
              id="exampleModalLongTitle"
              style="text-align: left"
            >
              {{ $t("new.Replies") }}
            </h5>
            <h5
              v-if="commentType == 'COMMENT'"
              class="modal-title"
              id="exampleModalLongTitle"
              style="text-align: left"
            >
              {{ $t("new.Comments") }}
            </h5>
            <button
              type="button"
              class="close"
              id="forgot-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img :src="imgClosebtn" width="20" height="20"
              /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12 col-sm-12" id="commentList12">
              <div
                class="mb-3 border-bottom pb-3"
                v-for="(data, index) in commentList"
                :key="index"
              >
                <div class="card-body">
                  <div class="d-flex flex-start">
                    <img
                      class="rounded-circle shadow-1-strong me-3"
                      :src="
                        data.userInfo.image ? data.userInfo.image : profilePlaceholder
                      "
                      alt="avatar"
                      width="40"
                      height="40"
                    />
                    <div class="w-100">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <p
                          class="text-base mb-0 fw-bold"
                          style="color: #101828; font-size: 16px"
                        >
                          {{ data.userInfo.name }}
                        </p>
                        <p class="mb-0 tag-line" style="opacity: 0.5">
                          {{ data.created_at }}
                        </p>
                      </div>
                      <p class="tag-line mb-0">{{ data.comment }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center" v-if="commentList.length < 1">
                <strong>{{ $t("new.No comments found") }}</strong>
              </div>
              <div class="custom-search w-100 mt-3" v-if="commentType == 'COMMENT'">
                <input
                  type="text"
                  class="custom-search-input"
                  :placeholder="$t('new.Type your reply')"
                  style="background: rgb(243, 243, 243); border: 0px none"
                  v-model="comment"
                />
                <button
                  class="custom-search-botton"
                  type="button"
                  style="background: #00ba75 !important; margin-top: 0"
                  :disabled="!comment"
                  id="comment"
                  v-on:click="sendComment()"
                >
                  {{ $t("new.Reply") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="DeleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div
            class="modal-header border-0 p-0"
            style="display: inline-block; text-align: center"
          >
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img :src="imgClosebtn" width="20" height="20"
              /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="login-form col-md-12 col-sm-12 text-center">
                <img :src="imgDelete" width="90" height="90" />
                <p class="py-2">{{ $t("new.Are you sure, you want to Delete?") }}</p>
                <button
                  type="button"
                  class="fill-btn"
                  data-bs-dismiss="modal"
                  v-on:click="deleteRecord"
                >
                  {{ $t("new.Delete") }}
                </button>
                <button type="button" data-bs-dismiss="modal" class="gery-fill-btn">
                  {{ $t("new.Cancel") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ShareModal ref="shareModal" />
    <Footer />
  </div>
</template>

<script setup>
import imgDots from "../../../public/static/img/dots.svg";
import imgPrivate from "../../../public/static/img/private.svg";
import imgPublic from "../../../public/static/img/public.svg";
import imgLike from "../../../public/static/img/like-icon.svg";
import imgLikeAct from "../../../public/static/img/like-act.svg";
import imgComments from "../../../public/static/img/comment-icon.svg";
import imgShare from "../../../public/static/img/share-icon.svg";
import imgReplay from "../../../public/static/img/replay-icon.svg";
import imgSearch from "../../../public/static/img/search.svg";
import imgRewards from "../../../public/static/img/rewards.svg";
import imgClosebtn from "../../../public/static/img/close-btn.svg";
import profilePlaceholder from "../../../public/static/img/profilePlaceholder.png";
import imgPublicAct from "../../../public/static/img/public-act.svg";
import imgPrivateAct from "../../../public/static/img/private-act.svg";
import placeholder from "../../../public/static/img/Placeholde-bg.jpg";
import imgVerifiedg from "../../../public/static/img/verified-green.svg";
import imgDelete from "../../../public/static/img/delete.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ErrorComponent from "@/components/ErrorComponent";
import PeopleYouShouldFollow from "@/components/PeopleYouShouldFollow";
import FollowTheTopic from "@/components/FollowTheTopic";
import HotPosts from "@/components/HotPosts";
import { Form, Field, ErrorMessage } from "vee-validate";
import ShareModal from "../../components/SocialShare/ShareModal.vue";
import moment from "moment";
import $ from "jquery";
export default {
  name: "CommunityPage",
  components: {
    Header,
    Footer,
    Form,
    Field,
    ErrorMessage,
    PeopleYouShouldFollow,
    FollowTheTopic,
    HotPosts,
    ErrorComponent,
    ShareModal,
  },
  data() {
    return {
      topicList: [],
      commentList: [],
      count: "",
      isLoader: false,
      itemsPerPage: "12",
      offset: 0,
      list: [],
      searchObj: {
        keyword: "",
        offset: 0,
        topic_id: "",
      },
      post: {
        type: "",
      },
      user_id: localStorage.getItem("userId"),
      commentType: "",
      comment: "",
      commentId: "",
      deleteId: "",
      url: window.location.href,
      innerNextOffset: -1,
      commentInnerNextOffset: -1,
    };
  },

  computed: {
    user() {
      return this.$storeService.getters.getUser;
    },
  },

  watch: {
    user(newValue) {
      this.someFunction(newValue);
    },
  },

  mounted() {
    var $this = this;
    $("#msgList12").scrollTop($("#msgList12").scrollHeight);
    $("#msgList12").scroll(function () {
      if ($this.isScrolledToBottom($(this))) {
        $this.appendInnerData();
      }
    });

    $("#commentList12").scrollTop($("#commentList12").scrollHeight);
    $("#commentList12").scroll(function () {
      if ($this.isScrolledToBottom($(this))) {
        $this.appendInnerDataComment();
      }
    });

    this.getMetaContent();
    this.getTopicList();
    this.getList(0);
  },
  methods: {
    isScrolledToBottom(container) {
      var scrollTop = container.scrollTop();
      var containerHeight = container.outerHeight();
      var scrollHeight = container[0].scrollHeight;
      return scrollTop + containerHeight >= scrollHeight;
    },
    appendInnerData() {
      if (this.innerNextOffset != -1) {
        this.getList(this.innerNextOffset);
      }
    },

    appendInnerDataComment() {
      if (this.commentInnerNextOffset != -1) {
        this.getComment(this.commentInnerNextOffset, this.commentType, this.commentId);
      }
    },
    someFunction(newValue) {
      console.log(newValue);
      this.user_id = localStorage.getItem("userId");
    },
    setKeyword() {
      this.$storeService.commit("setKeyword", this.keyword);
    },
    showSharePopup(slug, title, description) {
      var currentUrl = window.location.href;

      // Parse the URL
      var url = new URL(currentUrl);

      // Extract the protocol, host, and port
      var protocol = url.protocol;
      var host = url.host;
      // var port = url.port;
      var path = url.pathname;
      var languagePrefix = path.split("/")[1]; // Assumes language prefix is the first segment of the path

      // Construct the base URL with the dynamic language prefix
      var baseUrl =
        protocol + "//" + host + "/" + languagePrefix + "/community-info/" + slug;
      if (!localStorage.getItem("access_token")) {
        this.$refs.shareModal.showModal(baseUrl, title, description);
      } else {
        this.$refs.shareModal.showModal(baseUrl, title, description);
        this.shareCall();
      }
    },
    shareCall() {
      // var obj = {};
      // obj.product_id = this.detail.product_id;
      // this.$api
      //   .postAPI({
      //     _action: "/shared/post",
      //     _body: obj,
      //   })
      //   .then((res) => {
      //         this.$toast.success(res.message, { position: "top-right" });
      //   })
      //   .catch(() => { });
    },
    editPost(data) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.post = data;
        console.log(this.post);
        document.getElementById("createPostModalLink").click();
      }
    },
    setType(type) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.post = {};
        this.post.type = type;
        this.post.post_type = "PUBLIC";
        document.getElementById("createPostModalLink").click();
      }
    },
    setPostType(type) {
      this.post.post_type = type;
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      this.file = files[0];
    },
    update() {
      var url = "add-post";
      var method = "POST";
      if (this.post.id) {
        url = "edit-post/" + this.post.id;
        method = "POST";
      } else {
        if (!this.file) {
          this.$toast.error("Please select image", { position: "top-right" });
          return false;
        }
      }
      this.$api
        .webRequest({
          _method: method,
          _action: url,
          _body: this.post,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            this.post.id = res.id;
            document.getElementById("close-post").click();
            if (this.file) {
              this.uploadImage();
            } else {
              this.$toast.success(res.message, { position: "top-right" });
              this.getList(0);
            }
          }
        })
        .catch(() => {});
    },

    uploadImage() {
      var obj = {};
      obj.id = this.post.id;
      this.$api
        .uploadImageAPI({
          _action: "/post/photo",
          _key: "image",
          _file: this.file,
          _body: obj,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.getList(0);
        });
    },
    setId(id) {
      this.deleteId = id;
    },
    deleteRecord() {
      this.$api
        .webRequest({
          _method: "DELETE",
          _action: "delete-post/" + this.deleteId,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.getList();
        })
        .catch(() => {});
    },
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/8",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    getComment(offset, type, id) {
      this.isLoader = true;
      this.commentType = type;
      this.commentId = id;
      if (offset == 0) {
        this.commentList = [];
      }
      this.searchObj.offset = offset;
      this.searchObj.limit = 5;

      this.$api
        .webRequest({
          _method: "GET",
          _action: "post/comment/list/" + id,
          _body: this.searchObj,
        })
        .then((res) => {
          this.isLoader = false;
          this.count = res.count;
          for (var i in res.list) {
            res.list[i].name = this.$helperService.getName(res.list[i].name);
            res.list[i].created_at = moment(res.list[i].created_at).format("DD MMM YYYY");
            // this.list.push(res.list[i]);
          }

          if (res.list != null && res.list.length > 0) {
            if (offset == 0) {
              for (var j = 0; j < res.list.length; j++) {
                this.commentList.push(res.list[j]);
              }
            } else {
              for (var k = 0; k < res.list.length; k++) {
                this.commentList.push(res.list[k]);
              }
            }
          }
          if (res.next_offset) {
            this.commentInnerNextOffset = res.next_offset;
          } else {
            this.commentInnerNextOffset = -1;
          }

          // setTimeout(() => {
          //   var div_height = $("#commentList12").height();
          //   var div_offset = $("#commentList12").offset().top;
          //   var window_height = $("#commentList12").height();
          //   $("#commentList12").animate({
          //     scrollTop: div_offset + window_height + div_height,
          //   });
          // }, 700);

          this.isLoader = false;
        })
        .catch(() => {
          this.isLoader = false;
        });
    },
    getTopicList() {
      this.topicList = [];
      this.isLoader = true;

      this.$api
        .webRequest({
          _method: "GET",
          _action: "topic/all",
          _body: this.searchObj,
        })
        .then((res) => {
          this.isLoader = false;
          for (var i in res.list) {
            res.list[i].title = this.$helperService.getName(res.list[i].title);
            this.topicList.push(res.list[i]);
          }
          this.isLoader = false;
        })
        .catch(() => {
          this.isLoader = false;
        });
    },
    setTopic(id) {
      if (id) {
        this.searchObj.topic_id = id;
      } else {
        this.searchObj.topic_id = "";
      }
      this.getList(0);
    },

    getList(offset) {
      if (offset == 0) {
        this.list = [];
      }
      this.$refs.errorComponent.updateListLoader(true);
      this.isLoader = true;
      this.searchObj.offset = offset;
      this.searchObj.limit = 10;

      this.$api
        .webRequest({
          _method: "GET",
          _action: "post/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.isLoader = false;
          this.count = res.count;
          for (var i in res.list) {
            res.list[i].name = this.$helperService.getName(res.list[i].name);
            res.list[i].created_at = moment(res.list[i].created_at).format("DD MMM YYYY");
            // this.list.push(res.list[i]);
          }

          if (res.list != null && res.list.length > 0) {
            if (offset == 0) {
              for (var j = 0; j < res.list.length; j++) {
                this.list.push(res.list[j]);
              }
            } else {
              for (var k = 0; k < res.list.length; k++) {
                this.list.push(res.list[k]);
              }
            }
          }
          if (res.next_offset) {
            this.innerNextOffset = res.next_offset;
          } else {
            this.innerNextOffset = -1;
          }

          // setTimeout(() => {
          //   var div_height = $("#msgList12").height();
          //   var div_offset = $("#msgList12").offset().top;
          //   var window_height = $("#msgList12").height();
          //   $("#msgList12").animate({
          //     scrollTop: div_offset + window_height + div_height,
          //   });
          // }, 700);

          if (this.$refs.errorComponent && this.list.length < 1) {
            this.$refs.errorComponent.setData(
              res,
              this.$i18n.t("productList.Product not available")
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.updateListLoader(false);
          }
          this.isLoader = false;
        })
        .catch(() => {
          this.isLoader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },

    sendReply(data) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        var button_id = "reply_" + data.id;
        this.$api
          .webRequest({
            _method: "POST",
            _action: "post/comment",
            _body: { post_id: data.id, comment: data.comment },
            _buttonId: button_id,
          })
          .then(() => {
            data.comment = "";
            data.reply_count = data.reply_count + 1;
            // if (data.type == "DISCUSSION") {
            //   document.getElementById("rewardModalLink").click();
            // }
          })
          .catch(() => {});
      }
    },
    sendComment() {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.$api
          .webRequest({
            _method: "POST",
            _action: "post/comment",
            _body: { post_id: this.commentId, comment: this.comment },
            _buttonId: "comment",
          })
          .then(() => {
            this.comment = "";
            this.getComment(0, "COMMENT", this.commentId);
          })
          .catch(() => {});
      }
    },
    setLike(data, is_like) {
      // var flag = 1;
      // if (is_like == 0) {
      //   flag = 1;
      // }
      // if (is_like == 1) {
      //   flag = 0;
      // }
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.$api
          .webRequest({
            _method: "POST",
            _action: "post/like",
            _body: { post_id: data.id },
          })
          .then((res) => {
            data.like_count = res.count;
            if (is_like == 0) {
              data.is_like = 1;
            }
            if (is_like == 1) {
              data.is_like = 0;
            }
            // if (flag == 0) {
            //   data.is_like = 1;
            // }
            // if (flag == 1) {
            //   data.is_like = 0;
            // }
          })
          .catch(() => {});
      }
    },
  },
};
</script>

