<template>
  <div>
    <Header />
    <div class="home-slider">
      <div class="container p-l-r">
        <div class="col-md-12 col-sm-12">
          <a href="javascript:void(0)" class="back-text" v-on:click="$router.go(-1)">
            <img :src="imgBackarrow" width="25" height="25" /> {{ $t("new.Back") }}
          </a>
        </div>
        <div class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row h-100">
                <div class="col-md-4 col-sm-4 my-auto order-lg-1 order-2">
                  <div class="round-box mobile-step">
                    <h1>{{ info.name }}</h1>
                    <p>{{ info.description }}</p>
                    <button
                      class="btn button"
                      v-on:click="$router.push('/category/' + info.slug)"
                    >
                      {{ $t("recommendation.View all") }}
                      <img :src="imgWhitearrow" width="20" height="20" />
                    </button>
                  </div>
                </div>

                <div class="col-md-8 col-sm-8 my-auto 0rder-lg-2 order-1">
                  <img
                    :src="info.image ? info.image : bannerPlaceholder"
                    class="left-img-curve"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="green-gradient-bg"></section>
    <section class="category-page">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 pb-4">
            <h2 class="h2-title" style="text-align: left">{{ $t("new.Categories") }}</h2>
          </div>
        </div>
        <div class="row">
          <template v-for="(data, index) in subCategoryList" :key="index">
            <div class="col-md-2 col-sm-3 col-6">
              <ul>
                <li v-on:click="$router.push('/category/' + data.slug)">
                  <a href="javascript:void(0)">{{ data.name }}</a>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </section>
    <Blog />
    <Footer />
  </div>
</template>

<script setup>
import imgWhitearrow from "../../../public/static/img/white-arrow.svg";
import imgBackarrow from "../../../public/static/img/back-arrow.svg";
import bannerPlaceholder from "../../../public/static/img/banner.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Blog from "@/components/Blog";
import $ from "jquery";
export default {
  name: "CategoryDetailsPage",
  components: { Header, Footer, Blog },
  data() {
    return {
      loader: false,
      itemsPerPage: "12",
      subCategoryList: [],
      searchObj: {
        offset: "",
        keyword: "",
        slug: this.$route.params.slug,
      },
      info: {},
    };
  },
  watch: {
    $route(to, from) {
      if (to.fullPath != from.fullPath) {
        this.getSubCategoryList(0);
      }
    },
  },
  mounted() {
    this.getMetaContent();
    this.getSubCategoryList(0);
  },
  methods: {
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/6",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getSubCategoryList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getSubCategoryList(0);
    },
    getSubCategoryList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateListLoader(true);
      }
      if (offset == 0) {
        this.subCategoryList = [];
      }
      if (offset) {
        this.searchObj.offset = offset;
        this.searchObj.limit = this.itemsPerPage;
      }
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/sub-category/by/slug/list/" + this.searchObj.slug,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = true;
          for (var i in res.list) {
            res.list[i].name = this.$helperService.getName(res.list[i].name);
          }
          this.subCategoryList = res.list;
          this.info = res.info;
          this.searchObj.offset = res.next_offset;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, this.itemsPerPage);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              this.$i18n.t("productSubCategory.Sub category not available")
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>
