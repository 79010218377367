import {
  createStore
} from "vuex";
const store = createStore({
  state() {
    return {
      userInfo: {},
      access_token: null,
      openLoginPopupFlag: false,
      keyword: ""
    };
  },
  mutations: {
    setUser(state, payload) {
      state.userInfo = payload;
    },
    setAccessToken(state, payload) {
      state.access_token = payload;
    },
    setOpenLoginPopupFlag(state, payload) {
      state.openLoginPopupFlag = payload;
    },
    setKeyword(state, payload) {
      state.keyword = payload;
    },
  },
  getters: {
    getUser(state) {
      return state.userInfo;
    },
    getOpenLoginPopupFlag(state) {
      return state.openLoginPopupFlag;
    },
    getAccessToken(state) {
      return state.access_token
    },
    getKeyword(state) {
      return state.keyword;
    },
  },
  actions: {},
});

export default store;