<template>
  <footer>
    <div class="inner-footer">
      <div class="container h-100">
        <div class="row h-100">
          <div class="col-lg-6 col-sm-7 col-6 my-auto">
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  v-on:click="$router.push('/about-us?mobile=N')"
                  >{{ $t("Footer.About Us") }}</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" v-on:click="$router.push('/contact-us')">{{
                  $t("Footer.Contact Us")
                }}</a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  v-on:click="$router.push('/terms-conditions?mobile=N')"
                  >{{ $t("Footer.Terms Conditions") }}</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  v-on:click="$router.push('/privacy-policy?mobile=N')"
                  >{{ $t("Footer.Privacy Policy") }}</a
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-sm-5 col-6 text-end">
            <span>{{ $t("Footer.Follow us on") }} </span><br class="d-block d-sm-none" />
            <a href="javascript:void(0)" @click="openUrl(socialData.instagram)"
              ><img :src="imgTwitter" width="25" height="25"
            /></a>
            <a href="javascript:void(0)" @click="openUrl(socialData.facebook)"
              ><img :src="imgFacebook" width="25" height="25" style="border-radius: 5px"
            /></a>
            <a href="javascript:void(0)" @click="openUrl(socialData.instagram)"
              ><img :src="imgInstagram" width="25" height="25"
            /></a>
            <a :href="'mailto:' + socialData.email"
              ><img :src="imgMail" width="25" height="25"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bg">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>
              &copy; {{ year }} {{ $t("Footer.Grade My Beauty All rights reserved") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <!-- Login Modal -->
  <div
    class="modal fade"
    id="loginModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 560px"
    >
      <div class="modal-content">
        <div class="modal-header" style="display: inline-block; text-align: center">
          <h5 class="modal-title" id="exampleModalLongTitle">{{ $t("login.Log in") }}</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="login-close-btn"
          >
            <span aria-hidden="true"
              ><img :src="imgClosebtn" width="20" height="20"
            /></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <p>
                {{ $t("new.Login to Grade my beauty account using your credintials") }}
              </p>
            </div>
            <div class="login-form col-md-12 col-sm-12">
              <Form @submit="login" class="form-style">
                <div class="form-label-group">
                  <div class="input-group">
                    <span class="input-group-addon"
                      ><img :src="imgUser" width="24" height="24"
                    /></span>
                    <Field
                      type="email"
                      id="email"
                      class="form-control"
                      :placeholder="$t('login.Email')"
                      v-model="details.email"
                      name="Username"
                      :rules="'required:' + $t('login.Email') + '|email'"
                      :validateOnInput="true"
                    />
                  </div>
                  <ErrorMessage name="Username" class="validation-msg" />
                </div>
                <div class="form-label-group">
                  <div class="input-group">
                    <span class="input-group-addon"
                      ><img :src="imgLock" width="24" height="24"
                    /></span>
                    <Field
                      :type="passwordFieldType"
                      id="password"
                      class="form-control border-end-0"
                      v-model="details.password"
                      name="Password"
                      :placeholder="$t('login.Password')"
                      :rules="'required:' + $t('login.Password')"
                      :validateOnInput="true"
                    />
                  </div>
                  <ErrorMessage name="Password" class="validation-msg" />
                </div>
                <div class="form-label-group text-center">
                  <a
                    href="#forgotPasswordModal"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    >{{ $t("login.Forgot Password") }}</a
                  >
                </div>
                <div class="form-label-group">
                  <button type="submit" id="login-button" class="btn">
                    {{ $t("login.Log in") }} &nbsp;<i class="fa fa-sign-in"></i>
                  </button>
                </div>
                <div class="form-label-group text-center">
                  <span>- {{ $t("new.OR Continue with") }} -</span>
                </div>
                <div class="form-label-group text-center">
                  <a href="javascript:void(0)" class="mx-2" v-on:click="googleLogin"
                    ><img :src="imgGoogle" width="55" height="54"
                  /></a>
                  <!-- <a href="javascript:void(0)" class="mx-2"
                    ><img :src="imgiOS" width="55" height="54"
                  /></a>
                  <a href="javascript:void(0)" class="mx-2"
                    ><img :src="imgFacebooks" width="55" height="54"
                  /></a> -->
                </div>
              </Form>
              <div class="form-label-group text-center">
                <span>{{ $t("new.Create An Account") }}</span>
                <a
                  href="#signupModal"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  style="
                    font-family: 'Young Serif', serif;
                    color: #00ba75;
                    text-decoration: underline;
                  "
                >
                  {{ $t("login.Sign Up") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Forgot Password Modal -->
  <div
    class="modal fade"
    id="forgotPasswordModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 560px"
    >
      <div class="modal-content">
        <div class="modal-header" style="display: inline-block; text-align: center">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ $t("login.Forgot Password") }}
          </h5>
          <button
            type="button"
            class="close"
            id="forgot-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><img :src="imgClosebtn" width="20" height="20"
            /></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="login-form col-md-12 col-sm-12">
              <Form @submit="forgotPassword" class="form-style">
                <div class="form-label-group">
                  <div class="input-group">
                    <span class="input-group-addon"
                      ><img :src="imgUser" width="24" height="24"
                    /></span>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      :placeholder="$t('ForgetPassword.Email')"
                      class="form-control"
                      :rules="'required:' + $t('ForgetPassword.Email') + '|email'"
                      v-model="forgot.email"
                      :validateOnInput="true"
                    />
                  </div>
                  <ErrorMessage name="email" class="text-danger" />
                </div>
                <div class="mb-2">
                  <button type="submit" id="submit-button" class="btn">
                    {{ $t("new.Send") }} &nbsp;<i class="fa fa-send-o"></i>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- signup Modal -->
  <div
    class="modal fade"
    id="signupModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 560px"
    >
      <div class="modal-content">
        <div class="modal-header" style="display: inline-block; text-align: center">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ $t("login.Sign Up") }}
          </h5>
          <button
            type="button"
            class="close"
            id="sign-close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"
              ><img :src="imgClosebtn" width="20" height="20"
            /></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <p>{{ $t("new.Please enter following details to sign up") }}</p>
            </div>
          </div>
          <div class="login-form col-md-12 col-sm-12">
            <Form @submit="signup()">
              <div class="form-label-group">
                <div class="input-group">
                  <span class="input-group-addon"
                    ><img :src="imgUser" width="24" height="24"
                  /></span>
                  <Field
                    type="text"
                    id="fusername"
                    class="form-control"
                    name="username"
                    :placeholder="$t('new.UserName')"
                    v-model="detail.user_name"
                    :rules="'required:' + $t('new.UserName')"
                    :validateOnInput="true"
                  />
                </div>

                <ErrorMessage name="username" class="validation-msg" />
              </div>
              <div class="form-label-group">
                <div class="input-group">
                  <span class="input-group-addon"
                    ><img :src="imgUser" width="24" height="24"
                  /></span>
                  <Field
                    type="text"
                    id="fname"
                    class="form-control"
                    name="firstname"
                    :placeholder="$t('signup.Name')"
                    v-model="detail.name"
                    :rules="'required:' + $t('signup.Name')"
                    :validateOnInput="true"
                  />
                </div>

                <ErrorMessage name="firstname" class="validation-msg" />
              </div>
              <div class="form-label-group">
                <div class="input-group">
                  <span class="input-group-addon" style="top: 17px"
                    ><img :src="imgEmail" width="24" height="24"
                  /></span>
                  <Field
                    type="email"
                    id="email"
                    class="form-control"
                    name="email"
                    :placeholder="$t('signup.Email')"
                    v-model="detail.email"
                    :rules="'required:' + $t('signup.Email') + '|email'"
                    :validateOnInput="true"
                  />
                </div>
                <ErrorMessage name="email" class="validation-msg" />
              </div>
              <div class="form-label-group">
                <div class="input-group">
                  <span class="input-group-addon" style="top: 17px"
                    ><img :src="imgEmail" width="24" height="24"
                  /></span>
                  <Field
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    v-slot="{ field }"
                    v-model="detail.mobile_number"
                    :rules="'required:' + $t('signup.Mobile Number') + '|phone'"
                    :validateOnInput="true"
                    style="border: 0"
                  >
                    <PhoneCode
                      id="mobile"
                      :placeholder="$t('signup.Mobile Number')"
                      v-model:countryCode="detail.country_code"
                      v-model="detail.mobile_number"
                      v-model:dialCode="detail.dial_code"
                      v-bind="field"
                      style="
                        background: #f4f4f4;
                        border: 0;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                      "
                    />
                  </Field>
                </div>
                <ErrorMessage name="phone" class="validation-msg" />
              </div>
              <div class="form-label-group">
                <div class="input-group">
                  <span class="input-group-addon" style="top: 17px">
                    <img :src="imgWorld" width="24" height="24" />
                  </span>
                  <Field
                    v-slot="{ field }"
                    name="country"
                    :rules="'required:' + $t('signup.Country') + ',true'"
                    :validateOnInput="true"
                    v-model="detail.country_id"
                  >
                    <select
                      v-bind="field"
                      class="form-select form-control"
                      aria-label="Default select example"
                    >
                      <option value="">{{ $t("new.Select Country") }}</option>
                      <option
                        class="cursor-pointer"
                        :value="item.id"
                        v-for="item in countryList"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </Field>
                </div>
                <ErrorMessage name="country" class="validation-msg" />
              </div>

              <div class="form-label-group">
                <div class="input-group">
                  <span class="input-group-addon"
                    ><img :src="imgLock" width="24" height="24"
                  /></span>
                  <Field
                    :type="passwordFieldType"
                    id="password"
                    class="form-control"
                    name="password"
                    :placeholder="$t('signup.Password')"
                    v-model="detail.password"
                    v-on:keydown.space="$event.preventDefault()"
                    :rules="'required:' + $t('signup.Password') + '|password'"
                    :validateOnInput="true"
                  /><span
                    class="input-group-text"
                    id="basic-addon2"
                    style="border-radius: 0px 8px 8px 0px; border: 0; background: #f4f4f4"
                    ><a v-on:click="switchVisibility" class="psw-icon"
                      ><img :src="image" width="20" /></a
                  ></span>
                </div>
                <ErrorMessage name="password" class="validation-msg" />
              </div>
              <div class="form-label-group">
                <div class="input-group">
                  <span class="input-group-addon"
                    ><img :src="imgLock" width="24" height="24"
                  /></span>
                  <Field
                    :type="newPasswordFieldType"
                    id="conf_password"
                    name="confirm_password"
                    class="form-control"
                    :placeholder="$t('signup.Confirm Password')"
                    v-on:keydown.space="$event.preventDefault()"
                    :rules="
                      'required:' + $t('signup.Confirm Password') + '|confirmed:@password'
                    "
                    :validateOnInput="true"
                  /><span
                    class="input-group-text"
                    id="basic-addon2"
                    style="border-radius: 0px 8px 8px 0px; border: 0; background: #f4f4f4"
                    ><a v-on:click="switchVisibilityNew" class="psw-icon"
                      ><img :src="imageNew" width="20" /></a
                  ></span>
                </div>
                <ErrorMessage name="confirm_password" class="validation-msg" />
              </div>

              <div class="form-label-group">
                <div class="input-group">
                  <span class="input-group-addon"
                    ><img :src="imgLock" width="24" height="24"
                  /></span>
                  <Field
                    type="date_of_birth"
                    id="date_of_birth"
                    class="form-control"
                    placeholder="Date of Birth"
                    v-model="detail.date_of_birth"
                    name="date_of_birth"
                    :rules="'required:Date of Birth'"
                    :validateOnInput="true"
                  >
                    <VueDatePicker
                      v-model="detail.date_of_birth"
                      :disabled-dates="disabledDate"
                      :enable-time-picker="false"
                      placeholder="Date of Birth"
                    ></VueDatePicker>
                  </Field>
                </div>
                <ErrorMessage name="date_of_birth" class="validation-msg" />
              </div>

              <div class="form-label-group">
                <input
                  style="height: auto; padding: 8px !important"
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckChecked1"
                  v-model="isAcceptTermCondition"
                  true-value="1"
                  false-value="0"
                />
                &nbsp;<label
                  class="form-check-label"
                  for="flexCheckChecked1"
                  style="font-family: 'Quicksand', sans-serif"
                >
                  {{ $t("signup.By creating an account you have to agree with our") }}
                  <a
                    href="javascript:void(0)"
                    style="color: #00ba75"
                    v-on:click="$router.push('/terms-conditions')"
                  >
                    {{ $t("signup.terms conditions") }}</a
                  >
                </label>
              </div>
              <div class="form-label-group">
                <input
                  style="height: auto; padding: 8px !important"
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckChecked2"
                  v-model="is_newsletter"
                  true-value="Y"
                  false-value="N"
                />
                &nbsp;<label
                  class="form-check-label"
                  for="flexCheckChecked2"
                  style="font-family: 'Quicksand', sans-serif"
                >
                  {{ $t("signup.Subscribe for newsletter") }}
                </label>
              </div>

              <div class="form-label-group">
                <div class="form-group col-md-6">
                  <vue-recaptcha
                    sitekey="6Lc8M-IpAAAAABr7JdA6j835kKyKmbZ39c_yWsM7"
                    v-on:verify="recaptchaUpdated"
                    :loadRecaptchaScript="true"
                    hl="en"
                  >
                  </vue-recaptcha>
                </div>
              </div>

              <div class="form-label-group">
                <button type="submit" id="signup-button" class="btn">
                  {{ $t("login.Sign Up") }} &nbsp;<i class="fa fa-sign-in"></i>
                </button>
              </div>
              <div class="form-label-group text-center">
                <span>- {{ $t("new.OR Continue with") }} -</span>
              </div>
              <div class="form-label-group text-center">
                <a href="javascript:void(0)" class="mx-2" v-on:click="googleLogin"
                  ><img :src="imgGoogle" width="55" height="54"
                /></a>
                <!-- <a href="javascript:void(0)" class="mx-2"
                  ><img :src="imgiOS" width="55" height="54"
                /></a>
                <a href="javascript:void(0)" class="mx-2"
                  ><img :src="imgFacebooks" width="55" height="54"
                /></a> -->
              </div>
            </Form>
            <div class="form-label-group text-center">
              <span>{{ $t("new.Already Have An Account") }} </span>
              <a
                href="#loginModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                style="
                  font-family: 'Young Serif', serif;
                  color: #00ba75;
                  text-decoration: underline;
                "
              >
                {{ $t("login.Log in") }}
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="forgotOtpModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 340px"
    >
      <div class="modal-content">
        <div class="modal-header" style="display: inline-block; text-align: center">
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="forgot-close-btn"
          >
            <span aria-hidden="true"
              ><img :src="imgClosebtn" width="20" height="20"
            /></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 col-sm-12 text-center">
              <img :src="imgSuccess1" width="90" height="90" />
              <h1>{{ $t("new.Success") }}!</h1>
              <p class="pt-2">
                {{ $t("ForgetPassword.We have sent an OTP") }} <br />{{
                  $t("ForgetPassword.to your email address")
                }}
              </p>
              <button
                type="button"
                value="Ok"
                class="fill-btn w-50 mx-0"
                v-on:click="openotp('F')"
              >
                {{ $t("ForgetPassword.OK") }}</button
              ><br />
              {{ $t("ForgetPassword.Didnt received yet") }}
              <a style="cursor: pointer" v-on:click="resendOtp()"
                >{{ $t("ForgetPassword.Resend") }}.</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Success modal-1 -->
  <div
    class="modal fade"
    id="successModal1"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 340px"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 col-sm-12 text-center">
              <img :src="imgSuccess1" width="90" height="90" />
              <h1>{{ $t("new.Success") }}!</h1>
              <p class="pt-2">
                {{ $t("new.OTP has been verified") }} <br />
                {{ $t("new.successfully") }}
              </p>
              <button
                type="button"
                name=""
                value="Ok"
                class="fill-btn w-100 mx-0"
                data-bs-dismiss="modal"
              >
                {{ $t("new.Go To Login") }}
                <img :src="imgWhitearrow" width="24" height="24" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- OTP modal -->
  <div
    class="modal fade"
    id="OTPModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 540px"
    >
      <div class="modal-content">
        <div class="modal-header" style="display: inline-block; text-align: center">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ $t("new.Verify OTP") }}
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="otp-close-btn"
          >
            <span aria-hidden="true"
              ><img :src="imgClosebtn" width="20" height="20"
            /></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 col-sm-12 text-center">
              <p>{{ $t("new.Verify OTP which we have sent on your email") }}</p>
              <div class="row pt-2 py-4">
                <div class="col-3 col-md-3">
                  <input
                    class="otp-letter-input"
                    type="text"
                    id="codeBox1"
                    name="otp"
                    maxlength="1"
                    v-on:keyup="onKeyUpEvent(1, $event)"
                    v-on:focus="onFocusEvent(1)"
                  />
                </div>
                <div class="col-3 col-md-3">
                  <input
                    class="otp-letter-input"
                    type="text"
                    id="codeBox2"
                    name="otp"
                    maxlength="1"
                    v-on:keyup="onKeyUpEvent(2, $event)"
                    v-on:focus="onFocusEvent(2)"
                  />
                </div>
                <div class="col-3 col-md-3">
                  <input
                    class="otp-letter-input"
                    type="text"
                    id="codeBox3"
                    name="otp"
                    maxlength="1"
                    v-on:keyup="onKeyUpEvent(3, $event)"
                    v-on:focus="onFocusEvent(3)"
                  />
                </div>
                <div class="col-3 col-md-3">
                  <input
                    class="otp-letter-input"
                    type="text"
                    id="codeBox4"
                    name="otp"
                    maxlength="1"
                    v-on:keyup="onKeyUpEvent(4, $event)"
                    v-on:focus="onFocusEvent(4)"
                  />
                </div>
              </div>
              <button
                type="button"
                class="fill-btn mx-0"
                v-on:click="verifyOtp()"
                id="otp-button"
              >
                {{ $t("new.Verify") }} <img :src="imgWhitearrow" width="24" height="24" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a data-bs-toggle="modal" href="#OTPModal" id="otpModalLink" />

  <a data-bs-toggle="modal" href="#forgotOtpModal" id="forgotOtpModalLink" />
</template>

<script setup>
import imgTwitter from "../../public/static/img/twitter-icon.svg";
import imgFacebook from "../../public/static/img/facebook-icon.svg";
import imgInstagram from "../../public/static/img/instagram-icon.svg";
import imgMail from "../../public/static/img/mail-icon.svg";
import imgClosebtn from "../../public/static/img/close-btn.svg";
import imgUser from "../../public/static/img/user.svg";
import imgEmail from "../../public/static/img/email.svg";
import imgGoogle from "../../public/static/img/google-icon.svg";
// import imgiOS from "../../public/static/img/ios-icon.svg";
// import imgFacebooks from "../../public/static/img/facebook-icons.svg";
import imgLock from "../../public/static/img/lock.svg";
import imgHide from "../../public/static/img/hide.png";
import imgShow from "../../public/static/img/show.png";
import imgSuccess1 from "../../public/static/img/success.svg";
import imgWhitearrow from "../../public/static/img/white-arrow.svg";
import imgWorld from "../../public/static/img/world.png";
</script>

<script>
import PhoneCode from "@/components/PhoneCodePicker/PhoneCode";
import { Form, Field, ErrorMessage } from "vee-validate";
import { inject, toRefs } from "vue";
import vueRecaptcha from "vue3-recaptcha2";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  setup(props) {
    const { isSignIn } = toRefs(props);
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");
    const handleClickLogin = () => {};
    return {
      Vue3GoogleOauth,
      handleClickLogin,
      isSignIn,
    };
  },
  name: "FooterPage",
  components: {
    Form,
    Field,
    ErrorMessage,
    PhoneCode,
    vueRecaptcha,
    VueDatePicker,
  },
  data() {
    return {
      socialData: {},
      passwordFieldType: "password",
      newPasswordFieldType: "password",
      image: imgShow,
      imageNew: imgShow,
      details: {
        email: "",
        password: "",
        role: "USER",
      },
      detail: {
        name: "",
        country_id: "",
      },
      socialDetail: {
        type: "",
        social_id: "",
        email: "",
        first_name: "",
        last_name: "",
      },
      forgot: { email: "" },
      countryList: [],
      year: "",
      email: "",
      otp_type: "",
      isAcceptTermCondition: 0,
      is_newsletter: "N",
      isCaptcha: false,
    };
  },
  mounted() {
    this.getCountryList();
    var d = new Date();
    this.year = d.getFullYear();
    this.getDetail();
    this.contactUs();
  },
  methods: {
    disabledDate(time) {
      const userTurns18 = new Date();
      userTurns18.setFullYear(userTurns18.getFullYear() - 16);

      // Disable dates before the date user turns 18
      return time.getTime() > userTurns18.getTime();
    },
    initFacebook() {
      window.FB.init({
        appId: "763936901234891",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0",
      });
      var $this = this;
      setTimeout(() => {
        $this.facebookLogin();
      }, 1000);
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    facebookLogin() {
      const FB = window.FB;
      if (!this.isFacebokInit) {
        this.isFacebokInit = true;
        this.initFacebook();
      }
      var self = this;
      FB.getLoginStatus(function (response) {
        console.log("response....1", response);
        FB.login(function (response) {
          console.log("response....2", response);
          if (response.status == "connected") {
            FB.api("/me", function (response) {
              console.log("response....", response);
              var name = "";
              var last_name = "";
              if (response.name && response.name.indexOf(" ") != -1) {
                name = response.name.substring(0, response.name.lastIndexOf(" ") + 1);
                last_name = response.name.substring(
                  response.name.lastIndexOf(" ") + 1,
                  response.name.length
                );
              } else {
                name = response.name;
              }

              self.socailLogin(response.id, "FACEBOOK");
              console.log(name + "  " + last_name + "  " + response.id);
            });
          }
        });
      });
    },
    async googleLogin() {
      // alert("google");
      // console.log("vue..........",this.Vue3GoogleOauth.isInit);
      try {
        if (this.Vue3GoogleOauth && this.Vue3GoogleOauth.isInit) {
          const googleUser = await this.$gAuth.signIn();
          if (!googleUser) {
            return null;
          }

          console.log(googleUser.getBasicProfile());
          this.socialDetail.email = googleUser.getBasicProfile().getEmail();
          this.socialDetail.first_name = googleUser.getBasicProfile().getGivenName();
          this.socialDetail.last_name = googleUser.getBasicProfile().getFamilyName();
          this.socailLogin(googleUser.getBasicProfile().getId(), "GOOGLE");
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    getDetail() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/cms/social-media",
        })
        .then((res) => {
          this.socialData = res;
        })
        .catch(() => {});
    },
    contactUs() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/cms/contact-us",
        })
        .then((res) => {
          this.email = res.email;
        })
        .catch(() => {});
    },
    getCountryList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "country/list",
        })
        .then((res) => {
          this.countryList = res;
        })
        .catch(() => {});
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image = this.image === imgShow ? imgHide : imgShow;
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew = this.imageNew === imgShow ? imgHide : imgShow;
    },
    login() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "login",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);
            this.$storeService.commit("setAccessToken", res.access_token);
            this.$toast.success("You have logged in successfully.", {
              position: "top-right",
            });
            this.getMe();
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    socailLogin(id, type) {
      this.socialDetail.social_id = id;
      this.socialDetail.type = type;
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/social/login",
          _body: this.socialDetail,
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);
            this.$storeService.commit("setAccessToken", res.access_token);
            this.getMe();
          } else {
            this.$storeService.commit("setUser", this.socialDetail);
            this.$router.push("/signup");
          }
        })
        .catch(() => {});
    },
    getMe() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "users/me",
        })
        .then((res) => {
          if (res) {
            this.$storeService.commit("setUser", res);
            localStorage.setItem("email", res.email);
            localStorage.setItem("userId", res.id);
            localStorage.setItem("role", res.role);
            localStorage.setItem("uuid", res.uuid);
            document.getElementById("login-close-btn").click();
          } else {
            localStorage.removeItem("access_token");
            return;
          }
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    recaptchaUpdated(recaptcha) {
      console.log(recaptcha);
      this.isCaptcha = true;
    },
    signup() {
      if (this.isAcceptTermCondition == "0") {
        return this.$toast.error("Please accept terms and condition", {
          position: "top-right",
        });
      }

      if (!this.isCaptcha) {
        return this.$toast.error("Please verify captcha", { position: "top-right" });
      }

      this.detail.is_newsletter = this.is_newsletter;
      this.detail.role = "USER";
      this.detail.isAcceptTermCondition = this.isAcceptTermCondition;
      this.$api
        .webRequest({
          _method: "POST",
          _action: this.detail.social_id ? "social/signup" : "customer/signup",
          _body: this.detail,
          _buttonId: "signup-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);
            localStorage.setItem("userId", res.id);
            localStorage.setItem("role", res.role);
            localStorage.setItem("email", this.detail.email);
            this.otp_type = "S";
            document.getElementById("sign-close-btn").click();
            document.getElementById("otpModalLink").click();
            this.$storeService.commit("setAccessToken", res.access_token);
            this.getMe();
          }
        })
        .catch((e) => {
          localStorage.removeItem("access_token");
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
    forgotPassword() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "forgot/password",
          _body: this.forgot,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("email", this.forgot.email);
            document.getElementById("forgot-close").click();
            document.getElementById("forgotOtpModalLink").click();
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch(() => {});
    },
    openotp(type) {
      this.otp_type = type;
      document.getElementById("forgot-close-btn").click();
      document.getElementById("otpModalLink").click();
    },
    resendOtp() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "resend/otp",
          _body: { email: localStorage.getItem("email") },
        })
        .then((res) => {
          if (res) {
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch(() => {});
    },
    verifyOtp() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      obj.otp =
        document.getElementById("codeBox1").value +
        "" +
        document.getElementById("codeBox2").value +
        "" +
        document.getElementById("codeBox3").value +
        "" +
        document.getElementById("codeBox4").value;
      if (obj.otp.length == 4) {
        this.$api
          .webRequest({
            _method: "PUT",
            _action: "verify/otp",
            _body: obj,
            _buttonId: "otp-button",
          })
          .then((res) => {
            this.$toast.success(res.message, { position: "top-right" });
            document.getElementById("otp-close-btn").click();
            if (this.otp_type == "S") {
              this.$router.push("/home");
            } else if (this.otp_type == "F") {
              localStorage.setItem("token", obj.otp);
              this.$router.push("/reset-password");
            }
          })
          .catch(() => {});
      } else {
        this.validate = true;
      }
    },

    getCodeBoxElement(index) {
      return document.getElementById("codeBox" + index);
    },
    onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (this.getCodeBoxElement(index).value.length == 1) {
        if (index !== 4) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.getCodeBoxElement(index).blur();
          this.validate = false;
        }
      }
      if (eventCode === 8 && index !== 1) {
        this.getCodeBoxElement(index - 1).focus();
        this.validate = true;
      } else {
        this.validate = false;
      }
    },
    onFocusEvent(index) {
      for (var item = 1; item < index; item++) {
        const currentElement = this.getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
          break;
        }
      }
    },
  },
};
</script>
