<template>
  <div>
    <Header />
    <div class="profile-page" style="min-height: auto">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="sm-card-box">
              <div class="row">
                <div class="col-md-2 col-sm-2 mb-3">
                  <img
                    :src="userDetail.image ? userDetail.image : profilePlaceholder"
                    class=""
                    width="150"
                    height="150"
                  />
                </div>
                <div class="col-md-10 col-sm-10">
                  <div class="row">
                    <div class="col-md-4 col-sm-4">
                      <div>{{ userDetail.name }}</div>
                      <!-- <div class="mt-2 sm-text">{{ userDetail.email }}</div> -->
                      <div class="mt-2" v-if="userDetail.is_verified == 'Y'">
                        <span class="green-badge">
                          <a href="javascript:void(0)">
                            <img :src="imgVerifiedg" /> {{ $t("login.Verified") }}
                          </a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-5" v-if="userDetail.is_follow == 0">
                      <button
                        type="button"
                        v-on:click="follow()"
                        class="unfollow-btn fill-btn mx-0 btn mt-15"
                      >
                        {{ $t("new.Follow") }}
                      </button>
                    </div>

                    <div class="col-md-3 col-sm-3 col-5" v-if="userDetail.is_follow == 1">
                      <button
                        type="button"
                        v-on:click="follow()"
                        class="follow-btn fill-btn mx-0 mt-15"
                      >
                        {{ $t("new.UnFollow") }}
                      </button>
                    </div>
                    <div class="pt-3 highest-rated-section col-md-5 col-sm-5 col-7">
                      <!-- <a
                        href="javascript:void(0)"
                        v-on:click="$router.push('/view-activity')"
                      >
                        {{ $t("new.View Activity") }}
                        <img :src="imgGarrow" width="20" height="20" style="border: 0" />
                      </a> -->
                      <div class="d-flex">
                        <div>
                          <img :src="imgRewards" width="80" height="80" class="mr-2" />
                        </div>
                        <div class="my-auto">
                          <h4 style="color: #101828; font-size: 14px">
                            {{ $t("new.Reward Points") }}
                          </h4>
                          <h6 style="color: #00ba75">
                            {{ userDetail.reward_points }}
                            {{ $t("new.Points Earned Till Now!") }}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style="opacity: 0.1" />

                  <div class="row">
                    <div class="col-md-3 col-sm-4 col-4">
                      <div class="sm-text">{{ $t("signup.Country") }}</div>
                      <div class="md-text">{{ userDetail.country.name }}</div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-4">
                      <div class="sm-text">{{ $t("customerDetail.Followers") }}</div>
                      <div class="md-text">{{ userDetail.follower_count }}</div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-4">
                      <div class="sm-text">{{ $t("new.Post") }}</div>
                      <div class="md-text">{{ userDetail.total_post }}</div>
                    </div>
                  </div>
                  <hr style="opacity: 0.1" />
                  <div class="sm-text">
                    {{ userDetail.about_us ? userDetail.about_us : "-" }}
                  </div>

                  <hr style="opacity: 0.1" />
                  <div class="row">
                    <div class="colorPic">
                      <div class="sm-text">{{ $t("customerDetail.Skin Tone") }}</div>
                      <div class="d-flex justify-content-between mb15">
                        <a href="#"
                          ><span
                            class="clr1 col"
                            :class="userDetail.skin_tone == '#F1D8CD' ? 'active' : ''"
                            >&nbsp;</span
                          ></a
                        >
                        <a href="#"
                          ><span
                            class="clr2 col"
                            :class="userDetail.skin_tone == '#EFD2C5' ? 'active' : ''"
                            >&nbsp;</span
                          ></a
                        >
                        <a href="#"
                          ><span
                            class="clr3 col"
                            :class="userDetail.skin_tone == '#EDCBBD' ? 'active' : ''"
                            >&nbsp;</span
                          ></a
                        >
                        <a href="#"
                          ><span
                            class="clr4 col"
                            :class="userDetail.skin_tone == '#BA988A' ? 'active' : ''"
                            >&nbsp;</span
                          ></a
                        >
                        <a href="#"
                          ><span
                            class="clr5 col"
                            :class="userDetail.skin_tone == '#A28578' ? 'active' : ''"
                            >&nbsp;</span
                          ></a
                        >
                        <a href="#"
                          ><span
                            class="clr6 col"
                            :class="userDetail.skin_tone == '#745F56' ? 'active' : ''"
                            >&nbsp;</span
                          ></a
                        >
                        <a href="#"
                          ><span
                            class="clr7 col"
                            :class="userDetail.skin_tone == '#5D4C45' ? 'active' : ''"
                            >&nbsp;</span
                          ></a
                        >
                        <a href="#"
                          ><span
                            class="clr8 col"
                            :class="userDetail.skin_tone == '#2E2622' ? 'active' : ''"
                            >&nbsp;</span
                          ></a
                        >
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6 pt-15">
                      <div class="sm-text">{{ $t("myProfile.Hair Style") }}</div>
                      <div class="md-text">
                        {{ userDetail.hair_style ? userDetail.hair_style : "-" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section>
      <Blog />
    </section>
    <Footer />
  </div>
</template>

<script setup>
import imgRewards from "../../../public/static/img/rewards.svg";
import imgVerifiedg from "../../../public/static/img/verified-green.svg";
// import imgGarrow from "../../../public/static/img/green-arrow.svg";
import profilePlaceholder from "../../../public/static/img/profilePlaceholder.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Blog from "@/components/Blog";
import $ from "jquery";
export default {
  name: "UserProfileFollowPage",
  components: { Header, Footer, Blog },
  data() {
    return {
      uuid: this.$route.params.id,
      userDetail: { country: {} },
    };
  },
  computed: {
    user() {
      return this.$storeService.getters.getUser;
    },
  },

  watch: {
    user(newValue) {
      console.log(newValue);
      if (localStorage.getItem("access_token")) {
        this.getUserDetail();
      }
    },
  },
  mounted() {
    this.getMetaContent();
    this.getUserDetail();
  },
  methods: {
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/11",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    getUserDetail() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/customer/detail/" + this.uuid,
        })
        .then((res) => {
          this.userDetail = res;
          document.title = this.userDetail.name;
          $("meta[property='og:title']").attr("content", this.userDetail.name);
        })
        .catch(() => {
          this.loader = false;
        });
    },
    follow(id) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.followUser(id);
      }
    },
    followUser() {
      var obj = {};
      obj.to_id = this.userDetail.id;
      this.$api
        .webRequest({
          _method: "POST",
          _action: "/follow/user",
          _body: obj,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.getUserDetail();
        });
    },
  },
};
</script>
