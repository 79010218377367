<template>
  <div>
    <Header />
    <div class="profile-page">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-sm-8 mx-auto">
            <div class="sm-card-box">
              <a
                href="javascript:void(0)"
                v-on:click="$router.go(-1)"
                class="back-text pt-1 mt-0"
              >
                <img :src="imgBackarrow" width="25" height="25" /> {{ $t("new.Back") }}
              </a>
              <h1>{{ $t("new.Edit Profile") }}</h1>

              <Form @submit="update()">
                <div class="row profile-photo mb-4">
                  <div class="col-md-12 col-sm-12">
                    <!-- <div class="circle">
                    <img class="profile-pic" :src="imgProfilePic" />
                  </div> -->
                    <div style="cursor: pointer; position: relative">
                      <!-- <input class="file-upload" type="file" accept="image/*" /> -->

                      <div class="circle">
                        <img
                          class="profile-pic"
                          id="preview"
                          :src="detail.image ? detail.image : profilePlaceholder"
                        />
                      </div>

                      <span
                        style="position: absolute; right: -40px; bottom: 0"
                        v-if="detail.image"
                      >
                        <i
                          class="fa fa-trash upload-button text-danger"
                          v-on:click="deleteImage()"
                        ></i>
                      </span>

                      <div class="p-image" style="cursor: pointer; margin-right: -15px">
                        <i class="fa fa-pencil upload-button"></i>
                        <input
                          class="file-upload"
                          type="file"
                          accept="image/*"
                          @change="selectedFile($event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <label for="fname">{{ $t("myProfile.Name") }}</label>
                    <Field
                      type="text"
                      id="fname"
                      name="firstname"
                      v-model="detail.name"
                      class="form-control mb-3"
                      :rules="'required:' + $t('myProfile.name')"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="firstname" class="validation-msg" />
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <label for="fname">{{ $t("new.UserName") }}</label>
                    <Field
                      type="text"
                      id="fusername"
                      class="form-control"
                      name="username"
                      :placeholder="$t('new.UserName')"
                      v-model="detail.user_name"
                      :rules="'required:' + $t('new.UserName')"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="username" class="validation-msg" />
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <label for="email" class="">{{ $t("myProfile.Email") }}</label>
                    <Field
                      type="email"
                      id="email"
                      class="form-control"
                      name="email"
                      v-model="detail.email"
                      :rules="'required:' + $t('myProfile.email')"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="email" class="validation-msg" />
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <label>{{ $t("myProfile.Mobile Number") }}</label>
                    <Field
                      type="text"
                      name="phone"
                      :placeholder="$t('myProfile.Mobile Number')"
                      v-slot="{ field }"
                      v-model="detail.mobile_number"
                      :rules="'required:' + $t('myProfile.phone')"
                      :validateOnInput="true"
                    >
                      <PhoneCode
                        id="mobile"
                        placeholder="Mobile Number"
                        v-model:countryCode="detail.country_code"
                        v-model="detail.mobile_number"
                        v-model:dialCode="detail.dial_code"
                        v-bind="field"
                      />
                      <ErrorMessage name="phone" class="text-danger" />
                    </Field>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <label for=" " class="text-secondary">{{
                      $t("myProfile.Country")
                    }}</label>
                    <Field
                      as="select"
                      class="form-select form-control"
                      v-model="detail.country_id"
                      name="country"
                      :rules="'required:' + $t('myProfile.phone') + ',true'"
                    >
                      <option value="">{{ $t("myProfile.Select") }}</option>
                      <option
                        v-for="(data, index) in countryList"
                        :key="index"
                        :value="data.id"
                      >
                        {{ data.name }}
                      </option>
                    </Field>
                    <ErrorMessage name="country" class="validation-msg mt-2" />
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <label class="">{{ $t("myProfile.Instagram") }}</label>
                    <Field
                      type="email"
                      id="insta"
                      name="insta"
                      class="form-control"
                      v-model="detail.instagram"
                    />
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <label class="text-secondary">{{ $t("myProfile.Hair Style") }}</label>
                    <Field
                      as="select"
                      class="form-select form-control"
                      v-model="detail.hair_style"
                      name="hairstyle"
                    >
                      <option value="">{{ $t("myProfile.Select Hair") }}</option>
                      <option value="WAVE">{{ $t("myProfile.Wavy Hair") }}</option>
                      <option value="CURLY">{{ $t("myProfile.Curly Hair") }}</option>
                      <option value="STRAIGHT">
                        {{ $t("myProfile.Straight Hair") }}
                      </option>
                      <option value="KINKY">{{ $t("myProfile.Kinky Hair") }}</option>
                    </Field>
                  </div>

                  <div class="col-md-6 col-sm-6 colorPic">
                    <label class="">{{ $t("myProfile.Skin Tone") }}</label>
                    <div class="d-flex justify-content-between mb15">
                      <a href="#"
                        ><span
                          class="clr1 col"
                          :class="detail.skin_tone == '#F1D8CD' ? 'active' : ''"
                          v-on:click="setSkinTone('#F1D8CD')"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr2 col"
                          :class="detail.skin_tone == '#EFD2C5' ? 'active' : ''"
                          v-on:click="setSkinTone('#EFD2C5')"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr3 col"
                          :class="detail.skin_tone == '#EDCBBD' ? 'active' : ''"
                          v-on:click="setSkinTone('#EDCBBD')"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr4 col"
                          :class="detail.skin_tone == '#BA988A' ? 'active' : ''"
                          v-on:click="setSkinTone('#BA988A')"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr5 col"
                          :class="detail.skin_tone == '#A28578' ? 'active' : ''"
                          v-on:click="setSkinTone('#A28578')"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr6 col"
                          :class="detail.skin_tone == '#745F56' ? 'active' : ''"
                          v-on:click="setSkinTone('#745F56')"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr7 col"
                          :class="detail.skin_tone == '#5D4C45' ? 'active' : ''"
                          v-on:click="setSkinTone('#5D4C45')"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr8 col"
                          :class="detail.skin_tone == '#2E2622' ? 'active' : ''"
                          v-on:click="setSkinTone('#2E2622')"
                          >&nbsp;</span
                        ></a
                      >
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <label class="">{{ $t("myProfile.Alergic Ingredients") }}</label>
                    <Multiselect
                      mode="tags"
                      trackBy="name"
                      label="name"
                      valueProp="id"
                      :searchable="true"
                      v-model="selectedAlergicIngredient"
                      :options="ingredientList"
                      :rules="'required:' + $t('new.alergic ingredient')"
                      name="alergic_ingredient"
                      style="
                        background: #fff;
                        border: 1px solid #eee;
                        min-height: 55px !important;
                      "
                    />
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <label class="">{{ $t("myProfile.Favourite Ingredients") }}</label>
                    <Multiselect
                      mode="tags"
                      trackBy="name"
                      label="name"
                      valueProp="id"
                      :searchable="true"
                      v-model="selectedFavoriteIngredient"
                      :options="ingredientList"
                      :rules="'required:' + $t('new.favorite ingredient')"
                      name="favorite_ingredient"
                      style="
                        background: #fff;
                        border: 1px solid #eee;
                        min-height: 55px !important;
                      "
                    />
                  </div>
                  <div class="col-md-12 col-sm-12 mt-3">
                    <label for="about" class="">{{ $t("myProfile.About Me") }}</label>
                    <Field
                      as="textarea"
                      type="text"
                      id="about"
                      class="form-control h-auto"
                      v-model="detail.about_us"
                      name="about"
                    ></Field>
                  </div>
                  <div class="col-md-12 col-sm-12 text-center pt-3">
                    <button type="submit" id="submit-button" class="fill-btn">
                      {{ $t("myProfile.Submit") }}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import imgBackarrow from "../../../public/static/img/back-arrow.svg";
import profilePlaceholder from "../../../public/static/img/profilePlaceholder.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import $ from "jquery";
import { Form, Field, ErrorMessage } from "vee-validate";
import PhoneCode from "@/components/PhoneCodePicker/PhoneCode";
import Multiselect from "@vueform/multiselect";
export default {
  name: "EditProfilePage",
  components: {
    Header,
    Footer,
    Form,
    Field,
    ErrorMessage,
    PhoneCode,
    Multiselect,
  },
  data() {
    return {
      loader: false,
      id: this.$route.params.id,
      countryList: [],
      file: undefined,
      alergic_list: [""],
      favorite_list: [""],
      detail: {
        country: {},
        alergic_ingredients: "",
        favorite_ingredients: "",
      },
      selectedAlergicIngredient: [],
      selectedFavoriteIngredient: [],
    };
  },
  mounted() {
    this.getMetaContent();
    this.meCall(false);
    this.getCountryList();
  },
  methods: {
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/9",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    setSkinTone(type) {
      this.detail.skin_tone = type;
    },
    meCall(flag) {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "users/me",
        })
        .then((res) => {
          this.loader = false;
          this.detail = res;
          this.$storeService.commit("setUser", this.detail);
          if (flag) {
            this.$router.push("/profile");
          }
          this.getIngredientList();
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },

    getIngredientList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/all/ingredients",
        })
        .then((res) => {
          this.ingredientList = res.list;

          this.selectedAlergicIngredient = [];
          this.selectedFavoriteIngredient = [];

          if (this.detail.alergic_ingredient) {
            for (var i = 0; i < this.detail.alergic_ingredient.length; i++) {
              if (this.detail.alergic_ingredient[i].ingredient_id) {
                this.selectedAlergicIngredient.push(
                  this.detail.alergic_ingredient[i].ingredient_id
                );
              }
            }
          }

          this.selectedFavoriteIngredient = [];
          if (this.detail.favorite_ingredient) {
            for (var j = 0; j < this.detail.favorite_ingredient.length; j++) {
              if (this.detail.favorite_ingredient[j].ingredient_id) {
                this.selectedFavoriteIngredient.push(
                  this.detail.favorite_ingredient[j].ingredient_id
                );
              }
            }
          }
        })
        .catch(() => {});
    },

    getCountryList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "country/list",
        })
        .then((res) => {
          this.countryList = res;
          console.log("country", this.countryList);
        })
        .catch(() => {});
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file formate, please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    update() {
      this.detail.alergic_ingredients = this.selectedAlergicIngredient;
      this.detail.favorite_ingredients = this.selectedFavoriteIngredient;

      this.$api
        .webRequest({
          _method: "PUT",
          _action: "/edit/customer",
          _body: this.detail,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            this.$toast.success(res.message, { position: "top-right" });
            if (this.file) {
              this.uploadImage();
            } else {
              this.meCall(true);
            }
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    deleteImage() {
      var obj = {};
      obj.id = this.detail.id;
      this.$api
        .webRequest({
          _method: "DELETE",
          _action: "/remove/photo/" + this.detail.id,
          _body: obj,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.meCall(false);
        });
    },

    uploadImage() {
      if (!this.file) {
        this.$router.go(-1);
      }
      var obj = {};
      obj.id = this.detail.id;
      this.$api
        .uploadImageAPI({
          _action: "/user/photo",
          _key: "image",
          _file: this.file,
          _body: obj,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.meCall(true);
        });
    },
    readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $("#img_hoder_1").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.multiselect {
  background: #f9f9f9;
  border-radius: 8px;
  border: 0;
}

.multiselect-tag {
  background: #00ba75 !important;
}

.multiselect-tags-search {
  background: #f9f9f9 !important;
}

.spx-country-select .dropdown {
  border-radius: 5px 0px 0px 5px;
  background: #fff;
  border: 1px solid #eee !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 50px;
  margin-right: -1px;
}

.multiselect-tags-search {
  background: transparent !important;
  border: 0 !important;
  margin-top: -14px !important;
}

.multiselect-tag {
  font-size: 11px;
  font-family: "Quicksand", sans-serif;
}

.multiselect {
  min-height: 50px !important;
}
</style>
