<template>
  <div>
    <Header />
    <section class="highest-rated-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 py-4 pb-none">
            <a
              href="javascript:void(0)"
              class="back-text pt-1 ms-0"
              style="color: #101828; font-size: 16px"
            >
              <img :src="imgBackarrow" width="25" height="25" /> {{ $t("new.Back") }}
            </a>
            <h2 class="h2-title ms-5 ps-5" style="text-align: left">
              View Activity
            </h2>
          </div>
        </div>
        <div>
          <div class="row h-100">
            <div class="col-md-4 col-sm-4 mb-4 text-center">
              <div class="white-box">
                <div>
                  <img :src="imgmascara1" class="img-contain" />
                </div>
                <div class="product-title text-center">
                  Volume & Conditioning Mascara
                </div>
                <div class="py-2 pre-review">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star unchecked"></span>
                </div>
                <a href="javascript:void(0)" class="posAbs">
                  <img :src="imgHeart" width="20" height="20" />
                  <!-- <img :src="imgFillHeart" width="20" height="20" /> -->
                </a>
                <p class="paragraph mb-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting Lorem Ipsum is simply Lorem Ipsum is simply dummy
                  text of the printing and typesetting Lorem Ipsum is simply
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 mb-4 text-center">
              <div class="white-box">
                <div>
                  <img :src="imgmascara2" class="img-contain" />
                </div>
                <div class="product-title text-center">
                  Length & Lift Mascara
                </div>
                <div class="py-2 pre-review">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star unchecked"></span>
                </div>
                <a href="javascript:void(0)" class="posAbs">
                  <img :src="imgHeart" width="20" height="20" />
                  <!-- <img :src="imgFillHeart" width="20" height="20" /> -->
                </a>
                <p class="paragraph mb-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting Lorem Ipsum is simply Lorem Ipsum is simply dummy
                  text of the printing and typesetting Lorem Ipsum is simply
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 mb-4 text-center">
              <div class="white-box">
                <div>
                  <img :src="imgmascara1" class="img-contain" />
                </div>
                <div class="product-title text-center">
                  absolute Facelight Pallete
                </div>
                <div class="py-2 pre-review">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star unchecked"></span>
                </div>
                <a href="javascript:void(0)" class="posAbs">
                  <img :src="imgHeart" width="20" height="20" />
                  <!-- <img :src="imgFillHeart" width="20" height="20" /> -->
                </a>
                <p class="paragraph mb-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting Lorem Ipsum is simply Lorem Ipsum is simply dummy
                  text of the printing and typesetting Lorem Ipsum is simply
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 mb-4 text-center">
              <div class="white-box">
                <div>
                  <img :src="imgmascara1" class="img-contain" />
                </div>
                <div class="product-title text-center">
                  Volume & Conditioning Mascara
                </div>
                <div class="py-2 pre-review">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star unchecked"></span>
                </div>
                <a href="javascript:void(0)" class="posAbs">
                  <img :src="imgHeart" width="20" height="20" />
                  <!-- <img :src="imgFillHeart" width="20" height="20" /> -->
                </a>
                <p class="paragraph mb-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting Lorem Ipsum is simply Lorem Ipsum is simply dummy
                  text of the printing and typesetting Lorem Ipsum is simply
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 mb-4 text-center">
              <div class="white-box">
                <div>
                  <img :src="imgmascara2" class="img-contain" />
                </div>
                <div class="product-title text-center">
                  Length & Lift Mascara
                </div>
                <div class="py-2 pre-review">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star unchecked"></span>
                </div>
                <a href="javascript:void(0)" class="posAbs">
                  <img :src="imgFillHeart" width="20" height="20" />
                  <!-- <img :src="imgHeart" width="20" height="20" /> -->
                </a>
                <p class="paragraph mb-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting Lorem Ipsum is simply Lorem Ipsum is simply dummy
                  text of the printing and typesetting Lorem Ipsum is simply
                </p>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 mb-4 text-center">
              <div class="white-box">
                <div>
                  <img :src="imgmascara1" class="img-contain" />
                </div>
                <div class="product-title text-center">
                  absolute Facelight Pallete
                </div>
                <div class="py-2 pre-review">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star unchecked"></span>
                </div>
                <a href="javascript:void(0)" class="posAbs">
                  <img :src="imgFillHeart" width="20" height="20" />
                  <!-- <img :src="imgHeart" width="20" height="20" /> -->
                </a>
                <p class="paragraph mb-0">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting Lorem Ipsum is simply Lorem Ipsum is simply dummy
                  text of the printing and typesetting Lorem Ipsum is simply
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script setup>
import imgmascara1 from "../../../public/static/img/mascara1.svg";
import imgmascara2 from "../../../public/static/img/mascara2.svg";
import imgHeart from "../../../public/static/img/heart.png";
import imgFillHeart from "../../../public/static/img/fill-heart.png";
import imgBackarrow from "../../../public/static/img/back-arrow.svg";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "ViewActivityPage",
  components: { Header, Footer },

  mounted() {},
};
</script>
