<template>
  <div>
    <Header />

    <div class="community-page">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 pb-5">
            <a href="javascript:void(0)" class="back-text" v-on:click="$router.go(-1)">
              <img :src="imgBackarrow" width="25" height="25" /> {{ $t("new.Back") }}
            </a>
          </div>
          <div class="col-md-11 col-sm-11 mx-auto">
            <div class="card-box mb-3">
              <div class="d-flex justify-content-between align-items-center">
                <div
                  class="user d-flex flex-row align-items-center cursor: pointer"
                  v-on:click="$router.push('/user-info/' + postDetail.user.uuid)"
                >
                  <div class="mr-3">
                    <img
                      :src="
                        postDetail.user.image ? postDetail.user.image : profilePlaceholder
                      "
                      style="border-radius: 100px"
                      width="45"
                      height="45"
                    />
                  </div>
                  <div>
                    <span class="client-name">{{ postDetail.user.name }}</span
                    ><br />
                    <span class="green-badge" v-if="postDetail.user.is_verified == 'Y'"
                      ><a href="javascript:void(0)"
                        ><img :src="imgVerifiedg" /> {{ $t("login.Verified") }}
                      </a></span
                    >
                    <br v-if="postDetail.user.is_verified == 'Y'" />
                    <span class="dates">{{ postDetail.created_at }}</span>
                  </div>
                </div>

                <div class="user d-flex flex-row align-items-center">
                  <div class="blue-badge" v-if="postDetail.type == 'POST'">
                    <a href="javascript:void(0)" style="pointer-events: none">{{
                      $t("new.Post")
                    }}</a>
                  </div>
                  <div class="yellow-badge" v-if="postDetail.type == 'DISCUSSION'">
                    <a href="javascript:void(0)" style="pointer-events: none">{{
                      $t("new.Discussion")
                    }}</a>
                  </div>
                  <div v-if="user_id == postDetail.user_id">
                    <a
                      href="javascript:void(0)"
                      class="dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      ><img :src="imgDots" width="24" height="24"
                    /></a>
                    <div class="dropdown-menu">
                      <ul>
                        <li class="mb-1">
                          <a href="javascript:void(0)" v-on:click="editPost()"
                            ><i class="fa fa-edit"></i> {{ $t("new.Edit") }}</a
                          >
                        </li>
                        <li>
                          <a
                            href="#DeleteModal"
                            data-bs-toggle="modal"
                            v-on:click="setId()"
                            ><i class="fa fa-trash"></i> {{ $t("new.Delete") }}</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-3">
                <img
                  :src="postDetail.image ? postDetail.image : placeholder"
                  class="img-fluid comm-banner"
                  width="100%"
                />
              </div>
              <div class="category-title">{{ postDetail.title }}</div>
              <div class="blogs-section py-2">
                <a
                  href="javascript:void(0)"
                  class="category"
                  style="pointer-events: none"
                  >{{ postDetail.topic.title }}</a
                >
              </div>
              <p>{{ postDetail.description }}</p>
              <hr style="border-color: #cacaca" />
              <div class="comments-section" v-if="postDetail.type == 'POST'">
                <span class="mr-4" v-on:click="setLike(postDetail.is_like)">
                  <img
                    :src="imgLike"
                    width="24"
                    height="24"
                    v-if="postDetail.is_like == 0"
                  />
                  <img
                    :src="imgLikeAct"
                    width="24"
                    height="24"
                    v-if="postDetail.is_like == 1"
                  />
                  &nbsp;
                  <a href="javascript:void(0)"
                    >{{ $t("new.Like") }}({{
                      postDetail.like_count < 1 ? 0 : postDetail.like_count
                    }})</a
                  ></span
                >
                <span class="mr-4" v-on:click="getComment('COMMENT', postDetail.id)"
                  ><img :src="imgComments" width="24" height="24" /> &nbsp;
                  <a
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    data-bs-target="#RepliesModal"
                    >{{ $t("new.Comments") }}({{ postDetail.reply_count }})</a
                  ></span
                >
                <span
                  v-on:click="showSharePopup(postDetail.title, postDetail.description)"
                  ><img :src="imgShare" width="24" height="24" /> &nbsp;
                  <a href="javascript:void(0)">{{ $t("share.Share") }}</a></span
                >
              </div>

              <div
                class="comments-section"
                v-if="postDetail.type == 'DISCUSSION' && postDetail.reply_count > 0"
              >
                <span>
                  <a
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    data-bs-target="#RepliesModal"
                    v-on:click="getComment('REPLY', postDetail.id)"
                    ><img :src="imgReplay" width="24" height="24" />&nbsp;
                    {{ postDetail.reply_count }}+ {{ $t("new.Replies") }}
                  </a></span
                >
              </div>
              <div class="d-none d-sm-block" v-if="postDetail.type == 'DISCUSSION'">
                <div class="custom-search w-100 mt-3">
                  <input
                    type="text"
                    class="custom-search-input"
                    :placeholder="$t('new.Type your reply')"
                    style="background: #f3f3f3; border: 0"
                    v-model="postDetail.comment"
                  />
                  <button
                    class="custom-search-botton"
                    style="margin-top: 0"
                    type="button"
                    :disabled="!postDetail.comment"
                    :id="'reply_' + postDetail.id"
                    v-on:click="sendReply()"
                  >
                    {{ $t("new.Reply") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="DeleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div
            class="modal-header border-0 p-0"
            style="display: inline-block; text-align: center"
          >
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img :src="imgClosebtn" width="20" height="20"
              /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="login-form col-md-12 col-sm-12 text-center">
                <img :src="imgDelete" width="90" height="90" />
                <p class="py-2">{{ $t("new.Are you sure, you want to Delete?") }}</p>
                <button
                  type="button"
                  class="fill-btn"
                  data-bs-dismiss="modal"
                  v-on:click="deleteRecord"
                >
                  {{ $t("new.Delete") }}
                </button>
                <button type="button" data-bs-dismiss="modal" class="gery-fill-btn">
                  {{ $t("new.Cancel") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create a Post -->
    <div
      class="modal fade"
      id="createPostModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 600px"
      >
        <div class="modal-content">
          <div class="modal-header" style="display: inline-block; text-align: center">
            <h5 class="modal-title" id="exampleModalLongTitle" v-if="post.type == 'POST'">
              {{ $t("new.Create Post") }}
            </h5>
            <h5 class="modal-title" id="exampleModalLongTitle" v-else>
              {{ $t("new.Start New Discussion") }}
            </h5>
            <button
              type="button"
              class="close"
              id="close-post"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img :src="imgClosebtn" width="20" height="20"
              /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="login-form col-md-12 col-sm-12">
                <Form @submit="update()">
                  <div class="form-label-group" v-if="post.type == 'POST'">
                    <a
                      href="javascript:void(0)"
                      class="private-btn"
                      v-on:click="setPostType('PRIVATE')"
                      v-if="post.post_type == 'PUBLIC'"
                    >
                      <img :src="imgPrivate" width="20" height="20" />

                      {{ $t("new.Private") }}
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="public-btnAct"
                      v-on:click="setPostType('PUBLIC')"
                      v-if="post.post_type == 'PUBLIC'"
                    >
                      <img :src="imgPublicAct" width="20" height="20" />
                      {{ $t("new.Public") }}
                    </a>

                    <a
                      href="javascript:void(0)"
                      class="private-btnAct"
                      v-on:click="setPostType('PRIVATE')"
                      v-if="post.post_type == 'PRIVATE'"
                    >
                      <img :src="imgPrivateAct" width="20" height="20" />
                      {{ $t("new.Private") }}
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="public-btn"
                      v-on:click="setPostType('PUBLIC')"
                      v-if="post.post_type == 'PRIVATE'"
                    >
                      <img :src="imgPublic" width="20" height="20" />
                      {{ $t("new.Public") }}
                    </a>
                  </div>
                  <div class="form-label-group">
                    <Field
                      type="text"
                      id="title"
                      class="form-control"
                      name="title"
                      :placeholder="$t('Community.title')"
                      v-model="post.title"
                      :rules="'required:' + $t('Community.title')"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="title" class="validation-msg" />
                  </div>
                  <div class="form-label-group">
                    <Field
                      type="textarea"
                      name="description"
                      placeholder="t('Community.description')"
                      v-model="post.description"
                      :rules="'required:' + $t('Community.description')"
                      :validateOnInput="true"
                    >
                      <textarea
                        type="text"
                        class="form-control"
                        style="height: 100px; background: #f4f4f4; border: 0"
                        rows="4"
                        v-model="post.description"
                        v-bind="field"
                      />
                    </Field>
                    <ErrorMessage name="description" class="validation-msg" />
                  </div>

                  <div class="form-label-group">
                    <Field
                      v-slot="{ field }"
                      name="topic"
                      :rules="'required:' + $t('Community.topic') + ',true'"
                      :validateOnInput="true"
                      v-model="post.topic_id"
                    >
                      <select
                        v-bind="field"
                        class="form-select form-control"
                        aria-label="Default select example"
                      >
                        <option value="">{{ $t("new.Select Topic") }}</option>
                        <option
                          class="cursor-pointer"
                          :value="item.id"
                          v-for="item in topicList"
                          :key="item.id"
                        >
                          {{ item.title }}
                        </option>
                      </select>
                    </Field>
                    <ErrorMessage name="topic" class="validation-msg" />
                  </div>
                  <div class="form-label-group">
                    <input
                      type="file"
                      name="file"
                      class="form-control pl-3"
                      placeholder="Upload"
                      required="required"
                      @change="selectedFile($event)"
                    />
                  </div>

                  <div class="form-label-group">
                    <button type="submit" id="submit-button" class="btn">
                      {{ $t("new.Post") }}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Rewards Modal -->
    <div
      class="modal fade"
      id="rewardModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 560px"
      >
        <div class="modal-content">
          <div
            class="modal-header p-0 border-0"
            style="display: inline-block; text-align: center"
          >
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img :src="imgClosebtn" width="20" height="20"
              /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 col-sm-12 text-center pt-2">
                <img :src="imgRewards" width="105" height="105" />
                <h3 class="pt-3" style="color: #000">
                  {{ $t("new.Review Posted Successfully") }}
                </h3>
                <h3 class="pt-2" style="color: #00ba75">
                  {{ $t("new.5 Points Earned!") }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Replies Modal -->
    <div
      class="modal fade"
      id="RepliesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 560px"
      >
        <div class="modal-content">
          <div class="modal-header" style="display: inline-block; text-align: center">
            <h5
              v-if="commentType == 'REPLY'"
              class="modal-title"
              id="exampleModalLongTitle"
              style="text-align: left"
            >
              {{ $t("new.Replies") }}
            </h5>
            <h5
              v-if="commentType == 'COMMENT'"
              class="modal-title"
              id="exampleModalLongTitle"
              style="text-align: left"
            >
              {{ $t("new.Comments") }}
            </h5>
            <button
              type="button"
              class="close"
              id="forgot-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img :src="imgClosebtn" width="20" height="20"
              /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12 col-sm-12">
              <div
                class="mb-3 border-bottom pb-3"
                v-for="(data, index) in commentList"
                :key="index"
              >
                <div class="card-body">
                  <div class="d-flex flex-start">
                    <img
                      class="rounded-circle shadow-1-strong me-3"
                      :src="
                        data.userInfo.image ? data.userInfo.image : profilePlaceholder
                      "
                      alt="avatar"
                      width="40"
                      height="40"
                    />
                    <div class="w-100">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <p
                          class="text-base mb-0 fw-bold"
                          style="color: #101828; font-size: 16px"
                        >
                          {{ data.userInfo.name }}
                        </p>
                        <p class="mb-0 tag-line" style="opacity: 0.5">
                          {{ data.created_at }}
                        </p>
                      </div>
                      <p class="tag-line mb-0">{{ data.comment }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center" v-if="commentList.length < 1">
                <strong>{{ $t("new.No comments found") }}</strong>
              </div>
              <div class="custom-search w-100 mt-3" v-if="commentType == 'COMMENT'">
                <input
                  type="text"
                  class="custom-search-input"
                  :placeholder="$t('new.Type your reply')"
                  style="background: rgb(243, 243, 243); border: 0px none"
                  v-model="comment"
                />
                <button
                  class="custom-search-botton"
                  type="button"
                  style="background: #00ba75 !important; margin-top: 0"
                  :disabled="!comment"
                  id="comment"
                  v-on:click="sendComment()"
                >
                  {{ $t("new.Reply") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a data-bs-toggle="modal" href="#createPostModal" id="createPostModalLink" />
    <a data-bs-toggle="modal" href="#rewardModal" id="rewardModalLink" />
    <ShareModal ref="shareModal" />
    <Footer />
  </div>
</template>

<script setup>
import imgBackarrow from "../../../public/static/img/back-arrow.svg";
import imgDots from "../../../public/static/img/dots.svg";
import imgLike from "../../../public/static/img/like-icon.svg";
import imgLikeAct from "../../../public/static/img/like-act.svg";
import imgComments from "../../../public/static/img/comment-icon.svg";
import imgShare from "../../../public/static/img/share-icon.svg";
import profilePlaceholder from "../../../public/static/img/profilePlaceholder.png";
import imgPrivate from "../../../public/static/img/private.svg";
import imgPublic from "../../../public/static/img/public.svg";
import imgPublicAct from "../../../public/static/img/public-act.svg";
import imgPrivateAct from "../../../public/static/img/private-act.svg";
import placeholder from "../../../public/static/img/Placeholde-bg.jpg";
import imgVerifiedg from "../../../public/static/img/verified-green.svg";
import imgDelete from "../../../public/static/img/delete.png";
import imgClosebtn from "../../../public/static/img/close-btn.svg";
import imgReplay from "../../../public/static/img/replay-icon.svg";
import imgRewards from "../../../public/static/img/rewards.svg";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ShareModal from "../../components/SocialShare/ShareModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import $ from "jquery";
export default {
  name: "CommunityDetailPage",
  components: { Header, Footer, ShareModal, Form, Field, ErrorMessage },
  data() {
    return {
      slug: this.$route.params.slug,
      postDetail: { user: {}, topic: {} },
      post: {
        type: "",
      },
      user_id: localStorage.getItem("userId"),
      commentType: "",
      comment: "",
      commentId: "",
      deleteId: "",
      url: window.location.href,
      commentList: [],
      topicList: [],
    };
  },
  computed: {
    user() {
      return this.$storeService.getters.getUser;
    },
  },

  watch: {
    user(newValue) {
      console.log(newValue);
      if (localStorage.getItem("access_token")) {
        this.someFunction(newValue);
      }
    },
  },
  mounted() {
    this.getMetaContent();
    this.getpostDetail();
    this.getTopicList();
  },
  methods: {
    someFunction(newValue) {
      console.log(newValue);
      this.user_id = localStorage.getItem("userId");
    },
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/11",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    getTopicList() {
      this.topicList = [];
      this.isLoader = true;

      this.$api
        .webRequest({
          _method: "GET",
          _action: "topic/all",
          _body: this.searchObj,
        })
        .then((res) => {
          this.isLoader = false;
          for (var i in res.list) {
            res.list[i].title = this.$helperService.getName(res.list[i].title);
            this.topicList.push(res.list[i]);
          }
          this.isLoader = false;
        })
        .catch(() => {
          this.isLoader = false;
        });
    },
    getpostDetail() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/get-post/" + this.slug,
        })
        .then((res) => {
          this.postDetail = res.info;
          this.postDetail.created_at = moment(this.postDetail.created_at).format(
            "DD MMM YYYY"
          );
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showSharePopup(title, description) {
      if (!localStorage.getItem("access_token")) {
        this.$refs.shareModal.showModal(this.url, title, description);
      } else {
        this.$refs.shareModal.showModal(this.url, title, description);
        this.shareCall();
      }
    },
    shareCall() {
      // var obj = {};
      // obj.product_id = this.detail.product_id;
      // this.$api
      //   .postAPI({
      //     _action: "/shared/post",
      //     _body: obj,
      //   })
      //   .then((res) => {
      //         this.$toast.success(res.message, { position: "top-right" });
      //   })
      //   .catch(() => { });
    },
    editPost() {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.post = this.postDetail;
        console.log(this.post);
        document.getElementById("createPostModalLink").click();
      }
    },
    setId() {
      this.deleteId = this.postDetail.id;
    },
    deleteRecord() {
      this.$api
        .webRequest({
          _method: "DELETE",
          _action: "delete-post/" + this.deleteId,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.$router.push("/community");
        })
        .catch(() => {});
    },

    sendReply() {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        var button_id = "reply_" + this.postDetail.id;
        this.$api
          .webRequest({
            _method: "POST",
            _action: "post/comment",
            _body: { post_id: this.postDetail.id, comment: this.postDetail.comment },
            _buttonId: button_id,
          })
          .then(() => {
            this.postDetail.comment = "";
            this.postDetail.reply_count = this.postDetail.reply_count + 1;
            if (this.postDetail.type == "DISCUSSION") {
              document.getElementById("rewardModalLink").click();
            }
          })
          .catch(() => {});
      }
    },
    sendComment() {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.$api
          .webRequest({
            _method: "POST",
            _action: "post/comment",
            _body: { post_id: this.commentId, comment: this.comment },
            _buttonId: "comment",
          })
          .then(() => {
            this.comment = "";
            this.getComment("COMMENT", this.commentId);
          })
          .catch(() => {});
      }
    },
    setLike(flag) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        this.$api
          .webRequest({
            _method: "POST",
            _action: "post/like",
            _body: { post_id: this.postDetail.id },
          })
          .then((res) => {
            this.postDetail.like_count = res.count;
            if (flag == 0) {
              this.postDetail.is_like = 1;
            }
            if (flag == 1) {
              this.postDetail.is_like = 0;
            }
          })
          .catch(() => {});
      }
    },
    getComment(type, id) {
      this.commentType = type;
      this.commentId = id;
      this.commentList = [];
      this.isLoader = true;

      this.$api
        .webRequest({
          _method: "GET",
          _action: "post/comment/list/" + id,
        })
        .then((res) => {
          this.isLoader = false;
          for (var i in res.list) {
            res.list[i].created_at = moment(res.list[i].created_at).format("DD MMM YYYY");
            this.commentList.push(res.list[i]);
          }
          this.isLoader = false;
        })
        .catch(() => {
          this.isLoader = false;
        });
    },
    update() {
      var url = "add-post";
      var method = "POST";
      if (this.post.id) {
        url = "edit-post/" + this.post.id;
        method = "POST";
      }
      this.$api
        .webRequest({
          _method: method,
          _action: url,
          _body: this.post,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            this.post.id = res.id;
            document.getElementById("close-post").click();
            if (this.file) {
              this.uploadImage();
            } else {
              this.$toast.success(res.message, { position: "top-right" });
              this.getpostDetail();
            }
          }
        })
        .catch(() => {});
    },

    uploadImage() {
      var obj = {};
      obj.id = this.post.id;
      this.$api
        .uploadImageAPI({
          _action: "/post/photo",
          _key: "image",
          _file: this.file,
          _body: obj,
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.getpostDetail();
        });
    },
  },
};
</script>

