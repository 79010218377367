import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import api from "./services/WebService";
import "./services/ValidationRules";
import sharedService from "./services/ShareService";
import helperService from "./services/HelperService";
import storeService from "./services/StoreService";
import Notifications from "@kyvg/vue3-notification";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import mitt from "mitt";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap-vue/dist/bootstrap-vue";
import "../public/static/css/styles.css";
import "../public/static/css/media.css";
import GAuth from 'vue3-google-oauth2';

import {
    createI18n
} from "vue-i18n";
import messages from "./messages.js";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const i18n = createI18n({
    locale: localStorage.getItem('language') ? localStorage.getItem('language').toLowerCase() : "en",
    fallbackLocale: localStorage.getItem('language') ? localStorage.getItem('language').toLowerCase() : "en",
    messages,
});

const emitter = mitt();
const app = createApp(App);
app.use(i18n);
app.component('VueDatePicker', VueDatePicker);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$helperService = helperService;
app.config.globalProperties.$storeService = storeService;
app.config.globalProperties.$api = api;
app.config.globalProperties.$sharedService = sharedService;
app.use(ToastPlugin);
app.use(router);
app.use(Notifications);
app.mount("#app");
const gAuthOptions = {
    clientId: '1055046517499-5q6o0p2oip606985n740nq0sphf747km.apps.googleusercontent.com',
    scope: 'email',
    prompt: 'consent',
    fetch_basic_profile: false
}
app.use(GAuth, gAuthOptions)
