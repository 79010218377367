<template>
  <section class="highest-rated-section" v-if="recommendationCount > 0">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 py-4 pb-none mt-3">
          <h2 class="h2-title">{{ $t("recommendation.Our Recommendations") }}</h2>
        </div>
      </div>
      <div class="d-none d-sm-block">
        <div class="row h-100">
          <div
            class="col-md-4 col-sm-4 mb-4 cursor"
            v-on:click="gotoPage('RECOMM', recommendationList[0].product, $event)"
          >
            <div class="white-box" style="min-height: auto;">
              <div class="d-flex text-lf">
                <div>
                  <img
                    :src="
                      recommendationList[0].product.image
                        ? recommendationList[0].product.image
                        : productPlaceholder
                    "
                    class="img-contain"
                  />
                </div>
                <div class="my-auto">
                  <div class="product-title">
                    {{ recommendationList[0].product.name }}
                  </div>
                  <div class="">
                    <star-rating
                      id="expert-list"
                      class="mb-1 text-left"
                      :increment="0.5"
                      :rating="recommendationList[0].product.avg_rating"
                      :show-rating="false"
                      :star-size="15"
                      :read-only="true"
                    ></star-rating>
                  </div>
                </div>
              </div>
              <a href="javascript:void(0)" class="posAbs">
                <img
                  :src="imgHeart"
                  data-name="FAV"
                  v-if="recommendationList[0].is_favorite == '0'"
                  width="20"
                  height="20"
                />
                <img
                  data-name="FAV"
                  v-if="recommendationList[0].is_favorite == '1'"
                  :src="imgFillHeart"
                  width="20"
                  height="20"
                />
              </a>
            </div>
          </div>
          <div class="col-md-8 col-sm-8">
            <div class="row">
              <template v-for="(data, index) in recommendationList" :key="index">
                <div
                  class="col-md-6 col-sm-6 mb-4 cursor"
                  v-if="index > 0"
                  v-on:click="gotoPage('RECOMM', data.product, $event)"
                >
                  <div class="white-box" style="min-height: auto;">
                    <div class="d-flex justify-content-between">
                      <div>
                        <img
                          :src="
                            data.product.image ? data.product.image : productPlaceholder2
                          "
                          class="img-contain"
                        />
                      </div>
                      <div class="my-auto">
                        <div class="product-title">
                          {{ data.product.name }}
                        </div>
                        <div class="text-left">
                          <star-rating
                            id="expert-list"
                            class="mb-1 text-left"
                            :increment="0.5"
                            :rating="data.product.avg_rating"
                            :show-rating="false"
                            :star-size="15"
                            :read-only="true"
                          ></star-rating>
                        </div>
                      </div>
                    </div>
                    <a href="javascript:void(0)" class="posAbs">
                      <img
                        :src="imgHeart"
                        data-name="FAV"
                        v-if="data.is_favorite == '0'"
                        width="20"
                        height="20"
                      />
                      <img
                        data-name="FAV"
                        v-if="data.is_favorite == '1'"
                        :src="imgFillHeart"
                        width="20"
                        height="20"
                      />
                    </a>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile Highest Rated slider here -->
      <div class="d-block d-sm-none">
        <div class="row h-100 highest-rated-slider">
          <div
            class="col-md-4 col-sm-4 mb-4"
            v-for="(data, index) in recommendationList"
            :key="index"
          >
            <div class="white-box" style="min-height: auto;">
              <div class="d-flex text-lf cursor">
                <div>
                  <img
                    :src="data.image ? data.image : productPlaceholder2"
                    class="img-contain"
                  />
                </div>
                <div class="my-auto">
                  <div class="product-title">{{ data.product.name }}</div>
                  <div class="text-left">
                    <star-rating
                      id="expert-list"
                      class="mb-1 text-left"
                      :increment="0.5"
                      :rating="data.avg_rating"
                      :show-rating="false"
                      :star-size="15"
                      :read-only="true"
                    ></star-rating>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row mt-4">
          <div class="col-md-12 col-sm-12 txt-right">
            <a href="javascript:void(0)">
              View All Products
              <img :src="imgGarrow" width="20" height="20" style="border: 0"
            /></a>
          </div>
        </div> -->
    </div>
  </section>
</template>

<script setup>
import productPlaceholder from "../../public/static/img/productPlaceholder.png";
import productPlaceholder2 from "../../public/static/img/ProductPlaceholder2.png";
import imgHeart from "../../public/static/img/heart.png";
import imgFillHeart from "../../public/static/img/fill-heart.png";
</script>
<script>
import StarRating from "vue-star-rating";
export default {
  name: "BlogView",
  components: {StarRating},
  data() {
    return {
      recommendationCount: 0,
      recommendationList: [],
      searchObj: {
        keyword: "",
        offset: 0,
      },
      token: localStorage.getItem("access_token"),
    };
  },

  computed: {
    user() {
      return this.$storeService.getters.getUser;
    },
  },

  watch: {
    user() {
      this.getRecommendation(0);
    },
  },

  mounted() {
    this.getRecommendation(0);
  },

  methods: {
    getRecommendation(offset) {
      if (offset) {
        this.searchObj.offset = offset;
        this.searchObj.limit = this.itemsPerPage;
      }
      if (localStorage.getItem("access_token")) {
        this.$api
          .webRequest({
            _method: "GET",
            _action: "/recommended/product/list?limit=3",
            _body: this.searchObj,
          })
          .then((res) => {
            this.loader = false;
            for (var i in res.list) {
              res.list[i].product.name = this.$helperService.getName(
                res.list[i].product.name
              );
            }
            this.recommendationCount = res.count;
            this.recommendationList = res.list;
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        this.recommendationCount = 0;
        this.recommendationList = [];
      }
    },
    gotoPage: function (type, data, event) {
      var action = event.target.getAttribute("data-name");
      if (action && action == "FAV") {
        this.addFavorite(type, data);
      } else {
        this.$router.push("/product/" + data.slug);
      }
    },
    addFavorite(type, data) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        var obj = {};
        obj.product_id = data.id;
        this.$api
          .webRequest({
            _method: "POST",
            _action: "/favourite",
            _body: obj,
          })
          .then((res) => {
            this.$toast.success(res.message, { position: "top-right" });
            if (type == "RECOMM") {
              this.getRecommendation(0);
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.vue-star-rating{
  text-align: left !important;
}
</style>