<template>
  <div>
    <Header />
    <div class="profile-page">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-sm-8 mx-auto">
            <div class="sm-card-box">
              <h1>{{ $t("new.Profile") }}</h1>
              <div class="row">
                <div class="col-md-2 col-sm-2 mb-3">
                  <img
                    :src="detail.image ? detail.image : profilePlaceholder"
                    class="img-fluid"
                    width="65"
                    height="65"
                  />
                </div>
                <div class="col-md-10 col-sm-10">
                  <div class="d-flex justify-content-between">
                    <div>
                      <div>{{ detail.name }}</div>
                      <div class="mt-2 sm-text">{{ detail.email }}</div>
                      <div class="mt-2" v-if="detail.is_verified == 'Y'">
                        <span class="green-badge">
                          <a href="javascript:void(0)">
                            <img :src="imgVerifiedg" /> {{ $t("login.Verified") }}
                          </a>
                        </span>
                      </div>
                    </div>
                    <div>
                      <div class="mt-2 sm-text">{{ $t("new.UserName") }}</div>
                      <div class="md-text">{{ detail.user_name }}</div>
                    </div>

                    <div>
                      <div class="mt-2 sm-text">{{ $t("signup.Country") }}</div>
                      <div class="md-text">{{ detail.country.name }}</div>
                    </div>
                    <div class="pt-3">
                      <a
                        href="javascript:void(0)"
                        v-on:click="$router.push('edit-profile')"
                      >
                        <img :src="imgPencil" width="25" height="25" />
                      </a>
                    </div>
                  </div>
                  <hr style="opacity: 0.1" />
                  <div class="sm-text">
                    {{ detail.about_us ? detail.about_us : "-" }}
                  </div>
                  <hr style="opacity: 0.1" />
                  <div class="d-flex justify-content-between">
                    <div>
                      <div class="sm-text">{{ $t("myProfile.Hair Style") }}</div>
                      <div class="md-text">
                        {{ detail.hair_style ? detail.hair_style : "-" }}
                      </div>
                    </div>
                    <div>
                      <div class="sm-text">{{ $t("myProfile.Instagram") }}</div>
                      <div class="md-text">
                        {{ detail.instagram ? detail.instagram : "-" }}
                      </div>
                    </div>
                    <div>
                      <div class="sm-text">{{ $t("new.Phone") }}</div>
                      <div class="md-text">
                        <!-- <img :src="imgFlag" width="27" height="27" /> -->
                        {{ detail.mobile_number }}
                      </div>
                    </div>
                  </div>
                  <hr style="opacity: 0.1" />
                  <div class="colorPic">
                    <div class="sm-text">{{ $t("customerDetail.Skin Tone") }}</div>
                    <div class="d-flex justify-content-between mb15">
                      <a href="#"
                        ><span
                          class="clr1 col"
                          :class="detail.skin_tone == '#F1D8CD' ? 'active' : ''"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr2 col"
                          :class="detail.skin_tone == '#EFD2C5' ? 'active' : ''"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr3 col"
                          :class="detail.skin_tone == '#EDCBBD' ? 'active' : ''"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr4 col"
                          :class="detail.skin_tone == '#BA988A' ? 'active' : ''"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr5 col"
                          :class="detail.skin_tone == '#A28578' ? 'active' : ''"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr6 col"
                          :class="detail.skin_tone == '#745F56' ? 'active' : ''"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr7 col"
                          :class="detail.skin_tone == '#5D4C45' ? 'active' : ''"
                          >&nbsp;</span
                        ></a
                      >
                      <a href="#"
                        ><span
                          class="clr8 col"
                          :class="detail.skin_tone == '#2E2622' ? 'active' : ''"
                          >&nbsp;</span
                        ></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import imgVerifiedg from "../../../public/static/img/verified-green.svg";
import imgPencil from "../../../public/static/img/pencil.svg";
// import imgFlag from "../../../public/static/img/india-flag.svg";
import profilePlaceholder from "../../../public/static/img/profilePlaceholder.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import $ from "jquery";
export default {
  name: "ProfilePage",
  components: { Header, Footer },
  data() {
    return {
      loader: false,
      id: this.$route.params.id,
      countryList: [],
      file: undefined,
      alergic_list: [""],
      favorite_list: [""],
      detail: {
        country: {},
        alergic_ingredients: "",
        favorite_ingredients: "",
      },
      selectedAlergicIngredient: [],
      selectedFavoriteIngredient: [],
    };
  },
  mounted() {
    this.getMetaContent();
    this.meCall();
  },
  methods: {
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/10",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    meCall() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "users/me",
        })
        .then((res) => {
          this.loader = false;
          this.detail = res;

          // this.getIngredientList();
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, " ");
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
          }
        });
    },
  },
};
</script>
