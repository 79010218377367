<template>
  <div>
    <Header />
    <div class="profile-page">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-sm-8 mx-auto">
            <div class="sm-card-box">
              <h1>{{ $t("Footer.Contact Us") }}</h1>
              <Form @submit="submit">
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="form-group mb-3">
                      <label for="name">{{ $t("contact.Name") }}</label>
                      <Field
                        type="text"
                        id="name"
                        class="form-control"
                        name="name"
                        :placeholder="$t('contact.Name')"
                        v-model="contact.name"
                        :rules="'required:' + $t('contact.Name')"
                        :validateOnInput="true"
                      />
                    </div>
                    <ErrorMessage name="name" class="validation-msg"></ErrorMessage>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <label>{{ $t("contact.Email") }}</label>
                    <div class="form-group mb-3">
                      <Field
                        type="email"
                        id="email"
                        class="form-control"
                        name="email"
                        :placeholder="$t('contact.Email')"
                        v-model="contact.email"
                        :rules="'required|email:' + $t('contact.Email')"
                        :validateOnInput="true"
                      />
                    </div>
                    <ErrorMessage name="email" class="validation-msg" />
                  </div>
                  <div class="col-md-12 col-sm-12">
                    <div class="form-group mb-3">
                      <label>{{ $t("contact.Subject") }} </label>
                      <Field
                        type="text"
                        id="subject"
                        class="form-control"
                        name="subject"
                        :placeholder="$t('contact.Subject')"
                        v-model="contact.subject"
                        :rules="'required:' + $t('contact.Subject')"
                        :validateOnInput="true"
                      />
                    </div>

                    <ErrorMessage name="subject" class="validation-msg" />
                  </div>
                  <div class="col-md-12 col-sm-12">
                    <label>{{ $t("contact.Message") }}</label>
                    <div class="form-group mb-3">
                      <Field
                        as="textarea"
                        id="contact_message"
                        style="width: 100%"
                        rows="4"
                        class="form-control"
                        name="contact_message"
                        :placeholder="$t('contact.Message')"
                        v-model="contact.message"
                        :rules="'required:' + $t('contact.Message')"
                        :validateOnInput="true"
                      />
                    </div>
                    <ErrorMessage name="contact_message" class="validation-msg" />
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <vue-recaptcha
                        sitekey="6Lc8M-IpAAAAABr7JdA6j835kKyKmbZ39c_yWsM7"
                        v-on:verify="recaptchaUpdated"
                        :loadRecaptchaScript="true"
                        hl="en"
                      >
                      </vue-recaptcha>
                    </div>
                  </div>
                  <div class="col-md-12 col-sm-12 text-center pt-3">
                    <button type="submit" id="submit-button" class="fill-btn">
                      {{ $t("review.Submit") }}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup></script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { Form, Field, ErrorMessage } from "vee-validate";
import vueRecaptcha from "vue3-recaptcha2";
export default {
  name: "ContactUsPage",
  components: { Header, Footer, Form, Field, ErrorMessage, vueRecaptcha },
  data() {
    return {
      contact: {
        email: "",
        name: "",
        subject: "",
        message: "",
      },
      recaptcha: null,
      isCaptcha: false,
    };
  },
  mounted() {},
  methods: {
    recaptchaUpdated(recaptcha) {
      console.log(recaptcha);
      this.isCaptcha = true;
    },
    submit() {
      if (!this.isCaptcha) {
        return this.$notify({
          type: "error",
          text: "Please verify captcha",
        });
      }

      this.$api
        .webRequest({
          _method: "POST",
          _action: "/contact-us",
          _body: this.contact,
          _buttonId: "submit-button",
        })
        .then(() => {
          this.contact.name = "";
          this.contact.email = "";
          this.contact.subject = "";
          this.contact.message = "";

          if (this.user) {
            this.contact.name = this.user.name;
            this.contact.email = this.user.email;
          }
          this.$refs.reviewModal.closeModal();
        })
        .catch(() => {});
    },
  },
};
</script>
